import React, { useState, useEffect, useCallback, KeyboardEvent } from 'react';
import { Box, Paper, Typography, TextField, Button, Grid, IconButton, Tooltip, Tabs, Tab, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Stepper, Step, StepLabel, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Alert, Select, MenuItem, FormControlLabel, Checkbox, LinearProgress, Snackbar } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Components } from 'react-markdown';
import { DataGridPro, GridColDef, GridRowsProp, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';
import { GenNode, ImportSettings as BaseImportSettings, ExtendedGenNode } from '../types';
import { Folder, Description, ExpandMore, FiberManualRecord as DotIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, Refresh as RefreshIcon, PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled, alpha } from '@mui/material/styles';
import { DragEvent } from 'react';
import { Button as MuiButton } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

// Helper function to create API endpoint paths properly
const getApiEndpoint = (endpoint: string) => {
  // API_URL already includes /secret, just add /api prefix
  if (endpoint.startsWith('/')) {
    return `${API_URL}/api${endpoint}`;
  }
  return `${API_URL}/api/${endpoint}`;
};

// Helper function to get Carbon session ID from localStorage
const getCarbonSessionId = (): string | null => {
  try {
    const sessionInfo = localStorage.getItem('sessionInfo');
    if (sessionInfo) {
      const parsed = JSON.parse(sessionInfo);
      return parsed.sessionId || null;
    }
    return null;
  } catch (error) {
    console.error('Error getting session ID:', error);
    return null;
  }
};

// Add this type definition at the top of your file, after the imports
type ChatMessage = {
  role: 'user' | 'assistant';
  content: string;
};

// Add this component after your imports and before the CoPilot component
const MarkdownRenderer: React.FC<{ 
  content: string;
  setThemes: React.Dispatch<React.SetStateAction<Array<{ id: number; text: string }>>>;
  setChatTab: React.Dispatch<React.SetStateAction<'chat' | 'themeTagger'>>;
  primaryVariable: string | null;
  gridRows: GridRowsProp;
  setChatHistory: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  setIsChatLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setChatError: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ content, setThemes, setChatTab, primaryVariable, gridRows, setChatHistory, setIsChatLoading, setChatError }) => {
  const [hasOrderedList, setHasOrderedList] = useState(false);
  const [extractedThemes, setExtractedThemes] = useState<Array<{ id: number; text: string }>>([]);

  useEffect(() => {
    // Check for numbered list with bold items (no descriptions)
    const containsOrderedList = /^\d+\.\s\*\*.+\*\*$/m.test(content);
    setHasOrderedList(containsOrderedList);
    
    // Extract themes when content changes and has ordered list
    if (containsOrderedList) {
      extractThemesFromContent();
    }
  }, [content]);

  const extractThemesFromContent = () => {
    const themes = content.split('\n')
      .filter(line => /^\d+\.\s\*\*.+\*\*$/.test(line))
      .map((line, index) => {
        const match = line.match(/^(\d+)\.\s\*\*(.+?)\*\*$/);
        return {
          id: Date.now() + index,
          text: match ? match[2].trim() : ''
        };
      })
      .filter(theme => theme.text !== '');

    // Add "Not interested" option at the end
    const notInterestedTheme = {
      id: Date.now() + themes.length,
      text: "Not interested / Answer not clear"
    };
    
    const finalThemes = [...themes, notInterestedTheme];
    setExtractedThemes(finalThemes);
  };

  const moveToThemeTagger = () => {
    setThemes(extractedThemes);
    setChatTab('themeTagger');
  };

  const getThemeJustifications = async () => {
    if (!primaryVariable || gridRows.length === 0 || extractedThemes.length === 0) return;
    
    setIsChatLoading(true);
    setChatError(null);
    
    try {
      // Extract question and format responses
      const questionKey = Object.keys(gridRows[0]).find(key => key !== 'id');
      const question = questionKey || '';
      const formattedData = gridRows.map((row, index) => ({
        id: index + 1,
        response: row[questionKey || '']
      }));
      
      // Get extracted theme names with indices for reliable reference
      const themesList = extractedThemes
        .filter(theme => theme.text !== "Not interested / Answer not clear")
        .map((theme, index) => ({ index: index + 1, text: theme.text }));
      
      // Create the justification request - but don't add it to chat history
      const justificationPrompt = `Provide detailed justification with examples for each of these numbered themes that were identified in the responses:\n${themesList.map(theme => `${theme.index}. **${theme.text}**`).join('\n')}`;
      
      // Construct messages for justification request
      const messages = [
        {
          role: 'system',
          content: 'You are an expert at analyzing text data and providing evidence for themes. For each theme, referenced by its theme number from the list I provide:\n\n1. Provide a brief description of what the theme encompasses\n2. Give 2-3 specific examples from the data that demonstrate this theme\n3. Format your response as a numbered list that preserves the original theme numbers, with bold titles and detailed descriptions, e.g.:\n   1. **Theme One**: Description with examples from the data "example quote 1", "example quote 2"\n   3. **Theme Three**: Description with examples from the data "example quote 3", "example quote 4"'
        },
        {
          role: 'user',
          content: `Question being analyzed: "${question}"\n\nResponses to analyze (${formattedData.length} records):\n\n${formattedData.map(item => `${item.id}. "${item.response}"`).join('\n\n')}`
        },
        {
          role: 'user', 
          content: justificationPrompt
        }
      ];
      
      console.log('Sending justification request:', messages);
      
      // Get session ID from localStorage
      const sessionId = getCarbonSessionId();
      
      // Set max timeout to 60 seconds
      const response = await axios({
        method: 'post',
        url: getApiEndpoint('/v1/chat/completions'),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        },
        data: {
          model: "llama3.1:8b-instruct-q8_0",
          stream: false,
          max_tokens: 6048,
          messages: messages,
          session_id: sessionId
        },
        timeout: 60000 // 60 second timeout
      });
      
      console.log('Justification API response:', response.data);
      
      // Process and add the response to chat history
      let assistantMessage: ChatMessage;
      if (response.data.choices && response.data.choices[0] && response.data.choices[0].message) {
        assistantMessage = { 
          role: 'assistant', 
          content: response.data.choices[0].message.content 
        };
      } else if (typeof response.data === 'string') {
        assistantMessage = { 
          role: 'assistant', 
          content: response.data 
        };
      } else {
        throw new Error('Unexpected response structure from API');
      }
      
      // Only add the assistant response to the chat history (not the user message)
      setChatHistory(prev => [
        ...prev, 
        // Add a system message explaining what was generated
        { 
          role: 'assistant', 
          content: `### Theme Justifications\n\n${assistantMessage.content}` 
        }
      ]);
      
    } catch (error: any) {
      console.error('Justification error:', error);
      let errorMessage = 'Failed to get theme justifications';
      
      if (error.code === 'ECONNABORTED' || error.code === 'ETIMEDOUT') {
        errorMessage = `Request timed out. The server took too long to respond.`;
      } else if (error.response) {
        errorMessage = `Error ${error.response.status}: ${
          error.response.data?.error || error.response.data?.message || error.message
        }`;
      } else if (error.request) {
        errorMessage = `No response received from server. Please check your connection and try again.`;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      
      setChatError(errorMessage);
      // Only add the error as an assistant message, not a user message
      setChatHistory(prev => [...prev, { role: 'assistant', content: `### Error Getting Theme Justifications\n\n${errorMessage}` }]);
    } finally {
      setIsChatLoading(false);
    }
  };

  return (
    <>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      {hasOrderedList && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          <Button
            variant="contained"
            color="info"
            size="small"
            onClick={getThemeJustifications}
            sx={{ width: '100%' }}
          >
            Get Theme Justifications
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={moveToThemeTagger}
            sx={{ width: '100%' }}
          >
            Move to Theme Tagger
          </Button>
        </Box>
      )}
    </>
  );
};

// Update the interface for middleware data
interface MiddlewareData {
  exists: boolean;
  hasData: boolean;
  recordCount: number;
  totalInterviewCount: number;
  isComplete: boolean;
  uniqueInterviewCount: number;
  variableCount: number;
  variableIds: string[];
  totalCases?: number; // Add the new total_cases field
  runId?: string; // Add runId field to track which run this data belongs to
  status?: string; // Add status field for the new format
  error?: string;  // Add error field to capture any failure messages
}

// Add type definition for analysis runs
type AnalysisRunSummary = { 
  run_id: string; 
  run_name: string; 
  analysis_type: string; 
  status: string; 
  created_at: string; 
};

// Add this component for handling progress updates
const ClassificationProgress = React.memo(({ data }: { data: MiddlewareData }) => {
  // Calculate percentage based on the structure of the data
  // For new run status data format
  let percentage = 0;
  if (data.status && data.totalInterviewCount) {
    percentage = Math.min((data.recordCount / data.totalInterviewCount) * 100, 100);
  } 
  // For legacy format
  else if (data.uniqueInterviewCount > 0) {
    percentage = Math.min((data.recordCount / data.uniqueInterviewCount) * 100, 100);
  }
  
  // Calculate the percentage of total cases processed
  const totalCasesPercentage = data.totalCases && data.totalCases > 0
    ? Math.min((data.recordCount / data.totalCases) * 100, 100)
    : percentage;
  
  // Display status badge if available
  const statusBadge = data.status ? (
    <Box 
      sx={{ 
        px: 1, 
        py: 0.5, 
        borderRadius: '10px', 
        fontSize: '0.75rem',
        display: 'inline-block',
        bgcolor: 
          data.status === 'COMPLETED' ? 'success.light' : 
          data.status === 'FAILED' ? 'error.light' : 
          data.status === 'RUNNING' ? 'info.light' : 'warning.light',
        color: 'white',
        ml: 1
      }}
    >
      {data.status}
    </Box>
  ) : null;
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="body2" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
        Records processed: <span style={{ display: 'inline-block', minWidth: '60px', marginLeft: '4px' }}>{data.recordCount || 0}</span> / {data.totalInterviewCount || data.uniqueInterviewCount || 0}
        {statusBadge}
      </Typography>
      {data.totalCases && data.totalCases !== data.totalInterviewCount && (
        <Typography variant="body2">
          Total cases: {data.totalCases || 0}
        </Typography>
      )}
      <LinearProgress 
        variant="determinate" 
        value={percentage}
        sx={{ 
          height: 8, 
          borderRadius: 1,
          bgcolor: data.status === 'FAILED' ? 'rgba(244, 67, 54, 0.2)' : undefined
        }}
        color={data.status === 'FAILED' ? 'error' : 'primary'}
      />
    </Box>
  );
});

// Extend the ImportSettings interface to include run_id
interface ImportSettings extends BaseImportSettings {
  run_id?: string;
}

// Add this component to display analysis runs
const AnalysisRunsList: React.FC<{
  runs: AnalysisRunSummary[];
  currentRunId: string | null;
  onSelectRun: (runId: string) => void;
  onDeleteRun: (runId: string, runName: string) => void;
  onStartRun: (runId: string, analysisType: string) => void; // Add this prop
  isLoadingRuns: boolean;
}> = ({ runs, currentRunId, onSelectRun, onDeleteRun, onStartRun, isLoadingRuns }) => {
  if (isLoadingRuns) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (runs.length === 0) {
    return (
      <Typography variant="body2" sx={{ py: 1 }}>
        No analysis runs found for this job.
      </Typography>
    );
  }

  return (
    <List dense sx={{ maxHeight: '200px', overflow: 'auto' }}>
      {runs.map((run) => (
        <ListItem 
          key={run.run_id}
          selected={run.run_id === currentRunId}
          onClick={() => onSelectRun(run.run_id)}
          sx={{ 
            borderLeft: run.run_id === currentRunId ? '3px solid #1976d2' : '3px solid transparent',
            bgcolor: run.run_id === currentRunId ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
            cursor: 'pointer'
          }}
        >
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" fontWeight={run.run_id === currentRunId ? 'bold' : 'normal'}>
                  {run.run_name || `Run ${run.run_id.substring(0, 8)}`}
                </Typography>
                <Box 
                  sx={{ 
                    ml: 1, 
                    px: 1, 
                    borderRadius: '10px', 
                    fontSize: '0.7rem',
                    bgcolor: 
                      run.status === 'completed' ? 'success.light' : 
                      run.status === 'failed' ? 'error.light' : 
                      run.status === 'processing' ? 'info.light' : 'warning.light',
                    color: 'white'
                  }}
                >
                  {run.status}
                </Box>
              </Box>
            }
            secondary={
              <Typography variant="caption" display="block">
                {new Date(run.created_at).toLocaleString()} - {run.analysis_type}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            {/* Add start button for pending runs */}
            {run.status === 'PENDING' && (
              <IconButton 
                edge="end" 
                aria-label="start" 
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onStartRun(run.run_id, run.analysis_type);
                }}
                sx={{ mr: 1 }}
                title="Start Run"
              >
                <PlayArrowIcon fontSize="small" color="success" />
              </IconButton>
            )}
            <IconButton 
              edge="end" 
              aria-label="delete" 
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteRun(run.run_id, run.run_name || `Run ${run.run_id.substring(0, 8)}`);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export const CoPilot: React.FC = () => {
  const { carbonClient, isAuthenticated, isClientReady } = useAuth();
  const { selectedCustomer, selectedJob } = useJob();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [variableTree, setVariableTree] = useState<ExtendedGenNode[]>([]);
  const [expandedVariableItems, setExpandedVariableItems] = useState<string[]>([]);
  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);
  const [gridRows, setGridRows] = useState<GridRowsProp>([]);
  const [gridColumns, setGridColumns] = useState<GridColDef[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [variablesField, setVariablesField] = useState('');
  const [filterField, setFilterField] = useState('');
  const [selectedNode, setSelectedNode] = useState<ExtendedGenNode | null>(null);
  const [activeTab, setActiveTab] = useState<'classification' | 'chat'>('classification');
  const [chatInput, setChatInput] = useState('');
  const [classifierManagementVisible, setClassifierManagementVisible] = useState(false);
  const [chatTab, setChatTab] = useState<'chat' | 'themeTagger'>('chat');
  const [activeStep, setActiveStep] = useState(0);
  const [themes, setThemes] = useState<Array<{ id: number; text: string }>>([]);
  const [editingTheme, setEditingTheme] = useState<{ id: number; text: string } | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [primaryVariable, setPrimaryVariable] = useState<string | null>(null);
  const [chatThemes, setChatThemes] = useState<string[]>([]);
  const [showTagButton, setShowTagButton] = useState(false);
  const [taggingProgress, setTaggingProgress] = useState(0);
  const [isTagging, setIsTagging] = useState(false);
  const [taggingStatus, setTaggingStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [tagRunCount, setTagRunCount] = useState(0);
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const [isPreviewProcessing, setIsPreviewProcessing] = useState(false);
  const [previewProgress, setPreviewProgress] = useState(0);
  const [previewedGridColumns, setPreviewedGridColumns] = useState<GridColDef[]>([]);
  const [previewedGridRows, setPreviewedGridRows] = useState<GridRowsProp>([]);
  const [allVariables, setAllVariables] = useState<string[]>([]);
  const [newVariableName, setNewVariableName] = useState('');
  const [newSentimentVariableName, setNewSentimentVariableName] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [dataError, setDataError] = useState<string | null>(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [hasVariables, setHasVariables] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [chatError, setChatError] = useState<string | null>(null);
  const [includeSentimentAnalysis, setIncludeSentimentAnalysis] = useState(false);
  const [isClassifying, setIsClassifying] = useState(false);
  const [classificationComplete, setClassificationComplete] = useState(false);
  const [importComplete, setImportComplete] = useState(false);
  const [middlewareData, setMiddlewareData] = useState<MiddlewareData[]>([]);
  const [isLoadingMiddlewareData, setIsLoadingMiddlewareData] = useState(false);
  const [middlewareDataError, setMiddlewareDataError] = useState<string | null>(null);
  const [canAccessThirdStep, setCanAccessThirdStep] = useState(true);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('success');
  
  // New state for analysis run management
  const [currentRunId, setCurrentRunId] = useState<string | null>(null);
  const [analysisRuns, setAnalysisRuns] = useState<AnalysisRunSummary[]>([]);
  const [runName, setRunName] = useState<string>('');

  // Now, add state for delete confirmation dialog, loading states, and other UI enhancements near the top of the component where other state variables are defined
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [runToDelete, setRunToDelete] = useState<{id: string; name: string} | null>(null);
  const [isDeletingRun, setIsDeletingRun] = useState(false);
  const [isLoadingRuns, setIsLoadingRuns] = useState(false);

  // Add this near the top of the component after the useJob hook
  useEffect(() => {
    // Log the initial state
    console.log('TextAnalysis mounted with job:', {
      selectedJob,
      selectedCustomer,
      sessionInfo: JSON.parse(localStorage.getItem('sessionInfo') || '{}')
    });
  }, []); // Run once on mount

  // Update the existing job context effect with more details
  useEffect(() => {
    console.log('Job context changed:', {
      customer: selectedCustomer,
      job: selectedJob,
      jobId: selectedJob?.id,
      sessionInfo: localStorage.getItem('sessionInfo') ? 'present' : 'missing'
    });
  }, [selectedCustomer, selectedJob]);

  // Add effect to log job context changes
  useEffect(() => {
    console.log('Job context updated:', {
      customer: selectedCustomer,
      job: selectedJob,
      jobId: selectedJob?.id
    });
  }, [selectedCustomer, selectedJob]);

  useEffect(() => {
    const openJobAndFetchVariables = async () => {
      if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
        try {
          setIsLoading(true);
          await carbonClient.openCloudJob(selectedCustomer, selectedJob);
          const specAggregate = await carbonClient.getSpecAggregate();
          if (Array.isArray(specAggregate.variableTree)) {
            setVariableTree(specAggregate.variableTree.map(mapToExtendedGenNode));
          }
        } catch (error) {
          console.error('Error opening job or fetching variables:', error);
          setErrorMessage(`Error: ${(error as Error).message}`);
        } finally {
          setIsLoading(false);
        }
      }
    };
    openJobAndFetchVariables();
  }, [carbonClient, isClientReady, selectedCustomer, selectedJob]);

  const fetchDataFromAPI = async () => {
    setIsDataLoading(true);
    setDataError(null);
    
    try {
      if (!carbonClient || !isClientReady) {
        throw new Error('Carbon client is not ready');
      }

      if (!selectedCustomer) {
        throw new Error('No customer selected');
      }
      
      if (!selectedJob) {
        throw new Error('No job selected');
      }

      if (!variablesField.trim()) {
        throw new Error('Please specify at least one variable');
      }

      const varnames = variablesField.split(',').map(v => v.trim()).filter(v => v).join(',');
      const filter = filterField.trim();
      
      // Get session ID from localStorage
      const sessionId = getCarbonSessionId();
      
      console.log('Fetching data with params:', {
        job_id: selectedJob?.id,
        varnames,
        filter,
        primary_variable: primaryVariable,
        session_id: sessionId // Log the session ID
      });

      const response = await axios({
        method: 'get',
        url: getApiEndpoint('/run-join'),
        params: {
          job_id: selectedJob?.id,
          varnames: variablesField,
          filter: filterField,
          primary_variable: primaryVariable,
          session_id: sessionId // Add session ID here
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Accept": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        }
      });

      console.log('API Response:', response);

      if (!response.data) {
        throw new Error('No data received from API');
      }

      // Handle new response structure with total_cases
      let dataToLoad;
      let totalCases = 0;
      let isSampled = false;

      if (Array.isArray(response.data)) {
        // Handle legacy response format (just an array)
        dataToLoad = response.data;
      } else {
        // Handle new response format with total_cases
        dataToLoad = response.data.data || [];
        totalCases = response.data.total_cases || dataToLoad.length;
        isSampled = response.data.sampled || false;
        
        console.log(`Total cases: ${totalCases}, Showing: ${dataToLoad.length}, Sampled: ${isSampled}`);
      }

      loadDataIntoTable(dataToLoad);
      setIsDataLoaded(true);
      setAllVariables(variablesField.split(',').map(v => v.trim()).filter(v => v));
      
      if (!primaryVariable && variablesField.split(',').map(v => v.trim()).filter(v => v).length > 0) {
        setPrimaryVariable(variablesField.split(',').map(v => v.trim()).filter(v => v)[0]);
      }
    } catch (error) {
      console.error('Error fetching data from API:', error);
      setDataError(error instanceof Error ? error.message : 'An unknown error occurred');
      setIsDataLoaded(false);
      clearGridData();
    } finally {
      setIsDataLoading(false);
    }
  };

  const mapToExtendedGenNode = (node: GenNode): ExtendedGenNode => ({
    ...node,
    id: node.id.toString(),
    parentId: node.parentId?.toString() || null,
    children: node.children ? node.children.map(mapToExtendedGenNode) : undefined,
    label: node.name || node.value1 || node.value2 || 'Unnamed Node'
  });

  const handleExpandedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedVariableItems(nodeIds);
  }, []);

  const handleVariableClick = async (node: ExtendedGenNode) => {
    setSelectedNode(node);
    if (node.type === 'Variable' || node.type === 'Codeframe') {
      await handleVariableExpand(node.id);
    }
  };

  const handleVariableExpand = async (variableId: string) => {
    if (!carbonClient || !isClientReady) return;

    try {
      const node = findVariable(variableTree, variableId);
      if (node) {
        console.log(`Fetching variables for node:`, node);
        const nodeName = node.name || node.value1 || node.id.toString();
        console.log(`Using node name/id: ${nodeName}`);
        
        if (!node.children || node.children.length === 0) {
          const variables = await carbonClient.getVarNodes(nodeName);
          console.log(`Fetched codes for ${nodeName}:`, variables);
          if (variables.length === 0) {
            console.log(`No codes found for ${nodeName} (ID: ${variableId})`);
            setErrorMessage(`No codes found for ${nodeName}`);
          } else if (variables[0].type === 'Codeframe' && variables[0].children) {
            updateTreeWithCodeframe(variableId, variables);
          } else {
            updateTreeWithChildVariables(variableId, variables);
          }
        }
        
        setExpandedVariableItems(prev => {
          if (prev.includes(variableId)) {
            return prev.filter(id => id !== variableId);
          } else {
            return [...prev, variableId];
          }
        });

        setErrorMessage(null);
      } else {
        console.error(`Variable not found for ID: ${variableId}`);
        setErrorMessage(`Variable not found for ID: ${variableId}`);
      }
    } catch (error) {
      console.error('Error fetching node variables:', error);
      setErrorMessage(`Error fetching node variables: ${error instanceof Error ? error.message : String(error)}`);
    }
  };

  const updateTreeWithChildVariables = (parentId: string, childVariables: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === parentId) {
            return {
              ...variable,
              children: childVariables.map(mapToExtendedGenNode),
              isExpanded: true
            }
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) }
          }
          return variable
        })
      }
      return updateVariables(prevTree);
    })
  }

  const updateTreeWithCodeframe = (variableId: string, codeframe: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === variableId) {
            const codeNodes: ExtendedGenNode[] = codeframe[0].children?.map(code => ({
              ...code,
              id: `${variableId}_${code.id}`,
              type: 'Code',
              name: `${code.value1 || ''} - ${code.value2 || ''}`,
              isExpanded: false,
              children: undefined,
              label: `${code.value1 || ''} - ${code.value2 || ''}`,
              parentId: variableId,
            })) || [];

            return { ...variable, children: codeNodes, isExpanded: true };
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) };
          }
          return variable;
        });
      };
      return updateVariables(prevTree);
    });
  };

  const handleSelectedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    console.log('RichTreeView selection changed:', nodeIds);
    setSelectedVariables(nodeIds);
    // Remove the call to clearGridData()
  }, []);

  const findVariable = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.id.toString() === id) {
        return node;
      }
      if (node.children) {
        const found = findVariable(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  // We can keep the clearGridData function in case we need it elsewhere
  const clearGridData = () => {
    setGridColumns([]);
    setGridRows([]);
  };

  const getVariableIcon = (variable: ExtendedGenNode | null) => {
    if (!variable) return <DotIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />;

    if (variable.isFolder) return <Folder fontSize="medium" sx={{ mr: 1, color: 'lightblue' }} />;
    if (variable.type === 'Variable' || variable.type === 'Axis') {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex', mr: 1 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: '50%',
              background: 'radial-gradient(circle at 30% 30%, #FF6B6B, #FF0000)',
            }}
          />
          <ExpandMore sx={{ 
            position: 'absolute', 
            color: '#FFFFFF', 
            fontSize: 20, 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)'
          }} />
        </Box>
      );
    }
    if (variable.type === 'Code') {
      return (
        <Box
          sx={{
            width: 18,
            height: 18,
            borderRadius: '50%',
            mr: 1,
            background: 'radial-gradient(circle at 30% 30%, #FFFF66, #CCCC00)',
          }}
        />
      );
    }
    return <DotIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />;
  };

  // Add this helper function to find a node by its ID
  const findNodeById = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const found = findNodeById(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  // Add this helper function to find the parent variable of a code
  const findParentVariable = (nodes: ExtendedGenNode[], codeId: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.children) {
        const codeNode = node.children.find(child => child.id === codeId);
        if (codeNode) return node;
        const found = findParentVariable(node.children, codeId);
        if (found) return found;
      }
    }
    return null;
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>, field: 'variables' | 'filter') => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain');
    try {
      const node: ExtendedGenNode = JSON.parse(data);
      let value = (node.name || node.value1 || node.value2 || '').trim();

      if (field === 'variables' && node.type === 'Code') {
        const parentVariable = findParentVariable(variableTree, node.id);
        if (parentVariable) {
          value = (parentVariable.name || parentVariable.value1 || parentVariable.value2 || '').trim();
        }
      }

      if (value) {
        if (field === 'variables') {
          setVariablesField(prev => {
            const prevTrimmed = prev.trim();
            const newValue = prevTrimmed ? `${prevTrimmed},${value}` : value;
            const updatedVariables = newValue.split(',').map(v => v.trim()).filter(v => v);
            setAllVariables(updatedVariables);
            setHasVariables(updatedVariables.length > 0);
            
            if (!primaryVariable) {
              setPrimaryVariable(updatedVariables[0]);
            }
            
            return newValue;
          });
        } else {
          setFilterField(prev => {
            const prevTrimmed = prev.trim();
            return prevTrimmed ? `${prevTrimmed},${value}(*)` : `${value}(*)`;
          });
        }
      }
    } catch (error) {
      console.error('Error parsing dropped data:', error);
    }
  };

  // Modify the existing useEffect to not fetch data on mount
  useEffect(() => {
    // We'll fetch data when the user clicks the refresh button or changes variables/filters
  }, []);

  // Update these handlers to not trigger data fetching
  const handleVariablesFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setVariablesField(newValue);
    const variables = newValue.split(',').map(v => v.trim()).filter(v => v);
    setAllVariables(variables);
    setHasVariables(variables.length > 0);
    
    // Update primary variable if needed
    setPrimaryVariable(prev => {
      if (!prev && variables.length > 0) {
        return variables[0];
      }
      if (prev && !variables.includes(prev)) {
        return variables[0] || null;
      }
      return prev;
    });
  };

  const handleFilterFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterField(e.target.value);
    // Remove the call to debouncedFetchData()
  };

  // Add this new function to handle manual refresh
  const handleRefreshData = () => {
    fetchDataFromAPI();
  };

  // Add this useEffect to log state changes
  useEffect(() => {
    console.log('allVariables updated:', allVariables);
    console.log('primaryVariable updated:', primaryVariable);
  }, [allVariables, primaryVariable]);

  const handleDragStart = (event: DragEvent<HTMLLIElement>, node: ExtendedGenNode) => {
    event.dataTransfer.setData('text/plain', JSON.stringify(node));
    event.dataTransfer.effectAllowed = 'copy';
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };

  const CustomTreeItem = React.forwardRef<HTMLLIElement, TreeItem2Props>((props, ref) => {
    const { itemId, label, ...other } = props;
    const item = findVariable(variableTree, itemId);

    return (
      <TreeItem2
        ref={ref}
        {...other}
        itemId={itemId}
        label={
          <Box 
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            onClick={() => item && handleVariableClick(item)}
            draggable
            onDragStart={(event: React.DragEvent<HTMLDivElement>) => {
              if (item) {
                handleDragStart(event as unknown as DragEvent<HTMLLIElement>, item);
              }
            }}
          >
            {getVariableIcon(item)}
            <Typography variant="body2" sx={{ mr: 1 }}>{label}</Typography>
            {item?.type === 'Code' && item.meta && item.meta.map((metaItem: { key: string; value: string }, index: number) => (
              <Tooltip key={index} title={`${metaItem.key}: ${metaItem.value}`}>
                <span style={{ marginLeft: '8px', fontSize: '0.8em', color: 'gray' }}>
                  {metaItem.key === 'PC' ? '%' : metaItem.key}
                </span>
              </Tooltip>
            ))}
          </Box>
        }
      />
    );
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: 'classification' | 'chat') => {
    setActiveTab(newValue);
  };

  const handleChatSubmit = async () => {
    if (!chatInput.trim() || !isDataLoaded) return;

    setIsChatLoading(true);
    setChatError(null);

    // Add user message to UI chat history
    const userMessage: ChatMessage = {
      role: 'user',
      content: chatInput
    };
    setChatHistory(prev => [...prev, userMessage]);

    // Extract question and format responses
    const questionKey = Object.keys(gridRows[0]).find(key => key !== 'id');
    const question = questionKey || '';
    const formattedData = gridRows.map((row, index) => ({
      id: index + 1,
      response: row[questionKey || '']
    }));

    // Construct fresh message array for each request - no chat history needed
    const messages = [
      {
        role: 'system',
        content: 'You are an expert at analyzing text data and identifying common themes. When asked about themes:\n\n1. ONLY derive themes from patterns in the actual data provided\n2. Each theme must be directly supported by multiple examples in the data\n3. Use clear, concise theme names that reflect the actual content\n4. Format themes as a numbered list with bold titles only (no descriptions), e.g.:\n   1. **Specific Issue**\n   2. **Another Issue**\n\nFor non-theme questions, answer concisely based on the context.'
      },
      {
        role: 'user',
        content: `Question being analyzed: "${question}"\n\nResponses to analyze (${formattedData.length} records):\n\n${formattedData.map(item => `${item.id}. "${item.response}"`).join('\n\n')}`
      },
      userMessage // Add just the current user query
    ];

    console.log('Sending messages to API:', messages);

    try {
      // Get session ID from localStorage
      const sessionId = getCarbonSessionId();
      
      // Send chat request to the API
      const response = await axios({
        method: 'post',
        url: getApiEndpoint('/v1/chat/completions'),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        },
        data: {
          model: "llama3.1:8b-instruct-q8_0",
          stream: false,
          max_tokens: 6048,
          messages: messages,
          session_id: sessionId
        }
      });

      console.log('API response:', response.data);

      let assistantMessage: ChatMessage;
      if (response.data.choices && response.data.choices[0] && response.data.choices[0].message) {
        assistantMessage = { 
          role: 'assistant', 
          content: response.data.choices[0].message.content 
        };
      } else if (typeof response.data === 'string') {
        assistantMessage = { 
          role: 'assistant', 
          content: response.data 
        };
      } else {
        throw new Error('Unexpected response structure from API');
      }

      // Add assistant response to UI chat history
      setChatHistory(prev => [...prev, assistantMessage]);
      setChatInput('');
    } catch (error: any) {
      console.error('Chat error:', error);
      let errorMessage = 'Failed to get response from AI';
      
      if (error.code === 'ECONNABORTED' || error.code === 'ETIMEDOUT') {
        errorMessage = `Request timed out. The server took too long to respond.`;
      } else if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = `Error ${error.response.status}: ${
          error.response.data?.error || error.response.data?.message || error.message
        }`;
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = `No response received from server. Please check your connection and try again.`;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      
      setChatError(errorMessage);
      // Add error message to UI chat history
      setChatHistory(prev => [...prev, { role: 'assistant', content: `Error: ${errorMessage}` }]);
    } finally {
      setIsChatLoading(false);
    }
  };

  const handleTagThemes = () => {
    setThemes(chatThemes.map((text, id) => ({ id, text })));
    setChatTab('themeTagger');
    setShowTagButton(false);
  };

  const handleRunClassifier = (id: string) => {
    console.log(`Running classifier with ID: ${id}`);
    // Implement the logic to run the classifier
  };

  const handleChatTabChange = (event: React.SyntheticEvent, newValue: 'chat' | 'themeTagger') => {
    setChatTab(newValue);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 1 && !classificationComplete) {
        // If classification is not complete, skip to the third step
        fetchMiddlewareData(); // Just fetch once, polling will be handled by the useEffect
        return 2;
      }
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 2 && !classificationComplete) {
        // If coming back from the third step and classification is not complete, go back to the first step
        return 0;
      }
      return prevActiveStep - 1;
    });
  };

  // Update the handleReset function to properly reset all run-related state
  const handleReset = () => {
    setActiveStep(0);
    setPreviewGenerated(false);
    setPreviewedGridColumns([]);
    setPreviewedGridRows([]);
    setClassificationComplete(false);
    setImportComplete(false);
    setCurrentRunId(null);
    setMiddlewareData([]);
    
    // Stop any active polling
    if (pollingInterval) {
      clearInterval(pollingInterval);
      setPollingInterval(null);
    }
  };
  
  // Add an effect to reset run-related state when job changes
  useEffect(() => {
    // Reset run state when job changes
    setCurrentRunId(null);
    setClassificationComplete(false);
    setImportComplete(false);
    setMiddlewareData([]);
    setAnalysisRuns([]);
    
    // Stop any active polling
    if (pollingInterval) {
      clearInterval(pollingInterval);
      setPollingInterval(null);
    }
    
    // Reset active step to beginning
    setActiveStep(0);
  }, [selectedJob?.id]);

  // Updated CustomStepIcon component
  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props;

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Box
          sx={{
            width: 32,
            height: 32,
            borderRadius: '50%',
            backgroundColor: active ? 'primary.main' : 'grey.300',
            color: active ? 'white' : 'grey.700', // Changed from 'text.primary' to 'grey.700'
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
          }}
        >
          {icon}
        </Box>
      </Box>
    );
  };

  // Updated styled components for the tabs
  const StyledTabs = styled(Tabs)(({ theme }) => ({
    minHeight: '40px',
    height: '40px',
    '& .MuiTabs-flexContainer': {
      height: '100%',
      display: 'flex',
    },
  }));

  const StyledTab = styled(Tab)(({ theme }) => ({
    minHeight: '40px',
    height: '40px',
    flex: 1, // This will make each tab take up equal space
    maxWidth: 'none', // Remove max-width constraint
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    padding: '0 8px', // Reduced padding to accommodate longer text if needed
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  }));

  const handleEditTheme = (theme: { id: number; text: string }) => {
    setEditingTheme(theme);
    setIsEditDialogOpen(true);
  };

  const handleDeleteTheme = (id: number) => {
    setThemes(themes.filter(theme => theme.id !== id));
  };

  const handleAddTheme = () => {
    const newId = Math.max(...themes.map(t => t.id), 0) + 1;
    setEditingTheme({ id: newId, text: "" });
    setIsEditDialogOpen(true);
  };

  const handleSaveTheme = () => {
    if (editingTheme) {
      setThemes(prevThemes => {
        const existingThemeIndex = prevThemes.findIndex(t => t.id === editingTheme.id);
        if (existingThemeIndex !== -1) {
          // Update existing theme
          const updatedThemes = [...prevThemes];
          updatedThemes[existingThemeIndex] = editingTheme;
          return updatedThemes;
        } else {
          // Add new theme
          return [...prevThemes, editingTheme];
        }
      });
    }
    setIsEditDialogOpen(false);
    setEditingTheme(null);
  };

  const handleTagToDatabase = () => {
    console.log(`Tagging to database with new theme variable name: ${newVariableName}`);
    if (includeSentimentAnalysis) {
      console.log(`Tagging to database with new sentiment variable name: ${newSentimentVariableName}`);
    }
    console.log(`Include Sentiment Analysis: ${includeSentimentAnalysis}`);
    setIsTagging(true);
    setTaggingStatus('idle');
    setTaggingProgress(0);

    const totalRecords = 100;
    let currentRecord = 0;

    const processNextRecord = () => {
      if (currentRecord < totalRecords) {
        currentRecord++;
        setTaggingProgress(currentRecord);

        // Simulate processing time
        setTimeout(() => {
          processNextRecord();
        }, 50);
      } else {
        setIsTagging(false);
        
        // Alternate between success and error
        if (tagRunCount % 2 === 0) {
          setTaggingStatus('success');
        } else {
          setTaggingStatus('error');
        }
        
        // Increment the run count
        setTagRunCount(prevCount => prevCount + 1);
      }
    };

    processNextRecord();
  };

  const handleClearPreview = () => {
    // Only reset preview flag and the theme-related column data
    setPreviewGenerated(false);
    setNewVariableName(''); 
    setPreviewProgress(0);
    setIsPreviewProcessing(false);
    
    // Reset to original columns by filtering out theme columns
    const variableId = primaryVariable?.replace(/\s+/g, '_').toLowerCase() || 'default';
    setGridColumns(prevColumns => 
      prevColumns.filter(col => 
        !col.field.toString().includes(`bayesprice_ai_theme_${variableId}`) && 
        !col.field.toString().includes(`bayesprice_ai_sentiment_${variableId}`)
      )
    );
    
    // Reset row data to remove theme fields
    setGridRows(prevRows => {
      return prevRows.map(row => {
        const newRow = {...row};
        // Remove theme and sentiment fields
        Object.keys(newRow).forEach(key => {
          if (key.includes(`bayesprice_ai_theme_${variableId}`) || 
              key.includes(`bayesprice_ai_sentiment_${variableId}`)) {
            delete newRow[key];
          }
        });
        return newRow;
      });
    });
    
    // Reset sentiment variable name if needed
    if (includeSentimentAnalysis) {
      setNewSentimentVariableName('');
    }
  };

  const handlePreviewThemes = async () => {
    setIsLoading(true);
    setIsPreviewProcessing(true);
    setPreviewProgress(0);
    
    try {
      console.log('Current gridRows:', gridRows);
      const dataToPreview = gridRows.slice(0, 25); // Process only the first 25 rows for preview
      console.log('Data to preview:', {
        rowCount: dataToPreview.length,
        sampleData: dataToPreview.slice(0, 2),
        allColumns: dataToPreview.length > 0 ? Object.keys(dataToPreview[0]) : [],
      });

      if (!dataToPreview.length) {
        throw new Error('No data available to analyze. Please ensure data is loaded first.');
      }

      const themesToSend = themes.map(theme => theme.text);
      const variableId = primaryVariable?.replace(/\s+/g, '_').toLowerCase() || 'default';
      const sessionId = getCarbonSessionId();
      
      // Set up theme columns on the existing grid
      setupThemeColumns(variableId);

      // Process each row individually
      for (let i = 0; i < dataToPreview.length; i++) {
        const currentRow = dataToPreview[i];
        console.log(`Processing row ${i + 1} of ${dataToPreview.length}`);
        
        // Create a prompt specifically for this single row
        const prompt = `
          You are analyzing a text response to categorize it into themes. Your task:

          1. Carefully read the content in the Primary Analysis Variable: ${primaryVariable}
          2. Select the most relevant theme(s) from the numbered list below:
             ${themesToSend.map((theme, index) => `${index + 1}. ${theme}`).join('\n             ')}
          3. Each theme assignment must be justified by the actual text content
          4. If no theme clearly matches, or if there is no value to analyze, use the last theme number (${themesToSend.length}) which is "Not interested / Answer not clear"
          5. Multiple themes can be assigned if clearly justified by the content (separate with commas)

          ${includeSentimentAnalysis ? 'Also analyze the sentiment (positive, neutral, or negative) based on the specific content.' : ''}

          IMPORTANT: Return ONLY a JSON object. Do not include any explanations, code, or other text.
          Your response should:
          1. INCLUDE ALL ORIGINAL FIELDS from the input data
          2. ADD the following new field(s):
             - bayesprice_ai_theme_${variableId}_indices: Array of theme NUMBERS/INDICES (not names) that apply to this response. Example: [1, 3, 5]
             ${includeSentimentAnalysis ? `- bayesprice_ai_sentiment_${variableId}: Sentiment as "positive", "neutral", or "negative"` : ''}
          
          Example of correctly formatted response:
          {
            "id": 1,
            "Please tell us why you are dissatisfied with NOW": "Original text here",
            "bayesprice_ai_theme_${variableId}_indices": [1, 4]
          }
        `;

        try {
          const response = await axios({
            method: 'post',
            url: getApiEndpoint('/theme-preview'),
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Basic ${btoa('user:thundercats')}`
            },
            data: {
              messages: [
                { 
                  role: 'system', 
                  content: 'You are an expert at analyzing and tagging data. Your task is to assign the most relevant themes to a row based on the content of the specified column. Return all original fields plus the theme field.' 
                },
                { 
                  role: 'user', 
                  content: `Instructions:\n${prompt}\n\nInput record to analyze:\n${JSON.stringify(currentRow, null, 2)}`
                }
              ],
              session_id: sessionId
            }
          });

          if (response.data.choices && response.data.choices[0] && response.data.choices[0].message) {
            const content = response.data.choices[0].message.content;
            console.log(`Raw content for row ${i + 1}:`, content);
            
            try {
              // Clean up the content for parsing
              let cleanContent = content.trim();
              
              // Fix common JSON formatting issues
              // If it starts with ``` or ends with ```, remove those
              cleanContent = cleanContent.replace(/^```json\s*/, '').replace(/^```\s*/, '').replace(/\s*```$/, '');
              
              // Remove any potential problematic characters
              cleanContent = cleanContent
                .replace(/[\u0000-\u001F\u007F-\u009F]/g, '')
                .replace(/\s+/g, ' ');

              console.log(`Content to parse for row ${i + 1}:`, cleanContent);
              let processedRow: any;
              
              // Try to parse the JSON
              try {
                processedRow = JSON.parse(cleanContent);
                
                // Process theme indices and convert to theme names
                const themeIndicesField = `bayesprice_ai_theme_${variableId}_indices`;
                const themeField = `bayesprice_ai_theme_${variableId}`;
                
                if (processedRow[themeIndicesField] && Array.isArray(processedRow[themeIndicesField])) {
                  // Map indices to theme names (adjusting for 1-based indexing in the prompt)
                  const themeIndices = processedRow[themeIndicesField];
                  const selectedThemeNames = themeIndices.map(index => {
                    // Adjust for 1-based indexing and ensure within bounds
                    const adjustedIndex = Number(index) - 1;
                    return (adjustedIndex >= 0 && adjustedIndex < themesToSend.length) 
                      ? themesToSend[adjustedIndex] 
                      : "Not interested / Answer not clear";
                  });
                  
                  // Add the theme names as a comma-separated string
                  processedRow[themeField] = selectedThemeNames.join(', ');
                  
                  // Remove the indices field since we don't need to display it
                  delete processedRow[themeIndicesField];
                  
                  console.log(`Processed theme indices ${JSON.stringify(themeIndices)} to names: ${processedRow[themeField]}`);
                } else {
                  console.log(`No valid theme indices found for row ${i + 1}, checking for empty theme field...`);
                }

                // Check if the theme field is missing or empty and retry if needed
                if (!processedRow[themeField] || processedRow[themeField].trim() === '') {
                  console.log(`Empty theme value detected for row ${i + 1}, retrying...`);
                  
                  // Create a more direct prompt for retry
                  const retryPrompt = `
                    You are analyzing this specific text response: "${currentRow[primaryVariable || '']}"
                    
                    Your task is to categorize it into AT LEAST ONE of these numbered themes:
                    ${themesToSend.map((theme, index) => `${index + 1}. ${theme}`).join('\n                    ')}
                    
                    The text may indicate issues related to one or more of these themes.
                    Do not return an empty value - you MUST assign at least one theme index.
                    If truly unclear, use the last theme number (${themesToSend.length}) which is "Not interested / Answer not clear".
                    
                    Return ONLY a JSON object with all original fields and an array of theme indices.
                    Example: {
                      "id": ${i+1}, 
                      "${primaryVariable}": "${currentRow[primaryVariable || '']}", 
                      "${themeIndicesField}": [1, 3]
                    }
                  `;
                  
                  try {
                    const retryResponse = await axios({
                      method: 'post',
                      url: getApiEndpoint('/theme-preview'),
                      headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Basic ${btoa('user:thundercats')}`
                      },
                      data: {
                        messages: [
                          { 
                            role: 'system', 
                            content: 'You are a theme categorization expert. You must assign at least one theme. Empty responses are not acceptable.' 
                          },
                          { 
                            role: 'user', 
                            content: retryPrompt
                          }
                        ],
                        session_id: sessionId
                      }
                    });
                    
                    if (retryResponse.data.choices && retryResponse.data.choices[0] && retryResponse.data.choices[0].message) {
                      const retryContent = retryResponse.data.choices[0].message.content;
                      console.log(`Retry content for row ${i + 1}:`, retryContent);
                      
                      // Process the retry response
                      let retryCleanContent = retryContent.trim()
                        .replace(/^```json\s*/, '')
                        .replace(/^```\s*/, '')
                        .replace(/\s*```$/, '')
                        .replace(/[\u0000-\u001F\u007F-\u009F]/g, '')
                        .replace(/\s+/g, ' ');
                        
                      try {
                        const retryProcessedRow = JSON.parse(retryCleanContent);
                        
                        // If the retry returned theme indices, process them
                        if (retryProcessedRow[themeIndicesField] && Array.isArray(retryProcessedRow[themeIndicesField])) {
                          const retryThemeIndices = retryProcessedRow[themeIndicesField];
                          const retrySelectedThemeNames = retryThemeIndices.map(index => {
                            // Adjust for 1-based indexing and ensure within bounds
                            const adjustedIndex = Number(index) - 1;
                            return (adjustedIndex >= 0 && adjustedIndex < themesToSend.length) 
                              ? themesToSend[adjustedIndex] 
                              : "Not interested / Answer not clear";
                          });
                          
                          // Add the theme names as a comma-separated string
                          processedRow[themeField] = retrySelectedThemeNames.join(', ');
                          
                          // Also remove the indices field from the retry result
                          delete processedRow[themeIndicesField];
                          
                          console.log(`Retry successful, got themes from indices ${JSON.stringify(retryThemeIndices)}: ${processedRow[themeField]}`);
                        }
                        // Check if retry directly returned a theme field (backward compatibility)
                        else if (retryProcessedRow[themeField] && retryProcessedRow[themeField].trim() !== '') {
                          console.log(`Retry successful, got theme: ${retryProcessedRow[themeField]}`);
                          processedRow[themeField] = retryProcessedRow[themeField];
                        } else {
                          // If retry still failed, assign a default fallback theme
                          console.log(`Retry still failed, using fallback theme`);
                          processedRow[themeField] = "Not interested / Answer not clear";
                        }
                      } catch (retryParseError) {
                        console.error(`Error parsing retry JSON for row ${i + 1}:`, retryParseError);
                        // Assign default fallback theme
                        processedRow[themeField] = "Not interested / Answer not clear";
                      }
                    } else {
                      console.error(`Unexpected retry response structure for row ${i + 1}`);
                      // Assign default fallback theme
                      processedRow[themeField] = "Not interested / Answer not clear";
                    }
                  } catch (retryError) {
                    console.error(`Error during retry for row ${i + 1}:`, retryError);
                    // Assign default fallback theme
                    processedRow[themeField] = "Not interested / Answer not clear";
                  }
                }
              } catch (parseError) {
                console.error(`JSON parse error for row ${i + 1}:`, parseError);
                
                // If parsing failed, create a minimal response with original data
                processedRow = {
                  [`bayesprice_ai_theme_${variableId}`]: "Error processing themes",
                };
                
                if (includeSentimentAnalysis) {
                  processedRow[`bayesprice_ai_sentiment_${variableId}`] = "neutral";
                }
              }
              
              // We only need to extract the theme fields instead of updating the entire row
              const updatedFields: Record<string, any> = {};
              
              // Extract only the theme and sentiment fields
              Object.keys(processedRow).forEach(key => {
                if (key.includes(`bayesprice_ai_theme_`) || key.includes(`bayesprice_ai_sentiment_`)) {
                  updatedFields[key] = processedRow[key];
                }
              });
              
              // Update the specific row in the grid with the new theme data
              updateRowThemeData(currentRow.id, updatedFields);
              
              // Update progress
              setPreviewProgress(i + 1);
            } catch (error) {
              console.error(`Error processing content for row ${i + 1}:`, error);
              
              // Create error fields
              const errorFields: Record<string, any> = {
                [`bayesprice_ai_theme_${variableId}`]: "Error processing themes"
              };
              
              if (includeSentimentAnalysis) {
                errorFields[`bayesprice_ai_sentiment_${variableId}`] = "neutral";
              }
              
              // Update the specific row with error data
              updateRowThemeData(currentRow.id, errorFields);
              
              // Update progress even when there's an error
              setPreviewProgress(i + 1);
            }
          } else {
            throw new Error(`Unexpected response structure from API for row ${i + 1}`);
          }
        } catch (rowError) {
          console.error(`Error processing row ${i + 1}:`, rowError);
          
          // Create error fields
          const errorFields: Record<string, any> = {
            [`bayesprice_ai_theme_${variableId}`]: "Error processing themes"
          };
          
          if (includeSentimentAnalysis) {
            errorFields[`bayesprice_ai_sentiment_${variableId}`] = "neutral";
          }
          
          // Update the specific row with error data
          updateRowThemeData(currentRow.id, errorFields);
          
          // Update progress even when there's an error
          setPreviewProgress(i + 1);
        }
      }

      // Set previewGenerated to true at the end to ensure UI shows we're done
      setPreviewGenerated(true);
    } catch (error) {
      console.error('Error generating preview:', error);
      setDataError('Failed to generate preview. Please try again.');
    } finally {
      setIsLoading(false);
      setIsPreviewProcessing(false);
    }
  };

  // New function to add theme columns to the existing grid
  const setupThemeColumns = (variableId: string) => {
    const themeField = `bayesprice_ai_theme_${variableId}`;
    const sentimentField = `bayesprice_ai_sentiment_${variableId}`;
    
    // Check if the columns already exist
    const themeColumnExists = gridColumns.some(col => col.field === themeField);
    const sentimentColumnExists = gridColumns.some(col => col.field === sentimentField);
    
    const newColumns: GridColDef[] = [...gridColumns];
    
    // Add theme column if it doesn't exist
    if (!themeColumnExists) {
      newColumns.push({
        field: themeField,
        headerName: 'Theme',
        flex: 1,
        minWidth: 200,
        cellClassName: 'theme-column',
        renderCell: (params: GridRenderCellParams) => {
          const value = params.value;
          return (
            <Box sx={{ 
              backgroundColor: 'rgba(25, 118, 210, 0.08)',
              p: 1,
              borderRadius: 1,
              width: '100%'
            }}>
              {value || ''}
            </Box>
          );
        }
      });
    }
    
    // Add sentiment column if needed and it doesn't exist
    if (includeSentimentAnalysis && !sentimentColumnExists) {
      newColumns.push({
        field: sentimentField,
        headerName: 'Sentiment',
        flex: 0.5,
        minWidth: 130,
        cellClassName: 'theme-column',
        renderCell: (params: GridRenderCellParams) => {
          const value = params.value;
          return (
            <Box sx={{ 
              backgroundColor: 
                value === 'positive' ? 'rgba(76, 175, 80, 0.1)' :
                value === 'negative' ? 'rgba(244, 67, 54, 0.1)' :
                'rgba(158, 158, 158, 0.1)',
              p: 1,
              borderRadius: 1,
              width: '100%'
            }}>
              {value || ''}
            </Box>
          );
        }
      });
    }
    
    // Update grid columns
    setGridColumns(newColumns);
  };
  
  // New function to update a specific row's theme data
  const updateRowThemeData = (rowId: number | string, themeData: Record<string, any>) => {
    setGridRows(prevRows => {
      return prevRows.map(row => {
        if (row.id === rowId) {
          return { ...row, ...themeData };
        }
        return row;
      });
    });
  };

  // Update the handlePreviewData function
  const handlePreviewData = (previewData: any[], variableId: string, isFirstRow = false) => {
    console.log('Handling preview data:', previewData.length, 'rows. First row?', isFirstRow);

    if (isFirstRow) {
      // For the first row, establish the column structure
      const row = previewData[0];
      
      // Log the actual keys and values in the first row for debugging
      console.log('First row keys:', Object.keys(row));
      console.log('First row values:', Object.values(row));
      
      // Get all keys from the first object
      const allKeys = Object.keys(row);
      
      // ID should always be first
      const orderedKeys = ['id'];
      
      // Put theme and sentiment columns at the end (if they exist)
      const themeKey = allKeys.find(key => key.includes(`bayesprice_ai_theme_${variableId}`));
      const sentimentKey = allKeys.find(key => key.includes(`bayesprice_ai_sentiment_${variableId}`));
      
      // Add the remaining fields in the middle (except any index fields)
      const otherKeys = allKeys.filter(key => 
        key !== 'id' && 
        !key.includes('_indices') && 
        key !== themeKey && 
        key !== sentimentKey
      );
      
      // Combine all keys in the preferred order
      const orderedAllKeys = [
        ...orderedKeys,
        ...otherKeys,
        ...(themeKey ? [themeKey] : []),
        ...(sentimentKey ? [sentimentKey] : [])
      ];
      
      // Create columns with special handling for theme and sentiment columns
      const newColumns: GridColDef[] = orderedAllKeys.map(key => {
        // Base column definition
        const column: GridColDef = {
          field: key,
          headerName: key === 'id' ? 'ID' :
                     key === 'Please tell us why you are dissatisfied with NOW' ? 'Response' : 
                     key.includes('bayesprice_ai_theme') ? 'Theme' :
                     key.includes('bayesprice_ai_sentiment') ? 'Sentiment' : 
                     key,
        };
        
        // Handle column widths
        if (key === 'id') {
          // Fixed width for ID column with no flex
          column.width = 100;
          column.flex = undefined; // Make sure flex is not set
          column.minWidth = undefined; // No minWidth for ID
        } else if (key.includes('why')) {
          // Response column gets most space
          column.flex = 2;
          column.minWidth = 400;
        } else if (key.includes('bayesprice_ai_theme')) {
          // Theme column
          column.flex = 1;
          column.minWidth = 200;
        } else if (key.includes('bayesprice_ai_sentiment')) {
          // Sentiment column
          column.flex = 0.5;
          column.minWidth = 130;
        } else {
          // Other columns
          column.flex = 1;
          column.minWidth = 100;
        }
        
        // Special styling for theme and sentiment columns
        if (key.includes('bayesprice_ai_')) {
          column.cellClassName = 'theme-column';
          column.renderCell = (params: GridRenderCellParams) => {
            const value = params.value;
            return (
              <Box sx={{ 
                backgroundColor: key.includes('_sentiment_') ? 
                  value === 'positive' ? 'rgba(76, 175, 80, 0.1)' :
                  value === 'negative' ? 'rgba(244, 67, 54, 0.1)' :
                  'rgba(158, 158, 158, 0.1)' :
                  'rgba(25, 118, 210, 0.08)',
                p: 1,
                borderRadius: 1,
                width: '100%'
              }}>
                {value}
              </Box>
            );
          };
        }
        
        return column;
      });

      console.log('Created grid columns for first row:', newColumns);
      setPreviewedGridColumns(newColumns);
      setPreviewedGridRows(previewData);
      setPreviewGenerated(true);
    } else {
      // For subsequent rows, just add to the existing rows
      setPreviewedGridRows(prevRows => [...prevRows, ...previewData]);
    }
  };

  // Update the SuggestedPrompts component
  const SuggestedPrompts: React.FC<{ variable: string | null; onPromptClick: (prompt: string) => void; isDataLoaded: boolean }> = ({ variable, onPromptClick, isDataLoaded }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, width: '100%' }}>
      <Button 
        variant="outlined" 
        size="small" 
        onClick={() => variable && onPromptClick(`Find a positive quote about ${variable}`)}
        sx={{ 
          fontSize: '0.75rem', 
          textTransform: 'none', 
          width: '49%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        disabled={!isDataLoaded || !variable}
      >
        Find a positive quote about {variable || 'variable'}
      </Button>
      <Button 
        variant="outlined" 
        size="small" 
        onClick={() => variable && onPromptClick('Generate a list of 5 themes')}
        sx={{ 
          fontSize: '0.75rem', 
          textTransform: 'none', 
          width: '49%'
        }}
        disabled={!isDataLoaded || !variable}
      >
        Generate a list of 5 themes
      </Button>
    </Box>
  );

  // Add this function to handle clicking on a suggested prompt
  const handlePromptClick = (prompt: string) => {
    setChatInput(prompt);
  };

  // Add this new function to handle primary variable change
  const handlePrimaryVariableChange = (event: SelectChangeEvent<string>) => {
    setPrimaryVariable(event.target.value as string);
  };

  // Add a new function to handle the new variable name change
  const handleNewVariableNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewVariableName(event.target.value);
  };

  // Add this new function to handle the sentiment variable name change
  const handleNewSentimentVariableNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSentimentVariableName(event.target.value);
  };

  // Add this useEffect to reset isDataLoaded when customer or job changes
  useEffect(() => {
    setIsDataLoaded(false);
    setHasVariables(false);
  }, [selectedCustomer, selectedJob]);

  useEffect(() => {
    console.log('Current job details:', {
      name: selectedJob,
      id: selectedJob?.id,
      customer: selectedCustomer
    });
  }, [selectedJob, selectedJob?.id, selectedCustomer]);

  // Add a useEffect to log state changes
  useEffect(() => {
    console.log('State updated:', {
      hasVariables,
      variablesField,
      selectedCustomer,
      selectedJob,
      isDataLoading
    });
  }, [hasVariables, variablesField, selectedCustomer, selectedJob, isDataLoading]);

  // Add this function to your component
  const loadDataIntoTable = (data: any) => {
    try {
      console.log('Raw data received:', data);
      
      if (!Array.isArray(data) || data.length === 0) {
        console.log('No data found or invalid format');
        setGridColumns([]);
        setGridRows([]);
        return;
      }

      // Get all unique variable names from the first object
      const variableNames = Object.keys(data[0]);
      
      // Create columns
      const columns: GridColDef[] = [
        {
          field: 'id',
          headerName: 'ID',
          width: 100,
          flex: undefined, // Make sure flex is not set
          minWidth: undefined, // No minWidth constraint
        },
        ...variableNames.map(varName => {
          const column: GridColDef = {
            field: varName,
            headerName: varName,
          };
          
          if (varName.toLowerCase().includes('why')) {
            column.width = undefined; // Don't set fixed width for flex columns
            column.flex = 2;
            column.minWidth = 400;
            column.renderCell = (params: GridRenderCellParams) => {
              const value = params.value || '';
              return (
                <Tooltip title={value} placement="top-start">
                  <Box sx={{ 
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }
                  }}>
                    {value}
                  </Box>
                </Tooltip>
              );
            };
          } else {
            column.flex = 1;
            column.minWidth = 100;
          }
          
          return column;
        })
      ];

      // Map the data to rows
      const rows = data.map((item: any, index: number) => ({
        id: index + 1,
        ...item
      }));

      console.log('Setting grid data:', { columns, rowCount: rows.length });
      setGridColumns(columns);
      setGridRows(rows);
      setIsDataLoaded(true);
    } catch (error) {
      console.error('Error loading data into table:', error);
      throw new Error('Failed to process data for display');
    }
  };

  // Add this useEffect near the top of your component
  useEffect(() => {
    console.log('Job selection updated:', {
      selectedJob,
      selectedJobId: selectedJob?.id,
      selectedCustomer
    });
  }, [selectedJob, selectedJob?.id, selectedCustomer]);

  // Add this near the top of your component, after the hooks
  useEffect(() => {
    console.log('Job context updated:', {
      customer: selectedCustomer,
      job: selectedJob,
      jobId: selectedJob?.id
    });
  }, [selectedCustomer, selectedJob, selectedJob?.id]);

  // Modify the existing useEffect for job selection
  useEffect(() => {
    console.log('Job selection updated:', {
      selectedJob,
      selectedJobId: selectedJob?.id,
      selectedCustomer
    });
    
    // Log the current state of sessionInfo
    const sessionInfo = localStorage.getItem('sessionInfo');
    console.log('Current sessionInfo:', sessionInfo ? JSON.parse(sessionInfo) : 'Not found');
  }, [selectedJob, selectedJob?.id, selectedCustomer]);

  // Update this function to handle checkbox change
  const handleSentimentAnalysisChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeSentimentAnalysis(event.target.checked);
  };

  // Add this effect to update the variable names when primaryVariable changes
  useEffect(() => {
    if (primaryVariable) {
      const variableId = primaryVariable.replace(/\s+/g, '_').toLowerCase();
      setNewVariableName(`bayesprice_ai_theme_${variableId}`);
      setNewSentimentVariableName(`bayesprice_ai_sentiment_${variableId}`);
    }
  }, [primaryVariable]);

  // Add effect to set default run name when primary variable changes
  useEffect(() => {
    if (primaryVariable) {
      setRunName(`Theme Analysis: ${primaryVariable} - ${new Date().toLocaleDateString()}`);
    }
  }, [primaryVariable]);

  const handleStartClassification = async () => {
    // Prevent running if a run is already in progress
    if (currentRunId && !classificationComplete) {
      console.log('Analysis run already in progress:', currentRunId);
      setSnackbarMessage('An analysis run is already in progress. Please wait for it to complete or select "Reset".');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }
    
    setIsClassifying(true);
    // Reset current run ID and classification status
    setCurrentRunId(null);
    setClassificationComplete(false);
    
    try {
      // Validate we have the required inputs
      if (!selectedJob?.id) {
        throw new Error('No job selected');
      }
      
      if (!primaryVariable) {
        throw new Error('No primary variable selected');
      }
      
      if (themes.length === 0) {
        throw new Error('No themes defined for classification');
      }
      
      // Format themes with bold markdown formatting as expected by the middleware
      const themeText = themes.map(theme => `**${theme.text}**`).join('\n');
      const varnamesArray = variablesField.split(',');
      
      // Get session ID from localStorage
      const sessionId = getCarbonSessionId();
      
      // Use either the user-provided run name or generate a default one
      const effectiveRunName = runName.trim() || `Theme Analysis: ${primaryVariable} - ${new Date().toLocaleDateString()}`;
      
      // Prepare request payload for analysis runs endpoint
      const payload = {
        job_id: selectedJob.id,
        analysis_type: 'theme', // Use 'theme' instead of 'theme_classification'
        target_variable: primaryVariable,
        run_name: effectiveRunName,
        session_id: sessionId, // Add session ID here
        parameters: {
          varnames: varnamesArray,
          filter: filterField.split(','),
          theme_text: themeText,
          includeSentiment: includeSentimentAnalysis,
          theme_variable_name: newVariableName,
          sentiment_variable_name: includeSentimentAnalysis ? newSentimentVariableName : undefined
        }
      };
      
      console.log('Starting analysis run with payload:', payload);
      
      const response = await axios.post(getApiEndpoint('/analysis-runs'), payload, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`,
          "x-session-id": sessionId // Explicitly add session ID to headers
        }
      });

      console.log('Analysis run created response:', response.data);
      
      // Set current run ID from response
      if (response.data && response.data.run_id) {
        setCurrentRunId(response.data.run_id);
        
        // Show success message
        setSnackbarMessage(`Analysis run "${effectiveRunName}" started with ID: ${response.data.run_id}`);
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
        
        // Fetch list of runs to update UI
        fetchAnalysisRuns();
      } else {
        console.error('No run_id in response:', response.data);
        setSnackbarMessage('Analysis started but no run ID was returned');
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }

      // Move to step 3 immediately
      setActiveStep(2);
      
      // Fetch data once immediately - polling will be handled by the useEffect when on step 3
      await fetchMiddlewareData();

    } catch (error) {
      console.error('Error starting analysis run:', error);
      setDataError('Failed to start analysis run. Please try again.');
      
      // Show error in snackbar
      setSnackbarMessage(`Failed to start analysis run: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsClassifying(false);
    }
  };

  // Replace the existing fetchMiddlewareData function with this updated version
  const fetchMiddlewareData = async () => {
    if (!selectedJob?.id) return;
    
    // Return early if no currentRunId is available
    if (!currentRunId) {
      console.log('No active run ID found, skipping status fetch');
      return;
    }
    
    setIsLoadingMiddlewareData(true);
    try {
      // Get session ID from localStorage
      const sessionId = getCarbonSessionId();
      
      const response = await axios.get(getApiEndpoint(`/analysis-runs/${currentRunId}/status`), {
        params: { 
          session_id: sessionId
        },
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        }
      });
      
      console.log('Run status response:', response.data);
      
      const statusData = response.data;
      const isComplete = statusData.status === 'COMPLETED';
      const isFailed = statusData.status === 'FAILED';
      
      // Update middleware data with new format
      const middlewareData = {
        exists: true,
        hasData: statusData.processed_count > 0,
        recordCount: statusData.processed_count || 0,
        totalInterviewCount: statusData.total_count || 0,
        uniqueInterviewCount: statusData.total_count || 0,
        totalCases: statusData.total_count || 0,
        isComplete: isComplete,
        status: statusData.status,
        variableCount: 0,
        variableIds: [],
        runId: currentRunId
      };
      
      setMiddlewareData([middlewareData]);
      
      // Update classification status
      setClassificationComplete(isComplete);
      
      // Handle failed status
      if (isFailed) {
        setMiddlewareDataError(`Analysis run failed: ${statusData.error || 'Unknown error'}`);
        // Show notification for failed status
        setSnackbarMessage(`Analysis run failed: ${statusData.error || 'Unknown error'}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        
        // Stop polling on failure
        if (pollingInterval) {
          clearInterval(pollingInterval);
          setPollingInterval(null);
        }
      }
      
      // If processing is complete, update status and stop polling
      if (isComplete) {
        // Show notification
        setSnackbarMessage('Classification process completed successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        
        // Stop polling
        if (pollingInterval) {
          clearInterval(pollingInterval);
          setPollingInterval(null);
        }
      }
    } catch (error) {
      console.error('Error fetching run status:', error);
      setMiddlewareDataError('Failed to fetch processing status.');
    } finally {
      setIsLoadingMiddlewareData(false);
    }
  };

  // Add a new function to fetch analysis runs
  const fetchAnalysisRuns = async () => {
    if (!selectedJob?.id) return;
    
    setIsLoadingRuns(true);
    try {
      // Get session ID from localStorage
      const sessionId = getCarbonSessionId();
      
      const response = await axios.get(getApiEndpoint('/analysis-runs'), {
        params: { 
          job_id: selectedJob.id,
          session_id: sessionId
        },
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        }
      });
      
      console.log('Analysis runs response:', response.data);
      
      if (Array.isArray(response.data)) {
        setAnalysisRuns(response.data);
      } else {
        console.error('Expected array of analysis runs, got:', response.data);
        setAnalysisRuns([]);
      }
    } catch (error: any) {
      console.error('Error fetching analysis runs:', error);
      setAnalysisRuns([]);
      
      // Only show error message for non-404 errors
      // 404 is expected when no runs exist yet
      if (error.response && error.response.status !== 404) {
        setSnackbarMessage(`Failed to fetch analysis runs: ${error.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } finally {
      setIsLoadingRuns(false);
    }
  };

  // Add effect to fetch analysis runs when job changes
  useEffect(() => {
    // Only fetch analysis runs if we have a job selected AND either:
    // 1. We already have a currentRunId (meaning an analysis was started)
    // 2. We're on step 2 or 3 (after the user has started an analysis)
    // This prevents the 404 error when the page first loads
    if (selectedJob?.id && (currentRunId || activeStep >= 2)) {
      fetchAnalysisRuns();
    }
  }, [selectedJob?.id, currentRunId, activeStep]);

  // Add an effect to handle polling when on step 3
  useEffect(() => {
    console.log('Active step changed:', activeStep);
    
    if (activeStep === 2) {
      console.log('Starting to poll middleware data on step 3');
      // Initial fetch
      fetchMiddlewareData();
      
      // Start polling
      const interval = setInterval(fetchMiddlewareData, 3000);
      setPollingInterval(interval);

      return () => {
        console.log('Cleaning up polling interval - leaving step 3');
        if (interval) {
          clearInterval(interval);
          setPollingInterval(null);
        }
      };
    } else if (pollingInterval) {
      // If we're not on step 3 but polling is active, clear it
      console.log('Cleaning up stray polling interval - not on step 3');
      clearInterval(pollingInterval);
      setPollingInterval(null);
    }
  }, [activeStep, currentRunId]); // Add currentRunId as a dependency

  const handleImportVariables = async () => {
    // TODO: Implement the API call to import variables
    // For now, we'll simulate the process
    setTimeout(() => {
      setImportComplete(true);
    }, 2000);
  };

  // Update the handleImportFromMiddleware function
  const handleImportFromMiddleware = async () => {
    try {
      if (!carbonClient || !isAuthenticated) {
        throw new Error('Not authenticated or Carbon client not available');
      }

      if (!selectedCustomer || !selectedJob) {
        throw new Error('Please select a customer and job before importing');
      }
      
      if (!currentRunId) {
        throw new Error('No active analysis run to import');
      }

      // First, open the job
      await carbonClient.openCloudJob(selectedCustomer, selectedJob);

      // Configure import settings with run_id
      const importSettings: ImportSettings = {
        type: 'TSAPI',
        source: API_URL || '', // Use the API_URL (proxy server) instead of hardcoded API URL
        surveyId: currentRunId, // Use run_id as the surveyId for import
        link: 'case',
        overwrite: true,
        authorization: `${process.env.REACT_APP_BEARER_TOKEN}`,
        run_id: currentRunId  // Include run_id in import settings
      };

      // Log the import settings for debugging
      console.log('Importing with settings:', importSettings);

      // Perform the import
      await carbonClient.importPartialJob(importSettings);
      
      // Show success message
      setImportComplete(true);
      
      // Show success snackbar
      setSnackbarMessage('Data successfully imported to Carbon');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      
      // Refresh the middleware data
      await fetchMiddlewareData();
    } catch (error: any) {
      console.error('Error importing from middleware:', error);
      setMiddlewareDataError(`Failed to import data: ${error.message}`);
      
      // Show error snackbar
      setSnackbarMessage(`Import failed: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Add a function to handle closing the Snackbar
  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Inside the component, add a function to handle run selection
  const handleRunSelection = (runId: string): void => {
    setCurrentRunId(runId);
    fetchMiddlewareData();
    
    // Show notification
    setSnackbarMessage(`Selected run: ${runId}`);
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  // Add a function to handle run deletion
  const handleDeleteRun = (runId: string, runName: string): void => {
    setRunToDelete({ id: runId, name: runName });
    setIsDeleteDialogOpen(true);
  };

  // Add a function to confirm deletion
  const confirmDeleteRun = async () => {
    if (!runToDelete) return;
    
    setIsDeletingRun(true);
    try {
      // Get session ID from localStorage
      const sessionId = getCarbonSessionId();
      
      await axios.delete(getApiEndpoint(`/analysis-runs/${runToDelete.id}`), {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`
        },
        params: {
          session_id: sessionId
        }
      });
      
      // Show success message
      setSnackbarMessage(`Successfully deleted run: ${runToDelete.name}`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      
      // If we deleted the current run, reset currentRunId
      if (currentRunId === runToDelete.id) {
        setCurrentRunId(null);
        setMiddlewareData([]);
      }
      
      // Refresh the runs list
      await fetchAnalysisRuns();
    } catch (error) {
      console.error('Error deleting run:', error);
      
      // Show error message
      setSnackbarMessage(`Failed to delete run: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsDeletingRun(false);
      setIsDeleteDialogOpen(false);
      setRunToDelete(null);
    }
  };

  // Add this function to the CoPilot component
  const handleStartRun = async (runId: string, analysisType: string) => {
    try {
      // Always normalize the analysis type to match what the middleware expects
      // Backend only accepts "theme" or "sentiment" (not "theme_classification")
      const normalizedAnalysisType = analysisType.toLowerCase().includes('theme') 
        ? 'theme'
        : 'sentiment';
      
      // Create the proper endpoint path
      const endpoint = `/analysis-runs/${runId}/start-${normalizedAnalysisType}`;
      
      // Get session ID from localStorage
      const sessionId = getCarbonSessionId();
      
      console.log(`Starting ${normalizedAnalysisType} analysis for run: ${runId}, original type: ${analysisType}`);
      
      // Create request body with the exact parameters expected by the backend
      const requestBody = {
        session_id: sessionId,
        target_field: primaryVariable,
        run_id: runId
      };
      
      console.log('Starting run with parameters:', requestBody);
      
      // Use the getApiEndpoint helper function
      const url = getApiEndpoint(endpoint);
      
      // Call the API through the middleware with proper headers
      const response = await axios({
        method: 'post',
        url: url,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${btoa('user:thundercats')}`,
          "x-session-id": sessionId // Ensure session ID is explicitly in headers
        },
        data: requestBody
      });
      
      console.log('Analysis run start response:', response.data);
      
      // Show success snackbar
      setSnackbarMessage(`Analysis run started successfully`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      
      // Refresh the analysis runs list
      fetchAnalysisRuns();
      
      // Set as current run to track progress
      setCurrentRunId(runId);
      fetchMiddlewareData();
    } catch (error) {
      console.error('Error starting analysis run:', error);
      
      // Show error snackbar with more detail if available
      let errorMsg = error instanceof Error ? error.message : 'Unknown error';
      if (axios.isAxiosError(error) && error.response?.data) {
        errorMsg = JSON.stringify(error.response.data);
      }
      
      setSnackbarMessage(`Failed to start run: ${errorMsg}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        height: 'calc(100vh - 84px)',
        overflow: 'hidden',
        pl: 2,
        pr: 3,
        py: 2,
      }} 
      tabIndex={0}
      // Remove onKeyDown from here as we're using document-level event listener
    >
      {/* Left sidebar - Variable Tree */}
      <Paper sx={{ 
        width: 260, 
        p: 2, 
        mr: 2, // Right margin
        overflowY: 'auto', // Allow scrolling in the variable tree if needed
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography variant="h6" gutterBottom>Variable Tree</Typography>
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {isLoading ? (
            <Typography>Loading variable tree...</Typography>
          ) : errorMessage ? (
            <Typography color="error">{errorMessage}</Typography>
          ) : variableTree.length > 0 ? (
            <RichTreeView<ExtendedGenNode, true>
              items={variableTree}
              getItemLabel={(item: ExtendedGenNode): string => item.label || item.name || 'Unnamed Node'}
              slots={{
                item: (props: TreeItem2Props) => <CustomTreeItem key={props.itemId} {...props} />
              }}
              expandedItems={expandedVariableItems}
              selectedItems={selectedVariables}
              onExpandedItemsChange={handleExpandedItemsChange}
              onSelectedItemsChange={handleSelectedItemsChange}
              multiSelect={true}
              sx={{
                '& .MuiTreeItem-content': {
                  padding: '2px 0',
                },
                '& .MuiTreeItem-label': {
                  fontSize: '0.875rem',
                },
                '& .MuiTreeItem-group': {
                  marginLeft: 2,
                  paddingLeft: 1,
                  borderLeft: '1px dashed rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          ) : (
            <Typography>No variables found</Typography>
          )}
        </Box>
      </Paper>

      {/* Right side - Split view for DataGrid and Chat */}
      <Box sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
        {/* Data Grid Section - 70% */}
        <Box sx={{ flex: '0 0 70%', mr: 2, display: 'flex', flexDirection: 'column' }}>
          {/* Fields above the table */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 2, pt: .2 }}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Variables"
                value={variablesField}
                onChange={handleVariablesFieldChange}
                size="small"
                InputProps={{
                  sx: {
                    height: '40px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                  },
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiInputLabel-root': {
                    transform: 'translate(14px, 11px) scale(1)',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -6px) scale(0.75)',
                    },
                  },
                }}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, 'variables')}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Filter"
                value={filterField}
                onChange={handleFilterFieldChange}
                size="small"
                InputProps={{
                  sx: {
                    height: '40px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                  },
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiInputLabel-root': {
                    transform: 'translate(14px, 11px) scale(1)',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -6px) scale(0.75)',
                    },
                  },
                }}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, 'filter')}
              />
            </Grid>
            <Grid item xs={2}>
              <Button 
                variant="contained" 
                onClick={handleRefreshData}
                disabled={isDataLoading || !selectedCustomer || !selectedJob || !selectedJob.id || !hasVariables}
                startIcon={<RefreshIcon />}
              >
                {isDataLoading ? <CircularProgress size={24} /> : (isDataLoaded ? 'Refresh Data' : 'Load Data')}
              </Button>
            </Grid>
            {dataError && (
              <Grid item xs={12}>
                <Alert severity="error">{dataError}</Alert>
              </Grid>
            )}
          </Grid>

          {/* DataGrid */}
          <Paper sx={{ flex: 1, overflow: 'hidden' }}>
            <DataGridPro
              rows={gridRows}
              columns={gridColumns}
              loading={isDataLoading || isLoading}
              pagination
              checkboxSelection
              disableRowSelectionOnClick
              getRowId={(row) => row[Object.keys(row)[0]]}  // Use the first column as the row id
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              sx={{
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal',
                  lineHeight: 'normal',
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                },
              }}
            />
          </Paper>
        </Box>

        {/* Chat Section - 30% */}
        <Box sx={{ flex: '0 0 30%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Box sx={{ pr: 2, pb: 2, pt: .2 }}> {/* Added padding to the right and bottom */}
            <StyledTabs 
              value={chatTab} 
              onChange={handleChatTabChange} 
              sx={{ 
                '& .MuiTabs-indicator': {
                  backgroundColor: 'primary.main',
                  height: '2px',
                },
                border: 1,
                borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
                borderRadius: '4px',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <StyledTab label="Chat" value="chat" />
              <StyledTab label="Theme Tagger" value="themeTagger" />
            </StyledTabs>
          </Box>

          <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column', pr: 2 }}> {/* Added padding to the right */}
            {chatTab === 'chat' && (
              <Box sx={{ 
                flex: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                overflow: 'hidden'
              }}>
                {/* Analysis Variable Section */}
                <Paper sx={{ 
                  p: 1, 
                  mb: 2, 
                  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                  border: 1,
                  borderColor: 'primary.main',
                  borderRadius: '4px',
                  zIndex: 1,
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" fontWeight="medium" sx={{ mr: 1 }}>
                      Analysis Variable:
                    </Typography>
                    {isDataLoaded && allVariables.length > 0 ? (
                      <Select
                        value={primaryVariable || ''}
                        onChange={handlePrimaryVariableChange}
                        displayEmpty
                        variant="standard"
                        sx={{
                          minWidth: 120,
                          '&:before': { borderBottom: 'none' },
                          '&:after': { borderBottom: 'none' },
                          '& .MuiSelect-select': { py: 0 },
                        }}
                      >
                        {allVariables.map((variable, index) => (
                          <MenuItem key={index} value={variable}>{variable}</MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Typography variant="body2" fontWeight="medium">
                        {isDataLoaded ? 'No variables available' : 'Load data to select variable'}
                      </Typography>
                    )}
                  </Box>
                </Paper>

                {/* Chat History */}
                <Box sx={{ 
                  flex: 1, 
                  overflowY: 'auto', 
                  mb: 2, 
                  p: 2, 
                  border: '1px solid #ccc', 
                  borderRadius: 1, 
                  bgcolor: '#f9f9f9',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  {/* Chat messages */}
                  {chatHistory.map((chat, index) => (
                    <Box key={`${index}-${chat.content.substring(0, 20)}`} sx={{ mb: 1, textAlign: chat.role === 'user' ? 'right' : 'left' }}>
                      <Paper 
                        elevation={1}
                        sx={{ 
                          display: 'inline-block',
                          p: 1, 
                          maxWidth: '80%',
                          backgroundColor: chat.role === 'user' ? '#e3f2fd' : '#f0f0f0',
                          borderRadius: 2
                        }}
                      >
                        <MarkdownRenderer 
                          content={chat.content} 
                          setThemes={setThemes} 
                          setChatTab={setChatTab}
                          primaryVariable={primaryVariable}
                          gridRows={gridRows}
                          setChatHistory={setChatHistory}
                          setIsChatLoading={setIsChatLoading}
                          setChatError={setChatError}
                        />
                      </Paper>
                    </Box>
                  ))}
                  {isChatLoading && <CircularProgress size={24} />}
                  {chatError && <Typography color="error">{chatError}</Typography>}
                  {showTagButton && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                      <MuiButton variant="contained" onClick={handleTagThemes}>
                        Tag
                      </MuiButton>
                    </Box>
                  )}
                </Box>

                {/* Suggested Prompts */}
                <SuggestedPrompts variable={primaryVariable} onPromptClick={handlePromptClick} isDataLoaded={isDataLoaded} />

                {/* Chat Input and Buttons */}
                <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={isDataLoaded ? "Type your message here..." : "Load data to start chatting"}
                    value={chatInput}
                    onChange={(e) => setChatInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && isDataLoaded && handleChatSubmit()}
                    size="small"
                    disabled={!isDataLoaded}
                  />
                  <Button 
                    variant="contained" 
                    onClick={handleChatSubmit} 
                    sx={{ minWidth: 'auto', px: 2 }}
                    disabled={!isDataLoaded}
                  >
                    Chat
                  </Button>
                </Box>
              </Box>
            )}

            {chatTab === 'themeTagger' && (
              <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 1, bgcolor: '#f9f9f9', flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <Box sx={{ mb: 2 }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                      <StepLabel StepIconComponent={CustomStepIcon}>Refine Themes</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel StepIconComponent={CustomStepIcon}>AI Tagging</StepLabel>
                    </Step>
                    <Step completed={false}>
                      <StepLabel 
                        StepIconComponent={CustomStepIcon}
                      >
                        Import Tags
                      </StepLabel>
                    </Step>
                  </Stepper>
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                  {activeStep === 0 && (
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                      <TableContainer sx={{ maxHeight: 'calc(100% - 48px)', overflow: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', width: '70%' }}>Theme</TableCell>
                              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', width: '30%' }} align="right">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {themes.map((theme, index) => (
                              <TableRow key={index}>
                                <TableCell sx={{ width: '70%' }}>{theme.text}</TableCell>
                                <TableCell sx={{ width: '30%' }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton onClick={() => handleEditTheme(theme)} size="small">
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteTheme(theme.id)} size="small">
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={handleAddTheme}
                        sx={{ mt: 2 }}
                      >
                        Add New Theme
                      </Button>
                      
                      {/* Replace the RadioGroup with a single Checkbox */}
                      <Box sx={{ mt: 2 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeSentimentAnalysis}
                              onChange={handleSentimentAnalysisChange}
                              name="sentimentAnalysis"
                            />
                          }
                          label="Include Sentiment Analysis"
                        />
                      </Box>
                    </Box>
                  )}
                  {activeStep === 1 && (
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="h6" gutterBottom>
                        Tag Themes to Database
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '300px', gap: 2 }}>
                        <Button
                          variant="contained"
                          color="info"
                          onClick={previewGenerated ? handleClearPreview : handlePreviewThemes}
                          disabled={isClassifying || isPreviewProcessing}
                          fullWidth
                        >
                          {isLoading || isPreviewProcessing ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <CircularProgress size={24} />
                              {isPreviewProcessing && (
                                <Typography variant="body2">
                                  {previewProgress}/25
                                </Typography>
                              )}
                            </Box>
                          ) : (
                            previewGenerated ? 'Clear Preview' : 'Preview Themes'
                          )}
                        </Button>
                        {isPreviewProcessing && (
                          <Box sx={{ width: '100%', mt: 1 }}>
                            <LinearProgress 
                              variant="determinate" 
                              value={(previewProgress / 25) * 100} 
                              sx={{ height: 8, borderRadius: 1 }}
                            />
                          </Box>
                        )}
                        {previewGenerated && (
                          <>
                            <TextField
                              label="New Theme Variable Name"
                              value={newVariableName}
                              onChange={handleNewVariableNameChange}
                              disabled={isClassifying || classificationComplete}
                              fullWidth
                            />
                            {includeSentimentAnalysis && (
                              <TextField
                                label="New Sentiment Variable Name"
                                value={newSentimentVariableName}
                                onChange={handleNewSentimentVariableNameChange}
                                disabled={isClassifying || classificationComplete}
                                fullWidth
                              />
                            )}
                            <TextField
                              label="Analysis Run Name"
                              value={runName}
                              onChange={(e) => setRunName(e.target.value)}
                              disabled={isClassifying || classificationComplete}
                              placeholder={`Theme Analysis - ${new Date().toLocaleDateString()}`}
                              fullWidth
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleStartClassification}
                              disabled={isClassifying || classificationComplete || !newVariableName.trim() || (includeSentimentAnalysis && !newSentimentVariableName.trim()) || !runName.trim()}
                              fullWidth
                            >
                              {isClassifying ? <CircularProgress size={24} /> : 'Start Analysis Run'}
                            </Button>
                            {classificationComplete && (
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleImportVariables}
                                disabled={importComplete}
                                fullWidth
                              >
                                {importComplete ? 'Variables Imported' : 'Import Variables'}
                              </Button>
                            )}
                            {classificationComplete && (
                              <Alert severity="success">
                                Classification {includeSentimentAnalysis ? 'and Sentiment Analysis ' : ''}completed successfully!
                              </Alert>
                            )}
                            {importComplete && (
                              <Alert severity="success">
                                Variables imported successfully!
                              </Alert>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                  {activeStep === 2 && (
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                      <Typography variant="h6" gutterBottom>
                        Import Tagged Data to Carbon
                      </Typography>
                      
                      {/* Add Analysis Runs List */}
                      <Paper sx={{ p: 2, mb: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                          <Typography variant="subtitle1">
                            Analysis Runs
                          </Typography>
                          <IconButton 
                            size="small" 
                            onClick={fetchAnalysisRuns} 
                            title="Refresh runs list"
                            disabled={isLoadingRuns}
                          >
                            {isLoadingRuns ? <CircularProgress size={18} /> : <RefreshIcon fontSize="small" />}
                          </IconButton>
                        </Box>
                        <AnalysisRunsList 
                          runs={analysisRuns} 
                          currentRunId={currentRunId} 
                          onSelectRun={handleRunSelection}
                          onDeleteRun={handleDeleteRun}
                          onStartRun={handleStartRun} // Add this prop
                          isLoadingRuns={isLoadingRuns}
                        />
                      </Paper>
                      
                      {isLoadingMiddlewareData && middlewareData.length === 0 ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                          <CircularProgress />
                          <Typography>Checking classification progress...</Typography>
                        </Box>
                      ) : middlewareDataError ? (
                        <Typography color="error">{middlewareDataError}</Typography>
                      ) : middlewareData.length === 0 ? (
                        <Typography>No tagged data available yet. {currentRunId ? 'Classification is in progress...' : 'Select an analysis run or start a new one.'}</Typography>
                      ) : (
                        <List>
                          <ListItem key="classification-progress">
                            <ListItemText 
                              primary="Classification Progress" 
                              secondary={
                                <Box component="div">
                                  <ClassificationProgress data={middlewareData[0]} />
                                </Box>
                              }
                            />
                            {middlewareData[0].recordCount > 0 && (
                              <ListItemSecondaryAction>
                                <Button
                                  variant="contained"
                                  onClick={handleImportFromMiddleware}
                                  disabled={!middlewareData[0].isComplete}
                                >
                                  {middlewareData[0].isComplete ? 'Import' : 'Processing...'}
                                </Button>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        </List>
                      )}
                    </Box>
                  )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep === 2 ? (
                    <Button onClick={handleReset}>
                      Reset
                    </Button>
                  ) : (
                    <Button onClick={handleNext}>
                      {activeStep === 1 && !classificationComplete ? 'Skip to Import' : 'Next'}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Edit Theme Dialog */}
      <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
        <DialogTitle>{editingTheme?.id ? 'Edit Theme' : 'Add New Theme'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Theme"
            fullWidth
            value={editingTheme?.text || ''}
            onChange={(e) => setEditingTheme(prev => prev ? {...prev, text: e.target.value} : null)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveTheme}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for import notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Delete Run Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => !isDeletingRun && setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the analysis run:
            <Box component="span" sx={{ fontWeight: 'bold', display: 'block', mt: 1 }}>
              {runToDelete?.name}
            </Box>
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setIsDeleteDialogOpen(false)} 
            disabled={isDeletingRun}
          >
            Cancel
          </Button>
          <Button 
            onClick={confirmDeleteRun} 
            color="error" 
            disabled={isDeletingRun}
            startIcon={isDeletingRun ? <CircularProgress size={16} /> : null}
          >
            {isDeletingRun ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};