import React, { createContext, useContext, useState, useEffect } from 'react';
import { CarbonClient } from '../services/CarbonClient';
import { SessionInfo, AuthenticatedUser } from '../types/types';

export interface AuthContextType {
  isAuthenticated: boolean;
  isClientReady: boolean;
  carbonClient: CarbonClient | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  user: AuthenticatedUser | null;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isClientReady, setIsClientReady] = useState(false);
  const [carbonClient, setCarbonClient] = useState<CarbonClient | null>(null);
  const [user, setUser] = useState<AuthenticatedUser | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Derive authentication state from carbonClient
  const isAuthenticated = carbonClient !== null && user !== null;

  // Initialize auth state from localStorage
  useEffect(() => {
    const initializeAuth = async () => {
      setIsLoading(true);
      // Reset all auth state at the start of initialization
      setIsClientReady(false);
      setCarbonClient(null);
      setUser(null);

      try {
        const storedSession = localStorage.getItem('sessionInfo');
        if (!storedSession) {
          // No stored session, just finish loading
          setIsLoading(false);
          return;
        }

        const sessionInfo: SessionInfo = JSON.parse(storedSession);
        const client = new CarbonClient();
        
        // Set the session info in the client
        client.setSessionInfo(sessionInfo);
        
        // Store session ID for Excel downloads
        localStorage.setItem('sessionId', sessionInfo.sessionId);
        
        // Initialize the client and verify the session is still valid
        await client.initialize();
        
        // If we get here, the session is valid
        setCarbonClient(client);
        setIsClientReady(true);
        setUser({
          name: sessionInfo.name,
          sessionId: sessionInfo.sessionId,
          userId: sessionInfo.id // Add userId from sessionInfo
        });
        
        console.log('Session restored successfully:', {
          sessionId: sessionInfo.sessionId,
          userName: sessionInfo.name,
          userId: sessionInfo.id
        });
      } catch (error) {
        console.error('Failed to restore session:', error);
        // Clear invalid session data
        localStorage.removeItem('sessionInfo');
        localStorage.removeItem('sessionId');
        // Ensure all auth state is reset
        setIsClientReady(false);
        setCarbonClient(null);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    initializeAuth();
  }, []);

  const login = async (username: string, password: string) => {
    setIsLoading(true);
    setError(null);
    
    try {
      const client = new CarbonClient();
      const sessionInfo = await client.authenticate(username, password);
      
      // Store session info
      localStorage.setItem('sessionInfo', JSON.stringify(sessionInfo));
      localStorage.setItem('sessionId', sessionInfo.sessionId);
      
      // Initialize client
      client.setSessionInfo(sessionInfo);
      await client.initialize();
      
      // Update state
      setCarbonClient(client);
      setIsClientReady(true);
      setUser({
        name: sessionInfo.name,
        sessionId: sessionInfo.sessionId,
        userId: sessionInfo.id // Add userId from sessionInfo
      });
      
      console.log('Login successful:', {
        sessionId: sessionInfo.sessionId,
        userName: sessionInfo.name,
        userId: sessionInfo.id
      });
    } catch (error) {
      console.error('Login failed:', error);
      // Reset all auth state on login failure
      setIsClientReady(false);
      setCarbonClient(null);
      setUser(null);
      
      // Check if this is an active session error (302 response)
      if (error instanceof Error && error.name === 'ActiveSessionError') {
        throw error; // Re-throw the error to be handled by the Login component
      }
      setError('Login failed. Please check your credentials and try again.');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      if (carbonClient) {
        try {
          await carbonClient.closeJob();
          console.log('Closed current job');
          await carbonClient.logoff();
        } catch (error) {
          console.error('Logout failed:', error);
        }
      }
      
      // Clear all session data
      localStorage.removeItem('sessionInfo');
      localStorage.removeItem('sessionId');
    } finally {
      // Always reset state, even if the logout API call fails
      setCarbonClient(null);
      setIsClientReady(false);
      setUser(null);
      setIsLoading(false);
      console.log('Logout completed');
    }
  };

  const value = {
    isAuthenticated,
    isClientReady,
    carbonClient,
    login,
    logout,
    user,
    isLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};