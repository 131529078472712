import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Alert, Link } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordModal } from '../components/ResetPasswordModal';
import { CarbonClient } from '../services/CarbonClient';

export const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [activeSessions, setActiveSessions] = useState<string[]>([]);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setActiveSessions([]);
    
    try {
      await login(username, password);
      navigate('/dashboard');
    } catch (err: any) {
      console.log('Login error:', err);
      if (err.name === 'ActiveSessionError') {
        // Handle active session case
        setActiveSessions(err.sessions || []);
        setError(err.message || 'User already has active sessions');
      } else {
        setError('Login failed. Please check your credentials.');
      }
    }
  };

  const handleCloseExistingSessions = async () => {
    try {
      const client = new CarbonClient();
      await client.endExistingSessions(activeSessions);
      
      // After closing sessions, try to login again
      await login(username, password);
      navigate('/dashboard');
    } catch (err) {
      console.error('Failed to close sessions:', err);
      if (err instanceof Error) {
        setError(`Failed to close existing sessions: ${err.message}`);
      } else {
        setError('Failed to close existing sessions. Please try again.');
      }
    }
  };

  const handleForgotPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResetModalOpen(true);
  };

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)' }}>
      {/* Left section */}
      <Box sx={{ width: '50%', bgcolor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img 
          src="/assets/img/bp-banner.png" 
          alt="Bayes Price Title Banner" 
          style={{ width: '90%', maxWidth: '100%', height: 'auto' }} 
        />
      </Box>

      {/* Right section */}
      <Box sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            maxWidth: 400,
            width: '100%',
            p: 3,
            backgroundColor: 'background.paper',
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Welcome to Platinum
          </Typography>
          {error && (
            <Alert 
              severity={activeSessions.length > 0 ? "warning" : "error"} 
              sx={{ mb: 2 }}
            >
              {error}
              {activeSessions.length > 0 && (
                <Button
                  fullWidth
                  variant="outlined"
                  color="warning"
                  onClick={handleCloseExistingSessions}
                  sx={{ mt: 1 }}
                >
                  Close Existing Sessions
                </Button>
              )}
            </Alert>
          )}
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              margin="normal"
              required
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              required
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Login
            </Button>
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Link
                component="button"
                variant="body2"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <ResetPasswordModal
        open={isResetModalOpen}
        onClose={() => setIsResetModalOpen(false)}
      />
    </Box>
  );
};