export interface NodeTypeDefinition {
  type: string;
  allowedLevels: number[];
  canHaveChildren: boolean;
  validParents: string[];
  requiredFields: string[];
  optionalFields: string[];
  metaOptions: {
    key: string;
    value: string;
    description: string;
  }[];
}

export const VALID_PARENT_CHILDREN: Record<string, string[]> = {
  'TabAxis': ['Codeframe', 'Filter', 'Function', 'Spacer'],
  'Codeframe': ['Code', 'Base', 'Stat', 'Function', 'Spacer'],
  'Filter': ['Codeframe', 'Function'],
};

export const NODE_TYPE_DEFINITIONS: Record<string, NodeTypeDefinition> = {
  'TabAxis': {
    type: 'TabAxis',
    allowedLevels: [0],
    canHaveChildren: true,
    validParents: [],
    requiredFields: [],
    optionalFields: ['value2'],
    metaOptions: []
  },
  
  'Codeframe': {
    type: 'Codeframe',
    allowedLevels: [1, 2],
    canHaveChildren: true,
    validParents: ['TabAxis', 'Filter'],
    requiredFields: ['value1'],
    optionalFields: ['value2'],
    metaOptions: [
      { key: 'Base', value: 'cwf', description: 'Base calculation type' },
      { key: 'PC', value: '1', description: 'Percentage calculation' }
    ]
  },

  'Code': {
    type: 'Code',
    allowedLevels: [2, 3],
    canHaveChildren: false,
    validParents: ['Codeframe'],
    requiredFields: ['value1'],
    optionalFields: ['value2'],
    metaOptions: [
      { key: 'PC', value: '1', description: 'Percentage calculation' }
    ]
  },

  'Filter': {
    type: 'Filter',
    allowedLevels: [1],
    canHaveChildren: true,
    validParents: ['TabAxis'],
    requiredFields: ['value1'],
    optionalFields: ['value2'],
    metaOptions: []
  },

  'Function': {
    type: 'Function',
    allowedLevels: [1, 2],
    canHaveChildren: false,
    validParents: ['TabAxis', 'Filter'],
    requiredFields: ['value1'],
    optionalFields: ['value2'],
    metaOptions: []
  },

  'Spacer': {
    type: 'Spacer',
    allowedLevels: [1],
    canHaveChildren: false,
    validParents: ['TabAxis'],
    requiredFields: [],
    optionalFields: ['value1', 'value2'],
    metaOptions: []
  },

  'Base': {
    type: 'Base',
    allowedLevels: [2],
    canHaveChildren: false,
    validParents: ['Codeframe'],
    requiredFields: ['value1'],
    optionalFields: ['value2'],
    metaOptions: [
      { key: 'Base', value: 'cwf', description: 'Weighted calculation' },
      { key: 'Base', value: 'cuf', description: 'Unweighted calculation' }
    ]
  },

  'Stat': {
    type: 'Stat',
    allowedLevels: [4],
    canHaveChildren: false,
    validParents: ['Codeframe'],
    requiredFields: ['value1'],
    optionalFields: ['value2'],
    metaOptions: []
  }
}; 