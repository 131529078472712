import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  MenuItem,
  Select,
  Chip,
  OutlinedInput
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { ExtendedGenNode } from '../types';

export interface NodeEditOptions {
  type: 'Code' | 'Base' | 'Filter' | 'Codeframe';
  isLocked: boolean;
  isPC: boolean;
  value1?: string | null;
  value2?: string | null;
  codes?: string[];
  meta?: { key: string; value: string }[];
  children?: ExtendedGenNode[];
  moveToFilter?: string | null;
  moveToLevel?: number | null;
}

interface NodeEditModalProps {
  node: ExtendedGenNode;
  open: boolean;
  onClose: () => void;
  onSave: (nodeId: string, options: NodeEditOptions) => void;
  availableCodes?: string[];
  availableFilters?: ExtendedGenNode[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const isValidNodeType = (type: string): type is NodeEditOptions['type'] => {
  return ['Code', 'Base', 'Filter', 'Codeframe'].includes(type);
};

export const NodeEditModal: React.FC<NodeEditModalProps> = ({
  node,
  open,
  onClose,
  onSave,
  availableCodes = [],
  availableFilters = []
}) => {

  const [options, setOptions] = React.useState<NodeEditOptions>(() => {
    const initialOptions = {
      type: isValidNodeType(node.type) ? node.type : 'Code',
      isLocked: node.isLocked || false,
      isPC: node.type === 'Codeframe' 
        ? node.children?.some(child => child.meta?.some(m => m.key === 'PC' && m.value === '1')) || false
        : node.meta?.some(m => m.key === 'PC' && m.value === '1') || false,
      value1: node.value1,
      value2: node.value2,
      codes: node.codes || [],
      meta: node.meta || [],
      children: node.children || [],
      moveToFilter: null,
      moveToLevel: null
    };
    return initialOptions;
  });

  React.useEffect(() => {
    const newOptions = {
      type: isValidNodeType(node.type) ? node.type : 'Code',
      isLocked: node.isLocked || false,
      isPC: node.type === 'Codeframe'
        ? node.children?.some(child => child.meta?.some(m => m.key === 'PC' && m.value === '1')) || false
        : node.meta?.some(m => m.key === 'PC' && m.value === '1') || false,
      value1: node.value1,
      value2: node.value2,
      codes: node.codes || [],
      meta: node.meta || [],
      children: node.children || [],
      moveToFilter: null,
      moveToLevel: null
    };
    setOptions(newOptions);
  }, [node]);

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newType = event.target.value;
    if (isValidNodeType(newType)) {
      setOptions(prev => ({
        ...prev,
        type: newType,
      }));
    }
  };

  const handleLockChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions(prev => ({
      ...prev,
      isLocked: event.target.checked,
    }));
  };

  const handlePCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions(prev => ({
      ...prev,
      isPC: event.target.checked,
    }));
  };

  const handleValue1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions(prev => ({
      ...prev,
      value1: event.target.value,
    }));
  };

  const handleValue2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions(prev => ({
      ...prev,
      value2: event.target.value,
    }));
  };

  const handleCodesChange = (event: any) => {
    const value = event.target.value;
    
    // Handle "Select All" option
    if (value.includes('all')) {
      if ((options.codes || []).length === availableCodes.length) {
        setOptions(prev => ({
          ...prev,
          codes: []
        }));
      } else {
        setOptions(prev => ({
          ...prev,
          codes: [...availableCodes]
        }));
      }
    } else {
      setOptions(prev => ({
        ...prev,
        codes: value
      }));
    }
  };

  const handleSave = () => {
    if (node.type === 'Codeframe') {
      // For Codeframe nodes, don't include PC in its own meta
      const existingMeta = node.meta?.filter(m => m.key !== 'PC') || [];
      const baseMeta = existingMeta.find(m => m.key === 'Base');
      const newMeta = [
        ...existingMeta,
        ...(baseMeta ? [] : [{ key: 'Base', value: 'cwf' }])
      ];

      // Create updated children with PC metadata if percentage is checked
      const updatedChildren = node.children?.map(child => ({
        ...child,
        meta: [
          ...(child.meta || []).filter(m => m.key !== 'PC'),
          ...(options.isPC ? [{ key: 'PC', value: '1' }] : [])
        ]
      }));

      onSave(node.id, {
        ...options,
        meta: newMeta,
        type: 'Codeframe',
        children: updatedChildren
      });
    } else if (node.type === 'Code') {
      // For Code nodes, include PC in meta if selected
      onSave(node.id, {
        ...options,
        meta: [
          ...(node.meta || []).filter(m => m.key !== 'PC'),
          ...(options.isPC ? [{ key: 'PC', value: '1' }] : [])
        ],
        type: 'Code'
      });
    } else {
      // For other node types
      onSave(node.id, options);
    }
    onClose();
  };

  // Check if node is already at level 1
  const isAlreadyTopLevel = node.level === 1;

  // Check if node is in a filter and there's only one filter available
  const isInOnlyFilter = 
    availableFilters.length === 1 && 
    node.parentId && 
    availableFilters.some(filter => filter.id === node.parentId);

  // Determine if we should hide the Move Node section
  const shouldHideMoveNode = isAlreadyTopLevel && (availableFilters.length === 0 || isInOnlyFilter);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Edit Node: {node.name || node.value1 || node.type}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          {/* Select Codes section moved to the top */}
          {(node.type === 'Codeframe' || node.type === 'Code') && (
            <>
              <Typography variant="subtitle2" gutterBottom>
                Select Codes {availableCodes.length === 0 && '(No codes available)'}
              </Typography>
              {availableCodes.length > 0 ? (
                <FormControl component="fieldset" fullWidth>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={(options.codes || []).length === availableCodes.length}
                          indeterminate={(options.codes || []).length > 0 && (options.codes || []).length < availableCodes.length}
                          onChange={(e) => {
                            const value = e.target.checked;
                            setOptions(prev => ({
                              ...prev,
                              codes: value ? [...availableCodes] : []
                            }));
                          }}
                        />
                      }
                      label="Select All"
                    />
                    <Divider />
                    {availableCodes.map((code) => (
                      <FormControlLabel
                        key={code}
                        control={
                          <Checkbox
                            checked={(options.codes || []).includes(code)}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setOptions(prev => ({
                                ...prev,
                                codes: isChecked 
                                  ? [...(prev.codes || []), code]
                                  : (prev.codes || []).filter(c => c !== code)
                              }));
                            }}
                          />
                        }
                        label={code}
                      />
                    ))}
                  </Box>
                </FormControl>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No codes are available for selection
                </Typography>
              )}
              <Divider sx={{ my: 2 }} />
            </>
          )}

          {/* Add Move section if node is a Codeframe, Variable, Function, or Spacer */}
          {(node.type === 'Codeframe' || node.type === 'Variable' || node.type === 'Function' || node.type === 'Spacer') && !shouldHideMoveNode && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Move Node
              </Typography>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  value={options.moveToLevel === 1 ? "standalone" : options.moveToFilter || "current"}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "standalone") {
                      setOptions(prev => ({
                        ...prev,
                        moveToFilter: null,
                        moveToLevel: 1
                      }));
                    } else if (value === "current") {
                      setOptions(prev => ({
                        ...prev,
                        moveToFilter: null,
                        moveToLevel: null
                      }));
                    } else {
                      setOptions(prev => ({
                        ...prev,
                        moveToFilter: value,
                        moveToLevel: null
                      }));
                    }
                  }}
                >
                  <FormControlLabel 
                    value="current" 
                    control={<Radio />} 
                    label="Keep current position" 
                  />
                  {/* Only show standalone option for Codeframe and Variable nodes that aren't already at level 1 */}
                  {(node.type === 'Codeframe' || node.type === 'Variable') && !isAlreadyTopLevel && (
                    <FormControlLabel 
                      value="standalone" 
                      control={<Radio />} 
                      label="Move to level 1 (standalone)" 
                    />
                  )}
                  {availableFilters.length > 0 && !isInOnlyFilter && (
                    <>
                      <Typography variant="subtitle2" sx={{ mt: 1, mb: 1 }}>
                        Move to Filter:
                      </Typography>
                      {availableFilters.map((filter) => (
                        <FormControlLabel
                          key={filter.id}
                          value={filter.id}
                          // Disable the option if this is the current parent filter
                          disabled={filter.id === node.parentId}
                          control={<Radio />}
                          label={filter.value1 || filter.name || 'Unnamed Filter'}
                        />
                      ))}
                    </>
                  )}
                </RadioGroup>
              </FormControl>
            </Box>
          )}

          {node.type === 'Filter' ? (
            <>
              <Typography variant="subtitle2" gutterBottom>
                Filter By
              </Typography>
              <TextField
                fullWidth
                size="small"
                value={options.value1 || ''}
                onChange={handleValue1Change}
                placeholder="e.g., Q17(63)"
                helperText={
                  <Box component="span" sx={{ display: 'block', mt: 1 }}>
                    <Typography variant="caption" display="block" gutterBottom>
                      Filter format:
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ pl: 2 }}>
                      • Variable(Code) - e.g., Q17(63)<br />
                      • This indicates filtering on code 63 of variable Q17
                    </Typography>
                  </Box>
                }
                sx={{ mt: 1 }}
              />
            </>
          ) : (
            <></>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 