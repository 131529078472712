import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, CircularProgress, Typography, TextField, Button, Paper, useTheme, Tooltip, Snackbar, Alert, Stack, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Accordion, AccordionSummary, AccordionDetails, Badge, List, ListItem, ListItemText, ListItemSecondaryAction, Menu, MenuItem } from '@mui/material';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';
import { TocNode, ExtendedGenNode, RunSpecRequest, QuickUpdateRequest } from '../types/types';
import { useNavigate, useLocation } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { JsonTable } from '../components/JsonTable';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SaveIcon from '@mui/icons-material/Save';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';

interface TableData {
  r: Array<{  // rows
    c: Array<{  // cells
      v?: string | number;  // value
      s?: number;  // style reference
      m?: number;  // merge reference
      cs?: number; // colspan
      rs?: number; // rowspan
    }>;
    h?: number;  // row height
    hidden?: boolean; // row hidden state
  }>;
  sp?: {  // style palette
    [key: string]: {
      f?: {
        t: 'p' | 'n' | 'd' | 't' | 'g';
        d?: number;
        p?: boolean;
      };
      a?: {
        h?: 'left' | 'center' | 'right';
        v?: 'top' | 'middle' | 'bottom';
        w?: boolean;
        textRotation?: number;
        indent?: number;
      };
      font?: {
        n?: string;
        s?: number;
        c?: string;
        b?: boolean;
        i?: boolean;
        u?: boolean;
      };
      fill?: {
        type: string;
        fgColor?: string;
        bgColor?: string;
      };
      border?: {
        left?: { style: string; color: string };
        right?: { style: string; color: string };
        top?: { style: string; color: string };
        bottom?: { style: string; color: string };
      };
    };
  };
  m?: Array<[string, string]>;  // merged cells
  cols?: Array<{  // column information
    width: number;
    hidden?: boolean;
    customWidth?: boolean;
  }>;
  defaultRowHeight?: number;
  defaultColWidth?: number;
}

interface NavigationState {
  loadedReport?: string;
  freshSpec?: RunSpecRequest;
  xlsxUri?: string;
  displayOptions?: QuickUpdateRequest;
}

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:8090/secret'
  : process.env.REACT_APP_API_URL || 'https://app.bayesprice.ai/secret';

// Function to create API endpoint paths properly
const getApiEndpoint = (endpoint: string) => {
  if (endpoint.startsWith('/')) {
    return `${API_BASE_URL}/api${endpoint}`;
  }
  return `${API_BASE_URL}/api/${endpoint}`;
};

// Define the TableList type if not imported
interface TableList {
  id?: number;          // Adding id as an alternative to list_id
  list_id?: number;     // Making list_id optional in case it's not present
  user_id: number;
  list_name: string;
  tables: string[];
  created_at: string;
  updated_at: string;
}

export const Tables: React.FC = () => {
  const { carbonClient, isAuthenticated, isClientReady, user } = useAuth();
  const { selectedCustomer, selectedJob } = useJob();
  const location = useLocation();
  const [nodes, setNodes] = useState<ExtendedGenNode[]>([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedReport, setSelectedReport] = useState<string | null>(null);
  const [top, setTop] = useState<string>('');
  const [side, setSide] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [caseFilter, setCaseFilter] = useState<string>('');

  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>('');

  const [isTableSelected, setIsTableSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  const [displayOptions, setDisplayOptions] = useState<QuickUpdateRequest>({
    ShowFreq: true,
    ShowColPct: true,
    ShowRowPct: true,
    ShowSig: true,
    Filter: ''
  });

  const [excelUri, setExcelUri] = useState<string | null>(null);

  const [pendingDisplayOptions, setPendingDisplayOptions] = useState<QuickUpdateRequest>({
    ShowFreq: true,
    ShowColPct: true,
    ShowRowPct: true,
    ShowSig: true,
    Filter: ''
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('error');
  const [isSyntaxModalOpen, setIsSyntaxModalOpen] = useState(false);
  const [tableData, setTableData] = useState<TableData | null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isTextWrapped, setIsTextWrapped] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [hasFreshReport, setHasFreshReport] = useState(false);
  const [freshReportName, setFreshReportName] = useState<string | null>(null);

  // Add a ref to track if we've already handled the freshSpec once
  const freshSpecHandledRef = useRef(false);

  // Add a ref to track previous job for comparison
  const previousJobRef = useRef<string | null>(null);

  // Add state for delete confirmation dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<ExtendedGenNode | null>(null);

  const [isMultiTableMode, setIsMultiTableMode] = useState(false);
  const [selectedTables, setSelectedTables] = useState<{
    id: string;
    name: string;
    value1: string;
    value2: string;
    tableData: TableData | null;
    excelUri: string | null;
    isLoading: boolean;
    displayOptions: QuickUpdateRequest;
  }[]>([]);
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);
  const [isSaveListDialogOpen, setIsSaveListDialogOpen] = useState(false);
  const [isListManagementDialogOpen, setIsListManagementDialogOpen] = useState(false);
  const [listName, setListName] = useState('');
  const [tableLists, setTableLists] = useState<TableList[]>([]);
  const [selectedListId, setSelectedListId] = useState<string | number | null>(null);
  const [listMenuAnchorEl, setListMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [activeListItem, setActiveListItem] = useState<TableList | null>(null);
  const [listMenuOpen, setListMenuOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [currentList, setCurrentList] = useState<TableList | null>(null);

  // Add a state to track original list state for comparison
  const [originalListState, setOriginalListState] = useState<{
    listId: number | string | null;
    tables: string[];
  } | null>(null);
  
  // Add a state to track if the list has changes
  const [hasListChanges, setHasListChanges] = useState(false);

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showMessage = useCallback((message: string, severity: 'error' | 'warning' | 'info' | 'success' = 'error') => {
    if (!message.trim()) return; // Don't show empty messages
    setErrorMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  const mapToExtendedGenNode = useCallback((node: TocNode): ExtendedGenNode => ({
    ...node,
    id: node.id.toString(),
    label: node.type === 'Spacer' ? 'Spacer' : (node.value1 || node.name || 'Unnamed Node').replace(/\.cbt$/i, ''),
    children: node.children ? node.children.map(mapToExtendedGenNode) : undefined,
    value1: node.value1 || null,
    value2: node.value2 || null,
    meta: node.meta || null,
    isParentInfo: false,
    isPC: false,
    isBase: false,
    content: undefined,
    name: node.name,
    type: node.type,
    parentId: node.parentId,
    isExpanded: node.isExpanded,
    isFolder: node.isFolder,
    level: node.level,
    anyChildren: node.anyChildren,
  }), []);

  const findExecFolder = useCallback((nodes: ExtendedGenNode[]): string | null => {
    for (const node of nodes) {
      console.log('Checking node:', { value1: node.value1, id: node.id, type: node.type });
      if (node.value1?.toLowerCase() === 'exec' && node.type === 'Section') {
        console.log('Found exec folder:', node);
        return node.id;
      }
      if (node.children && node.children.length > 0) {
        const foundInChildren = findExecFolder(node.children);
        if (foundInChildren) return foundInChildren;
      }
    }
    return null;
  }, []);

  const handleLoadSyntax = useCallback(async (reportName: string, value2: string = ""): Promise<boolean> => {
    try {
      if (!carbonClient || !isAuthenticated || !isClientReady) {
        throw new Error('Carbon client is not initialized or not ready');
      }

      setIsTableLoading(true);
      setIsLoading(true);
      setErrorMessage(null);
      setTableData(null);
      setExcelUri(null);

      // Load report
      const loadResponse = await carbonClient.LoadReportAsync(reportName, value2);
      if (loadResponse.code !== 0) {
        // Check if this is a temporary report that doesn't exist
        if (reportName.startsWith('spec_') && loadResponse.message && loadResponse.message.includes('does not exist')) {
          setHasFreshReport(false);
          setFreshReportName(null);
          localStorage.removeItem('lastRunReport');
          localStorage.removeItem('lastRunSpec');
          
          if (window.history.state && window.history.state.freshSpec) {
            const newState = { ...window.history.state };
            delete newState.freshSpec;
            delete newState.xlsxUri;
            window.history.replaceState(newState, '');
          }
          
          throw new Error(`Temporary table no longer exists. Please run your spec again or select a saved table.`);
        }
        
        throw new Error(`Failed to load report: ${loadResponse.message}`);
      }

      // Generate Excel
      const xlsxResponse = await carbonClient.generateXlsx();
      
      if (!xlsxResponse.excelUri) {
        throw new Error('No Excel URI received');
      }
      
      setExcelUri(xlsxResponse.excelUri);

      // Explicitly convert Excel to JSON
      const convertResponse = await fetch(`${API_BASE_URL}/api/excel/convert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-session-info': localStorage.getItem('sessionInfo') || '',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          excelUri: xlsxResponse.excelUri
        })
      });

      if (!convertResponse.ok) {
        throw new Error(`Failed to convert Excel: ${convertResponse.statusText}`);
      }

      const jsonData = await convertResponse.json();
      
      if (jsonData.success && jsonData.data) {
        setTableData(jsonData.data);
        setIsTableSelected(true); // Explicitly mark table as selected
      } else {
        throw new Error('Invalid JSON data received from Excel conversion');
      }

      // Update display options from xlsx response
      setDisplayOptions(prev => ({
        ...prev,
        ShowFreq: xlsxResponse.showFrequencies,
        ShowColPct: xlsxResponse.showColPercents,
        ShowRowPct: xlsxResponse.showRowPercents,
        ShowSig: xlsxResponse.showSignificance,
      }));

      // Fetch report syntax
      const reportSyntax = await carbonClient.getReportSyntax();

      // Update state with syntax
      if (reportSyntax.length >= 5) {
        setTop(reportSyntax[0] || '');
        setSide(reportSyntax[1] || '');
        setFilter(reportSyntax[2] || '');
        setWeight(reportSyntax[3] || '');
        setCaseFilter(reportSyntax[4] || '');
      }

      // Set report info
      setSelectedReport(reportName);
      setIsTableSelected(true);

      return true;
    } catch (error) {
      console.error('Error loading syntax:', error);
      setErrorMessage(`Error loading syntax: ${(error as Error).message}`);
      setSelectedReport(null);
      setIsTableSelected(false);
      throw error;
    } finally {
      setIsTableLoading(false);
      setIsLoading(false);
    }
  }, [carbonClient, isAuthenticated, isClientReady]);

  const loadTocNodes = useCallback(async () => {
    if (!carbonClient || !isAuthenticated || !isClientReady || !selectedCustomer || !selectedJob) {
      if (!selectedCustomer || !selectedJob) {
        setErrorMessage("Please select a customer and job");
      } else {
        setErrorMessage("Carbon client is not initialized or not ready");
      }
      
      setLoading(false);
      return;
    }
    
    try {
      setLoading(true);
      setErrorMessage(null);
      
      const tocNodesResponse = await carbonClient.getTocNodes();
      
      // Process the TOC nodes response...
      if (tocNodesResponse && Array.isArray(tocNodesResponse)) {
        
        // Map the nodes to ExtendedGenNode[]
        const mappedNodes: ExtendedGenNode[] = [];
        for (const tocNode of tocNodesResponse) {
          if (tocNode) {
            const mappedNode = mapToExtendedGenNode(tocNode);
            mappedNodes.push(mappedNode);
          }
        }
        
        setNodes(mappedNodes);
        
        // Handle fresh report if applicable - BUT only if we haven't just saved a report
        // Check the localStorage flag that indicates we just completed a save
        const justSaved = localStorage.getItem('justSavedReport') === 'true';
        
        // IMPROVED CHECK: Only attempt to load a fresh report if:
        // 1. We have a fresh report name
        // 2. It hasn't been handled already
        // 3. We haven't just saved
        if (freshReportName && freshReportName.startsWith('spec_') && 
            !justSaved && !freshSpecHandledRef.current) {
          
          // Set this flag to prevent multiple loading attempts
          freshSpecHandledRef.current = true;
          
          // Check if the temp report exists in the TOC
          const tempReportExists = mappedNodes.some(node => {
            // Check this node
            if ((node.value1 === freshReportName || node.name === freshReportName) && 
                node.type === 'Table') {
              return true;
            }
            
            // Check children (if any)
            if (node.children) {
              return node.children.some(child => 
                (child.value1 === freshReportName || child.name === freshReportName) && 
                child.type === 'Table'
              );
            }
            
            return false;
          });
          
          if (!tempReportExists) {
            setHasFreshReport(false);
            setFreshReportName(null);
            localStorage.removeItem('lastRunReport');
            localStorage.removeItem('lastRunSpec');
            
            if (window.history.state && window.history.state.freshSpec) {
              const newState = { ...window.history.state };
              delete newState.freshSpec;
              delete newState.xlsxUri;
              window.history.replaceState(newState, '');
            }
            
          } else {
            
            try {
              let reportName = freshReportName;
              // Ensure report name has .cbt extension
              if (!reportName.toLowerCase().endsWith('.cbt')) {
                reportName = `${reportName}.cbt`;
              }
              
              await handleLoadSyntax(reportName, '');
              setHasFreshReport(false);
              setFreshReportName(null);
            } catch (err) {
              console.error('Error loading fresh report:', err);
              setErrorMessage(`Error loading fresh report: ${(err as Error).message}`);
            }
          }
        } else if (justSaved) {
          // Clear the flag since we've handled this TOC refresh
          localStorage.removeItem('justSavedReport');
        }
        
        // Find and expand the exec folder by default
        const execFolderId = findExecFolder(mappedNodes);
        if (execFolderId) {
          setExpandedItems(prev => [...prev, execFolderId]);
        } else {
          
          // As a fallback, try the existing approach of looking for an Execution folder
          const executionFolder = mappedNodes.find(node => 
            node && 'displayName' in node && node.displayName === 'Execution'
          );
          
          if (executionFolder && executionFolder.id) {
            setExpandedItems(prev => [...prev, executionFolder.id]);
          }
        }
      } else {
        setErrorMessage('Failed to load TOC nodes: Invalid response format');
      }
    } catch (error) {
      setErrorMessage(`Error loading TOC nodes: ${(error as Error).message}`);
    } finally {
      setLoading(false);
    }
  }, [carbonClient, isClientReady, isAuthenticated, selectedCustomer, selectedJob, freshReportName, handleLoadSyntax, mapToExtendedGenNode, findExecFolder]);

  useEffect(() => {
    console.log('Auth/Job state changed:', {
      isAuthenticated,
      isClientReady,
      hasClient: !!carbonClient,
      selectedCustomer,
      selectedJob: selectedJob?.name
    });

    // Check if job has changed
    const currentJobId = selectedJob?.id || null;
    const jobChanged = previousJobRef.current !== currentJobId;
    previousJobRef.current = currentJobId;

    // Clear TOC when customer changes or job changes
    if (!selectedCustomer || jobChanged) {
      setNodes([]);
      setExpandedItems([]);
      setSelectedItem('');
      setSelectedReport(null);
      setTableData(null);
      setIsTableSelected(false);
      
      // Clear any fresh report state
      setHasFreshReport(false);
      setFreshReportName(null);
      localStorage.removeItem('lastRunReport');
      localStorage.removeItem('lastRunSpec');
      
      // Reset handled flag to ensure fresh TOC load
      freshSpecHandledRef.current = false;
      
      // Reset any error messages
      setErrorMessage(null);
    }

    const initializeData = async () => {
      if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
        // If job changed, ensure we fully reload
        if (jobChanged) {
          setLoading(true);
          try {
            // Explicitly open the cloud job
            await carbonClient.openCloudJob(selectedCustomer, selectedJob);
          } catch (error) {
            console.error('Error opening cloud job after job change:', error);
            setErrorMessage(`Error opening job: ${(error as Error).message}`);
          }
        }
        
        await loadTocNodes();
      } else {
        setLoading(false);
        if (!carbonClient || !isClientReady) {
          setErrorMessage('Initializing client...');
        } else if (!selectedCustomer || !selectedJob) {
          setErrorMessage('Please select a customer and job');
        }
      }
    };

    initializeData();
  }, [isAuthenticated, isClientReady, carbonClient, selectedCustomer, selectedJob, loadTocNodes]);

  const findNodeById = useCallback((nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    const queue = [...nodes];
    while (queue.length > 0) {
      const node = queue.shift();
      if (node) {
        if (node.id === id) {
          return node;
        }
        if (node.children) {
          queue.push(...node.children);
        }
      }
    }
    return null;
  }, []);

  const handleRefreshTable = useCallback(async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Cannot refresh table: Client not ready');
      return;
    }

    try {
      console.log('Refreshing table with current values:', {
        top,
        side,
        filter,
        weight,
        caseFilter
      });
      
      setIsLoading(true);
      
      // First get the Excel URI
      const xlsxResponse = await carbonClient.generateXlsx();
      if (!xlsxResponse.excelUri) {
        throw new Error('No Excel URI received');
      }
      
      setExcelUri(xlsxResponse.excelUri);

      // Convert Excel to JSON
      const response = await fetch(`${API_BASE_URL}/api/excel/convert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-session-info': localStorage.getItem('sessionInfo') || ''
        },
        credentials: 'include',
        body: JSON.stringify({ excelUri: xlsxResponse.excelUri })
      });

      if (!response.ok) {
        throw new Error(`Failed to convert Excel: ${response.statusText}`);
      }

      const jsonData = await response.json();
      if (jsonData.success && jsonData.data) {
        console.log('Setting refreshed table data:', jsonData.data);
        setTableData(jsonData.data);
      } else {
        throw new Error('Invalid JSON data received');
      }
    } catch (error) {
      console.error('Error refreshing table:', error);
      setErrorMessage(`Error refreshing table: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  }, [carbonClient, isClientReady, top, side, filter, weight, caseFilter]);

  const handleReportSelect = useCallback(async (nodeId: string) => {
    setErrorMessage(null);

    try {
      if (!carbonClient || !isAuthenticated || !isClientReady) {
        throw new Error('Carbon client is not initialized or not ready');
      }

      // Reset any "fresh report" state
      setHasFreshReport(false);
      setFreshReportName(null);
      
      // Clear localStorage items
      localStorage.removeItem('lastRunReport');
      localStorage.removeItem('lastRunSpec');
      localStorage.removeItem('lastRunSpecName');
      localStorage.removeItem('justSavedReport');
      
      // Clear navigation state if possible
      if (window.history.state) {
        const newState = { ...window.history.state };
        delete newState.freshSpec;
        delete newState.xlsxUri;
        delete newState.displayOptions;
        window.history.replaceState(newState, '');
      }
      
      // Open the cloud job to ensure clean state
      try {
        if (selectedCustomer && selectedJob) {
          await carbonClient.openCloudJob(selectedCustomer, selectedJob);
        } else {
          console.warn('Cannot open cloud job: customer or job not selected');
        }
      } catch (jobError) {
        console.error('Error opening cloud job:', jobError);
        // Continue with the table selection even if job open fails
      }

      setIsTableLoading(true);

      // Find the selected node
      const selectedNode = findNodeById(nodes, nodeId);
      
      if (!selectedNode) {
        throw new Error('Selected node not found');
      }

      if (selectedNode.type !== 'Table') {
        setIsTableSelected(false);
        return;
      }

      // Use value1 and value2 for the report name
      const value1 = selectedNode.value1 || selectedNode.name;
      const value2 = selectedNode.value2 || '';
      
      if (!value1) {
        throw new Error('Report name not found');
      }

      // Load report and generate table in one step
      await handleLoadSyntax(value1, value2);

    } catch (error) {
      console.error('Error selecting report:', error);
      setErrorMessage(`Error selecting report: ${(error as Error).message}`);
      setSelectedReport(null);
      setIsTableSelected(false);
    } finally {
      setIsTableLoading(false);
    }
  }, [
    carbonClient,
    isAuthenticated,
    isClientReady,
    nodes,
    findNodeById,
    handleLoadSyntax,
    selectedCustomer,
    selectedJob
  ]);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setter(e.target.value);
  };

  const handleExpandedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedItems(nodeIds);
  }, []);

  const handleSelectedItemChange = useCallback((event: React.SyntheticEvent, itemIds: string | null) => {
    console.log('handleSelectedItemChange called with itemIds:', itemIds);
    if (!itemIds) {
      setSelectedItem('');
      return;
    }

    // Find the selected node to check if it's a table
    const selectedNode = findNodeById(nodes, itemIds);
    if (!selectedNode || selectedNode.type !== 'Table') {  // Changed back to uppercase
      return;
    }

    // In multi-table mode, don't do anything when a table is clicked
    // (tables should only be added via the + button which has its own handler)
    if (isMultiTableMode) {
      return;
    }

    // Single click - load everything (only in single table mode)
    setSelectedItem(itemIds);
    handleReportSelect(itemIds);
  }, [handleReportSelect, nodes, findNodeById, isMultiTableMode]);

  const getNodeIcon = (node: ExtendedGenNode | null) => {
    if (!node) return null;

    // Map node types to their corresponding icon names
    const iconMap: { [key: string]: string } = {
      'Table': 'NodeTable',
      'User': 'NodeUser',
      'Folder': 'NodeFolder',
      'Function': 'NodeFunction',
      'Base': 'NodeBase',
      'Code': 'NodeCode',
      'Filter': 'NodeFilter',
      'Variable': 'NodeVariable',
      'Weight': 'NodeWeight',
      'Net': 'NodeNet',
      'Stat': 'NodeStat',
      'Nes': 'NodeNes',
      'Axis': 'NodeAxis',
      'TabAxis': 'NodeTabAxis',
      'Arith': 'NodeArith',
      'Codeframe': 'NodeCodeframe'
    };

    // Use the exact same logic for PC suffix
    const hasPC = node.meta?.some((m: { key: string; value: string }) => m.key === 'PC');
    const baseIconName = iconMap[node.type] || 'NodeFolder'; // Default to folder if type not found
    const iconName = hasPC ? `${baseIconName}PC` : baseIconName;

    return (
      <Box 
        component="img"
        src={`/assets/icons/${iconName}.png`}
        alt={node.type}
        sx={{ 
          width: 20,
          height: 20,
          mr: 1,
          objectFit: 'contain'
        }}
      />
    );
  };

  const CustomTreeItem = React.forwardRef<HTMLLIElement, TreeItem2Props>((props, ref) => {
    const { itemId, label, ...other } = props;
    const node = findNodeById(nodes, itemId);
    
    // Check if this node is a direct child of a User node
    const isChildOfUserNode = node?.parentId ? 
      nodes.some(n => n.id === node.parentId && n.type === 'User') : false;
    
    // Determine if the table is user-generated (can be deleted)
    const isUserGenerated = node && node.type === 'Table' && (
      // Check if value2 contains 'User' or an email pattern (with @)
      (node.value2 && (node.value2.includes('User') || node.value2.includes('@'))) ||
      // Check if it's directly under a user node
      isChildOfUserNode ||
      // Or if the node is in a path that appears to be user-generated
      (node.label && node.label.includes('@'))
    );
    
    return (
      <TreeItem2
        ref={ref}
        {...other}
        itemId={itemId}
        label={
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              py: 0.5,
              pl: 0,
              pr: 1,
              '& .MuiTreeItem-iconContainer': {
                marginRight: 0
              }
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              width: 28, // Fixed width for icon container
              justifyContent: 'center',
              mr: 1
            }}>
              {getNodeIcon(node)}
            </Box>
            <Typography 
              variant="body2" 
              sx={{ 
                flexGrow: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {label}
            </Typography>
            {node && node.type === 'Table' && (
              <>
                {isMultiTableMode && (
                  <Tooltip title="Add to Multi-Table View">
                    <IconButton 
                      size="small" 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddTableToSelection(node);
                      }}
                      sx={{ 
                        ml: 1, 
                        p: 0.5,
                        '&:hover': { 
                          backgroundColor: 'rgba(25, 118, 210, 0.04)' 
                        } 
                      }}
                    >
                      <AddIcon fontSize="small" color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
                {isUserGenerated && (
                  <Tooltip title="Delete Table">
                    <IconButton 
                      size="small" 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteReport(node);
                      }}
                      sx={{ 
                        ml: 1, 
                        p: 0.5,
                        '&:hover': { 
                          backgroundColor: 'rgba(211, 47, 47, 0.04)' 
                        } 
                      }}
                    >
                      <DeleteIcon fontSize="small" color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </Box>
        }
      />
    );
  });

  // Handle opening the delete confirmation dialog
  const handleDeleteReport = (node: ExtendedGenNode) => {
    setReportToDelete(node);
    setDeleteDialogOpen(true);
  };

  // Handle delete confirmation
  const confirmDeleteReport = async () => {
    if (!reportToDelete) return;
    
    try {
      setIsTableLoading(true);
      
      // Get the report name - prioritize value1 as it's more likely to contain just the file name
      // value1 should correspond to just the report name according to the TocNode structure
      const reportName = reportToDelete.value1 || reportToDelete.name || '';
      
      // Get the subfolder path - this would be 'Tables/User/email' for example
      const subPath = reportToDelete.value2 || '';
      
      if (!reportName) {
        throw new Error('Report name not found');
      }
      
      console.log('Deleting report:', { reportName, subPath });
      
      const result = await carbonClient?.deleteReport(reportName, subPath);
      console.log('Delete report result:', result);
      
      if (result && result.code !== 0) {
        throw new Error(result.message || 'Failed to delete report');
      }
      
      // If the deleted report was currently selected, reset selection
      if (selectedItem === reportToDelete.id) {
        setSelectedItem('');
        setSelectedReport(null);
        setIsTableSelected(false);
      }
      
      // Refresh TOC to show updated list
      await loadTocNodes();
      
      showMessage(`Report "${reportName}" deleted successfully`, 'success');
    } catch (error) {
      console.error('Error deleting report:', error);
      showMessage(`Error deleting report: ${(error as Error).message}`, 'error');
    } finally {
      setIsTableLoading(false);
      setDeleteDialogOpen(false);
      setReportToDelete(null);
    }
  };

  // Handle cancel delete
  const cancelDeleteReport = () => {
    setDeleteDialogOpen(false);
    setReportToDelete(null);
  };

  const handleEditSpec = async () => {
    if (!carbonClient || !isClientReady || !selectedReport) {
      showMessage('Cannot edit spec: Client not ready or no report selected');
      return;
    }

    try {
      setIsLoading(true);
      const currentSpec = await carbonClient.getCurrentSpec();
      if (currentSpec) {
        // Store the current table info in localStorage
        if (selectedReport) {
          localStorage.setItem('loaded_table', selectedReport);
        }
        navigate('/specification', { state: { loadedSpec: currentSpec, reportName: selectedReport } });
      } else {
        showMessage('Failed to load current specification');
      }
    } catch (error) {
      console.error('Error loading current spec:', error);
      showMessage(`Error loading specification: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisplayOptionChange = (key: keyof Omit<QuickUpdateRequest, 'Filter'>) => {
    setPendingDisplayOptions(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleApplyDisplayOptions = async () => {
    if (!carbonClient || !isClientReady) return;

    const wouldHaveEnabled = Object.entries(pendingDisplayOptions)
      .filter(([k, v]) => k !== 'Filter' && v)
      .length > 0;
      
    if (!wouldHaveEnabled) {
      showMessage('At least one display option must be enabled', 'warning');
      return;
    }

    try {
      setIsLoading(true);
      const response = await carbonClient.quickUpdate(pendingDisplayOptions);
      
      setDisplayOptions({
        ...displayOptions,
        ShowFreq: response.showFrequencies,
        ShowColPct: response.showColPercents,
        ShowRowPct: response.showRowPercents,
        ShowSig: response.showSignificance,
        Filter: displayOptions.Filter
      });

      await handleRefreshTable();
      setErrorMessage(null);
    } catch (error) {
      console.error('Error updating display options:', error);
      showMessage(`Error updating display options: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPendingDisplayOptions(displayOptions);
  }, [displayOptions]);

  // Update the useEffect that handles navigation to set fresh report flags
  useEffect(() => {
    const state = location.state as NavigationState;
    
    const initializeTable = async () => {
      if (!carbonClient || !isClientReady) return;

      try {
        setIsLoading(true);

        // Reset the handled flag when the component initializes with no freshSpec
        if (!state?.freshSpec) {
          freshSpecHandledRef.current = false;
        }

        // IMPORTANT FIX: First check for stale temporary report info and clear it
        // This helps when refreshing the page with a temp report that doesn't exist anymore
        const lastRunReport = localStorage.getItem('lastRunReport');
        if (lastRunReport && lastRunReport.startsWith('spec_') && !state?.freshSpec) {
          console.log('Detected stale temporary report reference on page load:', lastRunReport);
          console.log('Clearing all temporary report references since we have no freshSpec in state');
          localStorage.removeItem('lastRunReport');
          localStorage.removeItem('lastRunSpec');
          setFreshReportName(null);
          setHasFreshReport(false);
        }

        if (state?.freshSpec && !freshSpecHandledRef.current) {
          // Mark that we've handled this fresh spec to prevent duplicate processing
          freshSpecHandledRef.current = true;
          
          // We're coming from a fresh spec run
          console.log('Handling fresh spec run...', state.freshSpec);
          
          // IMPORTANT: Store the spec ID instead of name - Carbon loads reports by their ID internally
          // Use a consistent ID for the report that we can track
          const specId = Math.floor(Date.now() / 1000).toString();
          const reportName = state.freshSpec.name || `spec_${specId}`;
          
          // Set fresh report tracking with the report ID
          setHasFreshReport(true);
          setFreshReportName(reportName);
          console.log('Set fresh report name:', reportName);
          
          // Also save to localStorage and carbonClient in-memory state
          localStorage.setItem('lastRunReport', reportName);
          localStorage.setItem('lastRunSpec', JSON.stringify(state.freshSpec));
          
          // Set the Excel URI and display options
          if (state.xlsxUri) {
            setExcelUri(state.xlsxUri);
          }
          if (state.displayOptions) {
            setDisplayOptions(state.displayOptions);
            setPendingDisplayOptions(state.displayOptions);
          }

          // Convert Excel to JSON
          const response = await fetch(`${API_BASE_URL}/api/excel/convert`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-session-info': localStorage.getItem('sessionInfo') || '',
              'Accept': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ excelUri: state.xlsxUri })
          });

          if (!response.ok) {
            throw new Error(`Failed to convert Excel: ${response.statusText}`);
          }

          const jsonData = await response.json();
          if (jsonData.success && jsonData.data) {
            setTableData(jsonData.data);
            setIsTableSelected(true);
          } else {
            throw new Error('Invalid JSON data received');
          }

          // IMPORTANT: Clear the navigation state after processing to prevent repeated processing
          // This prevents the infinite loop issue
          if (window.history && typeof window.history.replaceState === 'function') {
            // Remove the freshSpec from navigation state but keep other state
            const cleanState = { ...location.state };
            delete cleanState.freshSpec;
            delete cleanState.xlsxUri;
            window.history.replaceState(cleanState, '');
          }

        } else if (state?.loadedReport) {
          // We're loading an existing report from the TOC
          // Clear fresh report flags
          setHasFreshReport(false);
          setFreshReportName(null);
          
          const findNode = (nodes: ExtendedGenNode[]): ExtendedGenNode | null => {
            for (const node of nodes) {
              // Try to match by name or value1, with or without '.cbt' extension
              const reportName = state.loadedReport;
              const nodeValue = (node.value1 || '').replace(/\.cbt$/i, '');
              const nodeName = (node.name || '').replace(/\.cbt$/i, '');
              
              if (nodeValue === reportName || nodeName === reportName || 
                  nodeValue === reportName + '.cbt' || nodeName === reportName + '.cbt') {
                return node;
              }
              
              if (node.children) {
                const found = findNode(node.children);
                if (found) return found;
              }
            }
            return null;
          };

          const foundNode = findNode(nodes);
          if (foundNode) {
            await handleLoadSyntax(foundNode.value1 || foundNode.name || '', foundNode.value2 || '');
            // Clean up local storage after successful load to prevent potential loops
            localStorage.removeItem('loaded_table');
          } else {
            const errorMsg = `Could not find table "${state.loadedReport}" in the table of contents`;
            console.error(errorMsg);
            showMessage(errorMsg);
            // Clean up even on error
            localStorage.removeItem('loaded_table');
          }
        }
      } catch (error) {
        console.error('Error initializing table:', error);
        showMessage(`Error loading table: ${error instanceof Error ? error.message : String(error)}`);
      } finally {
        setIsLoading(false);
      }
      
      // Check for loaded_table in localStorage after everything else is done
      // This supports the use case of returning from Specification.tsx using window.history.back()
      const loadedTableFromStorage = localStorage.getItem('loaded_table');
      if (loadedTableFromStorage && nodes.length > 0) {
        console.log('Found loaded_table in localStorage:', loadedTableFromStorage);
        
        try {
          const findNodeByName = (nodes: ExtendedGenNode[]): ExtendedGenNode | null => {
            for (const node of nodes) {
              // First remove the .cbt extension if present for comparison
              const tableName = loadedTableFromStorage.replace(/\.cbt$/i, '');
              const nodeValue = (node.value1 || '').replace(/\.cbt$/i, '');
              const nodeName = (node.name || '').replace(/\.cbt$/i, '');
              
              if (nodeValue === tableName || nodeName === tableName || 
                  node.value1 === loadedTableFromStorage || node.name === loadedTableFromStorage) {
                return node;
              }
              
              if (node.children) {
                const found = findNodeByName(node.children);
                if (found) return found;
              }
            }
            return null;
          };
          
          const foundNode = findNodeByName(nodes);
          if (foundNode) {
            console.log('Found matching node for loaded_table:', foundNode);
            await handleLoadSyntax(foundNode.value1 || foundNode.name || '', foundNode.value2 || '');
          }
          
          // Always clear the loaded_table from localStorage to prevent repeated loads
          localStorage.removeItem('loaded_table');
        } catch (error) {
          console.error('Error loading table from localStorage:', error);
          // Still clear the localStorage item on error
          localStorage.removeItem('loaded_table');
        }
      }
    };

    initializeTable();
    
    // Only load TOC nodes if they're not already loaded
    // AND if we haven't just handled a fresh spec (to prevent double-loading)
    if (carbonClient && isClientReady && selectedCustomer && selectedJob && 
        (!nodes || nodes.length === 0) && !freshSpecHandledRef.current) {
      console.log('No TOC nodes loaded, attempting to load them now');
      loadTocNodes();
    }
  }, [carbonClient, isClientReady, location, nodes, handleLoadSyntax, loadTocNodes, selectedCustomer, selectedJob, setTableData, setIsTableSelected, setHasFreshReport, setFreshReportName, setIsLoading, setExcelUri, setDisplayOptions, setPendingDisplayOptions, showMessage]);

  // Handle escape key for fullscreen
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsFullscreen(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const handleMultiTableModeToggle = () => {
    setIsMultiTableMode(!isMultiTableMode);
    // Clear selected tables when exiting multi-table mode
    if (isMultiTableMode) {
      setSelectedTables([]);
      setExpandedAccordions([]);
      setCurrentList(null); // Clear current list reference when exiting multi-table mode
    }
  };

  const handleDownloadAllExcelFiles = () => {
    // Get tables with Excel URIs
    const tablesWithExcel = selectedTables.filter(table => table.excelUri);
    
    if (tablesWithExcel.length === 0) {
      showMessage('No Excel files available to download', 'warning');
      return;
    }

    // Function to download a file with a delay
    const downloadWithDelay = (uri: string, fileName: string, index: number) => {
      setTimeout(() => {
        // Create temporary anchor element
        const link = document.createElement('a');
        link.href = uri;
        link.download = fileName || `table-${index + 1}.xlsx`;
        // Remove target="_blank" to prevent new tabs
        link.setAttribute('style', 'display: none'); // Hide the link
        
        // Append to document, click, and remove
        document.body.appendChild(link);
        link.click();
        
        // Small timeout before removing the element to ensure download starts
        setTimeout(() => {
          document.body.removeChild(link);
        }, 100);
      }, index * 500); // 500ms delay between downloads
    };

    // Trigger downloads for each Excel file
    tablesWithExcel.forEach((table, index) => {
      if (table.excelUri) {
        // Create a clean file name from the table name
        const fileName = `${table.name.replace(/[^\w\s]/gi, '_').trim()}.xlsx`;
        downloadWithDelay(table.excelUri, fileName, index);
      }
    });

    showMessage(`Downloading ${tablesWithExcel.length} Excel files...`, 'info');
  };

  const handleAddTableToSelection = async (node: ExtendedGenNode) => {
    // Check if table is already in the selection
    if (selectedTables.some(t => t.id === node.id)) {
      return;
    }

    // Add table to selection with loading state
    const newTable = {
      id: node.id,
      name: node.label || node.name || 'Unnamed Table',
      value1: node.value1 || node.name || '',
      value2: node.value2 || '',
      tableData: null,
      excelUri: null,
      isLoading: true, // Set to loading initially to get the Excel URL
      displayOptions: { // Each table gets its own display options
        ShowFreq: true,
        ShowColPct: true,
        ShowRowPct: true,
        ShowSig: true,
        Filter: ''
      }
    };
    
    setSelectedTables(prev => [...prev, newTable]);
    
    // Immediately load the report and get Excel URI, but don't convert to JSON yet
    try {
      if (!carbonClient || !isAuthenticated || !isClientReady) {
        throw new Error('Carbon client is not initialized or not ready');
      }

      // Load report
      const loadResponse = await carbonClient.LoadReportAsync(newTable.value1, newTable.value2);
      if (loadResponse.code !== 0) {
        throw new Error(`Failed to load report: ${loadResponse.message}`);
      }

      // Generate Excel URI (but don't convert to JSON yet)
      const xlsxResponse = await carbonClient.generateXlsx();
      if (!xlsxResponse.excelUri) {
        throw new Error('No Excel URI received');
      }

      // Update the table with Excel URI, but still no table data yet
      // Also update display options from xlsx response
      setSelectedTables(prev => prev.map(table => 
        table.id === node.id 
          ? { 
              ...table, 
              excelUri: xlsxResponse.excelUri,
              isLoading: false, // No longer loading, but tableData still null
              displayOptions: {
                ...table.displayOptions,
                ShowFreq: xlsxResponse.showFrequencies,
                ShowColPct: xlsxResponse.showColPercents,
                ShowRowPct: xlsxResponse.showRowPercents,
                ShowSig: xlsxResponse.showSignificance,
              }
            } 
          : table
      ));
    } catch (error) {
      console.error('Error loading table:', error);
      setSelectedTables(prev => prev.map(table => 
        table.id === node.id 
          ? { ...table, isLoading: false } 
          : table
      ));
      showMessage(`Error loading table "${newTable.name}": ${(error as Error).message}`);
    }
  };

  const loadTableData = async (tableId: string) => {
    // Find the table in our selection
    const tableToLoad = selectedTables.find(t => t.id === tableId);
    if (!tableToLoad || tableToLoad.tableData) {
      return; // Table not found or already loaded
    }

    // Update loading state
    setSelectedTables(prev => prev.map(table => 
      table.id === tableId ? { ...table, isLoading: true } : table
    ));

    try {
      // If we don't have an Excel URI yet, we need to load the report first
      if (!tableToLoad.excelUri) {
        if (!carbonClient || !isAuthenticated || !isClientReady) {
          throw new Error('Carbon client is not initialized or not ready');
        }

        // Load report
        const loadResponse = await carbonClient.LoadReportAsync(tableToLoad.value1, tableToLoad.value2);
        if (loadResponse.code !== 0) {
          throw new Error(`Failed to load report: ${loadResponse.message}`);
        }

        // Generate Excel
        const xlsxResponse = await carbonClient.generateXlsx();
        if (!xlsxResponse.excelUri) {
          throw new Error('No Excel URI received');
        }
        
        // Update Excel URI in our state
        tableToLoad.excelUri = xlsxResponse.excelUri;
      }

      // Convert Excel to JSON (only do this when accordion is expanded)
      const convertResponse = await fetch(`${API_BASE_URL}/api/excel/convert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-session-info': localStorage.getItem('sessionInfo') || '',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          excelUri: tableToLoad.excelUri
        })
      });

      if (!convertResponse.ok) {
        throw new Error(`Failed to convert Excel: ${convertResponse.statusText}`);
      }

      const jsonData = await convertResponse.json();
      
      if (jsonData.success && jsonData.data) {
        // Update the table in our selection
        setSelectedTables(prev => prev.map(table => 
          table.id === tableId 
            ? { 
                ...table, 
                tableData: jsonData.data, 
                excelUri: tableToLoad.excelUri,
                isLoading: false 
              } 
            : table
        ));
      } else {
        throw new Error('Invalid JSON data received from Excel conversion');
      }
    } catch (error) {
      console.error('Error loading table:', error);
      setSelectedTables(prev => prev.map(table => 
        table.id === tableId 
          ? { ...table, isLoading: false } 
          : table
      ));
      showMessage(`Error loading table "${tableToLoad.name}": ${(error as Error).message}`);
    }
  };

  const handleRemoveTableFromSelection = (tableId: string) => {
    setSelectedTables(prev => prev.filter(table => table.id !== tableId));
    setExpandedAccordions(prev => prev.filter(id => id !== tableId));
  };

  const handleMoveTableUp = (tableId: string) => {
    setSelectedTables(prev => {
      const tableIndex = prev.findIndex(table => table.id === tableId);
      
      // If table not found or already at the top, do nothing
      if (tableIndex <= 0) return prev;
      
      // Create a new array with the table moved up
      const newTables = [...prev];
      const temp = newTables[tableIndex];
      newTables[tableIndex] = newTables[tableIndex - 1];
      newTables[tableIndex - 1] = temp;
      
      return newTables;
    });
  };

  const handleMoveTableDown = (tableId: string) => {
    setSelectedTables(prev => {
      const tableIndex = prev.findIndex(table => table.id === tableId);
      
      // If table not found or already at the bottom, do nothing
      if (tableIndex === -1 || tableIndex === prev.length - 1) return prev;
      
      // Create a new array with the table moved down
      const newTables = [...prev];
      const temp = newTables[tableIndex];
      newTables[tableIndex] = newTables[tableIndex + 1];
      newTables[tableIndex + 1] = temp;
      
      return newTables;
    });
  };

  const handleAccordionChange = (tableId: string) => {
    // If this accordion is already expanded, close it
    if (expandedAccordions.includes(tableId)) {
      setExpandedAccordions([]);
      return;
    }
    
    // Otherwise, close all others and open only this one
    setExpandedAccordions([tableId]);
    
    // Also load the data for this table if it's not loaded yet
    const selectedTable = selectedTables.find(t => t.id === tableId);
    if (selectedTable && !selectedTable.tableData && !selectedTable.isLoading) {
      loadTableData(tableId);
    }
  };

  // Add a handler for table-specific display option changes
  const handleTableDisplayOptionChange = (tableId: string, key: keyof Omit<QuickUpdateRequest, 'Filter'>) => {
    setSelectedTables(prev => prev.map(table => 
      table.id === tableId 
        ? { 
            ...table,
            displayOptions: {
              ...table.displayOptions,
              [key]: !table.displayOptions[key]
            }
          } 
        : table
    ));
  };

  // Add a handler for applying table-specific display options
  const handleApplyTableDisplayOptions = async (tableId: string) => {
    // Find the table
    const tableToUpdate = selectedTables.find(t => t.id === tableId);
    if (!tableToUpdate || !carbonClient || !isClientReady) return;

    const tableDisplayOptions = tableToUpdate.displayOptions;
    
    const wouldHaveEnabled = Object.entries(tableDisplayOptions)
      .filter(([k, v]) => k !== 'Filter' && v)
      .length > 0;
      
    if (!wouldHaveEnabled) {
      showMessage('At least one display option must be enabled', 'warning');
      return;
    }

    try {
      // Find the table index for later updates
      const tableIndex = selectedTables.findIndex(t => t.id === tableId);
      if (tableIndex === -1) return;
      
      // Set loading state for this table
      setSelectedTables(prev => {
        const newTables = [...prev];
        newTables[tableIndex] = {...newTables[tableIndex], isLoading: true};
        return newTables;
      });

      // Load the report first to ensure we're working with the right table
      const loadResponse = await carbonClient.LoadReportAsync(tableToUpdate.value1, tableToUpdate.value2);
      if (loadResponse.code !== 0) {
        throw new Error(`Failed to load report: ${loadResponse.message}`);
      }
      
      // Apply display options
      const response = await carbonClient.quickUpdate(tableDisplayOptions);
      
      // Get fresh Excel URI
      const xlsxResponse = await carbonClient.generateXlsx();
      if (!xlsxResponse.excelUri) {
        throw new Error('No Excel URI received');
      }

      // Convert to JSON
      const convertResponse = await fetch(`${API_BASE_URL}/api/excel/convert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-session-info': localStorage.getItem('sessionInfo') || '',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          excelUri: xlsxResponse.excelUri
        })
      });

      if (!convertResponse.ok) {
        throw new Error(`Failed to convert Excel: ${convertResponse.statusText}`);
      }

      const jsonData = await convertResponse.json();
      
      if (jsonData.success && jsonData.data) {
        // Update the table with new data and display options
        setSelectedTables(prev => prev.map(table => 
          table.id === tableId 
            ? { 
                ...table, 
                tableData: jsonData.data, 
                excelUri: xlsxResponse.excelUri,
                isLoading: false,
                displayOptions: {
                  ...table.displayOptions,
                  ShowFreq: response.showFrequencies,
                  ShowColPct: response.showColPercents,
                  ShowRowPct: response.showRowPercents,
                  ShowSig: response.showSignificance,
                }
              } 
            : table
        ));
      } else {
        throw new Error('Invalid JSON data received from Excel conversion');
      }
    } catch (error) {
      console.error('Error updating display options:', error);
      setSelectedTables(prev => prev.map(table => 
        table.id === tableId ? { ...table, isLoading: false } : table
      ));
      showMessage(`Error updating display options: ${(error as Error).message}`);
    }
  };

  const handleSaveList = async () => {
    if (!user || selectedTables.length === 0 || !listName.trim()) {
      showMessage('Please provide a list name and ensure you have tables selected', 'warning');
      return;
    }
    
    setIsListLoading(true);
    
    try {
      // Create the tables array with the current order
      const tableNames = selectedTables.map(table => table.value1 || table.name || '');
      
      // Check if we're updating an existing list or creating a new one
      if (selectedListId) {
        // Update existing list
        const response = await axios.put(getApiEndpoint(`/table-lists/${selectedListId}`), {
          user_id: parseInt(user.userId, 10),
          list_name: listName,
          tables: tableNames
        });
        
        // If we're updating the currently loaded list, refresh its data
        if (currentList && currentList.list_id === selectedListId) {
          setCurrentList({
            ...currentList,
            list_name: listName,
            tables: tableNames,
            updated_at: new Date().toISOString()
          });
        }
        
        showMessage(`List "${listName}" updated successfully`, 'success');
      } else {
        // Create new list
        const response = await axios.post(getApiEndpoint('/table-lists'), {
          user_id: parseInt(user.userId, 10),
          list_name: listName,
          tables: tableNames
        });
        
        // Set the newly created list as current
        if (response.data && response.data.list_id) {
          setCurrentList({
            list_id: response.data.list_id,
            user_id: parseInt(user.userId, 10),
            list_name: listName,
            tables: tableNames,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          });
        }
        
        showMessage(`List "${listName}" saved successfully`, 'success');
      }
      
      // Close the dialog and reset form
      setIsSaveListDialogOpen(false);
      setListName('');
      setSelectedListId(null);
      
      // Refresh the lists
      await fetchTableLists();
    } catch (error) {
      console.error('Error saving table list:', error);
      showMessage(`Error saving list: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
    } finally {
      setIsListLoading(false);
    }
  };
  
  const fetchTableLists = async () => {
    if (!user) {
      showMessage('You must be logged in to view table lists', 'warning');
      return;
    }
    
    setIsListLoading(true);
    
    try {
      // Get session info for authentication
      const sessionInfo = localStorage.getItem('sessionInfo') || '';
      
      // Make sure to include the same headers as other requests
      const response = await axios.get(
        getApiEndpoint(`/table-lists/user/${user.userId}`),
        {
          headers: {
            'Content-Type': 'application/json',
            'x-session-info': sessionInfo,
            'Accept': 'application/json'
          },
          withCredentials: true
        }
      );
      
      console.log('Fetched table lists:', response.data);
      
      // Log each list to check structure
      if (Array.isArray(response.data)) {
        response.data.forEach((list, index) => {
          console.log(`List ${index}:`, list);
          console.log(`  - list_id: ${list.list_id}`);
          console.log(`  - id: ${list.id}`);
        });
      }
      
      setTableLists(response.data);
    } catch (error) {
      console.error('Error fetching table lists:', error);
      showMessage(`Error fetching lists: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
    } finally {
      setIsListLoading(false);
    }
  };
  
  const handleDeleteList = async (listItemOrId: TableList | string) => {
    // Check if we have a table list object or just an ID string
    let listId: string | number | undefined;
    let listItem: TableList | undefined;
    
    if (typeof listItemOrId === 'string') {
      listId = listItemOrId;
    } else {
      listItem = listItemOrId;
      listId = listItem.id || listItem.list_id;
    }
    
    if (!listId) {
      showMessage('Invalid list ID', 'error');
      return;
    }
    
    // Make sure to convert the ID to an integer for the API
    const numericId = parseInt(listId.toString(), 10);
    
    if (isNaN(numericId)) {
      showMessage('Invalid list ID format', 'error');
      return;
    }
    
    console.log(`Attempting to delete list with ID: ${numericId} (original value: ${listId})`);
    setIsListLoading(true);
    
    try {
      // Get session info for authentication
      const sessionInfo = localStorage.getItem('sessionInfo') || '';
      
      // Make the DELETE request with the numeric ID
      const response = await axios.delete(
        getApiEndpoint(`/table-lists/${numericId}`),
        {
          headers: {
            'Content-Type': 'application/json',
            'x-session-info': sessionInfo,
            'Accept': 'application/json'
          },
          withCredentials: true
        }
      );
      
      console.log('Delete response:', response);
      showMessage('List deleted successfully', 'success');
      
      // If we deleted the current list, clear it
      if (currentList && (currentList.list_id === listId || currentList.id === listId)) {
        setCurrentList(null);
      }
      
      // Refresh the lists
      await fetchTableLists();
    } catch (error) {
      console.error('Error deleting table list:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Server error response:', {
            status: error.response.status,
            statusText: error.response.statusText,
            data: error.response.data
          });
          showMessage(`Error deleting list: ${error.response.status} - ${error.response.statusText}`, 'error');
        } else {
          showMessage('Error connecting to server', 'error');
        }
      } else {
        showMessage(`Error deleting list: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
      }
    } finally {
      setIsListLoading(false);
      setIsConfirmDeleteOpen(false);
      setActiveListItem(null);
    }
  };
  
  // Add a function to check if the current selected tables differ from the original list
  const checkForListChanges = useCallback(() => {
    if (!originalListState || !currentList) {
      setHasListChanges(false);
      return;
    }
    
    // Get current table names
    const currentTableNames = selectedTables.map(table => table.value1 || table.name || '');
    
    // Check if the length is different
    if (currentTableNames.length !== originalListState.tables.length) {
      setHasListChanges(true);
      return;
    }
    
    // Check if the order or content is different
    for (let i = 0; i < currentTableNames.length; i++) {
      if (currentTableNames[i] !== originalListState.tables[i]) {
        setHasListChanges(true);
        return;
      }
    }
    
    // No changes found
    setHasListChanges(false);
  }, [selectedTables, originalListState, currentList]);
  
  // Run the check whenever selected tables change or when a list is first loaded
  useEffect(() => {
    checkForListChanges();
  }, [selectedTables, checkForListChanges]);

  // Update handling of loading lists to track original state
  const handleLoadList = async (list: TableList, replace: boolean = true) => {
    if (!carbonClient || !isAuthenticated || !isClientReady) {
      showMessage('Cannot load list: Client not ready', 'error');
      return;
    }
    
    setIsListLoading(true);
    setListMenuOpen(false);
    
    try {
      // Enable multi-table mode if not already active
      if (!isMultiTableMode) {
        setIsMultiTableMode(true);
      }
      
      // If replace is true, clear current selection
      if (replace) {
        setSelectedTables([]);
        setExpandedAccordions([]);
      }
      
      // Load each table in the list
      for (const tableName of list.tables) {
        // Find the table in the TOC
        const findTable = (nodes: ExtendedGenNode[]): ExtendedGenNode | null => {
          for (const node of nodes) {
            // Try to match by name or value1
            if ((node.value1 === tableName || node.name === tableName) && node.type === 'Table') {
              return node;
            }
            
            // Check children
            if (node.children) {
              const found = findTable(node.children);
              if (found) return found;
            }
          }
          return null;
        };
        
        const tableNode = findTable(nodes);
        if (tableNode) {
          // Use existing function to add table to selection
          await handleAddTableToSelection(tableNode);
        }
      }
      
      // Store the current list in state for easy access later
      setCurrentList(list);
      
      // Track the original state of this list for change detection
      setOriginalListState({
        listId: list.list_id || list.id || null,
        tables: [...list.tables]  // Create a copy to avoid reference issues
      });
      
      // Reset changes flag
      setHasListChanges(false);
      
      showMessage(`Loaded list "${list.list_name}" with ${list.tables.length} tables`, 'success');
      setIsListManagementDialogOpen(false);
    } catch (error) {
      console.error('Error loading table list:', error);
      showMessage(`Error loading list: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
    } finally {
      setIsListLoading(false);
    }
  };

  // Add a handler for updating the current list
  const handleUpdateCurrentList = async () => {
    if (!currentList || !originalListState) return;
    
    setIsListLoading(true);
    
    try {
      // Get the current table names in the current order
      const tableNames = selectedTables.map(table => table.value1 || table.name || '');
      
      // Update the list
      const response = await axios.put(getApiEndpoint(`/table-lists/${currentList.list_id || currentList.id}`), {
        user_id: parseInt(user?.userId || '0', 10),
        list_name: currentList.list_name,
        tables: tableNames
      });
      
      // Update the current list state
      setCurrentList({
        ...currentList,
        tables: tableNames,
        updated_at: new Date().toISOString()
      });
      
      // Update the original state to match the new state
      setOriginalListState({
        listId: currentList.list_id || currentList.id || null,
        tables: [...tableNames]
      });
      
      // Reset changes flag
      setHasListChanges(false);
      
      showMessage(`List "${currentList.list_name}" updated successfully`, 'success');
      
      // Refresh the lists
      await fetchTableLists();
    } catch (error) {
      console.error('Error updating list:', error);
      showMessage(`Error updating list: ${error instanceof Error ? error.message : 'Unknown error'}`, 'error');
    } finally {
      setIsListLoading(false);
    }
  };

  const handleEditList = (list: TableList) => {
    setListName(list.list_name);
    setSelectedListId(list.list_id || list.id || null);
    setIsSaveListDialogOpen(true);
    setListMenuOpen(false);
  };
  
  const handleOpenListMenu = (event: React.MouseEvent<HTMLButtonElement>, list: TableList) => {
    console.log('List selected for menu:', list);
    console.log('  - list_id:', list.list_id);
    console.log('  - id:', list.id);
    
    setListMenuAnchorEl(event.currentTarget);
    setActiveListItem(list);
    setListMenuOpen(true);
  };

  // Add useEffect to fetch the table lists on component mount or when user changes
  useEffect(() => {
    if (user && isAuthenticated) {
      fetchTableLists();
    }
  }, [user, isAuthenticated]);

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', overflow: 'hidden', pl: 2, pr: 2, py: 2 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Left sidebar - TOC */}
          <Paper elevation={0} sx={{ width: 300, pt: 1, pb: 2, px: 2, mr: 2, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>Table of Contents</Typography>
            <RichTreeView<ExtendedGenNode>
              items={nodes}
              expandedItems={expandedItems}
              selectedItems={selectedItem}
              onExpandedItemsChange={handleExpandedItemsChange}
              onSelectedItemsChange={handleSelectedItemChange}
              getItemId={(item) => item.id.toString()}
              getItemLabel={(item) => item.label || item.name || 'Unnamed Node'}
              slots={{
                item: CustomTreeItem,
              }}
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                '& .MuiTreeItem-content': {
                  padding: '2px 0',
                },
                '& .MuiTreeItem-label': {
                  fontSize: '0.875rem',
                },
                '& .MuiTreeItem-group': {
                  marginLeft: 2,
                  paddingLeft: 1,
                  borderLeft: '1px dashed rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          </Paper>

          {/* Right side - Report content */}
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', position: 'relative' }}>
            {/* Loading overlay */}
            {isLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            )}

            {/* Table loading overlay */}
            {isTableLoading && !isMultiTableMode && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(255, 255, 255, 0.85)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1100, // Higher than general loading overlay
                }}
              >
                <CircularProgress color="primary" size={60} thickness={4} />
                <Typography variant="h6" sx={{ mt: 3, fontWeight: 500 }}>
                  Loading Table...
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  This may take a moment for large tables
                </Typography>
              </Box>
            )}

            {/* Top Action Buttons Section */}
            <Paper elevation={0} sx={{ mb: 2, p: 2 }}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                  {!isMultiTableMode && (
                    <>
                      <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={async () => {
                          if (!carbonClient || !isClientReady) return;
                          try {
                            const newSpec = await carbonClient.getNewSpec();
                            navigate('/specification', { state: { loadedSpec: newSpec, isNew: true } });
                          } catch (error) {
                            showMessage(`Error creating new spec: ${(error as Error).message}`);
                          }
                        }}
                        disabled={!isClientReady}
                        size="small"
                        sx={{ bgcolor: theme.palette.primary.main, '&:hover': { bgcolor: theme.palette.primary.dark } }}
                      >
                        New
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={handleEditSpec}
                        disabled={!isTableSelected || isLoading}
                        size="small"
                        sx={{ 
                          bgcolor: theme.palette.primary.main, 
                          '&:hover': { bgcolor: theme.palette.primary.dark },
                          color: theme.palette.primary.contrastText
                        }}
                      >
                        Edit Spec
                      </Button>
                      {excelUri && (
                        <Button
                          variant="contained"
                          startIcon={<DownloadIcon />}
                          component="a"
                          href={excelUri}
                          download
                          disabled={isLoading}
                          size="small"
                          sx={{ bgcolor: theme.palette.primary.main, '&:hover': { bgcolor: theme.palette.primary.dark } }}
                        >
                          Download XLSX
                        </Button>
                      )}
                    </>
                  )}
                  <Button
                    variant={isMultiTableMode ? "contained" : "outlined"}
                    startIcon={<ViewQuiltIcon />}
                    onClick={handleMultiTableModeToggle}
                    disabled={isLoading}
                    size="small"
                    sx={{ 
                      ...(isMultiTableMode 
                        ? { bgcolor: theme.palette.secondary.main, '&:hover': { bgcolor: theme.palette.secondary.dark } }
                        : {}),
                    }}
                  >
                    Multi Table Mode
                  </Button>
                  {isMultiTableMode && selectedTables.filter(t => t.excelUri).length > 0 && (
                    <Button
                      variant="contained"
                      startIcon={<CloudDownloadIcon />}
                      onClick={handleDownloadAllExcelFiles}
                      size="small"
                      sx={{ 
                        bgcolor: theme.palette.success.main, 
                        '&:hover': { bgcolor: theme.palette.success.dark },
                        color: theme.palette.success.contrastText,
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Download All
                        <Badge 
                          badgeContent={selectedTables.filter(t => t.excelUri).length} 
                          color="error"
                          sx={{ ml: 1 }}
                        />
                      </Box>
                    </Button>
                  )}
                  {isMultiTableMode && (
                    <>
                      {currentList && hasListChanges && (
                        <Button
                          variant="contained"
                          color="warning"
                          startIcon={<SaveIcon />}
                          onClick={handleUpdateCurrentList}
                          disabled={isListLoading}
                          size="small"
                          sx={{ 
                            color: 'white',
                          }}
                        >
                          Update List
                        </Button>
                      )}
                      {selectedTables.length > 0 && (
                        <Button
                          variant="contained"
                          startIcon={<SaveIcon />}
                          onClick={() => {
                            // Pre-fill with current list data if editing current list
                            if (currentList) {
                              setListName(currentList.list_name);
                              setSelectedListId(currentList.list_id || currentList.id || null);
                            } else {
                              setListName('');
                              setSelectedListId(null);
                            }
                            setIsSaveListDialogOpen(true);
                          }}
                          size="small"
                          sx={{ 
                            bgcolor: theme.palette.info.main, 
                            '&:hover': { bgcolor: theme.palette.info.dark },
                            color: theme.palette.info.contrastText,
                          }}
                        >
                          {currentList ? 'Save As' : 'Save List'}
                        </Button>
                      )}
                    </>
                  )}
                  <Button
                    variant="outlined"
                    startIcon={<FolderIcon />}
                    onClick={() => {
                      setIsListManagementDialogOpen(true);
                      fetchTableLists();
                    }}
                    size="small"
                  >
                    My Lists
                  </Button>
                </Stack>
              </Stack>
            </Paper>

            {/* Current List Title - Show when a list is loaded */}
            {currentList && (
              <Paper 
                elevation={0} 
                sx={{ 
                  mb: 2, 
                  p: 2, 
                  display: 'flex', 
                  alignItems: 'center',
                  justifyContent: 'space-between', 
                  borderLeft: '4px solid',
                  borderColor: 'primary.main',
                  bgcolor: 'primary.light',
                  color: 'primary.contrastText'
                }}
              >
                <Typography variant="h6">
                  List: {currentList.list_name}
                </Typography>
                <Typography variant="body2">
                  {currentList.tables.length} tables
                </Typography>
              </Paper>
            )}

            {/* Report content area */}
            <Paper 
              elevation={0}
              sx={{ 
                flex: 1, 
                p: 2, 
                overflowY: 'auto', 
                display: 'flex', 
                flexDirection: 'column',
                bgcolor: theme.palette.background.paper,
                position: 'relative',
              }}
            >
              {isMultiTableMode ? (
                // Multi-table accordion view
                selectedTables.length > 0 ? (
                  <Box sx={{ width: '100%' }}>
                    {selectedTables.map((table, index) => (
                      <Accordion 
                        key={table.id}
                        expanded={expandedAccordions.includes(table.id)}
                        onChange={() => handleAccordionChange(table.id)}
                        sx={{ mb: 2 }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          sx={{ 
                            flexDirection: 'row-reverse',
                            '& .MuiAccordionSummary-expandIconWrapper': {
                              marginRight: 1
                            }
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                            <Typography sx={{ flexGrow: 1 }}>{table.name}</Typography>
                            <Box>
                              {/* Move Up/Down Buttons */}
                              <Tooltip title="Move Up">
                                <span> {/* Wrap in span to allow disabled tooltip */}
                                  <IconButton 
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleMoveTableUp(table.id);
                                    }}
                                    disabled={index === 0}
                                    sx={{ mr: 1, opacity: index === 0 ? 0.5 : 1 }}
                                  >
                                    <ArrowUpwardIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <Tooltip title="Move Down">
                                <span> {/* Wrap in span to allow disabled tooltip */}
                                  <IconButton 
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleMoveTableDown(table.id);
                                    }}
                                    disabled={index === selectedTables.length - 1}
                                    sx={{ mr: 1, opacity: index === selectedTables.length - 1 ? 0.5 : 1 }}
                                  >
                                    <ArrowDownwardIcon fontSize="small" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              
                              {table.isLoading ? (
                                <CircularProgress size={20} thickness={4} sx={{ mr: 2 }} />
                              ) : (
                                table.excelUri && (
                                  <Tooltip title="Download Excel">
                                    <IconButton 
                                      size="small" 
                                      component="a"
                                      href={table.excelUri}
                                      download
                                      onClick={(e) => e.stopPropagation()}
                                      sx={{ mr: 1 }}
                                    >
                                      <DownloadIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )
                              )}
                              <Tooltip title="Remove from selection">
                                <IconButton 
                                  size="small" 
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveTableFromSelection(table.id);
                                  }}
                                >
                                  <RemoveCircleOutlineIcon fontSize="small" color="error" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          {table.isLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                              <CircularProgress />
                            </Box>
                          ) : table.tableData ? (
                            <Box sx={{ 
                              overflowX: 'auto',
                              border: '1px solid #e0e0e0',
                              borderRadius: '0px',
                              '& *': { fontFamily: theme.typography.fontFamily }
                            }}>
                              <JsonTable 
                                data={table.tableData}
                                fullscreen={false}
                                wrapText={isTextWrapped}
                                isSearchVisible={isSearchVisible}
                                onFullscreenChange={() => {}} // Disabled in multi-table mode
                                onWrapTextChange={setIsTextWrapped}
                                onSearchVisibleChange={setIsSearchVisible}
                                displayOptions={table.displayOptions}
                                onDisplayOptionChange={(key) => handleTableDisplayOptionChange(table.id, key)}
                                onApplyDisplayOptions={() => handleApplyTableDisplayOptions(table.id)}
                                isDisplayOptionsDisabled={false}
                              />
                            </Box>
                          ) : (
                            <Typography variant="body2" sx={{ p: 2 }}>
                              Failed to load table data.
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <Typography variant="h6" gutterBottom>
                      Multi-Table Mode Active
                    </Typography>
                    <Typography variant="body1" color="text.secondary" align="center">
                      Click the + button next to any table in the Table of Contents to add it to this view.
                    </Typography>
                  </Box>
                )
              ) : tableData ? (
                // Single table view (original view)
                <Box sx={{ 
                  position: 'relative',
                  overflowX: 'auto',
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  '& *': { fontFamily: theme.typography.fontFamily }
                }}>
                  <JsonTable 
                    data={tableData}
                    fullscreen={isFullscreen}
                    wrapText={isTextWrapped}
                    isSearchVisible={isSearchVisible}
                    onFullscreenChange={setIsFullscreen}
                    onWrapTextChange={setIsTextWrapped}
                    onSearchVisibleChange={setIsSearchVisible}
                    displayOptions={pendingDisplayOptions}
                    onDisplayOptionChange={handleDisplayOptionChange}
                    onApplyDisplayOptions={handleApplyDisplayOptions}
                    isDisplayOptionsDisabled={!isTableSelected || isLoading}
                  />
                </Box>
              ) : (
                <Typography variant="body1" color="text.secondary">
                  No report generated yet. Select a report from the table of contents.
                </Typography>
              )}
            </Paper>
          </Box>

          {/* Report Delete Dialog */}
          <Dialog open={deleteDialogOpen} onClose={cancelDeleteReport}>
            <DialogTitle>Delete Report</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete the report "{reportToDelete?.name || reportToDelete?.value1}"?
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                This action cannot be undone.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelDeleteReport}>Cancel</Button>
              <Button onClick={confirmDeleteReport} color="error" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          {/* Save List Dialog */}
          <Dialog open={isSaveListDialogOpen} onClose={() => setIsSaveListDialogOpen(false)}>
            <DialogTitle>{selectedListId ? 'Update List' : 'Save Table List'}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="list-name"
                label="List Name"
                type="text"
                fullWidth
                value={listName}
                onChange={(e) => setListName(e.target.value)}
                variant="outlined"
                required
              />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                This will save a list of the {selectedTables.length} currently selected tables in the order they appear.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsSaveListDialogOpen(false)}>Cancel</Button>
              <Button 
                onClick={handleSaveList} 
                variant="contained" 
                disabled={!listName.trim() || isListLoading}
              >
                {isListLoading ? <CircularProgress size={24} /> : (selectedListId ? 'Update' : 'Save')}
              </Button>
            </DialogActions>
          </Dialog>

          {/* List Management Dialog */}
          <Dialog 
            open={isListManagementDialogOpen} 
            onClose={() => setIsListManagementDialogOpen(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>My Table Lists</DialogTitle>
            <DialogContent>
              {isListLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                  <CircularProgress />
                </Box>
              ) : tableLists.length > 0 ? (
                <List sx={{ width: '100%' }}>
                  {tableLists.map((list) => (
                    <ListItem
                      key={list.list_id}
                      sx={{ 
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        mb: 1,
                        '&:hover': { bgcolor: 'action.hover' }
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                            {list.list_name}
                          </Typography>
                        }
                        secondary={
                          <Box>
                            <Typography variant="body2" color="text.secondary">
                              {list.tables.length} tables
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              Updated: {new Date(list.updated_at).toLocaleDateString()}
                            </Typography>
                          </Box>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleLoadList(list)}
                          sx={{ mr: 1 }}
                        >
                          Load
                        </Button>
                        <IconButton
                          edge="end"
                          onClick={(e) => handleOpenListMenu(e, list)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body1" align="center" sx={{ py: 4 }}>
                  You haven't saved any table lists yet.
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsListManagementDialogOpen(false)}>Close</Button>
            </DialogActions>
          </Dialog>

          {/* List Item Menu */}
          <Menu
            anchorEl={listMenuAnchorEl}
            open={listMenuOpen}
            onClose={() => setListMenuOpen(false)}
          >
            <MenuItem onClick={() => {
              if (activeListItem) {
                handleLoadList(activeListItem, false); // false = add to current selection
              }
            }}>
              Add to Current Selection
            </MenuItem>
            <MenuItem onClick={() => {
              if (activeListItem) {
                handleEditList(activeListItem);
              }
            }}>
              Edit
            </MenuItem>
            <MenuItem onClick={() => {
              setIsConfirmDeleteOpen(true);
              setListMenuOpen(false);
            }} sx={{ color: 'error.main' }}>
              Delete
            </MenuItem>
          </Menu>

          {/* Confirm Delete Dialog */}
          <Dialog open={isConfirmDeleteOpen} onClose={() => setIsConfirmDeleteOpen(false)}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete the list "{activeListItem?.list_name}"?
              </Typography>
              {activeListItem && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  List ID: {activeListItem.list_id || activeListItem.id || 'Unknown'}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                setIsConfirmDeleteOpen(false);
                setActiveListItem(null); // Clear the active item when canceling
              }}>
                Cancel
              </Button>
              <Button 
                onClick={() => {
                  console.log('Delete button clicked, activeListItem:', activeListItem);
                  if (activeListItem) {
                    handleDeleteList(activeListItem);
                  } else {
                    showMessage('Cannot delete: Invalid list item', 'error');
                    setIsConfirmDeleteOpen(false);
                  }
                }} 
                color="error" 
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          {/* Existing dialogs and components */}
          {/* ... existing dialogs ... */}
        </>
      )}
    </Box>
  );
};