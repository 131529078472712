import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Alert,
  Divider
} from '@mui/material';
import { ExtendedGenNode } from '../types';

interface FilterCreationModalProps {
  open: boolean;
  onClose: () => void;
  onAdd: (filterNode: ExtendedGenNode) => void;
  sourceVariable: ExtendedGenNode | null;
  targetVariable: ExtendedGenNode | null;
  carbonClient: any;
}

export const FilterCreationModal: React.FC<FilterCreationModalProps> = ({
  open,
  onClose,
  onAdd,
  sourceVariable,
  targetVariable,
  carbonClient,
}) => {
  const [error, setError] = useState<string | null>(null);
  
  // Source variable state
  const [sourceAvailableCodes, setSourceAvailableCodes] = useState<ExtendedGenNode[]>([]);
  const [selectedSourceCodes, setSelectedSourceCodes] = useState<string[]>([]);

  // Reset state when modal opens
  useEffect(() => {
    if (open) {
      // Don't reset selected codes - will be set when codes are fetched
      setError(null);
    }
  }, [open]);
  
  // Fetch codes for source variable
  useEffect(() => {
    const fetchSourceCodes = async () => {
      if (!sourceVariable || !carbonClient) return;

      try {
        const nodeName = sourceVariable.value1 || sourceVariable.name;
        if (!nodeName) {
          setError('Source variable has no name');
          return;
        }

        const variables = await carbonClient.getVarNodes(nodeName);
        if (variables?.[0]?.children) {
          const sourceCodes = variables[0].children;
          setSourceAvailableCodes(sourceCodes);
          // Select all codes by default
          setSelectedSourceCodes(sourceCodes.map((code: ExtendedGenNode) => code.value1 || '').filter(Boolean));
        } else {
          setError('No codes found for source variable');
        }
      } catch (error: any) {
        setError(`Error fetching source codes: ${error instanceof Error ? error.message : String(error)}`);
      }
    };

    if (open && sourceVariable) {
      fetchSourceCodes();
    }
  }, [sourceVariable, carbonClient, open]);

  const handleCreate = () => {
    if (!sourceVariable) {
      setError('No source variable selected');
      return;
    }
    
    // For creating a filter, we need both source variable and selected source codes
    if (selectedSourceCodes.length === 0) {
      setError('Please select at least one code from the source variable');
      return;
    }

    // Make sure we have a target variable
    if (!targetVariable) {
      setError('No target variable selected');
      return;
    }

    const filterId = `filter-${Date.now()}`;
    const targetCodeframeId = `target-codeframe-${Date.now()}`;

    // Create a new target node with all its codes
    const targetNode: ExtendedGenNode = {
      ...(targetVariable || {}),
      type: 'Codeframe',
      id: targetCodeframeId,
      parentId: filterId,
      isExpanded: true,
      isFolder: false,
      value1: targetVariable.value1 || targetVariable.name || '',
      value2: targetVariable.value2 || '',
      meta: targetVariable.meta || [{ key: 'Base', value: 'cwf' }],
      level: 2,
      anyChildren: true,
      name: targetVariable.name || '',
      children: targetVariable.children?.map(code => ({
        ...code,
        id: `${targetCodeframeId}_${code.id}`,
        parentId: targetCodeframeId,
        level: 3
      }))
    };

    // Format the filter value
    const sourceVarName = sourceVariable.value1 || sourceVariable.name || '';
    
    // Check if all available codes are selected
    const allCodesSelected = sourceAvailableCodes.length > 0 && 
      sourceAvailableCodes.every(code => 
        selectedSourceCodes.includes(code.value1 || '')
      );

    let filterValue: string;
    if (allCodesSelected) {
      // If all codes are selected, use asterisk
      filterValue = `${sourceVarName}(*)`;
    } else {
      // Format codes using proper syntax with ranges and semicolons
      // First, convert to numbers and sort
      const sortedCodes = selectedSourceCodes
        .map(code => parseInt(code, 10))
        .filter(code => !isNaN(code))
        .sort((a, b) => a - b);
      
      // Group consecutive codes into ranges
      const ranges: string[] = [];
      let rangeStart = sortedCodes[0];
      let rangeEnd = sortedCodes[0];
      
      for (let i = 1; i < sortedCodes.length; i++) {
        if (sortedCodes[i] === rangeEnd + 1) {
          // Continue the current range
          rangeEnd = sortedCodes[i];
        } else {
          // End the current range and start a new one
          if (rangeStart === rangeEnd) {
            ranges.push(`${rangeStart}`);
          } else {
            ranges.push(`${rangeStart}/${rangeEnd}`);
          }
          rangeStart = sortedCodes[i];
          rangeEnd = sortedCodes[i];
        }
      }
      
      // Add the last range
      if (rangeStart === rangeEnd) {
        ranges.push(`${rangeStart}`);
      } else {
        ranges.push(`${rangeStart}/${rangeEnd}`);
      }
      
      // Join ranges with semicolons
      filterValue = `${sourceVarName}(${ranges.join(';')})`;
    }

    // Create the filter node
    const filterNode: ExtendedGenNode = {
      id: filterId,
      parentId: null, // Root level node
      type: 'Filter',
      name: 'Filter',
      value1: filterValue,
      value2: '',
      meta: [{ key: 'Base', value: 'cwf' }],
      isExpanded: true,
      isFolder: true,
      level: 1,
      anyChildren: true,
      children: [targetNode]
    };
    
    // Call the onAdd callback with the new filter node
    if (onAdd) {
      onAdd(filterNode);
    }
    
    // Close the modal
    onClose();
  };

  const renderCodeSelection = (
    availableCodes: ExtendedGenNode[],
    selectedCodes: string[],
    setSelectedCodes: (codes: string[]) => void,
    title: string
  ) => (
    <FormControl fullWidth>
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>
      {availableCodes.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCodes.length === availableCodes.length}
                indeterminate={selectedCodes.length > 0 && selectedCodes.length < availableCodes.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedCodes(availableCodes.map(code => code.value1 || '').filter(Boolean));
                  } else {
                    setSelectedCodes([]);
                  }
                }}
              />
            }
            label="Select All"
          />
          <Divider sx={{ my: 1 }} />
          <Box sx={{ 
            maxHeight: '300px', 
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5
          }}>
            {availableCodes.map((code) => (
              <FormControlLabel
                key={code.id}
                sx={{ 
                  py: 0.5,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                  margin: 0,
                  '&:last-child': {
                    borderBottom: 'none'
                  }
                }}
                control={
                  <Checkbox
                    checked={selectedCodes.includes(code.value1 || '')}
                    onChange={(e) => {
                      const value = code.value1 || '';
                      if (e.target.checked) {
                        setSelectedCodes([...selectedCodes, value]);
                      } else {
                        setSelectedCodes(selectedCodes.filter(c => c !== value));
                      }
                    }}
                  />
                }
                label={`${code.value1 || ''} ${code.value2 || ''}`}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Typography color="text.secondary">
          No codes available for this variable
        </Typography>
      )}
    </FormControl>
  );

  const getNodeDisplayName = (node: ExtendedGenNode | null): string => {
    if (!node) return '';
    return node.value1 || node.name || '';
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Filter Codes</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {!sourceVariable && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              No source variable detected. Please ensure you have selected a source variable for filtering.
            </Alert>
          )}

          {!targetVariable && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              No target variable detected. Please ensure you have selected a target variable to be filtered.
            </Alert>
          )}

          {/* Show source and target information */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Filter Configuration
            </Typography>
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  Source Variable (Filter By):
                </Typography>
                <Typography variant="body1">
                  {getNodeDisplayName(sourceVariable)}
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  Target Variable (To Be Filtered):
                </Typography>
                <Typography variant="body1">
                  {getNodeDisplayName(targetVariable)}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Alert severity="info" sx={{ mb: 3 }}>
            Select codes from {getNodeDisplayName(sourceVariable)} to filter by
          </Alert>
          
          {renderCodeSelection(
            sourceAvailableCodes,
            selectedSourceCodes,
            setSelectedSourceCodes,
            `Select codes from ${getNodeDisplayName(sourceVariable)}:`
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleCreate}
          variant="contained"
          color="primary"
          disabled={
            !sourceVariable || 
            !targetVariable || 
            selectedSourceCodes.length === 0
          }
        >
          {selectedSourceCodes.length === 0 ? 'Select At Least One Code' : 'Create Filter'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 