import axios, { AxiosInstance } from 'axios'
import {
  SessionInfo,
  RunSpecRequest,
  GenericResponse,
  ChangePasswordRequest,
  UpdateAccountRequest,
  OpenCloudJobResponse,
  XlsxResponse,
  MultiOxtRequest,
  MultiOxtResponse,
  QuickUpdateRequest,
  ValidateExpRequest,
  AzDashboard,
  UpsertDashboardRequest,
  DashboardRequest,
  TocNode,
  GenNode,
  ServiceInfo,
  SessionStatus,
  CloseJobResponse,
  LogoffResponse,
  SpecAggregate,
  GenTabRequest,
  XDisplayProperties, // Add this import if not already present
  ICarbonClient, // Make sure this import is present
  JobInfo,
  CustomerInfo,
  ResetPasswordRequest,
  ImportSettings
} from '../types/types'

const BASE_URL = process.env.REACT_APP_BPR_CARBON_URL || 'https://bayesprice.azurewebsites.net/carbontest/'
const LICENSING_URL = process.env.REACT_APP_BPR_LICENSING_URL || 'https://bayesprice.azurewebsites.net/licensing/'
const LICENSING_API_KEY = process.env.REACT_APP_LICENSING_API_KEY || '316227' // Provide fallback for development

console.log('Environment variables loaded:', {
  REACT_APP_BPR_CARBON_URL: process.env.REACT_APP_BPR_CARBON_URL,
  REACT_APP_BPR_LICENSING_URL: process.env.REACT_APP_BPR_LICENSING_URL,
  REACT_APP_LICENSING_API_KEY: process.env.REACT_APP_LICENSING_API_KEY ? 'Present' : 'Missing'
});

console.log('Using URLs:', {
  BASE_URL,
  LICENSING_URL,
  LICENSING_API_KEY: LICENSING_API_KEY ? 'Present' : 'Missing'
});

export class CarbonClient implements ICarbonClient {
  private axiosInstance: AxiosInstance
  private licensingAxiosInstance: AxiosInstance
  private sessionInfo: SessionInfo | null = null
  private isJobOpen: boolean = false
  private currentLoadedReport: string | null = null // Track the currently loaded report name

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.licensingAxiosInstance = axios.create({
      baseURL: LICENSING_URL,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': LICENSING_API_KEY,
      },
    })

    // Add an interceptor to always include the session ID if available
    this.axiosInstance.interceptors.request.use((config) => {
      if (this.sessionInfo && this.sessionInfo.sessionId) {
        config.headers['x-session-id'] = this.sessionInfo.sessionId
        console.log('Adding x-session-id to request:', this.sessionInfo.sessionId)
      } else {
        console.log('No session ID available for request')
      }
      return config
    })

    // Add an interceptor for the licensing instance to ensure API key is included and log requests
    this.licensingAxiosInstance.interceptors.request.use((config) => {
      if (!config.headers['x-api-key']) {
        config.headers['x-api-key'] = LICENSING_API_KEY;
      }
      const fullUrl = `${config.baseURL || LICENSING_URL}${config.url || ''}`;
      console.log('Making licensing request to:', fullUrl);
      console.log('Request headers:', config.headers);
      return config;
    })

    // Add response interceptor to log responses
    this.licensingAxiosInstance.interceptors.response.use(
      (response) => {
        console.log('Licensing response from:', response.config?.url || '');
        console.log('Response status:', response.status);
        console.log('Response data:', response.data);
        return response;
      },
      (error) => {
        console.error('Licensing request error:', error.config?.url || '');
        console.error('Error response:', error.response?.data);
        throw error;
      }
    );
  }

  setSessionInfo(sessionInfo: SessionInfo | null) {
    this.sessionInfo = sessionInfo
  }

  isAuthenticated(): boolean {
    return this.sessionInfo !== null;
  }

  // Comment: This method aligns with the /session/start/authenticate/name endpoint in the swagger spec
  async authenticate(name: string, password: string): Promise<SessionInfo> {
    try {
      const response = await this.axiosInstance.post('session/start/authenticate/name', {
        name,
        password,
        skipCache: true,
      });

      // Handle the case where we get a 302 response indicating an active session
      if (response.data.code === 302) {
        console.log('Received 302 response:', response.data);
        const error = new Error(response.data.message || 'User already has active sessions');
        error.name = 'ActiveSessionError';
        (error as any).sessions = response.data.data;
        throw error;
      }

      // Handle the normal successful response
      const sessionInfo = response.data;
      this.setSessionInfo(sessionInfo);
      return sessionInfo;
    } catch (error: any) {
      console.error('Authentication error:', error);
      // If this is already an ActiveSessionError, re-throw it
      if (error.name === 'ActiveSessionError') {
        throw error;
      }
      // If this is an axios error with a 302 response, convert it to an ActiveSessionError
      if (axios.isAxiosError(error) && error.response?.data?.code === 302) {
        console.log('Received 302 error response:', error.response.data);
        const activeError = new Error(error.response.data.message || 'User already has active sessions');
        activeError.name = 'ActiveSessionError';
        (activeError as any).sessions = error.response.data.data;
        throw activeError;
      }
      // Otherwise, throw the original error
      throw error;
    }
  }

  // Add a new method to handle ending multiple sessions
  async endExistingSessions(sessionIds: string[]): Promise<void> {
    try {
      for (const sessionId of sessionIds) {
        await axios.delete(`${BASE_URL}session/end`, {
          headers: {
            'Content-Type': 'application/json',
            'x-session-id': sessionId
          }
        });
        console.log(`Successfully ended session: ${sessionId}`);
      }
    } catch (error) {
      console.error('Error ending sessions:', error);
      throw error;
    }
  }

  // Update openCloudJob method
  async openCloudJob(customerName: string, job: JobInfo): Promise<OpenCloudJobResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    
    const requestBody = {
      sessionId: this.sessionInfo?.sessionId,
      customerName,
      jobName: job.name,
      vartreeName: "VarTree", // Default vartree name
      getDisplayProps: true,
      getVartreeNames: true,
      getAxisTreeNames: true,
      tocType: 1, // Assuming JobTocType.Full
      getDrills: true
    }
    
    try {
      console.log('Opening cloud job, current loaded report:', this.currentLoadedReport);
      const response = await this.axiosInstance.post<OpenCloudJobResponse>('job/open', requestBody);
      this.isJobOpen = true;
      
      // Reset the current loaded report when opening a job
      // as the job open operation likely unloads any previously loaded report
      const previousReport = this.currentLoadedReport;
      this.currentLoadedReport = null;
      console.log('Job opened, report tracking reset from:', previousReport, 'to null');

      // Save dProps to localStorage
      if (response.data.dProps) {
        console.log('dProps received:', response.data.dProps);
        localStorage.setItem('dProps', JSON.stringify(response.data.dProps));
        console.log('dProps saved to localStorage');
      } else {
        console.warn('No dProps received in the response');
      }

      return response.data;
    } catch (error) {
      console.error('Error opening cloud job:', error);
      throw error;
    }
  }

  // Comment: Method to close the job
  async closeJob(): Promise<CloseJobResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.delete('job/close')
    this.isJobOpen = false
    return response.data
  }

  // Comment: Method to logoff the session
  async logoff(): Promise<LogoffResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.delete('session/end/logoff')
    return response.data
  }

  async getTocNodes(): Promise<TocNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    
    try {
      console.log('Fetching TOC nodes...');
      const response = await this.axiosInstance.get<TocNode[]>(`job/toc/simple/true`);
      console.log('TOC nodes response:', response.data);
      
      if (!response.data || !Array.isArray(response.data)) {
        throw new Error('Invalid response data for TOC nodes');
      }

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error response:', error.response?.data);
        throw new Error(`Failed to fetch TOC nodes: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  // Update the mapToGenNodes method
  private mapToGenNodes(nodes: any[], parentId: string | null = null): TocNode[] {
    return nodes.map(node => {
      // Determine the node type
      const nodeType = this.getNodeType(node.type)

      // Create the TocNode object
      const tocNode: TocNode = {
        name: node.value1 || '',
        type: nodeType,
        id: node.id,
        parentId: parentId || null,
        isExpanded: false,
        isFolder: nodeType === 'Folder',
        value1: node.value1,
        value2: node.value2,
        meta: node.meta || null,
        children: node.children ? this.mapToGenNodes(node.children, node.id) : null,
        level: 0,
        anyChildren: !!node.children
      }

      // Set additional properties based on node type
      switch (nodeType) {
        case 'Section':
        case 'Folder':
        case 'Table':
        case 'User':
          tocNode.value1 = node.value1 || ''
          tocNode.value2 = node.value2 || ''
          break
        default:
          // For unknown types, we'll keep the default values
          break
      }

      return tocNode
    })
  }

  // Comment: Helper method to determine the node type
  private getNodeType(type: string): string {
    // Define known types
    const knownTypes = ['Section', 'Folder', 'Table', 'User']
    
    // If the type is known, return it; otherwise, return 'Unknown'
    return knownTypes.includes(type) ? type : 'Unknown'
  }

  // Update setVartreeName method
  async setVartreeName(treeName: string, jobName: string, customerName: string): Promise<boolean> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('job/vartree/set', {
      treeName,
      jobName,
      customerName
    })
    return response.data
  }

  // Comment: This method aligns with the /job/vartree/nodes endpoint in the swagger spec
  async variableTreeAsNodes(): Promise<TocNode[]> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get('job/vartree/nodes')
    return response.data
  }

  // Update method signature to match ICarbonClient interface
  async runSpec(spec: RunSpecRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Running specification:', spec);
      const response = await this.axiosInstance.post<GenericResponse>('job/spec', spec);
      console.log('Run specification response:', response.data);
      
      // When running a spec, a new report is loaded
      if (response.data && (!('code' in response.data) || response.data.code === 0)) {
        // The spec name becomes the loaded report
        this.currentLoadedReport = spec.name || 'Untitled Report';
        console.log(`Spec run successfully, tracking report as: ${this.currentLoadedReport}`);
      }
      
      return response.data;
    } catch (error) {
      console.error('Error running spec:', error);
      throw error;
    }
  }

  // Update loadReport method
  public async LoadReportAsync(value1: string, value2: string): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      console.error('Authentication check failed in LoadReportAsync');
      throw new Error('Not authenticated');
    }

    // Store the original input parameters for debugging
    const originalValue1 = value1;
    const originalValue2 = value2;

    // Combine path components safely
    const path = value2 ? `${value2}/${value1}` : value1;
    const combinedPath = path.replace(/^\/+|\/+$/g, '');
    
    // Add .cbt extension only if it doesn't already exist
    const reportName = combinedPath.toLowerCase().endsWith('.cbt') 
      ? combinedPath 
      : `${combinedPath}.cbt`;
    
    // Store clean report name (without extension) for tracking
    const cleanReportName = reportName.replace(/\.cbt$/i, '');
    
    console.log('LoadReportAsync request:');
    console.log('- Original values:', { value1: originalValue1, value2: originalValue2 });
    console.log('- Combined path:', combinedPath);
    console.log('- Final report name:', reportName);
    console.log('- Clean report name for tracking:', cleanReportName);
    console.log('- Previous loaded report:', this.currentLoadedReport);

    try {
      const response = await this.axiosInstance.post<GenericResponse>('report/load', { Name: reportName });
      console.log('LoadReportAsync response:', response.data);
      
      // Update the currently loaded report name if load was successful
      if (response.data && (!('code' in response.data) || response.data.code === 0)) {
        this.currentLoadedReport = cleanReportName;
        console.log(`Report loaded successfully, tracking now: ${this.currentLoadedReport}`);
        
        // Store in localStorage for debugging
        try {
          localStorage.setItem('lastLoadedReport', cleanReportName);
        } catch (e) {
          console.warn('Failed to store lastLoadedReport in localStorage:', e);
        }
      } else {
        console.warn(`Failed to load report: ${reportName}`, response.data);
        
        if ('message' in response.data && 
            response.data.message && 
            response.data.message.includes('does not exist')) {
          console.error(`Report file not found: ${reportName}`);
        }
      }

      return response.data;
    } catch (error) {
      console.error('Error in LoadReportAsync:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        throw new Error(`LoadReportAsync failed: ${error.response?.status} - ${JSON.stringify(error.response?.data)}`);
      }
      throw new Error(`LoadReportAsync failed: ${(error as Error).message}`);
    }
  }

  // Comment: This method aligns with the /report/xlsx endpoint in the swagger spec
  async getReportXlsx(reportName: string): Promise<XlsxResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    
    try {
      const response = await this.axiosInstance.get<XlsxResponse>('report/xlsx', { params: { reportName } });
      return response.data;
    } catch (error) {
      console.error('Error fetching XLSX:', error);
      throw error;
    }
  }

  // Update multiOxt method
  async multiOxt(request: MultiOxtRequest): Promise<MultiOxtResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('report/multioxt', request)
    return response.data
  }

  // Update quickUpdate method
  async quickUpdate(request: QuickUpdateRequest): Promise<XlsxResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('report/quickupdate', request)
    return response.data
  }

  // Implement listSessions method
  async listSessions(): Promise<SessionStatus[]> {
    const response = await this.axiosInstance.get('session/list')
    return response.data
  }

  // Update the method signature to match ICarbonClient interface
  async changePassword(request: ChangePasswordRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Changing password');
      const response = await this.licensingAxiosInstance.post<GenericResponse>('user/password/change', request);
      console.log('Change password response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error changing password:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to change password: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to change password: ${(error as Error).message}`);
    }
  }

  async updateAccount(request: UpdateAccountRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    // Implement the API call using the request object
    const response = await this.axiosInstance.post('account/update', request)
    return response.data
  }

  async getServiceInfo(): Promise<ServiceInfo> {
    const response = await this.axiosInstance.get('service/info')
    return response.data
  }

  async listDashboards(customerName: string, jobName: string): Promise<AzDashboard[]> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get(`dashboard/list/${customerName}/${jobName}`)
    return response.data
  }

  // Comment: Implement getDashboard method
  async getDashboard(request: DashboardRequest): Promise<AzDashboard> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('dashboard/get', request)
    return response.data
  }

  // Comment: Implement deleteDashboard method
  async deleteDashboard(request: DashboardRequest): Promise<boolean> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('dashboard/delete', request)
    return response.data
  }

  // Comment: Implement upsertDashboard method
  async upsertDashboard(request: UpsertDashboardRequest): Promise<AzDashboard> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('dashboard/upsert', request)
    return response.data
  }

  // Comment: Implement validateExpression method
  async validateExpression(request: ValidateExpRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('job/validate/expression', request)
    return response.data
  }

  // Implement listVartrees method
  async listVartrees(): Promise<string[]> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get('job/vartree/list')
    return response.data
  }

  // Comment: Add getCustomers method to retrieve customer names from sessionInfo
  async getCustomers(): Promise<CustomerInfo[]> {
    if (!this.sessionInfo) {
      throw new Error('Not authenticated')
    }
    return this.sessionInfo.sessionCusts;
  }

  // Comment: Add getJobs method to retrieve job names for a specific customer
  async getJobs(customerName: string): Promise<JobInfo[]> {
    if (!this.sessionInfo) {
      throw new Error('Not authenticated')
    }
    const customer = this.sessionInfo.sessionCusts.find(cust => cust.name === customerName)
    if (!customer) {
      throw new Error(`Customer ${customerName} not found`)
    }
    return customer.sessionJobs;
  }

  // Add this method to your CarbonClient class
  async getVarNodes(nodeName: string): Promise<GenNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    if (!nodeName) {
      console.error('Node name is empty');
      return [];
    }
    try {
      console.log(`Fetching variables for node: ${nodeName}`);
      const response = await this.axiosInstance.get<GenNode[]>(`job/varnodes/${encodeURIComponent(nodeName)}`);
      console.log(`Response for ${nodeName}:`, response.data);
      return response.data;
    } catch (error) {
      console.error(`Error fetching variables for node ${nodeName}:`, error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        console.error(`API Error: ${errorMessage}`);
        console.error('Full error response:', error.response);
        if (error.response?.status === 404 || error.response?.status === 500) {
          console.log(`No variables found for node ${nodeName}, returning empty array`);
          return [];
        }
      }
      throw error;
    }
  }

  async getAxisNodes(nodeName: string): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>(`job/axisnodes/${nodeName}`);
    return response.data;
  }

  async getFunctionNodes(nodeName: string): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>(`job/functionnodes/${nodeName}`);
    return response.data;
  }

  async getSpecAggregate(): Promise<SpecAggregate> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    const response = await this.axiosInstance.get<SpecAggregate>('job/spec/edit');
    return response.data;
  }

  // Add these methods to your CarbonClient class
  async getVariableTree(): Promise<GenNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get<GenNode[]>('job/vartree/nodes');
    return response.data;
  }

  async getAxisTree(): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>('job/axistree/nodes');
    return response.data;
  }

  async getFunctionTree(): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>('job/function/nodes');
    return response.data;
  }

  public async initialize(): Promise<void> {
    // Implement the initialization logic here
    // For example:
    try {
      // Perform any necessary setup
      console.log('CarbonClient initialized successfully');
    } catch (error) {
      console.error('Failed to initialize CarbonClient:', error);
      throw error;
    }
  }

  async startJob(jobType: 'sentiment' | 'complaint-theme', data: any): Promise<any> {
    const response = await this.axiosInstance.post(`jobs/${jobType}`, data);
    return response.data;
  }

  async getMetadata(jobId: string): Promise<any> {
    const response = await this.axiosInstance.get(`Surveys/${jobId}/Metadata`);
    return response.data;
  }

  async getInterviews(jobId: string): Promise<any> {
    const response = await this.axiosInstance.get(`Surveys/${jobId}/Interviews`);
    return response.data;
  }

  async deleteJob(jobId: string): Promise<void> {
    await this.axiosInstance.delete(`Surveys/${jobId}`);
  }

  // Add this method to the CarbonClient class
  async updateReportDisplay(options: QuickUpdateRequest): Promise<XlsxResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    const response = await this.axiosInstance.post<XlsxResponse>('report/quickupdate', options);
    return response.data;
  }

  // Add this public method to check if a job is open
  public isJobCurrentlyOpen(): boolean {
    return this.isJobOpen;
  }

  async getCurrentSpec(): Promise<RunSpecRequest | null> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<RunSpecRequest>('job/spec/edit');
      return response.data;
    } catch (error) {
      console.error('Error getting current spec:', error);
      return null;
    }
  }

  async getNewSpec(): Promise<RunSpecRequest | null> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<RunSpecRequest>('job/spec/edit', {});
      return response.data;
    } catch (error) {
      console.error('Error getting new spec:', error);
      return null;
    }
  }

  async saveSpec(spec: RunSpecRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Saving specification:', spec);
      const response = await this.axiosInstance.post<GenericResponse>('job/spec/save', spec);
      console.log('Save specification response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error saving spec:', error);
      throw error;
    }
  }

  async editSpec(spec: RunSpecRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Editing specification:', spec);
      const response = await this.axiosInstance.put<GenericResponse>('job/spec/edit', spec);
      console.log('Edit specification response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error editing spec:', error);
      throw error;
    }
  }

  public getBaseUrl(): string {
    return BASE_URL;
  }

  public getHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      ...(this.sessionInfo?.sessionId ? { 'x-session-id': this.sessionInfo.sessionId } : {})
    };
  }

  async runSpecification(request: RunSpecRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    console.log('Sending runSpecification request to:', `${this.axiosInstance.defaults.baseURL}job/spec`);
    console.log('Request data:', JSON.stringify(request, null, 2));
    try {
      const response = await this.axiosInstance.post<GenericResponse>('job/spec', request);
      console.log('RunSpecification response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in runSpecification:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      throw error;
    }
  }

  async saveCurrentSpec(spec: RunSpecRequest): Promise<void> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      await this.axiosInstance.post('job/spec/save', spec);
    } catch (error) {
      console.error('Error saving current spec:', error);
      throw error;
    }
  }

  public validateSpec = async (spec: RunSpecRequest['spec']): Promise<GenericResponse> => {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    console.log('Sending validateSpec request to:', `${this.axiosInstance.defaults.baseURL}job/spec/validate/spec`);
    console.log('Request data:', JSON.stringify(spec, null, 2));
    try {
      const response = await this.axiosInstance.post<GenericResponse>('job/spec/validate/spec', spec);
      console.log('ValidateSpec response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in validateSpec:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      throw error;
    }
  }

  public async saveReport(name: string, sub: string): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      console.error('Authentication check failed in saveReport');
      throw new Error('Not authenticated');
    }

    // Standardize name - remove any existing .cbt extension
    const originalName = name;
    const cleanName = name.replace(/\.cbt$/i, '');
    const saveName = cleanName; // Don't add .cbt here - the API handles it

    console.log('Sending saveReport request:');
    console.log('- Original name provided:', originalName);
    console.log('- Cleaned name (without .cbt):', cleanName);
    console.log('- Name being sent to API:', saveName);
    console.log('- Sub folder path:', sub || '(none)');
    console.log('- Current loaded report:', this.currentLoadedReport);
    
    try {
      // Always use the standardized name
      const saveRequest = { 
        name: saveName, 
        sub 
      };
      console.log('Save request details:', saveRequest);
      
      const response = await this.axiosInstance.post<GenericResponse>('report/save', saveRequest);
      console.log('SaveReport response:', response.data);
      
      // Update the currently loaded report name if save was successful
      if (response.data && (!('code' in response.data) || response.data.code === 0)) {
        this.currentLoadedReport = saveName;
        console.log(`Report saved successfully as "${saveName}", now tracking as current report`);
        
        // Store in localStorage for debugging and recovery
        try {
          localStorage.setItem('lastSavedReport', saveName);
          localStorage.setItem('lastSavedReportTime', new Date().toISOString());
        } catch (e) {
          console.warn('Failed to store lastSavedReport in localStorage:', e);
        }
      }

      return response.data;
    } catch (error) {
      console.error('Error in saveReport:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        console.error('Response headers:', error.response?.headers);
        throw new Error(`SaveReport failed: ${error.response?.status} - ${JSON.stringify(error.response?.data)}`);
      }
      throw new Error(`SaveReport failed: ${(error as Error).message}`);
    }
  }

  async setOutputFormat(format: string): Promise<void> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      await this.axiosInstance.post('report/format', { format });
    } catch (error) {
      console.error('Error setting output format:', error);
      throw error;
    }
  }

  private createDefaultGenTabRequest(reportName: string): GenTabRequest {
    return {
      name: reportName,
      top: '',
      side: '',
      sProps: {
        caseFilter: null,
        initAsMissing: false,
        excludeNE: false,
        padHierarchics: false,
        arithOverStats: false,
        topInsert: null,
        sideInsert: null,
        level: null,
        fullStats: false
      },
      dProps: {}
    };
  }

  async getReportHtml(reportName: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const genTabRequest = this.createDefaultGenTabRequest(reportName);
      const response = await this.axiosInstance.post<string>('job/gentab', genTabRequest);
      return response.data;
    } catch (error) {
      console.error('Error getting HTML report:', error);
      throw error;
    }
  }

  async GenTab(request: GenTabRequest, format: string | number = 'HTML'): Promise<any> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      let url: string;
      if (format === 'JSON') {
        url = `${this.getBaseUrl()}/report/gentab/pandas/1`;
      } else {
        url = `${this.getBaseUrl()}/report/gentab/text/${format}`;
      }
      const response = await this.axiosInstance.post<any>(url, request);
      return response.data;
    } catch (error) {
      console.error('Error generating report:', error);
      throw error;
    }
  }

  async getReportText(reportName: string, format: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const genTabRequest = this.createDefaultGenTabRequest(reportName);
      const response = await this.axiosInstance.post<string>(`report/gentab/text/${format}`, genTabRequest);
      return response.data;
    } catch (error) {
      console.error(`Error generating ${format} report:`, error);
      throw error;
    }
  }

  async getReportPandas(reportName: string, shape: number): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<string>(`report/gentab/pandas/${shape}`, {
        name: reportName
      });
      return response.data;
    } catch (error) {
      console.error('Error generating Pandas report:', error);
      throw error;
    }
  }

  private async ensureJobOpen(customerName: string, job: JobInfo): Promise<void> {
    if (!this.isJobOpen) {
      await this.openCloudJob(customerName, job);
      this.isJobOpen = true;
    }
  }

  async getProperties(customerName: string, jobName: string): Promise<XDisplayProperties> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<XDisplayProperties>('job/props');
      return response.data;
    } catch (error) {
      console.error('Error fetching properties:', error);
      throw error;
    }
  }

  async generateXlsx(): Promise<XlsxResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Generating XLSX report');
      const response = await this.axiosInstance.get<XlsxResponse>('report/xlsx');
      console.log('Generate XLSX response:', response.data);
      if (response.data.reportName) {
        console.log(`XLSX generated for report: ${response.data.reportName}`);
      } else {
        console.warn('XLSX generated, but no report name provided in the response');
      }
      return response.data;
    } catch (error) {
      console.error('Error generating XLSX report:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to generate XLSX report: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to generate XLSX report: ${(error as Error).message}`);
    }
  }

  async GenTabAsHTML(top: string, side: string, filter: string, weight: string, caseFilter: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Generating HTML table with params:', { top, side, filter, weight, caseFilter });
      const response = await this.axiosInstance.post<string>('report/gentab/html', { top, side, filter, weight, caseFilter });
      console.log('GenTabAsHTML response length:', response.data.length);
      return response.data;
    } catch (error) {
      console.error('Error in GenTabAsHTML:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to generate HTML table: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to generate HTML table: ${(error as Error).message}`);
    }
  }

  async AxisSyntaxToNodes(syntax: string): Promise<GenNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<GenNode[]>('report/axis/syntaxtonodes', { syntax });
      return response.data;
    } catch (error) {
      console.error('Error in AxisSyntaxToNodes:', error);
      throw error;
    }
  }

  async AxisNodesToSyntax(gn: GenNode[]): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<string>('report/axis/nodestosyntax', { nodes: gn });
      return response.data;
    } catch (error) {
      console.error('Error in AxisNodesToSyntax:', error);
      throw error;
    }
  }

  async CurrentSyntax(): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<string>('report/syntax/current');
      return response.data;
    } catch (error) {
      console.error('Error in CurrentSyntax:', error);
      throw error;
    }
  }

  async ValidateSyntax(syntax: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<string>('report/syntax/validate', { syntax });
      return response.data;
    } catch (error) {
      console.error('Error in ValidateSyntax:', error);
      throw error;
    }
  }

  async getReportSyntax(): Promise<string[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Fetching report syntax');
      const response = await this.axiosInstance.get<string | string[]>('report/syntax');
      console.log('Report syntax response:', response.data);
      
      let syntaxArray: string[];
      
      if (Array.isArray(response.data)) {
        syntaxArray = response.data;
      } else if (typeof response.data === 'string') {
        syntaxArray = response.data.split('\n').map(line => line.trim()).filter(line => line !== '');
      } else {
        console.error('Unexpected response format:', response.data);
        throw new Error('Unexpected response format from report/syntax endpoint');
      }
      
      // Ensure we have at least 5 elements (including the Case filter)
      while (syntaxArray.length < 5) {
        syntaxArray.push('');
      }
      
      return syntaxArray;
    } catch (error) {
      console.error('Error fetching report syntax:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to fetch report syntax: ${error.response.data.message || error.message}`);
      }
      throw error;
    }
  }

  // Add this new method to the CarbonClient class
  async getReportFormatHtml(): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Fetching HTML report format');
      const response = await this.axiosInstance.get<string>('report/format/html');
      console.log('HTML report format response length:', response.data.length);
      return response.data;
    } catch (error) {
      console.error('Error fetching HTML report format:', error);
      throw error;
    }
  }

  async getReportFormatHtmlWithSyntax(
    top: string,
    side: string,
    filter: string,
    weight: string,
    caseFilter: string
  ): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Fetching HTML report format with syntax values:', {
        top,
        side,
        filter,
        weight,
        caseFilter
      });
      const response = await this.axiosInstance.get<string>('report/format/html/joined', {
        params: {
          top,
          side,
          filter,
          weight,
          caseFilter
        }
      });
      console.log('HTML report format response length:', response.data.length);
      return response.data;
    } catch (error) {
      console.error('Error fetching HTML report format:', error);
      throw error;
    }
  }

  async generateTableHtml(
    top: string,
    side: string,
    filter: string,
    weight: string,
    caseFilter: string
  ): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const startTime = performance.now();
      console.log('Generating table HTML with syntax values:', {
        top,
        side,
        filter,
        weight,
        caseFilter
      });
      const response = await this.axiosInstance.post<string>('report/gentab/html/joined', {
        top,
        side,
        filter,
        weight,
        caseFilter
      });
      console.log(`Carbon API response time: ${performance.now() - startTime}ms`);
      return response.data;
    } catch (error) {
      console.error('Error generating table HTML:', error);
      throw error;
    }
  }

  async resetPassword(request: ResetPasswordRequest): Promise<GenericResponse> {
    try {
      console.log('Resetting password');
      const response = await this.licensingAxiosInstance.post<GenericResponse>('user/password/reset', request);
      console.log('Reset password response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error resetting password:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to reset password: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to reset password: ${(error as Error).message}`);
    }
  }

  // Add this method to the CarbonClient class
  async endSession(sessionId: string): Promise<void> {
    try {
      await this.axiosInstance.delete('session/end', {
        headers: {
          'x-session-id': sessionId
        }
      });
      console.log(`Session ${sessionId} ended successfully`);
    } catch (error) {
      console.error(`Error ending session ${sessionId}:`, error);
      throw error;
    }
  }

  async importPartialJob(importSettings: ImportSettings): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Importing partial job with settings:', importSettings);
      console.log('Current session ID:', this.sessionInfo?.sessionId);
      const response = await this.axiosInstance.post<GenericResponse>('job/import/partial', importSettings);
      console.log('Import partial job response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error importing partial job:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
        throw new Error(`Failed to import partial job: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to import partial job: ${(error as Error).message}`);
    }
  }

  async importFullJob(importSettings: ImportSettings): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Importing full job with settings:', importSettings);
      const response = await this.axiosInstance.post<GenericResponse>('job/import/full', importSettings);
      console.log('Import full job response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error importing full job:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to import full job: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to import full job: ${(error as Error).message}`);
    }
  }

  async getReportFormat(format: string): Promise<string[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<string[]>(`report/format/${format}`);
      return response.data;
    } catch (error) {
      console.error('Error getting report format:', error);
      throw error;
    }
  }

  // Add this getter for sessionInfo
  public getSessionInfo() {
    return this.sessionInfo;
  }

  // Add the required HTTP methods to implement ICarbonClient
  public async get(url: string): Promise<any> {
    return (await this.axiosInstance.get(url)).data;
  }

  public async post(url: string, data: any): Promise<any> {
    return (await this.axiosInstance.post(url, data)).data;
  }

  public async put(url: string, data: any): Promise<any> {
    return (await this.axiosInstance.put(url, data)).data;
  }

  public async delete(url: string): Promise<any> {
    return (await this.axiosInstance.delete(url)).data;
  }

  // Add a getter for the currently loaded report
  public getCurrentlyLoadedReport(): string | null {
    return this.currentLoadedReport;
  }

  async deleteReport(name: string, sub: string): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      console.error('Authentication check failed in deleteReport');
      throw new Error('Not authenticated');
    }

    // Store the original input parameters for debugging
    const originalName = name;
    const originalSub = sub;

    // Create a full path from the inputs
    // If we have both name and sub, combine them, otherwise just use name
    const fullPath = sub ? `${sub}/${name}` : name;
    
    // Remove any leading or trailing slashes
    const cleanPath = fullPath.replace(/^\/+|\/+$/g, '');
    
    // Add .cbt extension only if it doesn't already exist
    const finalPath = cleanPath.toLowerCase().endsWith('.cbt') 
      ? cleanPath 
      : `${cleanPath}.cbt`;
    
    console.log('Sending deleteReport request:');
    console.log('- Original params:', { name: originalName, sub: originalSub });
    console.log('- Full path:', fullPath);
    console.log('- Clean path:', cleanPath);
    console.log('- Final path being sent:', finalPath);
    
    try {
      // Send a 'Name' parameter instead of 'path' as required by the API
      const deleteRequest = { 
        Name: finalPath
      };
      console.log('Delete request details:', deleteRequest);
      
      const response = await this.axiosInstance.post<GenericResponse>('report/delete', deleteRequest);
      console.log('DeleteReport response:', response.data);
      
      // If the current loaded report is the one being deleted, reset it
      if (this.currentLoadedReport === cleanPath) {
        this.currentLoadedReport = null;
        console.log('Deleted current loaded report, tracking reset to null');
      }

      return response.data;
    } catch (error) {
      console.error('Error in deleteReport:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        throw new Error(`DeleteReport failed: ${error.response?.status} - ${JSON.stringify(error.response?.data)}`);
      }
      throw new Error(`DeleteReport failed: ${(error as Error).message}`);
    }
  }
}