import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#37474F',
      light: '#62727b',
      dark: '#102027',
      contrastText: '#fff'
    },
    secondary: {
      main: '#FFB300',
      light: '#FFE54C',
      dark: '#C68400',
      contrastText: '#000'
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 4,
          '&:hover': {
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          },
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
  },
});

export default theme; 