import React, { useState, useEffect, ErrorInfo, useCallback } from 'react';
import { Box, Paper, Typography, TextField, Button, IconButton, Tooltip, Modal, Dialog, DialogTitle, DialogContent, DialogActions, ToggleButtonGroup, ToggleButton, Snackbar, Alert, CircularProgress } from '@mui/material'
import { ArrowUpward, ArrowDownward, Delete, Percent, Functions, Close, Save as SaveIcon, Download as DownloadIcon, Edit as EditIcon } from '@mui/icons-material'
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2';
import { useAuth } from '../contexts/AuthContext'
import { GenNode, RunSpecRequest, GenericResponse, ExtendedGenNode } from '../types'
import axios from 'axios';
import { useJob } from '../contexts/JobContext';
import { useLocation } from 'react-router-dom';
import NodeTreeEditor from '../components/NodeTreeEditor';
import { DndContext } from '@dnd-kit/core';
import { JsonTable } from '../components/JsonTable';

// Add ID generation helper
const generateNodeId = (() => {
  let counter = Math.floor(Date.now() / 1000);
  return () => (counter++).toString();
})();

// Helper for safe numeric ID conversion
function safeNumericId(id: string): string {
  const parsed = parseInt(id, 10);
  return isNaN(parsed) ? generateNodeId() : parsed.toString();
}

// Single declaration of mapToExtendedGenNode with proper anyChildren handling
const mapToExtendedGenNode = (node: GenNode): ExtendedGenNode => {
  const isExpandable = Boolean(
    node.type === 'Folder' || 
    node.isFolder || 
    node.type === 'Variable' || 
    node.anyChildren || 
    (node.children && node.children.length > 0)
  );

  return {
    ...node,
    id: safeNumericId(node.id.toString()),
    name: node.name || node.value1 || '',
    value1: node.value1 || '',
    value2: node.value2 || '',
    meta: node.meta || [],
    level: node.level || 0,
    children: node.children ? node.children.map(mapToExtendedGenNode) : undefined,
    isFolder: node.isFolder || node.type === 'Folder',
    parentId: node.parentId ? safeNumericId(node.parentId.toString()) : null,
    type: node.type,
    anyChildren: Boolean(node.anyChildren || (node.children && node.children.length > 0)),
    expandable: isExpandable
  };
};

// Update processNodes to preserve anyChildren
const processNodes = (nodes: GenNode[], parentNode: ExtendedGenNode): ExtendedGenNode[] => {
  return nodes.map((node, index) => ({
    ...node,
    id: safeNumericId(node.id?.toString() || `${parentNode.id}-${index}`),
    parentId: safeNumericId(parentNode.id.toString()),
    name: `${node.value1 || ''} ${node.value2 || ''}`.trim(),
    isExpanded: node.type === 'Variable',
    anyChildren: Boolean(node.anyChildren || (node.children && node.children.length > 0)),
    children: node.children ? processNodes(node.children, node as ExtendedGenNode) : undefined,
  } as ExtendedGenNode));
};

// Update mapNodeToGenNode to preserve anyChildren
const mapNodeToGenNode = (node: ExtendedGenNode): GenNode => ({
  type: node.type,
  id: safeNumericId(node.id),
  parentId: node.parentId ? safeNumericId(node.parentId) : null,
  isExpanded: node.isExpanded,
  isFolder: node.isFolder,
  value1: node.value1 || '',
  value2: node.value2 || '',
  meta: node.meta || [],
  level: node.level || 0,
  anyChildren: Boolean(node.anyChildren || (node.children && node.children.length > 0)),
  children: node.children ? node.children.map(mapNodeToGenNode) : null,
  name: node.name || node.value1 || '',
  isPC: node.isPC || false,
  isBase: node.isBase || false
});

// Add this helper function after the mapToExtendedGenNode function
const ensureTabAxisWrapper = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
  // If no nodes, create an empty TabAxis node
  if (nodes.length === 0) {
    return [{
      id: `tabaxis-${generateNodeId()}`,
      type: 'TabAxis',
      name: 'TabAxis',
      value1: 'TabAxis',
      value2: null,
      meta: [],
      level: 0,
      children: [] as ExtendedGenNode[], // Add explicit typing
      isExpanded: true,
      isFolder: false,
      parentId: null,
      anyChildren: false
    }];
  }
  
  // If first node is already a TabAxis, return as is
  if (nodes.length > 0 && nodes[0].type === 'TabAxis') {
    return nodes;
  }
  
  // Otherwise, wrap existing nodes in a TabAxis
  return [{
    id: `tabaxis-${generateNodeId()}`,
    type: 'TabAxis',
    name: 'TabAxis',
    value1: 'TabAxis',
    value2: null,
    meta: [],
    level: 0,
    children: nodes,
    isExpanded: true,
    isFolder: false,
    parentId: null,
    anyChildren: nodes.length > 0
  }];
};

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

interface DragPayload {
  nodeId: string;
  treeType: 'variable';
  type: string;
  id: string;
  value1: string | null;
  value2: string | null;
  parentId: string | null;
  meta: { key: string; value: string }[];
  isExpanded: boolean;
  isFolder: boolean;
  level: number;
  anyChildren: boolean;
  name: string | null;
  children?: ExtendedGenNode[];
}

export const Specification = (): JSX.Element => {
  const location = useLocation();
  const { carbonClient, isClientReady } = useAuth()
  const { selectedCustomer, selectedJob } = useJob();
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [hasChanges, setHasChanges] = useState(false);
  
  const [variableTree, setVariableTree] = useState<ExtendedGenNode[]>([])
  
  const [expandedVariableItems, setExpandedVariableItems] = useState<string[]>([])
  const [selectedVariables, setSelectedVariables] = useState<string[]>([])

  const [topNodes, setTopNodes] = useState<ExtendedGenNode[]>([]);
  const [sideNodes, setSideNodes] = useState<ExtendedGenNode[]>([]);

  const [selectedTopNode, setSelectedTopNode] = useState<ExtendedGenNode | null>(null);
  const [selectedSideNode, setSelectedSideNode] = useState<ExtendedGenNode | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedNodeVariables, setSelectedNodeVariables] = useState<string[]>([]);

  const [currentSpec, setCurrentSpec] = useState<RunSpecRequest | null>(null);

  const [isFetching, setIsFetching] = useState(false);

  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false);
  const [validateResponse, setValidateResponse] = useState<GenericResponse | null>(null);

  const [isRunSpecModalOpen, setIsRunSpecModalOpen] = useState(false);
  const [runSpecResponse, setRunSpecResponse] = useState<GenericResponse | null>(null);

  // New state variables for preview functionality
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewTableData, setPreviewTableData] = useState<any | null>(null);
  const [previewExcelUri, setPreviewExcelUri] = useState<string | null>(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const [isOverwriteConfirmOpen, setIsOverwriteConfirmOpen] = useState(false);
  const [newTableName, setNewTableName] = useState('');
  const [isTextWrapped, setIsTextWrapped] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('error');
  const [previewTitle, setPreviewTitle] = useState<string>('Table Preview');
  
  // Display options state
  const [displayOptions, setDisplayOptions] = useState<{
    ShowFreq: boolean;
    ShowColPct: boolean;
    ShowRowPct: boolean;
    ShowSig: boolean;
    Filter: string;
  }>({
    ShowFreq: false,
    ShowColPct: false,
    ShowRowPct: false,
    ShowSig: false,
    Filter: ''
  });
  
  const [pendingDisplayOptions, setPendingDisplayOptions] = useState({
    ShowFreq: false,
    ShowColPct: false,
    ShowRowPct: false,
    ShowSig: false,
    Filter: ''
  });

  const [filter, setFilter] = useState('');
  const [weight, setWeight] = useState('');
  const [caseFilter, setCaseFilter] = useState('');

  const [reportTitle, setReportTitle] = useState<string>(() => {
    const state = location.state as { reportName?: string } | null;
    return state?.reportName || 'Untitled Report';
  });

  const [treeViewMode, setTreeViewMode] = useState<'variable' | 'filter'>('variable');
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);
  const [debugInfo, setDebugInfo] = useState<string>('');

  const [selectedVariable, setSelectedVariable] = useState<ExtendedGenNode | null>(null);
  const [isVariableReady, setIsVariableReady] = useState(false);

  const [hasInitialFetch, setHasInitialFetch] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [nodeToDelete, setNodeToDelete] = useState<{ target: 'top' | 'side', parentId: string | null, index: number } | null>(null);

  const showSnackbarMessage = (message: string, severity: 'error' | 'warning' | 'info' | 'success' = 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const loadSpec = (spec: RunSpecRequest) => {
    setCurrentSpec(spec);
    console.log('Loading spec:', spec);
    
    if (spec.spec) {
      let mappedTopNodes = spec.spec.topAxis.map(mapToExtendedGenNode);
      let mappedSideNodes = spec.spec.sideAxis.map(mapToExtendedGenNode);
      
      // Ensure TabAxis wrappers
      mappedTopNodes = ensureTabAxisWrapper(mappedTopNodes);
      mappedSideNodes = ensureTabAxisWrapper(mappedSideNodes);
      
      console.log('Mapped top nodes with TabAxis:', mappedTopNodes);
      console.log('Mapped side nodes with TabAxis:', mappedSideNodes);
      
      setTopNodes(prevNodes => {
        if (JSON.stringify(prevNodes) === JSON.stringify(mappedTopNodes)) {
          console.log('No changes in top nodes, skipping update');
          return prevNodes;
        }
        console.log('Updating top nodes');
        return mappedTopNodes;
      });
      setSideNodes(prevNodes => {
        if (JSON.stringify(prevNodes) === JSON.stringify(mappedSideNodes)) {
          console.log('No changes in side nodes, skipping update');
          return prevNodes;
        }
        console.log('Updating side nodes');
        return mappedSideNodes;
      });
      
      // Set filter and weight
      setFilter(spec.spec.filter || '');
      setWeight(spec.spec.weight || '');
      
      // Set preview title based on loaded spec
      setPreviewTitle(`Run Spec: ${(spec.name || 'Untitled Report').replace(/\.cbt$/i, '')}`);
      // Update report title as well
      setReportTitle((spec.name || 'Untitled Report').replace(/\.cbt$/i, ''));
    } else {
      console.warn('Loaded spec does not have valid axis data');
      // Initialize with empty TabAxis nodes
      setTopNodes(ensureTabAxisWrapper([]));
      setSideNodes(ensureTabAxisWrapper([]));
      // Set default preview title
      setPreviewTitle('Run Spec: New Specification');
      // Update report title as well
      setReportTitle('New Specification');
    }
  };

  useEffect(() => {
    const initialize = async () => {
      if (!carbonClient || !isClientReady || !selectedCustomer || !selectedJob) {
        return;
      }

      setIsLoading(true);
      setErrorMessage(null);

      try {
        // Always fetch the spec aggregate first to get the latest variable tree
        console.log('Fetching spec aggregate for', selectedCustomer, selectedJob.name);
        const specAggregate = await carbonClient.getSpecAggregate();
        
        if (Array.isArray(specAggregate.variableTree)) {
          const mappedVariableTree = specAggregate.variableTree.map(node => {
            const mapped = mapToExtendedGenNode(node);
            if (mapped.type === 'Variable') {
              return {
                ...mapped,
                children: undefined,
                expandable: true
              };
            }
            return mapped;
          });
          console.log('Setting variable tree:', mappedVariableTree);
          setVariableTree(mappedVariableTree);
        }

        // Check if we have a loadedSpec from navigation state
        const state = location.state as { loadedSpec?: RunSpecRequest, reportName?: string } | null;
        if (state?.loadedSpec) {
          console.log('Loading spec from navigation state:', state.loadedSpec);
          loadSpec(state.loadedSpec);
          if (state.reportName) {
            setReportTitle(state.reportName.replace(/\.cbt$/i, ''));
          }
        } else {
          // Check for saved spec in localStorage
          const savedSpec = localStorage.getItem('currentSpec');
          if (savedSpec) {
            try {
              const parsedSpec = JSON.parse(savedSpec);
              console.log('Loading saved spec from localStorage:', parsedSpec);
              loadSpec(parsedSpec);
              if (!location.state?.reportName) {
                setReportTitle((parsedSpec.name || 'Untitled Report').replace(/\.cbt$/i, ''));
              }
            } catch (error) {
              console.error('Error parsing saved spec:', error);
              localStorage.removeItem('currentSpec');
            }
          } else if (specAggregate.spec) {
            // If no saved spec, use the one from specAggregate
            console.log('Loading spec from specAggregate:', specAggregate.spec);
            loadSpec(specAggregate.spec);
          } else {
            // Initialize with empty TabAxis nodes
            console.log('No spec found, initializing with empty TabAxis nodes');
            setTopNodes(ensureTabAxisWrapper([]));
            setSideNodes(ensureTabAxisWrapper([]));
            // Set default preview title
            setPreviewTitle('Run Spec: New Specification');
            // Update report title as well
            setReportTitle('New Specification');
          }
        }

        setHasInitialFetch(true);
      } catch (error) {
        console.error('Error initializing specification:', error);
        setErrorMessage(`Error initializing specification: ${error instanceof Error ? error.message : String(error)}`);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();
  }, [carbonClient, isClientReady, selectedCustomer, selectedJob, location.state]);

  useEffect(() => {
    console.log('Top nodes updated:', topNodes);
  }, [topNodes]);

  useEffect(() => {
    console.log('Side nodes updated:', sideNodes);
  }, [sideNodes]);

  useEffect(() => {
    console.log('variableTree updated:', variableTree);
  }, [variableTree]);

  // Inside the Specification component, update the useEffect to create initial empty TabAxis nodes
  useEffect(() => {
    // Create empty TabAxis nodes for a new spec
    if (!hasInitialFetch && !topNodes.length && !sideNodes.length) {
      console.log('Creating initial empty TabAxis nodes for new spec');
      
      const emptyTabAxis = {
        id: `tabaxis-${generateNodeId()}`,
        type: 'TabAxis',
        name: 'TabAxis',
        value1: 'TabAxis',
        value2: null,
        meta: [],
        level: 0,
        children: [] as ExtendedGenNode[],
        isExpanded: true,
        isFolder: false,
        parentId: null,
        anyChildren: false
      };
      
      setTopNodes([{...emptyTabAxis, id: `tabaxis-top-${generateNodeId()}`}]);
      setSideNodes([{...emptyTabAxis, id: `tabaxis-side-${generateNodeId()}`}]);
      setHasInitialFetch(true);
    }
  }, [hasInitialFetch, topNodes.length, sideNodes.length]);

  const isAxiosError = (error: any): error is { response: { status: number } } => {
    return error && error.response && typeof error.response.status === 'number';
  };

  const findVariable = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.id.toString() === id) {
        return node;
      }
      if (node.children) {
        const found = findVariable(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const handleVariableExpand = async (nodeId: string) => {
    if (!carbonClient || !isClientReady) return;

    try {
      const node = findVariable(variableTree, nodeId);
      if (!node) {
        return;
      }

      // If we already have children, just toggle expanded state
      if (node.children && node.children.length > 0) {
        setExpandedVariableItems(prev => {
          const isCurrentlyExpanded = prev.includes(nodeId);
          return isCurrentlyExpanded 
            ? prev.filter(id => id !== nodeId)
            : [...prev, nodeId];
        });
        return;
      }

      setIsLoading(true);
      try {
        // For variables, fetch their codes (only in variable mode)
        if (node.type === 'Variable' && treeViewMode === 'variable') {
          const nodeName = node.value1 || node.name || '';
          const variables = await carbonClient.getVarNodes(nodeName);
          if (variables && variables.length > 0 && variables[0].children) {
            const mappedChildren = variables[0].children.map(child => mapToExtendedGenNode(child));
            
            // Update the tree with codes
            setVariableTree(prev => {
              const updateNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
                return nodes.map(item => {
                  if (item.id === nodeId) {
                    return {
                      ...item,
                      children: mappedChildren,
                      expandable: true,
                      isExpanded: true
                    };
                  }
                  if (item.children) {
                    return {
                      ...item,
                      children: updateNode(item.children)
                    };
                  }
                  return item;
                });
              };
              return updateNode(prev);
            });

            // Add to expanded items
            setExpandedVariableItems(prev => [...prev, nodeId]);
          }
        }
        // For folders, they should already have their structure from initial load
        else if (node.type === 'Folder' || node.isFolder) {
          // Just mark as expanded since children should already be there
          setExpandedVariableItems(prev => [...prev, nodeId]);
        }
      } catch (error) {
        console.error('Error fetching children:', error);
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error in handleVariableExpand:', error);
    }
  };

  const updateTreeWithChildVariables = (parentId: string, childVariables: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === parentId) {
            return {
              ...variable,
              children: childVariables.map(mapToExtendedGenNode),
              isExpanded: true
            }
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) }
          }
          return variable
        })
      }
      const updatedTree = updateVariables(prevTree);
      if (JSON.stringify(updatedTree) === JSON.stringify(prevTree)) {
        console.log('No changes in variableTree, skipping update');
        return prevTree;
      }
      console.log('Updating variableTree with new children');
      return updatedTree;
    })
  }

  const updateTreeWithCodeframe = (variableId: string, codeframe: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === variableId) {
            const codeNodes: ExtendedGenNode[] = codeframe[0].children?.map(code => ({
              ...code,
              id: `${variableId}_${code.id}`,
              type: 'Code',
              name: `${code.value1 || ''} - ${code.value2 || ''}`,
              isExpanded: false,
              children: undefined,
            })) || [];

            return { ...variable, children: codeNodes, isExpanded: true };
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) };
          }
          return variable;
        });
      };
      const updatedTree = updateVariables(prevTree);
      if (JSON.stringify(updatedTree) === JSON.stringify(prevTree)) {
        console.log('No changes in variableTree, skipping update');
        return prevTree;
      }
      console.log('Updating variableTree with codeframe');
      return updatedTree;
    });
  };

  const handleDragStart = async (event: React.DragEvent<HTMLDivElement>, node: ExtendedGenNode) => {
    event.stopPropagation();
    console.log('Drag started:', { node });
    
    try {
      const dragData: DragPayload = {
        nodeId: node.id,
        treeType: 'variable',
        type: node.type,
        id: node.id,
        value1: node.value1,
        value2: node.value2,
        parentId: node.parentId,
        meta: node.meta || [],
        isExpanded: node.isExpanded,
        isFolder: node.isFolder,
        level: node.level,
        anyChildren: node.anyChildren,
        name: node.name,
        children: node.children || []
      };
      
      event.dataTransfer.setData('application/json', JSON.stringify(dragData));
      event.dataTransfer.effectAllowed = 'copy';
      
      // Set a custom drag image if needed
      const dragPreview = document.createElement('div');
      dragPreview.className = 'drag-preview';
      dragPreview.textContent = node.name || node.value1 || 'Item';
      dragPreview.style.padding = '4px 8px';
      dragPreview.style.background = '#fff';
      dragPreview.style.border = '1px solid #ccc';
      dragPreview.style.borderRadius = '4px';
      dragPreview.style.position = 'absolute';
      dragPreview.style.top = '-1000px';
      document.body.appendChild(dragPreview);
      event.dataTransfer.setDragImage(dragPreview, 0, 0);
      
      // Clean up the preview element after drag ends
      setTimeout(() => document.body.removeChild(dragPreview), 0);
    } catch (error) {
      console.error('Error in drag start:', error);
    }
  };

  // Consolidated findParentNode function that can handle both string IDs and nodes
  const findParentNode = (nodes: ExtendedGenNode[], target: string | ExtendedGenNode): ExtendedGenNode | null => {
    const targetId = typeof target === 'string' ? target : target.id;
    
    for (const node of nodes) {
      if (node.children?.some(child => child.id === targetId)) {
        return node;
      }
      if (node.children) {
        const found = findParentNode(node.children, targetId);
        if (found) return found;
      }
    }
    return null;
  };

  const getVariableIcon = (node: ExtendedGenNode | null, mode: 'variable' | 'filter') => {
    if (!node) return null;

    // If we're in filter mode and this is a Variable or Code node, show the Filter icon
    if (mode === 'filter' && (node.type === 'Variable' || node.type === 'Code')) {
      return (
        <Box 
          component="img"
          src="/assets/icons/Filter.png"
          alt="Filter"
          sx={{ 
            width: 20,
            height: 20,
            mr: 1,
            objectFit: 'contain'
          }}
        />
      );
    }

    // Otherwise show the regular node icon
    const hasPC = node.meta?.some((m: { key: string; value: string }) => m.key === 'PC');
    const iconName = hasPC ? `Node${node.type}PC` : `Node${node.type}`;
    const iconPath = `/assets/icons/${iconName}.png`;

    return (
      <Box 
        component="img"
        src={iconPath}
        alt={node.type}
        sx={{ 
          width: 20,
          height: 20,
          mr: 1,
          objectFit: 'contain'
        }}
      />
    );
  };

  const renderTreeItems = (variables: ExtendedGenNode[], treeType: 'variable' | 'axis' | 'function'): ExtendedGenNode[] => {
    return variables.filter(variable => variable.type !== 'TabAxis').map(variable => {
      // Format the label to only show value1 and value2
      const label = [variable.value1, variable.value2]
        .filter(Boolean)
        .join(' - ');

      const baseNode = {
        ...variable,
        id: variable.id.toString(),
        label: label,
        expandable: variable.expandable,
        content: (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {getVariableIcon(variable, treeViewMode)}
            <span>{label}</span>
            {variable.type === 'Code' && variable.meta && variable.meta.map((metaItem: { key: string; value: string }, index: number) => (
              <Tooltip key={index} title={`${metaItem.key}: ${metaItem.value}`}>
                <span style={{ marginLeft: '8px', fontSize: '0.8em', color: 'gray' }}>
                  {metaItem.key === 'PC' ? '%' : metaItem.key}
                </span>
              </Tooltip>
            ))}
          </Box>
        ),
      };

      if (variable.children) {
        return {
          ...baseNode,
          children: renderTreeItems(variable.children, treeType)
        };
      }

      return baseNode;
    });
  };

  const handleVariableSelect = async (node: ExtendedGenNode) => {
    setSelectedVariable(node);
    
    // In filter mode, only expand folders and allow selection of variables
    if (treeViewMode === 'filter') {
      if (node.type === 'Variable' || node.type === 'Codeframe') {
        // When a variable is selected in filter mode, don't expand it
        return;
      }
      if (!node.isFolder) {
        return;
      }
    }
    
    // Only expand if it's a folder or (in variable mode) a variable that might have children
    if (((treeViewMode === 'variable' && node.type === 'Variable') || node.isFolder) && !node.children) {
      try {
        const nodeName = node.name || node.value1 || node.id.toString();
        const variables = await carbonClient?.getVarNodes(nodeName);
        
        if (variables?.[0]?.children) {
          const mappedChildren = variables[0].children.map(child => ({
            ...mapToExtendedGenNode(child),
            id: `${node.id}_${child.id}`,
            type: child.type || 'Code',
            name: `${child.value1 || ''} - ${child.value2 || ''}`,
            parentId: node.id,
            anyChildren: Boolean(child.anyChildren || (child.children && child.children.length > 0)),
            // In filter mode, only folders should be expandable
            expandable: treeViewMode === 'filter' ? (child.type === 'Folder' || child.isFolder) : true
          }));

          // Update the variable tree with the new children
          setVariableTree(prev => {
            const updateNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
              return nodes.map(item => {
                if (item.id === node.id) {
                  return {
                    ...item,
                    children: mappedChildren,
                    isExpanded: true,
                    anyChildren: true,
                    // In filter mode, only folders should be expandable
                    expandable: treeViewMode === 'filter' ? (item.type === 'Folder' || item.isFolder) : true
                  };
                }
                if (item.children) {
                  return {
                    ...item,
                    children: updateNode(item.children)
                  };
                }
                return item;
              });
            };
            return updateNode(prev);
          });

          // Expand the node
          setExpandedVariableItems(prev => {
            if (!prev.includes(node.id.toString())) {
              return [...prev, node.id.toString()];
            }
            return prev;
          });
        }
      } catch (error) {
        console.error('Error loading children:', error);
      }
    }
  };

  const handleExpandedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedVariableItems(nodeIds);
    
    // Only fetch children for newly expanded nodes
    const newlyExpanded = nodeIds.filter(id => !expandedVariableItems.includes(id));
    newlyExpanded.forEach(id => {
      const node = findVariable(variableTree, id);
      if (node && !node.children) {
        handleVariableExpand(id);
      }
    });
  }, [expandedVariableItems, variableTree]);

  const handleSelectedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    console.log('RichTreeViewPro selection changed:', nodeIds);
    setSelectedVariables(nodeIds);
    if (nodeIds.length > 0) {
      const selectedNode = findVariable(variableTree, nodeIds[0]);
      console.log('Selected node:', selectedNode);
      if (selectedNode) {
        handleVariableSelect(selectedNode);
      }
    }
  }, [variableTree]);

  const CustomTreeItem = React.forwardRef<HTMLLIElement, TreeItem2Props>((props, ref) => {
    const { itemId, label, ...other } = props;
    const item = findVariable(variableTree, itemId);
    const isSelected = selectedVariable?.id === itemId;

    return (
      <TreeItem2
        ref={ref}
        {...other}
        itemId={itemId}
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              p: 0.5,
              borderRadius: 1,
              bgcolor: isSelected ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
              cursor: 'grab',
              '&:hover': { bgcolor: 'rgba(25, 118, 210, 0.04)' }
            }}
            draggable="true"
            onDragStart={(event) => {
              event.stopPropagation();
              if (item) {
                handleDragStart(event, item);
              }
            }}
          >
            {getVariableIcon(item, treeViewMode)}
            <Tooltip title={label || item?.name || item?.value1 || ''}>
              <Typography
                variant="body2"
                sx={{ mr: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {label || item?.name || item?.value1 || ''}
              </Typography>
            </Tooltip>
            {item?.type === 'Code' &&
              item.meta &&
              item.meta.map((metaItem: { key: string; value: string }, index: number) => (
                <Tooltip key={index} title={`${metaItem.key}: ${metaItem.value}`}> 
                  <span style={{ marginLeft: '8px', fontSize: '0.8em', color: 'gray' }}>
                    {metaItem.key === 'PC' ? '%' : metaItem.key}
                  </span>
                </Tooltip>
              ))
            }
          </Box>
        }
      />
    );
  });

  const handlePreviewTable = async (): Promise<void> => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready');
      return;
    }

    try {
      setIsPreviewLoading(true);
      
      // Create a spec with a guaranteed unique name if none is provided
      const specData = getCurrentSpec();
      if (!specData.name || specData.name.trim() === '' || specData.name === 'Untitled Report') {
        const customTitle = "Customised Spec";
        specData.name = customTitle;
        setPreviewTitle(`Run Spec: ${customTitle}`);
        setReportTitle(customTitle);
        console.log('Using consistent name for spec:', specData.name);
      } else {
        setPreviewTitle(`Run Spec: ${specData.name.replace(/\.cbt$/i, '')}`);
        setReportTitle(specData.name.replace(/\.cbt$/i, ''));
      }
      
      // First validate the spec
      console.log('Validating spec before running...', specData);
      const validationResponse = await carbonClient.validateSpec(specData.spec);
      
      // Check if validation failed (if there's an error message, it failed)
      if (validationResponse.message && validationResponse.message.toLowerCase().includes('error')) {
        setValidateResponse(validationResponse);
        setIsValidateModalOpen(true);
        setErrorMessage('Specification validation failed. Please fix the errors before running.');
        setIsPreviewLoading(false);
        return;
      }

      // If validation passed, run the spec
      console.log('Validation passed, running spec with name:', specData.name);
      console.log('Full spec data including rawData:', specData);
      const response = await carbonClient.runSpec(specData);
      
      if (response.message && response.message.toLowerCase().includes('error')) {
        // If run failed, show the error
        setValidateResponse(response);
        setIsValidateModalOpen(true);
        setErrorMessage('Failed to run specification. Please check the errors.');
        setIsPreviewLoading(false);
        return;
      }

      // Generate Excel file for the new spec
      const xlsxResponse = await carbonClient.generateXlsx();
      setPreviewExcelUri(xlsxResponse.excelUri);
      
      // Update display options from xlsx response
      setDisplayOptions(prev => ({
        ...prev,
        ShowFreq: xlsxResponse.showFrequencies,
        ShowColPct: xlsxResponse.showColPercents,
        ShowRowPct: xlsxResponse.showRowPercents,
        ShowSig: xlsxResponse.showSignificance,
      }));
      
      // Also update pendingDisplayOptions to match
      setPendingDisplayOptions(prev => ({
        ...prev,
        ShowFreq: xlsxResponse.showFrequencies,
        ShowColPct: xlsxResponse.showColPercents,
        ShowRowPct: xlsxResponse.showRowPercents,
        ShowSig: xlsxResponse.showSignificance,
      }));
      
      // Store the spec in localStorage as backup
      try {
        localStorage.setItem('lastRunSpec', JSON.stringify(specData));
        localStorage.setItem('lastRunSpecName', specData.name);
      } catch (storageError) {
        console.warn('Failed to store spec in localStorage:', storageError);
      }
      
      // Set flag to indicate we have changes
      setHasChanges(false);
      
      // Define API base URL for Excel conversion
      const API_BASE_URL = process.env.NODE_ENV === 'development' 
        ? 'http://localhost:8090/secret'
        : process.env.REACT_APP_API_URL || 'https://app.bayesprice.ai';
      
      // Convert Excel to JSON for preview
      const convertResponse = await fetch(`${API_BASE_URL}/api/excel/convert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-session-info': localStorage.getItem('sessionInfo') || '',
          'Accept': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          excelUri: xlsxResponse.excelUri
        })
      });

      if (!convertResponse.ok) {
        throw new Error(`Failed to convert Excel: ${convertResponse.statusText}`);
      }

      const jsonData = await convertResponse.json();
      if (jsonData.success && jsonData.data) {
        setPreviewTableData(jsonData.data);
        setIsPreviewModalOpen(true);
      } else {
        throw new Error('Invalid JSON data received from Excel conversion');
      }
      
    } catch (error) {
      console.error('Error running spec:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = `Run failed: ${error.response?.data?.message || error.message}`;
        setErrorMessage(errorMessage);
      } else {
        setErrorMessage(`Run failed: ${(error as Error).message}`);
      }
    } finally {
      setIsPreviewLoading(false);
    }
  }

  const fetchNodeCodes = async (nodeId: string): Promise<string[]> => {
    if (!carbonClient || !selectedCustomer || !selectedJob) {
      return [];
    }

    try {
      const response = await carbonClient.get(`/api/v1/customers/${selectedCustomer}/jobs/${selectedJob.name}/variables/${nodeId}/codes`);
      return response.data.codes || [];
    } catch (error) {
      console.error('Error fetching codes:', error);
      return [];
    }
  };

  const getCurrentSpec = (): RunSpecRequest => {
    // Get the children for the API spec format
    const topAxisChildren = topNodes
      .filter(node => node.type === 'TabAxis')
      .flatMap(node => (node.children || []))
      .map(mapNodeToGenNode);
    
    const sideAxisChildren = sideNodes
      .filter(node => node.type === 'TabAxis')
      .flatMap(node => (node.children || []))
      .map(mapNodeToGenNode);

    return {
      name: hasChanges ? '' : (reportTitle || 'Untitled Report'),
      DProps: currentSpec?.DProps || {},
      spec: {
        topAxis: topAxisChildren,
        sideAxis: sideAxisChildren,
        topLock: currentSpec?.spec?.topLock || false,
        sideLock: currentSpec?.spec?.sideLock || false,
        useFilter: filter !== '',
        filter: filter,
        useWeight: weight !== '',
        weight: weight,
        specProperties: {
          caseFilter: caseFilter || null,
          initAsMissing: currentSpec?.spec?.specProperties?.initAsMissing || false,
          excludeNE: currentSpec?.spec?.specProperties?.excludeNE || false,
          padHierarchics: currentSpec?.spec?.specProperties?.padHierarchics || false,
          arithOverStats: currentSpec?.spec?.specProperties?.arithOverStats || false,
          topInsert: currentSpec?.spec?.specProperties?.topInsert || null,
          sideInsert: currentSpec?.spec?.specProperties?.sideInsert || null,
          level: currentSpec?.spec?.specProperties?.level || null,
          fullStats: currentSpec?.spec?.specProperties?.fullStats || false
        }
      },
      // Include raw data with complete TabAxis structure
      rawData: {
        topNodes: topNodes,
        sideNodes: sideNodes
      }
    };
  };

  const handleSaveSpec = async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready');
      return;
    }

    try {
      setIsLoading(true);
      
      const saveSpec: RunSpecRequest = {
        name: newTableName,
        DProps: {},
        spec: {
          topAxis: topNodes
            .filter(node => node.type === 'TabAxis')
            .flatMap(node => (node.children || []))
            .map(mapNodeToGenNode),
          sideAxis: sideNodes
            .filter(node => node.type === 'TabAxis')
            .flatMap(node => (node.children || []))
            .map(mapNodeToGenNode),
          topLock: false,
          sideLock: false,
          useFilter: filter !== '',
          filter: filter,
          useWeight: weight !== '',
          weight: weight,
          specProperties: {
            caseFilter: null,
            initAsMissing: false,
            excludeNE: false,
            padHierarchics: false,
            arithOverStats: false,
            topInsert: null,
            sideInsert: null,
            level: null,
            fullStats: false
          }
        }
      };

      // First run the spec to ensure it's valid
      await carbonClient.runSpec(saveSpec);
      
      // Then save it using the report/save endpoint
      // Using empty string as sub (folder) parameter since we're saving at root level
      await carbonClient.saveReport(newTableName, '');
      
      setIsSaveDialogOpen(false);
      setNewTableName('');
      setErrorMessage(null);
    } catch (error) {
      console.error('Error saving specification:', error);
      setErrorMessage(`Error saving specification: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to check if a report with the given name already exists in the TOC
  const checkIfReportExists = async (reportName: string): Promise<boolean> => {
    if (!carbonClient || !isClientReady) {
      return false;
    }
    
    try {
      // Get TOC nodes
      const tocNodes = await carbonClient.getTocNodes();
      
      // If no nodes, report doesn't exist
      if (!tocNodes || !Array.isArray(tocNodes) || tocNodes.length === 0) {
        return false;
      }
      
      // Clean the report name (remove .cbt extension if present)
      const cleanReportName = reportName.replace(/\.cbt$/i, '');
      
      // Recursive function to search nodes
      const searchNodes = (nodes: any[]): boolean => {
        for (const node of nodes) {
          // Check if this is a Table node with matching name
          if (node.type === 'Table') {
            const nodeValue = (node.value1 || '').replace(/\.cbt$/i, '');
            const nodeName = (node.name || '').replace(/\.cbt$/i, '');
            
            // Match if the node's value1 or name matches the report name
            if (nodeValue === cleanReportName || nodeName === cleanReportName) {
              return true;
            }
          }
          
          // Check children if they exist
          if (node.children && Array.isArray(node.children) && node.children.length > 0) {
            const foundInChildren = searchNodes(node.children);
            if (foundInChildren) {
              return true;
            }
          }
        }
        return false;
      };
      
      return searchNodes(tocNodes);
    } catch (error) {
      console.error('Error checking if report exists:', error);
      return false;
    }
  };

  const handleSavePreview = async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready');
      return;
    }

    try {
      // Check if a report with this name already exists
      const reportExists = await checkIfReportExists(newTableName);
      
      // If report exists and confirmation dialog isn't already open, show it
      if (reportExists && !isOverwriteConfirmOpen) {
        setIsOverwriteConfirmOpen(true);
        return;
      }
      
      // If we get here, either the report doesn't exist or user confirmed overwrite
      setIsPreviewLoading(true);
      setIsOverwriteConfirmOpen(false);
      
      const saveSpec: RunSpecRequest = {
        name: newTableName,
        DProps: {},
        spec: {
          topAxis: topNodes
            .filter(node => node.type === 'TabAxis')
            .flatMap(node => (node.children || []))
            .map(mapNodeToGenNode),
          sideAxis: sideNodes
            .filter(node => node.type === 'TabAxis')
            .flatMap(node => (node.children || []))
            .map(mapNodeToGenNode),
          topLock: false,
          sideLock: false,
          useFilter: filter !== '',
          filter: filter,
          useWeight: weight !== '',
          weight: weight,
          specProperties: {
            caseFilter: null,
            initAsMissing: false,
            excludeNE: false,
            padHierarchics: false,
            arithOverStats: false,
            topInsert: null,
            sideInsert: null,
            level: null,
            fullStats: false
          }
        }
      };

      // First run the spec to ensure it's valid
      await carbonClient.runSpec(saveSpec);
      
      // Then save it using the report/save endpoint
      // Using empty string as sub (folder) parameter since we're saving at root level
      await carbonClient.saveReport(newTableName, '');
      
      setIsSaveDialogOpen(false);
      setNewTableName('');
      setErrorMessage(null);
      
      // Show success message
      showSnackbarMessage('Table saved successfully', 'success');
      
      // Store the newly saved table name in localStorage for Tables.tsx to load it
      // This allows Tables.tsx to select and display this table when we navigate back
      // Store without .cbt extension since Tables.tsx handles both formats but primarily compares without extension
      const tableName = newTableName.replace(/\.cbt$/i, '');
      localStorage.setItem('loaded_table', tableName);
      
      // Clean up temporary report state
      localStorage.removeItem("lastRunReport");
      localStorage.removeItem("lastRunSpec");
      localStorage.removeItem("lastRunSpecName");
      
      // Close the preview modal and navigate back to Tables.tsx
      setIsPreviewModalOpen(false);
      window.history.back();
    } catch (error) {
      console.error('Error saving specification:', error);
      setErrorMessage(`Error saving specification: ${(error as Error).message}`);
      showSnackbarMessage(`Error saving specification: ${(error as Error).message}`, 'error');
    } finally {
      setIsPreviewLoading(false);
    }
  };

  const handleTopNodesChange = (newNodes: ExtendedGenNode[]) => {
    console.log('Top nodes changing:', newNodes);
    
    // Check if TabAxis structure is already present
    const hasTabAxis = newNodes.length > 0 && newNodes[0].type === 'TabAxis';
    if (hasTabAxis) {
      console.log('TabAxis structure already present in incoming nodes');
      setTopNodes(newNodes);
    } else {
      // Ensure TabAxis wrapper
      console.log('Wrapping incoming nodes in TabAxis');
      const withTabAxis = ensureTabAxisWrapper(newNodes);
      setTopNodes(withTabAxis);
    }
    
    // Use a consistent title for customized specs
    const customTitle = "Customised Spec";
    setPreviewTitle(`Run Spec: ${customTitle}`);
    setReportTitle(customTitle);
    setHasChanges(true);
  };

  const handleSideNodesChange = (newNodes: ExtendedGenNode[]) => {
    console.log('Side nodes changing:', newNodes);
    
    // Check if TabAxis structure is already present
    const hasTabAxis = newNodes.length > 0 && newNodes[0].type === 'TabAxis';
    if (hasTabAxis) {
      console.log('TabAxis structure already present in incoming nodes');
      setSideNodes(newNodes);
    } else {
      // Ensure TabAxis wrapper
      console.log('Wrapping incoming nodes in TabAxis');
      const withTabAxis = ensureTabAxisWrapper(newNodes);
      setSideNodes(withTabAxis);
    }
    
    // Use a consistent title for customized specs
    const customTitle = "Customised Spec";
    setPreviewTitle(`Run Spec: ${customTitle}`);
    setReportTitle(customTitle);
    setHasChanges(true);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    // Use a consistent title for customized specs
    const customTitle = "Customised Spec";
    setPreviewTitle(`Run Spec: ${customTitle}`);
    setReportTitle(customTitle);
    setHasChanges(true);
  };

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeight(event.target.value);
    // Use a consistent title for customized specs
    const customTitle = "Customised Spec";
    setPreviewTitle(`Run Spec: ${customTitle}`);
    setReportTitle(customTitle);
    setHasChanges(true);
  };

  const handleCaseFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCaseFilter(event.target.value);
    // Use a consistent title for customized specs
    const customTitle = "Customised Spec";
    setPreviewTitle(`Run Spec: ${customTitle}`);
    setReportTitle(customTitle);
    setHasChanges(true);
  };

  useEffect(() => {
    if (treeViewMode === 'filter') {
      // Clear selections and expanded items
      setSelectedVariables([]);
      setExpandedVariableItems([]);
      setSelectedVariable(null);
      
      // Update variable tree to only show folders and variables (no codes)
      setVariableTree(prev => {
        const resetNodes = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
          return nodes.map(node => ({
            ...node,
            // Remove children from non-folder nodes in filter mode
            children: (node.type === 'Folder' || node.isFolder) 
              ? (node.children ? resetNodes(node.children) : undefined)
              : undefined,
            isExpanded: false,
            // Only folders and variables should be selectable in filter mode
            expandable: node.type === 'Folder' || node.isFolder,
            // Keep anyChildren flag for folders
            anyChildren: (node.type === 'Folder' || node.isFolder) ? node.anyChildren : false
          }));
        };
        return resetNodes(prev);
      });
    }
  }, [treeViewMode]);

  // Update the debug button code to show only the rawData with TabAxis structure
  const handleShowDebugInfo = () => {
    const spec = getCurrentSpec();
    console.log('Current spec structure:', spec);
    console.log('Raw top nodes:', topNodes);
    console.log('Raw side nodes:', sideNodes);
    
    // Only show the rawData which already contains the TabAxis wrapper structure
    setDebugInfo(JSON.stringify(spec.rawData, null, 2));
    
    setIsDebugModalOpen(true);
  };

  // Handler for applying display options
  const handleApplyDisplayOptions = async (): Promise<void> => {
    if (!carbonClient || !isClientReady) return;
    
    const wouldHaveEnabled = Object.entries(pendingDisplayOptions)
      .filter(([k, v]) => k !== 'Filter' && v)
      .length > 0;
      
    if (!wouldHaveEnabled) {
      showSnackbarMessage('At least one display option must be enabled', 'warning');
      return;
    }
    
    try {
      setIsPreviewLoading(true);
      
      // Call quickUpdate to update display options on the server
      const response = await carbonClient.quickUpdate(pendingDisplayOptions);
      
      // Update displayOptions with server response values
      setDisplayOptions({
        ...displayOptions,
        ShowFreq: response.showFrequencies,
        ShowColPct: response.showColPercents,
        ShowRowPct: response.showRowPercents,
        ShowSig: response.showSignificance,
        Filter: displayOptions.Filter
      });
      
      // Get the updated Excel URI
      setPreviewExcelUri(response.excelUri);
      
      // Convert Excel to JSON for preview
      if (response.excelUri) {
        const API_BASE_URL = process.env.NODE_ENV === 'development' 
          ? 'http://localhost:8090/secret'
          : process.env.REACT_APP_API_URL || 'https://app.bayesprice.ai';
          
        const convertResponse = await fetch(`${API_BASE_URL}/api/excel/convert`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-session-info': localStorage.getItem('sessionInfo') || '',
            'Accept': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({
            excelUri: response.excelUri
          })
        });

        if (!convertResponse.ok) {
          throw new Error(`Failed to convert Excel: ${convertResponse.statusText}`);
        }

        const jsonData = await convertResponse.json();
        if (jsonData.success && jsonData.data) {
          setPreviewTableData(jsonData.data);
        } else {
          throw new Error('Invalid JSON data received from Excel conversion');
        }
      }
      
    } catch (error) {
      console.error('Error updating display options:', error);
      showSnackbarMessage(`Error updating display options: ${(error as Error).message}`, 'error');
    } finally {
      setIsPreviewLoading(false);
    }
  };

  // Fixed handler for display option changes to match JsonTable prop type
  const handleDisplayOptionToggle = (key: 'ShowFreq' | 'ShowColPct' | 'ShowRowPct' | 'ShowSig'): void => {
    setPendingDisplayOptions(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <ErrorBoundary>
      <DndContext>
        <Box sx={{ 
          display: 'flex', 
          height: 'calc(100vh - 84px)',
          overflow: 'hidden',
          pl: 2,
          pr: 3,
          py: 2,
        }}>
          {/* Left sidebar - Variable Tree */}
          <Paper sx={{ 
            width: 320, 
            p: 2, 
            mr: 2,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography variant="h6" gutterBottom>Variable Tree</Typography>
            <Box sx={{ mb: 2, display: 'none' }}>
              <ToggleButtonGroup
                value={treeViewMode}
                exclusive
                onChange={(e, newMode) => {
                  if (newMode !== null) {
                    // Clear expanded items when switching to filter mode
                    if (newMode === 'filter') {
                      setExpandedVariableItems([]);
                      // Update variable tree to reset all expanded nodes and only show expandable folders
                      setVariableTree(prev => {
                        const resetNodes = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
                          return nodes.map(node => ({
                            ...node,
                            // Remove children from non-folder nodes
                            children: (node.type === 'Folder' || node.isFolder) 
                              ? (node.children ? resetNodes(node.children) : undefined)
                              : undefined,
                            isExpanded: false,
                            // Only folders and variables should be selectable in filter mode
                            expandable: node.type === 'Folder' || node.isFolder,
                            // Reset anyChildren based on node type
                            anyChildren: node.type === 'Folder' || node.isFolder ? node.anyChildren : false
                          }));
                        };
                        return resetNodes(prev);
                      });
                    }
                    setTreeViewMode(newMode);
                  }
                }}
                fullWidth
                size="small"
              >
                <ToggleButton value="variable">
                  Variable
                </ToggleButton>
                <ToggleButton value="filter">
                  Filter
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {isLoading ? (
              <Typography>Loading variable tree...</Typography>
            ) : variableTree.length > 0 ? (
              <RichTreeViewPro<ExtendedGenNode, true>
                items={renderTreeItems(variableTree, 'variable')}
                slots={{
                  item: CustomTreeItem
                }}
                expandedItems={expandedVariableItems}
                selectedItems={selectedVariables}
                onExpandedItemsChange={handleExpandedItemsChange}
                onSelectedItemsChange={handleSelectedItemsChange}
                multiSelect={true}
                draggable="true"
                sx={{
                  '.MuiTreeItem-root': {
                    padding: 0,
                  },
                  '.MuiTreeItem-content': {
                    padding: '2px 0',
                  },
                  '.MuiTreeItem-iconContainer': {
                    width: 20,
                    marginRight: 0,
                    padding: '0 4px',
                  },
                  '.MuiTreeItem-group': {
                    marginLeft: '20px',
                    padding: 0,
                  }
                }}
              />
            ) : (
              <Typography>No variables found</Typography>
            )}
          </Paper>

          {/* Right side - Specification content */}
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            {/* Top toolbar */}
            <Paper sx={{ p: 2, mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button 
                    variant="contained" 
                    onClick={handlePreviewTable} 
                    disabled={isLoading || isPreviewLoading}
                    startIcon={isPreviewLoading ? <CircularProgress size={16} color="inherit" /> : null}
                  >
                    {isPreviewLoading ? 'Running...' : 'Run Spec'}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      // Simply use browser history to go back
                      // This avoids any state management issues with react-router
                      
                      // First clear any localStorage items that might cause issues
                      localStorage.removeItem('lastRunReport');
                      localStorage.removeItem('lastRunSpec');
                      localStorage.removeItem('lastRunSpecName');
                      
                      // Don't clear loaded_table - we want to keep that for the Tables page
                      
                      // Go back in browser history instead of using navigate()
                      window.history.back();
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, flex: 1, ml: 4 }}>
                  <TextField
                    label="Filter"
                    value={filter}
                    onChange={handleFilterChange}
                    size="small"
                    sx={{ flex: 3 }}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      const data = e.dataTransfer.getData('application/json');
                      if (!data) return;
                      try {
                        const dragData = JSON.parse(data) as unknown as DragPayload;
                        let newFilter: string;

                        if (dragData.type === 'Code') {
                          // Find the parent variable name
                          const parentVariable = findParentNode(variableTree, dragData);
                          if (!parentVariable) return;
                          
                          const varName = parentVariable.value1 || parentVariable.name || '';
                          const codeValue = dragData.value1 || '1';
                          newFilter = `${varName}(${codeValue})`;
                        } else {
                          // Regular variable drop
                          const droppedName = dragData.value1 || dragData.name || 'Var';
                          newFilter = `${droppedName}(*)`;
                        }

                        setFilter((prev) =>
                          !prev.trim() ? newFilter : `${prev.trim()}&${newFilter}`
                        );
                        setHasChanges(true);
                      } catch (error) {
                        console.error('Error handling filter drop:', error);
                      }
                    }}
                  />
                  <TextField
                    label="Weight"
                    value={weight}
                    onChange={handleWeightChange}
                    size="small"
                    sx={{ flex: 1 }}
                  />
                  <TextField
                    label="Case Filter"
                    value={caseFilter}
                    onChange={handleCaseFilterChange}
                    size="small"
                    sx={{ flex: 1 }}
                  />
                </Box>
              </Box>
            </Paper>

            {/* Main content area */}
            <Paper sx={{ 
              flex: 1, 
              p: 2, 
              overflowY: 'auto', 
              display: 'flex',
              flexDirection: 'column'
            }}>
              {/* Table Name */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-between' }}>
                <Typography variant="h5" gutterBottom>
                  {reportTitle.replace(/\.cbt$/i, '')}
                </Typography>
                <Button 
                  variant="outlined" 
                  size="small" 
                  onClick={handleShowDebugInfo}
                  startIcon={<Functions />}
                >
                  Show Carbon Spec
                </Button>
              </Box>

              {/* New NodeTreeEditor components */}
              <Box sx={{ display: 'flex', mt: 4, flex: 1, minHeight: 0 }}>
                <Box sx={{ width: '50%', pr: 1, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>Side Axis Editor</Typography>
                  <Box sx={{ flex: 1, minHeight: 0, border: '1px solid #ccc', borderRadius: '4px' }}>
                    <NodeTreeEditor
                      initialItems={sideNodes}
                      onItemsChange={handleSideNodesChange}
                      isLoading={isLoading}
                      treeViewMode={treeViewMode}
                      carbonClient={carbonClient}
                      variableTree={variableTree}
                      onFetchCodes={fetchNodeCodes}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: '50%', pl: 1, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" gutterBottom>Top Axis Editor</Typography>
                  <Box sx={{ flex: 1, minHeight: 0, border: '1px solid #ccc', borderRadius: '4px' }}>
                    <NodeTreeEditor
                      initialItems={topNodes}
                      onItemsChange={handleTopNodesChange}
                      isLoading={isLoading}
                      treeViewMode={treeViewMode}
                      carbonClient={carbonClient}
                      variableTree={variableTree}
                      onFetchCodes={fetchNodeCodes}
                    />
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>

        {/* Validate Spec Modal */}
        <Modal
          open={isValidateModalOpen}
          onClose={() => setIsValidateModalOpen(false)}
          aria-labelledby="validate-spec-modal"
          aria-describedby="validate-spec-response"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <Typography id="validate-spec-modal" variant="h6" component="h2">
              Spec Validation Result
            </Typography>
            <Typography id="validate-spec-response" sx={{ mt: 2 }}>
              {validateResponse ? JSON.stringify(validateResponse, null, 2) : 'No response'}
            </Typography>
          </Box>
        </Modal>

        {/* Custom Snackbar for preview-related messages */}
        <Snackbar 
          open={snackbarOpen} 
          autoHideDuration={6000} 
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setSnackbarOpen(false)} 
            severity={snackbarSeverity} 
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        {/* Debug Modal */}
        <Dialog
          open={isDebugModalOpen}
          onClose={() => setIsDebugModalOpen(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Carbon Specification</span>
            <Button 
              variant="outlined" 
              size="small" 
              onClick={() => {
                navigator.clipboard.writeText(debugInfo);
              }}
            >
              Copy JSON
            </Button>
          </DialogTitle>
          <DialogContent>
            <Box 
              component="pre" 
              sx={{ 
                whiteSpace: 'pre-wrap', 
                wordBreak: 'break-word',
                bgcolor: 'grey.100',
                p: 2,
                borderRadius: 1,
                fontFamily: 'monospace',
                fontSize: '0.875rem',
                maxHeight: '70vh',
                overflowY: 'auto'
              }}
            >
              {debugInfo}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDebugModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Save Dialog */}
        <Dialog open={isSaveDialogOpen} onClose={() => setIsSaveDialogOpen(false)}>
          <DialogTitle>Save Table</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Table Name"
              fullWidth
              value={newTableName}
              onChange={(e) => setNewTableName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsSaveDialogOpen(false)}>Cancel</Button>
            <Button 
              onClick={handleSaveSpec} 
              variant="contained" 
              disabled={!newTableName.trim()}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar 
          open={errorMessage !== null} 
          autoHideDuration={6000} 
          onClose={() => setErrorMessage(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setErrorMessage(null)} 
            severity="error" 
            sx={{ width: '100%' }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>

        {/* Preview Table Modal */}
        <Dialog
          open={isPreviewModalOpen}
          onClose={() => setIsPreviewModalOpen(false)}
          maxWidth="xl"
          fullWidth
          fullScreen
          PaperProps={{
            sx: { 
              height: '100vh', 
              maxWidth: '100vw',
              maxHeight: '100vh',
              margin: 0,
              display: 'flex', 
              flexDirection: 'column' 
            }
          }}
        >
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid', borderColor: 'divider' }}>
            <Typography variant="h6">{previewTitle}</Typography>
            <Box>
              {/* Text wrap and search controls removed - now handled in JsonTable component */}
              <IconButton
                onClick={() => setIsPreviewModalOpen(false)}
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ padding: '16px', overflow: 'hidden' }}>
            <JsonTable 
              data={previewTableData}
              fullscreen={false}
              wrapText={isTextWrapped}
              isSearchVisible={isSearchVisible}
              onWrapTextChange={setIsTextWrapped}
              onSearchVisibleChange={setIsSearchVisible}
              onFullscreenChange={() => {}}
              displayOptions={pendingDisplayOptions}
              onDisplayOptionChange={handleDisplayOptionToggle}
              onApplyDisplayOptions={handleApplyDisplayOptions}
              isDisplayOptionsDisabled={false}
            />
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2, borderTop: '1px solid', borderColor: 'divider', justifyContent: 'space-between' }}>
            <Box>
              <Button 
                onClick={() => setIsPreviewModalOpen(false)}
                variant="contained" 
                startIcon={<EditIcon />}
                sx={{ mr: 2 }}
              >
                Edit Spec
              </Button>
              {previewExcelUri && (
                <a href={previewExcelUri} download style={{ textDecoration: 'none' }}>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    disabled={isPreviewLoading}
                    sx={{ mr: 2 }}
                  >
                    Download XLSX
                  </Button>
                </a>
              )}
              <Button 
                onClick={() => setIsSaveDialogOpen(true)}
                variant="contained" 
                startIcon={<SaveIcon />}
                disabled={!previewTableData || isPreviewLoading}
              >
                Save As New Spec
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  localStorage.removeItem("lastRunReport");
                  localStorage.removeItem("lastRunSpec");
                  localStorage.removeItem("lastRunSpecName");
                  // Keep loaded_table in localStorage for Tables component
                  setIsPreviewModalOpen(false);
                  window.history.back();
                }}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        {/* Save Dialog for Preview */}
        <Dialog open={isSaveDialogOpen} onClose={() => setIsSaveDialogOpen(false)}>
          <DialogTitle>Save Table As</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Table Name"
              fullWidth
              value={newTableName}
              onChange={(e) => setNewTableName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsSaveDialogOpen(false)}>Cancel</Button>
            <Button 
              onClick={handleSavePreview} 
              variant="contained" 
              disabled={!newTableName.trim() || isPreviewLoading}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Overwrite Confirmation Dialog */}
        <Dialog open={isOverwriteConfirmOpen} onClose={() => setIsOverwriteConfirmOpen(false)}>
          <DialogTitle>Confirm Overwrite</DialogTitle>
          <DialogContent>
            <Typography>
              A table named "{newTableName}" already exists. Do you want to overwrite it?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOverwriteConfirmOpen(false)}>Cancel</Button>
            <Button 
              onClick={handleSavePreview} 
              variant="contained" 
              color="warning"
            >
              Overwrite
            </Button>
          </DialogActions>
        </Dialog>
      </DndContext>
    </ErrorBoundary>
  )
}
