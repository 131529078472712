"use client"

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Tooltip,
  Toolbar,
  ButtonGroup,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Search,
  Business as BusinessIcon,
  Work as WorkIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { licensingClient } from '../services/LicensingClient';
import { User, CustomerInfo, JobInfo } from '../types/types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`project-tabpanel-${index}`}
      aria-labelledby={`project-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ProjectManagement: React.FC = () => {
  const { carbonClient } = useAuth();
  const [tabValue, setTabValue] = useState(0);
  const [customers, setCustomers] = useState<CustomerInfo[]>([]);
  const [jobs, setJobs] = useState<JobInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editCustomerDialogOpen, setEditCustomerDialogOpen] = useState(false);
  const [editJobDialogOpen, setEditJobDialogOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Partial<CustomerInfo> | null>(null);
  const [selectedJob, setSelectedJob] = useState<Partial<JobInfo> | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchCustomersAndJobs = useCallback(async () => {
    try {
      setLoading(true);
      if (!carbonClient) {
        throw new Error('Carbon client not available');
      }

      // Get customers and jobs from the session info
      const sessionInfo = carbonClient.getSessionInfo();
      if (!sessionInfo) {
        throw new Error('No session info available');
      }

      setCustomers(sessionInfo.sessionCusts);
      const allJobs = sessionInfo.sessionCusts.flatMap(cust => cust.sessionJobs);
      setJobs(allJobs);
      setError(null);
    } catch (err) {
      setError('Failed to fetch customers and jobs');
      console.error('Error fetching customers and jobs:', err);
    } finally {
      setLoading(false);
    }
  }, [carbonClient]);

  useEffect(() => {
    fetchCustomersAndJobs();
  }, [fetchCustomersAndJobs]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleNewCustomer = () => {
    setSelectedCustomer({});
    setEditCustomerDialogOpen(true);
  };

  const handleEditCustomer = (customer: CustomerInfo) => {
    setSelectedCustomer(customer);
    setEditCustomerDialogOpen(true);
  };

  const handleSaveCustomer = async () => {
    if (!selectedCustomer?.name) {
      setError('Customer name is required');
      return;
    }

    try {
      setLoading(true);
      const customerData = {
        name: selectedCustomer.name,
        displayName: selectedCustomer.displayName || null,
        agencyId: selectedCustomer.agencyId || null,
      };

      if (selectedCustomer.id) {
        // Update existing customer
        await licensingClient.updateCustomer(selectedCustomer.id, customerData);
      } else {
        // Create new customer
        await licensingClient.createCustomer(customerData);
      }

      // Refresh the customers list
      await fetchCustomersAndJobs();
      setEditCustomerDialogOpen(false);
      setError(null);
    } catch (err) {
      setError('Failed to save customer');
      console.error('Error saving customer:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleNewJob = () => {
    setSelectedJob({});
    setEditJobDialogOpen(true);
  };

  const handleEditJob = (job: JobInfo) => {
    setSelectedJob(job);
    setEditJobDialogOpen(true);
  };

  const handleSaveJob = async () => {
    // TODO: Implement job save logic
    setEditJobDialogOpen(false);
  };

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
          <Typography variant="h4" component="h1">
            Project Management
          </Typography>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab icon={<BusinessIcon />} label="Customers" />
            <Tab icon={<WorkIcon />} label="Jobs" />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Toolbar sx={{ gap: 2 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleNewCustomer}
            >
              New Customer
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <TextField
              size="small"
              placeholder="Search customers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <Search />
              }}
            />
          </Toolbar>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Display Name</TableCell>
                  <TableCell>Agency ID</TableCell>
                  <TableCell>Jobs</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers
                  .filter(customer => 
                    customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    customer.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((customer) => (
                    <TableRow key={customer.id}>
                      <TableCell>{customer.id}</TableCell>
                      <TableCell>{customer.name}</TableCell>
                      <TableCell>{customer.displayName}</TableCell>
                      <TableCell>{customer.agencyId}</TableCell>
                      <TableCell>{customer.sessionJobs.length}</TableCell>
                      <TableCell>
                        <Tooltip title="Edit Customer">
                          <IconButton
                            size="small"
                            onClick={() => handleEditCustomer(customer)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Toolbar sx={{ gap: 2 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleNewJob}
            >
              New Job
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <TextField
              size="small"
              placeholder="Search jobs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <Search />
              }}
            />
          </Toolbar>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Display Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs
                  .filter(job => 
                    job.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    job.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((job) => (
                    <TableRow key={job.id}>
                      <TableCell>{job.id}</TableCell>
                      <TableCell>{job.name}</TableCell>
                      <TableCell>{job.displayName}</TableCell>
                      <TableCell>{job.description}</TableCell>
                      <TableCell>
                        {customers.find(c => 
                          c.sessionJobs.some(j => j.id === job.id)
                        )?.name}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit Job">
                          <IconButton
                            size="small"
                            onClick={() => handleEditJob(job)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Paper>

      {/* Edit Customer Dialog */}
      <Dialog 
        open={editCustomerDialogOpen} 
        onClose={() => setEditCustomerDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedCustomer?.id ? 'Edit Customer' : 'New Customer'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={selectedCustomer?.name || ''}
            onChange={(e) =>
              setSelectedCustomer(prev => ({ ...prev, name: e.target.value }))
            }
          />
          <TextField
            margin="dense"
            label="Display Name"
            fullWidth
            value={selectedCustomer?.displayName || ''}
            onChange={(e) =>
              setSelectedCustomer(prev => ({ ...prev, displayName: e.target.value }))
            }
          />
          <TextField
            margin="dense"
            label="Agency ID"
            fullWidth
            value={selectedCustomer?.agencyId || ''}
            onChange={(e) =>
              setSelectedCustomer(prev => ({ ...prev, agencyId: e.target.value }))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditCustomerDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveCustomer} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Job Dialog */}
      <Dialog 
        open={editJobDialogOpen} 
        onClose={() => setEditJobDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedJob?.id ? 'Edit Job' : 'New Job'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={selectedJob?.name || ''}
            onChange={(e) =>
              setSelectedJob(prev => ({ ...prev, name: e.target.value }))
            }
          />
          <TextField
            margin="dense"
            label="Display Name"
            fullWidth
            value={selectedJob?.displayName || ''}
            onChange={(e) =>
              setSelectedJob(prev => ({ ...prev, displayName: e.target.value }))
            }
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={selectedJob?.description || ''}
            onChange={(e) =>
              setSelectedJob(prev => ({ ...prev, description: e.target.value }))
            }
          />
          {!selectedJob?.id && (
            <FormControl fullWidth margin="dense">
              <InputLabel>Customer</InputLabel>
              <Select
                value={selectedJob?.customerId || ''}
                onChange={(e) =>
                  setSelectedJob(prev => ({ ...prev, customerId: e.target.value }))
                }
              >
                {customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditJobDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveJob} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProjectManagement; 