import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Alert,
  Box,
  Stack
} from '@mui/material';
import { NODE_TYPE_DEFINITIONS } from '../constants/node-types';
import { ExtendedGenNode } from '../types';

interface AddNodeModalProps {
  open: boolean;
  onClose: () => void;
  onAdd: (node: ExtendedGenNode) => void;
  parentNode?: ExtendedGenNode | null;
  currentLevel: number;
  type: 'Function' | 'Spacer';
}

// Field definitions for Function and Spacer nodes
const NODE_TYPE_FIELDS = {
  'Function': {
    value1Label: 'Function Expression',
    value1Help: 'Enter the function expression (e.g., (v1$c+v2$c)/2',
    value2Label: 'Description',
    value2Help: 'Optional description of the function',
    value1Required: true
  },
  'Spacer': {
    value1Label: 'Label (Optional)',
    value1Help: 'Optional label for the spacer',
    value2Label: 'Description',
    value2Help: 'Optional description',
  }
} as const;

export const AddNodeModal: React.FC<AddNodeModalProps> = ({
  open,
  onClose,
  onAdd,
  parentNode,
  currentLevel,
  type
}) => {
  const [values, setValues] = useState<{
    value1: string;
    value2: string;
  }>({
    value1: '',
    value2: '',
  });

  const handleAdd = () => {
    const def = NODE_TYPE_DEFINITIONS[type];
    const fields = NODE_TYPE_FIELDS[type];

    // Validate required fields for Function
    if (type === 'Function' && !values.value1) return;

    const newNode: ExtendedGenNode = {
      type,
      id: Math.floor(Date.now() / 1000).toString(),
      parentId: parentNode?.id || null,
      isExpanded: false,
      isFolder: def.canHaveChildren,
      value1: values.value1 || null,
      value2: values.value2 || null,
      meta: [],
      level: currentLevel,
      anyChildren: false,
      name: values.value1 || `New ${type}`,
      children: []
    };

    onAdd(newNode);
    onClose();
    
    // Reset form
    setValues({
      value1: '',
      value2: '',
    });
  };

  // Reset values when modal opens
  useEffect(() => {
    if (open) {
      setValues({
        value1: '',
        value2: '',
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add {type}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Stack spacing={2}>
            {type === 'Function' ? (
              <>
                <TextField
                  fullWidth
                  label={NODE_TYPE_FIELDS.Function.value1Label}
                  value={values.value1}
                  onChange={(e) => setValues(prev => ({ ...prev, value1: e.target.value }))}
                  required={NODE_TYPE_FIELDS.Function.value1Required}
                  helperText={NODE_TYPE_FIELDS.Function.value1Help}
                  autoFocus
                />
                <TextField
                  fullWidth
                  label={NODE_TYPE_FIELDS.Function.value2Label}
                  value={values.value2}
                  onChange={(e) => setValues(prev => ({ ...prev, value2: e.target.value }))}
                  helperText={NODE_TYPE_FIELDS.Function.value2Help}
                />
              </>
            ) : (
              <>
                <TextField
                  fullWidth
                  label={NODE_TYPE_FIELDS.Spacer.value1Label}
                  value={values.value1}
                  onChange={(e) => setValues(prev => ({ ...prev, value1: e.target.value }))}
                  helperText={NODE_TYPE_FIELDS.Spacer.value1Help}
                  autoFocus
                />
                <TextField
                  fullWidth
                  label={NODE_TYPE_FIELDS.Spacer.value2Label}
                  value={values.value2}
                  onChange={(e) => setValues(prev => ({ ...prev, value2: e.target.value }))}
                  helperText={NODE_TYPE_FIELDS.Spacer.value2Help}
                />
              </>
            )}

            {/* Node Info */}
            <Alert severity="info">
              <Typography variant="body2">
                Level: {currentLevel}<br />
                Parent: {parentNode?.type || 'None (Root Level)'}
              </Typography>
            </Alert>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleAdd}
          disabled={type === 'Function' && !values.value1}
          variant="contained"
        >
          Add {type}
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 