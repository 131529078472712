import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { 
  Box, 
  Tooltip, 
  useTheme, 
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  InputAdornment,
  Paper,
  Button,
  Stack,
  Collapse,
  FormControlLabel,
  Switch
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import WrapTextIcon from '@mui/icons-material/WrapText';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useJob } from '../contexts/JobContext';
import './JsonTable.css';

interface CellStyle {
    f?: {
        t?: 'p' | 'n' | 'd' | 't' | 'g';
        d?: number;
        p?: boolean;
        ts?: boolean;  // thousands separator
        dt?: boolean;  // date time
        txt?: boolean; // text
    };
    alignment?: {
        horizontal?: 'left' | 'center' | 'right';
        vertical?: 'top' | 'middle' | 'bottom';
        wrapText?: boolean;
        textRotation?: number;
        indent?: number;
    };
    font?: {
        n?: string;
        s?: number;
        c?: string;
        b?: boolean;
        i?: boolean;
        u?: boolean;
    };
    fill?: {
        type: string;
        fgColor?: string;
        bgColor?: string;
    };
    border?: {
        left?: { style: string; color: string };
        right?: { style: string; color: string };
        top?: { style: string; color: string };
        bottom?: { style: string; color: string };
    };
    format?: {
        type: 'p' | 'n' | 'd' | 't' | 'g';
        decimal_places: number;
        percentage: boolean;
        thousands_separator: boolean;
        date_time: boolean;
        text: boolean;
        isAlreadyPercentage?: boolean;
    };
    format_code?: string;
}

// Define types for the enhanced data structure
interface EnhancedDataStructure {
  labels: {
    columns: Record<number, {
      ref: string;
      label: string;
      level: number;
      colName: string;
      isDataColumn: boolean;
    }>;
    rows: Record<number, {
      ref: string;
      label: string;
      level: number;
      isDataRow: boolean;
    }>;
    topGroups: Array<{
      ref: string;
      label: string;
      level: number;
      startColumn: number;
      endColumn: number;
      span: number;
    }>;
    sideGroups: Array<{
      ref: string;
      label: string;
      level: number;
      startRow: number;
      endRow: number;
      span: number;
    }>;
    [cellRef: string]: {
      row: Array<{
        ref: string;
        label: string;
        type: string;
        level?: number;
      }>;
      column: Array<{
        ref: string;
        label: string;
        type: string;
        level?: number;
      }>;
    } | any; // Use any to avoid TypeScript index signature issues
  };
  cellTypes: Record<string, 'topLabel' | 'sideLabel' | 'topGroup' | 'sideGroup' | 'data'>;
  styleRefs: Record<string, string>;
  dataGrid: {
    startRow: number;
    startCol: number;
    endRow: number;
    endCol: number;
  };
}

// Add new interfaces for the optimized data structure
interface ColorMapEntry {
  fg?: string;       // Foreground color
  bg?: string;       // Background color
  type?: string;     // Format type (p, n, d, t, g)
  percentage?: boolean;
  percentType?: 'column' | 'row'; // For distinguishing column vs row percentages
  decimal?: number;  // Decimal places
  isBold?: boolean;  // If the text should be bold
  isAlreadyPercentage?: boolean;
}

interface CellMap {
  columns: {
    [colIndex: string]: {
      ref: string;          // Cell reference
      label: string;        // Column label text
      groups: Array<{       // Parent groups, ordered from highest level to lowest
        ref: string;
        name: string;       // Group name
        level: number;      // Hierarchy level (higher = further from data)
        span: number;       // How many columns this group spans
      }>;
    }
  };
  rows: {
    [rowIndex: string]: {
      ref: string;          // Cell reference
      label: string;        // Row label text
      groups: Array<{       // Parent groups, ordered from highest level to lowest
        ref: string;
        name: string;       // Group name
        level: number;      // Hierarchy level (higher = further from data)
        span: number;       // How many rows this group spans
      }>;
    }
  };
  dataRegion: {
    startRow: number;
    startCol: number;
    endRow: number;
    endCol: number;
  };
}

interface JsonTableProps {
    data: {
        r: Array<{  // rows
            c: Array<{  // cells
                v?: string | number;  // value
                s?: number;  // style reference
                m?: number;  // merge reference
                cs?: number; // colspan
                rs?: number; // rowspan
                l?: string; // label type
                ref?: string; // cell reference
            }>;
            h?: number;  // row height
            hidden?: boolean; // row hidden state
        }>;
        sp?: {  // style palette (legacy)
            [key: string]: CellStyle;
        };
        colorMap?: {  // New optimized color map
            [key: string]: ColorMapEntry;
        };
        cellMap?: CellMap;  // New optimized cell structure map
        m?: Array<[string, string]>;  // merged cells
        cols?: Array<{  // column information (legacy)
            width: number;
            hidden?: boolean;
            customWidth?: boolean;
        }>;
        defaultRowHeight?: number;
        defaultColWidth?: number;
        // Legacy data structures
        enhanced?: EnhancedDataStructure;
        tableStructureMap?: any;
    };
    fullscreen?: boolean;
    wrapText?: boolean;
    isSearchVisible?: boolean;
    onFullscreenChange?: (fullscreen: boolean) => void;
    onWrapTextChange?: (wrapText: boolean) => void;
    onSearchVisibleChange?: (isSearchVisible: boolean) => void;
    // Add display options
    displayOptions?: {
      ShowFreq: boolean;
      ShowColPct: boolean;
      ShowRowPct: boolean;
      ShowSig: boolean;
    };
    onDisplayOptionChange?: (key: 'ShowFreq' | 'ShowColPct' | 'ShowRowPct' | 'ShowSig') => void;
    onApplyDisplayOptions?: () => void;
    isDisplayOptionsDisabled?: boolean;
}

interface SearchMatch {
  rowIndex: number;
  colIndex: number;
  value: string;
}

const TooltipCell: React.FC<{ content: string; children: React.ReactNode }> = ({ content, children }) => {
  return (
    <Tooltip 
      title={content}
      placement="top"
      arrow
      enterDelay={200}
      leaveDelay={0}
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: '1rem',
            padding: '8px 12px',
            bgcolor: 'rgba(0, 0, 0, 0.85)'
          }
        },
        arrow: {
          sx: {
            color: 'rgba(0, 0, 0, 0.85)'
          }
        }
      }}
    >
      <div style={{ 
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%'
      }}>
        {children}
      </div>
    </Tooltip>
  );
};

// // Modify the DataCellTooltip component to use "Top" and "Side" terminology
// const DataCellTooltip: React.FC<{ 
//   columnLabel: string; 
//   rowLabel: string; 
//   open: boolean;
//   children: React.ReactNode 
// }> = ({ columnLabel, rowLabel, open, children }) => {
//   const theme = useTheme();
  
//   return (
//     <Tooltip 
//       title={
//         <Box sx={{ p: 0.5 }}>
//           <Typography variant="caption" sx={{ fontWeight: 'bold', display: 'block' }}>
//             Top: {columnLabel}
//           </Typography>
//           <Typography variant="caption" sx={{ fontWeight: 'bold', display: 'block' }}>
//             Side: {rowLabel}
//           </Typography>
//         </Box>
//       }
//       placement="top"
//       arrow
//       open={open}
//       enterDelay={1000}
//       leaveDelay={0}
//       componentsProps={{
//         tooltip: {
//           sx: {
//             fontSize: '1rem',
//             padding: '8px 12px',
//             bgcolor: 'rgba(0, 0, 0, 0.85)',
//             maxWidth: '300px'
//           }
//         },
//         arrow: {
//           sx: {
//             color: 'rgba(0, 0, 0, 0.85)'
//           }
//         }
//       }}
//     >
//       <div style={{ 
//         width: '100%',
//         height: '100%',
//         display: 'flex',
//         alignItems: 'center',
//         whiteSpace: 'nowrap',
//         overflow: 'hidden',
//         textOverflow: 'ellipsis',
//         maxWidth: '100%'
//       }}>
//         {children}
//       </div>
//     </Tooltip>
//   );
// };

const MetadataBlock: React.FC<{
  metadata: {
    name?: string;
    top?: string;
    side?: string;
    filter?: string;
    weight?: string;
    status?: string;
  };
}> = ({ metadata }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { selectedJob } = useJob();

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  
  return (
    <Accordion 
      expanded={expanded} 
      onChange={handleChange}
      sx={{
        backgroundColor: theme.palette.grey[50],
        borderRadius: '8px !important',
        '&:before': {
          display: 'none',  // Remove the default divider
        },
        boxShadow: 'none',
        border: `1px solid ${theme.palette.grey[200]}`,
        '&.Mui-expanded': {
          margin: '0 !important'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          minHeight: '48px',
          backgroundColor: theme.palette.grey[50],
          '& .MuiAccordionSummary-content': {
            margin: '12px 0 !important'
          }
        }}
      >
        <Typography 
          sx={{ 
            fontWeight: 500,
            color: theme.palette.text.primary 
          }}
        >
          {selectedJob?.displayName || selectedJob?.name || 'Unknown Job'} &gt; {metadata.name?.replace(/^Name:\s*/, '').replace(/\.cbt$/i, '') || 'Untitled Table'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0, pb: 2, px: 3 }}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
        }}>
          {Object.entries(metadata).map(([key, value]) => {
            // Skip name (shown in summary) and status (always empty)
            if (key === 'name' || key === 'status') return null;
            
            // Remove the label prefix from the value (e.g., "Top: ", "Side: ", etc.)
            const cleanValue = value?.replace(new RegExp(`^${key}:\\s*`, 'i'), '') || '-';
            
            return (
              <Box key={key} sx={{ width: '100%' }}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    display: 'flex',
                    gap: 1,
                    '& .label': {
                      fontWeight: 500,
                      minWidth: '80px',
                    }
                  }}
                >
                  <span className="label">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
                  <span style={{ flex: 1, wordBreak: 'break-word' }}>{cleanValue}</span>
                </Typography>
              </Box>
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

// Helper function to get column letter from index (0 -> A, 1 -> B, etc.)
const getColumnLetter = (index: number): string => {
  let columnName = '';
  let dividend = index + 1;
  let modulo;

  while (dividend > 0) {
    modulo = (dividend - 1) % 26;
    columnName = String.fromCharCode(65 + modulo) + columnName;
    dividend = Math.floor((dividend - modulo) / 26);
  }

  return columnName;
};

// Helper function to get column index from letter (A -> 0, B -> 1, etc.)
const getColumnIndex = (column: string): number => {
  let result = 0;
  for (let i = 0; i < column.length; i++) {
    result = result * 26 + (column.charCodeAt(i) - 64);
  }
  return result - 1;
};

const getCellColumn = (ref: string): string => {
  return ref.match(/[A-Z]+/)?.[0] || '';
};

const getCellRow = (ref: string): number => {
  return parseInt(ref.match(/\d+/)?.[0] || '0');
};

// Add this helper function at the top level
const shouldShowTooltip = (cell: any, formattedValue: string) => {
  // Show tooltip for label cells
  if (cell.l) return true;
  
  // Show tooltip for long text that might be truncated
  if (typeof formattedValue === 'string' && formattedValue.length > 20) return true;
  
  return false;
};

const isPartOfMerge = (cellRef: string, mergedRanges: Array<[string, string]> | undefined): boolean => {
  if (!cellRef || !mergedRanges?.length) return false;
  
  // Check if this cell is explicitly referenced in any merged range
  return mergedRanges.some(([start, end]) => {
    // Check if this cell is the start of a merged range
    if (start === cellRef) return true;
    
    // If not, check if it falls within any merged range
    const startCol = getCellColumn(start);
    const startRow = getCellRow(start);
    const endCol = getCellColumn(end);
    const endRow = getCellRow(end);
    
    const cellCol = getCellColumn(cellRef);
    const cellRow = getCellRow(cellRef);
    
    const startColIndex = getColumnIndex(startCol);
    const endColIndex = getColumnIndex(endCol);
    const cellColIndex = getColumnIndex(cellCol);
    
    return (
      cellColIndex >= startColIndex && 
      cellColIndex <= endColIndex && 
      cellRow >= startRow && 
      cellRow <= endRow
    );
  });
};

// Right after any imports, add the excelHeightToPixels function
// Convert Excel row height to pixels (approximate conversion)
const excelHeightToPixels = (height: number) => {
  // Excel's height unit is in points, 1 point = 1.333333 pixels
  const pixels = Math.floor(height * 1.333333);
  return pixels;
};

// Create a new TableControls component
interface TableControlsProps {
  fullscreen: boolean;
  wrapText: boolean;
  isSearchVisible: boolean;
  onFullscreenChange: (fullscreen: boolean) => void;
  onWrapTextChange: (wrapText: boolean) => void;
  onSearchVisibleChange: (isSearchVisible: boolean) => void;
  // Add display options
  displayOptions?: {
    ShowFreq: boolean;
    ShowColPct: boolean;
    ShowRowPct: boolean;
    ShowSig: boolean;
  };
  onDisplayOptionChange?: (key: 'ShowFreq' | 'ShowColPct' | 'ShowRowPct' | 'ShowSig') => void;
  onApplyDisplayOptions?: () => void;
  isDisplayOptionsDisabled?: boolean;
  // New prop for the close button in fullscreen mode
  showCloseButton?: boolean;
}

const TableControls: React.FC<TableControlsProps> = ({
  fullscreen,
  wrapText,
  isSearchVisible,
  onFullscreenChange,
  onWrapTextChange,
  onSearchVisibleChange,
  // Display options
  displayOptions,
  onDisplayOptionChange,
  onApplyDisplayOptions,
  isDisplayOptionsDisabled = false,
  showCloseButton = false
}) => {
  const theme = useTheme();

  // Create custom switch styles similar to the original
  const switchStyles = {
    Freq: {
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#000000',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#000000',
      },
      '& .MuiSwitch-root': {
        marginRight: 0
      }
    },
    ColPct: {
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#0066cc',
        '&:hover': {
          backgroundColor: 'rgba(0, 102, 204, 0.08)',
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#0066cc',
      },
      '& .MuiSwitch-root': {
        marginRight: 0
      }
    },
    RowPct: {
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#cc0000',
        '&:hover': {
          backgroundColor: 'rgba(204, 0, 0, 0.08)',
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#cc0000',
      },
      '& .MuiSwitch-root': {
        marginRight: 0
      }
    },
    Sig: {
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#000000',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#000000',
      },
      '& .MuiSwitch-root': {
        marginRight: 0
      }
    },
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: { xs: 'stretch', sm: 'center' },
        gap: 1,
        p: 1,
        borderRadius: 1,
        backgroundColor: theme.palette.grey[50]
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: { xs: 1, sm: 0 } 
      }}>
        
        <Box sx={{ display: 'flex', gap: 0.5 }}>
          <Tooltip title="Toggle Search">
            <IconButton
              onClick={() => onSearchVisibleChange(!isSearchVisible)}
              size="small"
              sx={{
                color: isSearchVisible ? 'primary.main' : 'action.active',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover
                },
                p: 0.5
              }}
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={wrapText ? "Unwrap Text" : "Wrap Text"}>
            <IconButton
              onClick={() => onWrapTextChange(!wrapText)}
              size="small"
              sx={{
                color: wrapText ? 'primary.main' : 'action.active',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover
                },
                p: 0.5
              }}
            >
              <WrapTextIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={fullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}>
            <IconButton
              onClick={() => onFullscreenChange(!fullscreen)}
              size="small"
              sx={{
                color: fullscreen ? 'primary.main' : 'action.active',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover
                },
                p: 0.5
              }}
            >
              {fullscreen ? <FullscreenExitIcon fontSize="small" /> : <OpenInFullIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      
      {/* Display options - show only if displayOptions is provided */}
      {displayOptions && (
        <Box sx={{ 
          display: 'flex', 
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: { xs: 0, sm: 0.5 },
          pt: { xs: 1, sm: 0 },
          borderTop: { xs: `1px solid ${theme.palette.divider}`, sm: 'none' }
        }}>
          <FormControlLabel
            control={
              <Switch
                checked={displayOptions.ShowFreq}
                onChange={() => onDisplayOptionChange && onDisplayOptionChange('ShowFreq')}
                disabled={isDisplayOptionsDisabled}
                size="small"
                sx={switchStyles.Freq}
              />
            }
            label={<Typography variant="caption">Freq</Typography>}
            sx={{ 
              m: 0,
              mr: 0.5
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={displayOptions.ShowColPct}
                onChange={() => onDisplayOptionChange && onDisplayOptionChange('ShowColPct')}
                disabled={isDisplayOptionsDisabled}
                size="small"
                sx={switchStyles.ColPct}
              />
            }
            label={<Typography variant="caption">Col%</Typography>}
            sx={{ 
              m: 0,
              mr: 0.5
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={displayOptions.ShowRowPct}
                onChange={() => onDisplayOptionChange && onDisplayOptionChange('ShowRowPct')}
                disabled={isDisplayOptionsDisabled}
                size="small"
                sx={switchStyles.RowPct}
              />
            }
            label={<Typography variant="caption">Row%</Typography>}
            sx={{ 
              m: 0,
              mr: 0.5
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={displayOptions.ShowSig}
                onChange={() => onDisplayOptionChange && onDisplayOptionChange('ShowSig')}
                disabled={isDisplayOptionsDisabled}
                size="small"
                sx={switchStyles.Sig}
              />
            }
            label={<Typography variant="caption">Sig</Typography>}
            sx={{ 
              m: 0
            }}
          />
          <Button
            variant="outlined"
            onClick={() => onApplyDisplayOptions && onApplyDisplayOptions()}
            disabled={isDisplayOptionsDisabled}
            size="small"
            sx={{ 
              ml: 1,
              minWidth: '70px',
              height: '24px',
              fontSize: '0.75rem'
            }}
          >
            Apply
          </Button>
          
          {/* Close button in fullscreen mode */}
          {showCloseButton && (
            <Tooltip title="Close Fullscreen">
              <IconButton
                onClick={() => onFullscreenChange(false)}
                size="small"
                sx={{
                  ml: 1,
                  color: 'error.main',
                  '&:hover': {
                    backgroundColor: 'rgba(211, 47, 47, 0.04)'
                  },
                  p: 0.5
                }}
                aria-label="Close fullscreen"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Paper>
  );
};

interface TableContentProps {
  data: JsonTableProps['data'];
  containerStyle?: React.CSSProperties;
  wrapText?: boolean;
  isSearchVisible?: boolean;
  isSideExpanded?: boolean;
  onSideExpandedChange?: (expanded: boolean) => void;
}

// CSS classes for row vs column percentages
const tableStyleExtensions = `
.percentType-row {
  color: #cc0000 !important; /* Red for row percentages */
}
.percentType-column {
  color: #0066cc !important; /* Blue for column percentages */
}
`;

// Update the TableContent component
const TableContent = ({ 
  data, 
  containerStyle, 
  wrapText, 
  isSearchVisible = false,
  isSideExpanded = false,
  onSideExpandedChange
}: TableContentProps): React.ReactNode => {
  // Make sure we always have a data object to work with
  const safeData = useMemo(() => data || { r: [] }, [data]);
  
  // Style injection
  useEffect(() => {
    const styleEl = document.createElement('style');
    styleEl.textContent = tableStyleExtensions;
    document.head.appendChild(styleEl);
    
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);
  
  const theme = useTheme();
  const [hoveredCell, setHoveredCell] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchMatches, setSearchMatches] = useState<SearchMatch[]>([]);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(-1);
  const [searchInputFocused, setSearchInputFocused] = useState(false);
  const hoverTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Add these styles to your existing styles
  const tableStyles = {
    '.highlighted': {
      transition: 'border 0.2s ease',
    },
    '.hovered': {
      transition: 'border 0.2s ease',
      position: 'relative',
      zIndex: 2
    }
  };

  // Add cell type styles
  const cellTypeStyles = {
    '.cell-sideLabel': {
      fontWeight: 'bold',
      textAlign: 'right',
      backgroundColor: '#f5f5f5',
    },
    '.cell-topLabel': {
      fontWeight: 'bold',
      textAlign: 'center',
      backgroundColor: '#f5f5f5',
    },
    '.cell-topGroup': {
      fontWeight: 'bold',
      textAlign: 'center',
      backgroundColor: '#e0e0e0',
    },
    '.cell-sideGroup': {
      fontWeight: 'bold',
      textAlign: 'right',
      backgroundColor: '#e0e0e0',
    },
    '.format-p, .format-percentage': {
      color: '#0066cc',
      textAlign: 'right',
    },
    '.format-n': {
      textAlign: 'right',
    },
    '.align-right': {
      textAlign: 'right',
    },
    '.align-center': {
      textAlign: 'center',
    },
    '.align-left': {
      textAlign: 'left',
    }
  };

  // Extract metadata from first 6 rows
  const extractMetadata = useCallback(() => {
    const metadataRows = safeData.r.slice(0, 6);
    const metadata: { [key: string]: string } = {};

    metadataRows.forEach((row, index) => {
      // Skip empty rows
      if (!row.c || row.c.length === 0) return;

      // Get the first two cells (if they exist)
      const firstCell = row.c[0];
      const secondCell = row.c[1];

      // Get cell values, converting to strings if needed
      const firstValue = firstCell?.v !== undefined && firstCell?.v !== null 
        ? String(firstCell.v).trim() 
        : '';
      const secondValue = secondCell?.v !== undefined && secondCell?.v !== null 
        ? String(secondCell.v).trim() 
        : '';

      // First try to find metadata in the format "Label: Value" in the first cell
      const labelValueMatch = firstValue.match(/^(Name|Top|Side|Filter|Weight|Status)\s*:\s*(.+)$/i);
      if (labelValueMatch) {
        const [, label, value] = labelValueMatch;
        const key = label.toLowerCase();
        metadata[key] = value;
        return;
      }

      // Then check if first cell is a label and second cell has the value
      const isLabel = /^(Name|Top|Side|Filter|Weight|Status)\s*:?\s*$/i.test(firstValue);
      if (isLabel && secondValue) {
        const label = firstValue.replace(/[:\s]+$/, '').toLowerCase();
        metadata[label] = secondValue;
        return;
      }

      // Finally, try to infer metadata type from row position if we haven't found it yet
      if (!metadata.name && index === 0 && (firstValue || secondValue)) {
        metadata.name = secondValue || firstValue;
      } else if (!metadata.top && index === 1 && (firstValue || secondValue)) {
        metadata.top = secondValue || firstValue;
      } else if (!metadata.side && index === 2 && (firstValue || secondValue)) {
        metadata.side = secondValue || firstValue;
      } else if (!metadata.filter && index === 3 && (firstValue || secondValue)) {
        metadata.filter = secondValue || firstValue;
      } else if (!metadata.weight && index === 4 && (firstValue || secondValue)) {
        metadata.weight = secondValue || firstValue;
      }
    });
    
    return metadata;
  }, [safeData.r]);

  // Helper function to check if a row is empty
  const isRowEmpty = useCallback((row: typeof safeData.r[0]): boolean => {
    return row.c.every(cell => cell === undefined || cell.v === undefined || cell.v === null || cell.v === '');
  }, [safeData]);

  // Filter out metadata rows and trailing empty rows
  const filteredRows = useMemo(() => {
    let rows = safeData.r.slice(6); // Skip metadata rows
    let lastNonEmptyIndex = rows.length - 1;
    while (lastNonEmptyIndex >= 0 && isRowEmpty(rows[lastNonEmptyIndex])) {
      lastNonEmptyIndex--;
    }
    return rows.slice(0, lastNonEmptyIndex + 1);
  }, [safeData]);

  // Update the getStyleForCell function to use safeData
  const getStyleForCell = useCallback((cell: any): React.CSSProperties => {
    // Start with an empty style object
    const cellStyle: Record<string, any> = {};
    
    // Get styleId
    const styleId = typeof cell.s === 'number' ? cell.s : undefined;
    
    // Try to use the new colorMap first
    if (styleId !== undefined && safeData.colorMap && safeData.colorMap[styleId]) {
      const colorInfo = safeData.colorMap[styleId];
      
      // Add background color if present
      if (colorInfo.bg) {
        cellStyle.backgroundColor = colorInfo.bg;
      } else if (colorInfo.fg) {
        // Some formats use foreground color as background
        cellStyle.backgroundColor = colorInfo.fg;
      }
      
      // Add text color based on percent type
      if (colorInfo.percentType === 'row') {
        cellStyle.color = '#cc0000'; // Red for row percentages
      } else if (colorInfo.percentType === 'column') {
        cellStyle.color = '#0066cc'; // Blue for column percentages
      }
      
      // Add font weight
      if (colorInfo.isBold) {
        cellStyle.fontWeight = 'bold';
      }
    }
    // Fall back to the legacy style if needed
    else if (styleId !== undefined && safeData.sp && safeData.sp[styleId]) {
      const styleObj = safeData.sp[styleId];
      
      // Handle background color if specified
      if (styleObj.fill && styleObj.fill.fgColor) {
        cellStyle.backgroundColor = styleObj.fill.fgColor;
      }
      
      // Handle font styling if specified
      if (styleObj.font) {
        if (styleObj.font.c) cellStyle.color = styleObj.font.c;
        if (styleObj.font.b) cellStyle.fontWeight = 'bold';
        if (styleObj.font.i) cellStyle.fontStyle = 'italic';
        if (styleObj.font.u) cellStyle.textDecoration = 'underline';
      }
    }
    
    return cellStyle;
  }, [safeData]);

  // Define functions to handle cell formatting and styling
  const formatCellValue = useCallback((
    value: any, 
    styleId?: number, 
    isLabel?: boolean, 
    tableData?: JsonTableProps['data']
  ): { formattedValue: string; formatClasses: string } => {
    if (value === undefined || value === null) return { formattedValue: '', formatClasses: '' };
    
    // Default class
    let formatClasses = '';
    
    // Special handling for label cells - preserve exact string value
    if (isLabel) {
      return { 
        formattedValue: String(value), 
        formatClasses: 'text-format' 
      };
    }

    // Check for significance letters in string values (e.g. "44.34% a")
    let significanceLetter = '';
    if (typeof value === 'string') {
      // Pattern to match a number (possibly with % sign) followed by space and a single letter
      const significanceMatch = value.match(/^([\d.,%]+)(\s+)([a-zA-Z])$/);
      if (significanceMatch) {
        // Extract the numeric part and the significance letter
        value = significanceMatch[1]; // The numeric part
        significanceLetter = significanceMatch[2] + significanceMatch[3]; // Space + letter
      }
    }

    // Try to use the new colorMap first - Fix the type error with proper type guard
    let colorInfo: ColorMapEntry | undefined = undefined;
    if (typeof styleId === 'number' && tableData?.colorMap && styleId in tableData.colorMap) {
      colorInfo = tableData.colorMap[styleId];
    }
    
    // Fall back to the legacy style palette - Fix the type error with proper type guard
    let style: CellStyle | undefined = undefined;
    let format: any = undefined;
    if (typeof styleId === 'number' && tableData?.sp && styleId in tableData.sp) {
      style = tableData.sp[styleId];
      format = style?.format;
    }

    // Special handling for strings
    if (typeof value === 'string') {
      // Check if this is already a percentage string
      const isAlreadyPercentage = value.includes('%');
      
      // Check if it's a numeric string that needs formatting
      const numValue = parseFloat(value);
      const hasNumericFormat = 
        (colorInfo && 'type' in colorInfo && colorInfo.type) || 
        (format && format.type);
      
      if (!isNaN(numValue) && hasNumericFormat) {
        // Process as number if it has a numeric format
        value = numValue;
        
        // Store the percentage info to use later
        if (isAlreadyPercentage) {
          // Add property to colorInfo if it exists
          if (colorInfo) {
            colorInfo = { ...colorInfo, isAlreadyPercentage: true };
          }
          // Otherwise store it in a new variable
          else if (!format?.isAlreadyPercentage) {
            format = { ...format, isAlreadyPercentage: true };
          }
        }
      } else {
        // For any other string value, preserve it exactly as is
        return { 
          formattedValue: value + significanceLetter,
          formatClasses: 'text-format'
        };
      }
    }

    // Handle object values
    if (typeof value === 'object') {
      // If it's a rich text object with 't' property
      if (value.t !== undefined) {
        return {
          formattedValue: value.t + significanceLetter,
          formatClasses: 'text-format'
        };
      }
      // If it's an array of rich text objects
      if (Array.isArray(value)) {
        return {
          formattedValue: value.map((item: { t?: string }) => item.t || '').join('') + significanceLetter,
          formatClasses: 'text-format'
        };
      }
      // If it has a text property
      if (value.text !== undefined) {
        return {
          formattedValue: value.text + significanceLetter,
          formatClasses: 'text-format'
        };
      }
      // If it has a value property
      if (value.value !== undefined) {
        return {
          formattedValue: String(value.value) + significanceLetter,
          formatClasses: 'text-format'
        };
      }
      // Last resort - try toString()
      return {
        formattedValue: (value?.toString() || '') + significanceLetter,
        formatClasses: 'text-format'
      };
    }

    // Convert to number if numeric
    let numericValue: number | undefined;
    if (typeof value === 'number') {
      numericValue = value;
    } else if (typeof value === 'string' && !isNaN(parseFloat(value))) {
      numericValue = parseFloat(value);
    }

    // Handle numbers with formatting
    if (numericValue !== undefined) {
      // First try using new colorMap
      if (colorInfo) {
        // Percentage formatting
        if (colorInfo.percentage) {
          const decimalPlaces = colorInfo.decimal ?? 1;
          const percentType = colorInfo.percentType || '';
          formatClasses = `percent-format decimal-${decimalPlaces} percentType-${percentType}`;
          
          // Don't multiply by 100 if the value already included a % symbol
          const percentValue = colorInfo.isAlreadyPercentage 
            ? numericValue.toFixed(decimalPlaces)
            : (numericValue * 100).toFixed(decimalPlaces);
            
          return {
            formattedValue: `${percentValue}%${significanceLetter}`,
            formatClasses
          };
        }
        
        // Number formatting
        if (colorInfo.type === 'n') {
          const decimalPlaces = colorInfo.decimal ?? (Number.isInteger(numericValue) ? 0 : 2);
          formatClasses = `number-format decimal-${decimalPlaces}`;
          return {
            formattedValue: (Number.isInteger(numericValue) ? 
              numericValue.toString() : 
              numericValue.toFixed(decimalPlaces)) + significanceLetter,
            formatClasses
          };
        }
      }
      
      // Fall back to legacy style formatting
      if (format) {
        // Percentage format
        if (format.type === 'p' || format.percentage) {
          const decimalPlaces = format.decimal_places ?? 1;
          formatClasses = `percent-format decimal-${decimalPlaces}`;
          
          // Don't multiply by 100 if the value already included a % symbol
          const percentValue = format.isAlreadyPercentage 
            ? numericValue.toFixed(decimalPlaces)
            : (numericValue * 100).toFixed(decimalPlaces);
            
          return {
            formattedValue: `${percentValue}%${significanceLetter}`,
            formatClasses
          };
        }
        
        // Number format with decimal places
        if (format.type === 'n') {
          let formatted = format.decimal_places !== undefined ? 
            numericValue.toFixed(format.decimal_places) : 
            Number.isInteger(numericValue) ? numericValue.toString() : numericValue.toFixed(2);
            
          // Add thousands separator if specified
          formatClasses = `number-format decimal-${format.decimal_places || '0'}`;
          if (format.thousands_separator) {
            formatClasses += ' thousands-separator';
            const parts = formatted.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            formatted = parts.join('.');
          }
          return {
            formattedValue: formatted + significanceLetter,
            formatClasses
          };
        }

        // Date format
        if (format.type === 'd' || format.date_time) {
          formatClasses = 'date-format';
          try {
            const date = new Date(numericValue);
            return {
              formattedValue: date.toLocaleDateString() + significanceLetter,
              formatClasses
            };
          } catch {
            return {
              formattedValue: String(numericValue) + significanceLetter,
              formatClasses
            };
          }
        }
      }
      
      // Default number formatting
      const decimalPlaces = Number.isInteger(numericValue) ? 0 : 2;
      formatClasses = `number-format decimal-${decimalPlaces}`;
      return {
        formattedValue: (Number.isInteger(numericValue) ? 
          numericValue.toString() : 
          numericValue.toFixed(2)) + significanceLetter,
        formatClasses
      };
    }

    // Default to string representation
    return {
      formattedValue: String(value) + significanceLetter,
      formatClasses: 'text-format'
    };
  }, []);

  // Update getCellLabelClasses to use the enhanced structure
  const getCellLabelClasses = useCallback((cell: any, mergedCellInfo: any, colIndex: number, merges?: any[]): { labelClasses: string; isSideLabel: boolean; isSideGroup: boolean } | string => {
    let labelClasses = '';
    let isSideLabel = false;
    let isSideGroup = false;
    
    if (cell.ref && mergedCellInfo) {
      const cellCol = getCellColumn(cell.ref);
      const cellRow = getCellRow(cell.ref);
      
      // Check if cell is part of any merged range
      const cellIsMerged = isPartOfMerge(cell.ref, merges || []);
      
      // Check if this is the origin cell (starting cell) of the first merged range
      const isOriginCell = merges && merges.length > 0 && cell.ref === merges[0][0];
      
      if (isOriginCell) {
        // Special class for the origin cell of the first merged range
        labelClasses += ' cell-mergeOrigin';
      } else {
        // Apply sideLabel class if in the columns of the first merged cell
        if (mergedCellInfo.columns.includes(cellCol)) {
          labelClasses += ' cell-sideLabel';
          isSideLabel = true;
          
          // Add sideGroup class if the cell spans multiple columns or is part of a merged range
          if ((cell.cs && cell.cs > 1) || cellIsMerged) {
            labelClasses += ' cell-sideGroup';
            isSideGroup = true;
          }
        }
        
        // Apply topLabel class if in the rows of the first merged cell
        if (mergedCellInfo.rows.includes(cellRow)) {
          labelClasses += ' cell-topLabel';
          
          // Add topGroup class if the cell spans multiple rows or is part of a merged range
          if ((cell.rs && cell.rs > 1) || cellIsMerged) {
            labelClasses += ' cell-topGroup';
          }
        }
      }
    }
    
    // Use the enhanced structure if available
    if (safeData?.enhanced && cell.ref) {
      const cellType = safeData.enhanced.cellTypes[cell.ref];
      if (cellType) {
        if (cellType === 'topLabel') return 'cell-topLabel';
        if (cellType === 'sideLabel') return 'cell-sideLabel';
        if (cellType === 'topGroup') return 'cell-topGroup';
        if (cellType === 'sideGroup') return 'cell-sideGroup';
      }
    }
    
    return { labelClasses, isSideLabel, isSideGroup };
  }, [safeData]);

  // Function to find all matches in the table
  const findMatches = useCallback((query: string) => {
    if (!query.trim()) {
      setSearchMatches([]);
      setCurrentMatchIndex(-1);
      return;
    }

    const matches: SearchMatch[] = [];
    const searchRegex = new RegExp(query, 'i');

    // Helper function to get searchable text from a cell
    const getCellSearchText = (cell: any): string => {
      // If it's undefined or null, return empty string
      if (cell.v === undefined || cell.v === null) return '';
      
      // If it's already a string, return it
      if (typeof cell.v === 'string') return cell.v;
      
      // If it's a number, convert to string
      if (typeof cell.v === 'number') return String(cell.v);
      
      // If it's an object (like rich text)
      if (typeof cell.v === 'object') {
        if (cell.v.t !== undefined) return cell.v.t;
        if (Array.isArray(cell.v)) return cell.v.map((item: { t?: string }) => item.t || '').join('');
        if (cell.v.text !== undefined) return cell.v.text;
        if (cell.v.value !== undefined) return String(cell.v.value);
        return cell.v.toString();
      }
      
      // Fallback: convert to string
      return String(cell.v);
    };

    // Only search through the data rows (skip metadata rows)
    safeData.r.slice(6).forEach((row, rowIndex) => {
      row.c.forEach((cell, colIndex) => {
        // Get the raw text value for searching
        const searchText = getCellSearchText(cell);
        
        // Also get the formatted value for display
        const { formattedValue } = formatCellValue(cell.v, cell.s, cell.l !== undefined, safeData);

        if (searchText && searchRegex.test(searchText)) {
          matches.push({
            rowIndex: rowIndex,
            colIndex,
            value: formattedValue // Use formatted value for display
          });
        }
      });
    });

    setSearchMatches(matches);
    
    if (matches.length > 0) {
      setCurrentMatchIndex(0);
      // Use requestAnimationFrame to ensure the state has updated before scrolling
      requestAnimationFrame(() => {
        const firstMatch = matches[0];
        const cell = document.querySelector(`tr:nth-child(${firstMatch.rowIndex + 1}) td:nth-child(${firstMatch.colIndex + 1})`);
        if (cell) {
          cell.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    } else {
      setCurrentMatchIndex(-1);
    }
  }, [formatCellValue, safeData]);

  // Navigate to next/previous match
  const navigateMatch = useCallback((direction: 'next' | 'prev') => {
    if (searchMatches.length === 0) return;

    let newIndex;
    if (direction === 'next') {
      newIndex = currentMatchIndex + 1 >= searchMatches.length ? 0 : currentMatchIndex + 1;
    } else {
      newIndex = currentMatchIndex - 1 < 0 ? searchMatches.length - 1 : currentMatchIndex - 1;
    }
    setCurrentMatchIndex(newIndex);

    // Scroll to the matched cell
    const match = searchMatches[newIndex];
    const cell = document.querySelector(`tr:nth-child(${match.rowIndex + 1}) td:nth-child(${match.colIndex + 1})`);
    if (cell) {
      cell.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [searchMatches, currentMatchIndex]);

  // Handle search input changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    findMatches(newQuery);
  };

  // Handle clearing the search
  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
    setSearchMatches([]);
    setCurrentMatchIndex(-1);
    // Scroll table back to top-left
    const tableContainer = document.querySelector('.table-container');
    if (tableContainer) {
      tableContainer.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, []);

  // Handle keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!searchInputFocused) return;

      if (e.key === 'Enter') {
        if (e.shiftKey) {
          navigateMatch('prev');
        } else {
          navigateMatch('next');
        }
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [searchInputFocused, navigateMatch]);

  // Add effect to clear search when search bar is hidden
  useEffect(() => {
    if (!isSearchVisible) {
      handleClearSearch();
    }
  }, [isSearchVisible, handleClearSearch]);

  // Determine merged cell information for styling
  const mergedCellInfo = useMemo(() => {
    if (!safeData.m || safeData.m.length === 0) return null;
    
    // Get the first merged cell range
    const [start, end] = safeData.m[0];
    
    const startCol = getCellColumn(start);
    const startRow = getCellRow(start);
    const endCol = getCellColumn(end);
    const endRow = getCellRow(end);
    
    const startColIndex = getColumnIndex(startCol);
    const endColIndex = getColumnIndex(endCol);
    
    // Create arrays of column letters and row numbers in the range
    const columns = Array.from(
      { length: endColIndex - startColIndex + 1 },
      (_, i) => getColumnLetter(startColIndex + i)
    );
    
    const rows = Array.from(
      { length: endRow - startRow + 1 },
      (_, i) => startRow + i
    );
    
    return {
      start,
      end,
      startCol,
      startRow,
      endCol,
      endRow,
      columns,
      rows,
      colSpan: endColIndex - startColIndex + 1,
      rowSpan: endRow - startRow + 1
    };
  }, [safeData.m]);

  // Add search bar component at the top of the table
  const SearchBar = (
    <Collapse in={isSearchVisible} timeout={300} onEntered={() => {
      // Focus the search input after animation completes
      const searchInput = document.querySelector<HTMLInputElement>('input[placeholder="Search in table..."]');
      if (searchInput) {
        searchInput.focus();
      }
    }}>
      <Paper 
        elevation={0} 
        sx={{ 
          p: 1, 
          mb: 2,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: 1,
          backgroundColor: theme.palette.grey[50],
          position: 'relative',
          zIndex: 5 // Lower than the controls panel but higher than table content
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            size="small"
            placeholder="Search in table..."
            value={searchQuery}
            onChange={handleSearchChange}
            onFocus={() => setSearchInputFocused(true)}
            onBlur={() => setSearchInputFocused(false)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
              endAdornment: searchQuery ? (
                <InputAdornment position="end">
                  <Tooltip title="Clear search" arrow placement="right">
                    <IconButton
                      aria-label="clear search"
                      onClick={handleClearSearch}
                      edge="end"
                      size="small"
                      sx={{ 
                        color: 'action.active',
                        '&:hover': {
                          color: 'primary.main'
                        }
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ) : null
            }}
            sx={{ flex: 1 }}
          />
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigateMatch('prev')}
              disabled={searchMatches.length === 0}
              startIcon={<NavigateBeforeIcon />}
            >
              Previous
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigateMatch('next')}
              disabled={searchMatches.length === 0}
              endIcon={<NavigateNextIcon />}
            >
              Next
            </Button>
          </Stack>
          {searchMatches.length > 0 && (
            <Typography variant="body2" color="text.secondary">
              {currentMatchIndex + 1} of {searchMatches.length} matches
            </Typography>
          )}
        </Stack>
      </Paper>
    </Collapse>
  );

  // Add the expand/contract toggle component
  const SideExpandToggle = useMemo(() => (
    <Box 
      sx={{ 
        position: 'absolute', 
        top: '10px', 
        left: '10px', 
        zIndex: 100 
      }}
    >
      <Tooltip title={isSideExpanded ? "Contract side labels" : "Expand side labels"}>
        <IconButton
          size="small"
          onClick={() => onSideExpandedChange?.(!isSideExpanded)}
          sx={{
            bgcolor: 'background.paper',
            border: '1px solid',
            borderColor: 'divider',
            boxShadow: 1,
            '&:hover': {
              bgcolor: 'action.hover'
            }
          }}
        >
          {isSideExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  ), [isSideExpanded, onSideExpandedChange]);

  // Get cell class name based on its formatting
  const getCellClassName = useCallback((cell: any): string => {
    const styleId = typeof cell.s === 'number' ? cell.s : undefined;
    const formatClass = [];
    
    // Try to use the new colorMap first
    if (styleId !== undefined && safeData?.colorMap && safeData.colorMap[styleId]) {
      const colorInfo = safeData.colorMap[styleId];
      
      // Add format type class
      if (colorInfo && typeof colorInfo === 'object' && 'type' in colorInfo && colorInfo.type) {
        formatClass.push(`format-${colorInfo.type}`);
      }
      
      // Add percentage class
      if (colorInfo && typeof colorInfo === 'object' && 'percentage' in colorInfo && colorInfo.percentage) {
        formatClass.push('format-percentage');
        
        // Add percentage type class
        if (colorInfo.percentType) {
          formatClass.push(`percentType-${colorInfo.percentType}`);
        }
      }
    }
    // Fall back to legacy style palette
    else if (styleId !== undefined && safeData?.sp && safeData.sp[styleId]) {
      const styleObj = safeData.sp[styleId];
      
      // Add format type class if format exists
      if (styleObj && typeof styleObj === 'object' && styleObj.format && styleObj.format.type) {
        formatClass.push(`format-${styleObj.format.type}`);
      }
      
      // Add percentage class if percentage format
      if (styleObj && typeof styleObj === 'object' && styleObj.format && styleObj.format.percentage) {
        formatClass.push('format-percentage');
      }
    }
    
    return formatClass.join(' ');
  }, [safeData]);

  // Update memoizedRows to use the new formatting
  const memoizedRows = useMemo(() => {
    return filteredRows.map((row, rowIndex) => {
      // Check if this row contains the current match
      const isCurrentRowMatch = currentMatchIndex !== null && 
        searchMatches[currentMatchIndex]?.rowIndex === rowIndex;
      
      const height = row.h 
        ? excelHeightToPixels(row.h)
        : safeData.defaultRowHeight
          ? excelHeightToPixels(safeData.defaultRowHeight)
          : undefined;

      const rowStyle: React.CSSProperties = {
        height: height ? `${height}px` : undefined,
        minHeight: height ? `${height}px` : undefined,
        display: row.hidden ? 'none' : undefined
      };

      return (
        <tr 
          key={`data-${rowIndex}`} 
          data-height={row.h}
          style={rowStyle}
          className={`table-row ${isCurrentRowMatch ? 'current-match-row' : ''}`}
        >
          {row.c.map((cell, colIndex) => {
            // Format the cell value with the new color map - update to use tableData parameter name
            const { formattedValue } = formatCellValue(
              cell.v, 
              cell.s, 
              cell.l !== undefined,
              safeData  // Use the consistent variable name
            );
            
            // Get cell style
            const cellStyle = getStyleForCell(cell);
            
            // Get format classes
            const formatTypeClasses = getCellClassName(cell);
            
            // Determine if this cell is part of a label column
            const labelResult = getCellLabelClasses(cell, mergedCellInfo, colIndex, safeData.m);
            // Replace the problematic line with inline handling of the result
            const labelClasses = typeof labelResult === 'string' ? labelResult : labelResult.labelClasses;            
            // For search highlighting
            const isCurrentMatch = currentMatchIndex !== null && 
                                searchMatches[currentMatchIndex]?.rowIndex === rowIndex && 
                                searchMatches[currentMatchIndex]?.colIndex === colIndex;
            
            const cellClassName = [
              formatTypeClasses,
              cell.ref ? `cell-${cell.ref}` : '',
              cell.ref === hoveredCell ? 'hovered' : '',
              isCurrentMatch ? 'search-match-current' : '',
              searchMatches.some(match => match.rowIndex === rowIndex && match.colIndex === colIndex) ? 'search-match' : '',
              labelClasses,
              isSideExpanded && (labelClasses.includes('cell-sideLabel') || labelClasses.includes('cell-sideGroup')) ? 'side-expanded-cell' : '',
              colIndex === 0 && (cell.v === undefined || cell.v === null || cell.v === '') ? 'empty-cell' : '',
              'cell-label-text'
            ].filter(Boolean).join(' ');

            const handleMouseEnter = () => {
              if (cell.ref) {
                setHoveredCell(cell.ref);
                
                // Use timeout to prevent flickering of tooltips
                if (hoverTimerRef.current) {
                  clearTimeout(hoverTimerRef.current);
                }
              }
            };

            const handleMouseLeave = () => {
              setHoveredCell(null);
              
              // Clear the timer
              if (hoverTimerRef.current) {
                clearTimeout(hoverTimerRef.current);
                hoverTimerRef.current = null;
              }
            };

            return (
              <td
                key={`data-${rowIndex}-${colIndex}`}
                style={cellStyle}
                colSpan={cell.cs}
                rowSpan={cell.rs}
                className={cellClassName}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {shouldShowTooltip(cell, formattedValue) ? (
                  <TooltipCell content={formattedValue}>
                    <div className={`cell-content ${wrapText && !isSideExpanded ? 'wrap' : 'nowrap'}`}>
                      {formattedValue}
                    </div>
                  </TooltipCell>
                ) : (
                  <div className={`cell-content ${wrapText && !isSideExpanded ? 'wrap' : 'nowrap'}`}>
                    {formattedValue}
                  </div>
                )}
              </td>
            );
          })}
        </tr>
      );
    });
  }, [
    filteredRows, 
    currentMatchIndex, 
    searchMatches, 
    safeData,
    mergedCellInfo, 
    hoveredCell, 
    wrapText, 
    isSideExpanded,
    getStyleForCell,
    formatCellValue,
    getCellLabelClasses,
    getCellClassName
  ]);

  // Memoize the colgroup to prevent re-rendering
  const memoizedColgroup = useMemo(() => {
    return (
      <colgroup>
        {safeData.r[0]?.c.map((_, colIndex) => {
          // Check if this column contains side label cells
          const containsSideLabel = safeData.r.some(row => {
            const cell = row.c[colIndex];
            if (!cell) return false;
            // Get the cell's classes or check colIndex directly if you can identify side label columns
            const hasLabelClass = cell.ref && mergedCellInfo?.columns.includes(getCellColumn(cell.ref));
            return hasLabelClass;
          });
          
          // Check if this is the first column and if all cells with cell-sideGroup are empty
          let isEmpty = false;
          if (colIndex === 0) {
            // Find all cells in this column that would have the cell-sideGroup class
            const sideGroupCells = safeData.r.filter(row => {
              const cell = row.c[colIndex];
              if (!cell || !cell.ref) return false;
              
              // Check if this cell would get the cell-sideGroup class
              // This logic should match the logic used when assigning the cell-sideGroup class
              const cellCol = getCellColumn(cell.ref);
              const cellIsMerged = isPartOfMerge(cell.ref, safeData.m);
              const isSideGroup = mergedCellInfo?.columns.includes(cellCol) && 
                                ((cell.cs && cell.cs > 1) || cellIsMerged);
              
              return isSideGroup;
            }).map(row => row.c[colIndex]);
            
            // Check if all these cells are empty
            isEmpty = sideGroupCells.length > 0 && sideGroupCells.every(cell => 
              cell.v === undefined || cell.v === null || cell.v === '');
          }
          
          // Determine the class based on both conditions
          const colClass = containsSideLabel 
            ? (isEmpty ? 'empty-col' : 'side-label-col')
            : 'table-col';
          
          return (
            <col
              key={colIndex}
              className={colClass}
              style={{
                display: safeData.cols?.[colIndex]?.hidden ? 'none' : undefined
              }}
            />
          );
        })}
      </colgroup>
    );
  }, [safeData.r, safeData.cols, safeData.m, mergedCellInfo]);

  // Clean up the timer when component unmounts
  useEffect(() => {
    return () => {
      if (hoverTimerRef.current) {
        clearTimeout(hoverTimerRef.current);
      }
    };
  }, []);

  return (
    <Box sx={{ 
      width: '100%', 
      backgroundColor: '#FFF',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minHeight: 0,
      overflow: 'hidden',
      ...containerStyle,
      ...tableStyles,
      ...cellTypeStyles
    }}>
      {isSearchVisible && SearchBar}
      <MetadataBlock metadata={extractMetadata()} />
      <Box sx={{ 
        flex: 1,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden'
      }} className={`table-container ${isSideExpanded ? 'side-expanded' : ''}`}>
        {SideExpandToggle}
        <Box sx={{
          flex: 1,
          minHeight: 0,
          maxHeight: 'calc(100vh - 200px)', // Fixed maximum height to enable browser virtualization
          overflowX: 'auto',
          overflowY: 'auto'
        }}>
          <table className={`json-table ${isSideExpanded ? 'side-expanded' : ''}`} style={{ 
            borderCollapse: 'collapse',
            tableLayout: 'fixed',
            width: 'fit-content',
            minWidth: '100%'
          }}>
            {memoizedColgroup}
            <tbody>
              {memoizedRows}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export const JsonTable: React.FC<JsonTableProps> = ({ 
  data, 
  fullscreen = false, 
  wrapText = false, 
  isSearchVisible = false,
  onFullscreenChange,
  onWrapTextChange,
  onSearchVisibleChange,
  displayOptions,
  onDisplayOptionChange,
  onApplyDisplayOptions,
  isDisplayOptionsDisabled
}) => {
  
  const theme = useTheme();
  const [sideExpanded, setSideExpanded] = useState(false);

  return (
    <Box sx={{ 
      width: '100%', 
      position: 'relative', 
      height: '100%',
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      {/* Normal view */}
      {!fullscreen && (
        <>
          <Box sx={{ zIndex: 10, position: 'relative' }}>
            <TableControls
              fullscreen={fullscreen}
              wrapText={wrapText}
              isSearchVisible={isSearchVisible}
              onFullscreenChange={onFullscreenChange || (() => {})}
              onWrapTextChange={onWrapTextChange || (() => {})}
              onSearchVisibleChange={onSearchVisibleChange || (() => {})}
              displayOptions={displayOptions}
              onDisplayOptionChange={onDisplayOptionChange}
              onApplyDisplayOptions={onApplyDisplayOptions}
              isDisplayOptionsDisabled={isDisplayOptionsDisabled}
              showCloseButton={false}
            />
          </Box>
          <Box sx={{ flex: 1, minHeight: 0, overflow: 'hidden' }}>
            <TableContent
              data={data}
              containerStyle={{
                height: '100%',
                minHeight: 0,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
              }}
              wrapText={wrapText}
              isSearchVisible={isSearchVisible}
              isSideExpanded={sideExpanded}
              onSideExpandedChange={setSideExpanded}
            />
          </Box>
        </>
      )}

      {/* Fullscreen view */}
      {fullscreen && (
        <Box sx={{ 
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'background.default',
          zIndex: theme.zIndex.modal,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          {/* Fixed position controls in fullscreen mode */}
          <Box sx={{ 
            position: 'sticky',
            top: 0,
            left: 0,
            right: 0,
            zIndex: theme.zIndex.modal + 2,
            p: 2,
            pb: 0,
            backgroundColor: theme.palette.background.default
          }}>
            <TableControls
              fullscreen={fullscreen}
              wrapText={wrapText}
              isSearchVisible={isSearchVisible}
              onFullscreenChange={onFullscreenChange || (() => {})}
              onWrapTextChange={onWrapTextChange || (() => {})}
              onSearchVisibleChange={onSearchVisibleChange || (() => {})}
              displayOptions={displayOptions}
              onDisplayOptionChange={onDisplayOptionChange}
              onApplyDisplayOptions={onApplyDisplayOptions}
              isDisplayOptionsDisabled={isDisplayOptionsDisabled}
              showCloseButton={true}
            />
          </Box>
          
          <Box sx={{ 
            flex: 1,
            p: 2,
            pt: 0,
            overflow: 'hidden'
          }}>
            <TableContent
              data={data}
              containerStyle={{
                height: '100%',
                minHeight: 0,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
              }}
              wrapText={wrapText}
              isSearchVisible={isSearchVisible}
              isSideExpanded={sideExpanded}
              onSideExpandedChange={setSideExpanded}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}; 