import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Toolbar, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Box, Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { JobInfo, CustomerInfo } from '../types/types';

const Header: React.FC = () => {
  const { isAuthenticated, carbonClient, logout, isLoading } = useAuth();
  const { selectedCustomer, selectedJob, setSelectedCustomer, setSelectedJob } = useJob();
  const navigate = useNavigate();
  const location = useLocation();

  const [customers, setCustomers] = useState<CustomerInfo[]>([]);
  const [jobs, setJobs] = useState<JobInfo[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isSessionValid, setIsSessionValid] = useState(false);
  
  const sessionInfo = carbonClient?.getSessionInfo();
  const isLicenseManager = sessionInfo?.roles?.includes('Licman');
  const isLoginPage = location.pathname === '/';

  // Verify session validity whenever authentication state changes
  useEffect(() => {
    const verifySession = async () => {
      if (!isAuthenticated || !carbonClient) {
        setIsSessionValid(false);
        return;
      }

      try {
        // Try to make a simple API call to verify the session
        await carbonClient.getCustomers();
        setIsSessionValid(true);
      } catch (error) {
        console.error('Session verification failed:', error);
        setIsSessionValid(false);
        // Session is invalid, trigger logout
        await logout();
        navigate('/');
      }
    };

    verifySession();
  }, [isAuthenticated, carbonClient, logout, navigate]);

  // Fetch customers when component mounts
  useEffect(() => {
    const fetchCustomers = async () => {
      if (carbonClient) {
        setIsLoadingData(true);
        try {
          const customerList = await carbonClient.getCustomers();
          setCustomers(customerList);
        } catch (error) {
        } finally {
          setIsLoadingData(false);
        }
      } else {
        console.log('Carbon client not available');
      }
    };
    fetchCustomers();
  }, [carbonClient]);

  // Fetch jobs when customer changes
  useEffect(() => {
    const fetchJobs = async () => {
      if (carbonClient && selectedCustomer) {
        setIsLoadingData(true);
        try {
          const jobList = await carbonClient.getJobs(selectedCustomer);
          setJobs(jobList);

          // If we have a persisted selectedJob but it's not in the new job list,
          // we should clear it
          if (selectedJob && !jobList.find(job => job.id === selectedJob.id)) {
            setSelectedJob(null);
          }
        } catch (error) {
          console.error('Error fetching jobs:', error);
        } finally {
          setIsLoadingData(false);
        }
      }
    };
    fetchJobs();
  }, [carbonClient, selectedCustomer, selectedJob, setSelectedJob]);

  // Initialize job when selectedJob changes
  useEffect(() => {
    const initializeJob = async () => {
      if (carbonClient && selectedCustomer && selectedJob) {
        setIsLoadingData(true);
        try {
          await carbonClient.openCloudJob(selectedCustomer, selectedJob);
        } catch (error) {
          console.error('Error initializing job:', error);
          setSelectedJob(null);
        } finally {
          setIsLoadingData(false);
        }
      }
    };
    initializeJob();
  }, [carbonClient, selectedCustomer, selectedJob, setSelectedJob]);

  const closeCurrentJob = useCallback(async () => {
    if (carbonClient && selectedJob) {
      try {
        await carbonClient.closeJob();
        setSelectedJob(null);
      } catch (error) {
        console.error('Error closing job:', error);
      }
    }
  }, [carbonClient, selectedJob, setSelectedJob]);

  const handleCustomerChange = async (event: SelectChangeEvent<string>) => {
    await closeCurrentJob();
    // First clear the customer to trigger TOC clearing
    setSelectedCustomer(null);
    // Then set the new customer in the next tick
    setTimeout(() => {
      setSelectedCustomer(event.target.value);
    }, 0);
  };

  const handleJobChange = async (event: SelectChangeEvent<string>) => {
    await closeCurrentJob();
    
    const jobId = event.target.value;
    const selectedJobObject = jobs.find(job => job.id === jobId);
    if (selectedJobObject) {
      setSelectedJob(selectedJobObject);
    }
  };

  const handleLogout = async () => {
    await closeCurrentJob();
    await logout();
    navigate('/');
  };

  // Don't render anything while loading
  if (isLoading) {
    return null;
  }

  // Render simplified header for login page or unauthenticated state
  if (isLoginPage || !isAuthenticated) {
    return (
      <AppBar
        position="fixed"
        color="default"
        elevation={0}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            backgroundColor: 'white',
            borderBottom: '6px solid #FFC000',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img 
              src="/assets/img/bayes-price-logo.svg" 
              alt="Bayes Price Logo" 
              style={{ height: '24px', marginRight: '16px' }} 
            />
          </Box>
        </Toolbar>
      </AppBar>
    );
  }

  // Render full header for authenticated state
  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={0}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between', 
          backgroundColor: 'white',
          borderBottom: '6px solid #FFC000',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img 
            src="/assets/img/bayes-price-logo.svg" 
            alt="Bayes Price Logo" 
            style={{ height: '24px', marginRight: '16px' }} 
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <FormControl size="small" sx={{ minWidth: 100 }}>
            <InputLabel>Customer</InputLabel>
            <Select
              value={selectedCustomer || ''}
              label="Customer"
              onChange={handleCustomerChange}
              disabled={isLoadingData}
            >
              {customers.map((customer) => (
                <MenuItem key={customer.name} value={customer.name}>
                  {customer.displayName || customer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 100 }}>
            <InputLabel>Job</InputLabel>
            <Select
              value={selectedJob?.id || ''}
              label="Job"
              onChange={handleJobChange}
              disabled={!selectedCustomer || isLoadingData}
            >
              {jobs.map((job) => (
                <MenuItem key={job.id} value={job.id}>{job.displayName || job.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button color="inherit" component={RouterLink} to="/dashboard" sx={{ color: 'text.primary' }} disabled={isLoadingData}>
            Dashboard
          </Button>
          <Button color="inherit" component={RouterLink} to="/tables" sx={{ color: 'text.primary' }} disabled={isLoadingData}>
            Tables
          </Button>
          <Button color="inherit" component={RouterLink} to="/copilot" sx={{ color: 'text.primary' }} disabled={isLoadingData}>
            CoPilot
          </Button>
          <Button color="inherit" component={RouterLink} to="/files" sx={{ color: 'text.primary' }} disabled={isLoadingData}>
            Files
          </Button>
          <Button color="inherit" component={RouterLink} to="/experiments" sx={{ color: 'text.primary' }} disabled={isLoadingData}>
            Experiments
          </Button>
          <Button color="inherit" component={RouterLink} to="/users" sx={{ color: 'text.primary' }} disabled={isLoadingData}>
            Users
          </Button>
          <Button color="inherit" component={RouterLink} to="/project-management" sx={{ color: 'text.primary' }} disabled={isLoadingData}>
            Project Management
          </Button>
          <Button color="inherit" onClick={handleLogout} sx={{ color: 'text.primary' }} disabled={isLoadingData}>
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header; 