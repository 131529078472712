import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Button, Paper, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, FormControl, InputLabel, Select, Snackbar, Alert, Toolbar, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Breadcrumbs, ListItemIcon } from '@mui/material';
import { CloudUpload, GetApp, CreateNewFolder, Edit, Delete, MoreVert, Folder, InsertDriveFile, ArrowBack, Sort, Search, FileCopy, NavigateNext, PictureAsPdf, Image, Description, Code, TableChart, Archive, AudioFile, VideoFile, TextSnippet } from '@mui/icons-material';
import { useJob } from '../contexts/JobContext';
import { useCarbonClient } from '../hooks/useCarbonClient';
import { FileIcon } from '../components/FileIcon';

interface FileInfo {
  name: string;
  displayName: string;
  size?: number;
  lastModified?: Date;
  isFolder: boolean;
  parentFolder: string | null;
}

interface CustomerInfo {
  name: string;
  storageKey: string;
}

interface RenameFileDialog {
  open: boolean;
  oldName: string;
  newName: string;
  folderPath: string;
}

type SortField = 'name' | 'createdAt';
type SortOrder = 'asc' | 'desc';

interface SortConfig {
  field: SortField;
  order: SortOrder;
}

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

// Function to create API endpoint paths properly
const getApiEndpoint = (endpoint: string) => {
  // The server routes are defined with /api prefix but our API_URL is configured with /secret
  // We need to add /api to the path after removing the API_URL's /secret path
  if (endpoint.startsWith('/')) {
    return `${API_URL.replace(/\/secret$/, '')}/api${endpoint}`;
  }
  return `${API_URL.replace(/\/secret$/, '')}/api/${endpoint}`;
};

const sanitizeFileName = (fileName: string): string => {
  // Remove any leading/trailing whitespace
  let sanitized = fileName.trim();
  
  // Remove any leading slashes or 'Docs/'
  sanitized = sanitized.replace(/^(\/|Docs\/)+/, '');
  
  // Replace any invalid characters with underscores
  // This covers most invalid characters in Azure Blob Storage names
  sanitized = sanitized.replace(/[<>:"/\\|?*]/g, '_');
  
  return sanitized;
};

const formatFileSize = (bytes: number | undefined): string => {
  if (bytes === undefined) return 'Unknown';
  const mb = bytes / (1024 * 1024);
  if (mb < 0.1) return '< 0.1 MB';
  return `${mb.toFixed(1)} MB`;
};

const getFileIcon = (fileName: string, isOpen = false) => {
  return <FileIcon filename={fileName} isFolder={false} size={24} />;
};

const getSortArrow = (field: SortField, currentSort: SortConfig) => {
  if (currentSort.field !== field) return null;
  return (
    <span style={{ fontSize: '1.2rem', marginLeft: '4px' }}>
      {currentSort.order === 'asc' ? '↑' : '↓'}
    </span>
  );
};

// Helper function to find a unique filename by adding (1), (2), etc.
const getUniqueFileName = (fileName: string, existingFiles: FileInfo[]): string => {
  // If file doesn't exist, return the original name
  const existingNames = existingFiles.map(f => f.displayName.toLowerCase());
  
  if (!existingNames.includes(fileName.toLowerCase())) {
    return fileName;
  }
  
  // Parse filename and extension
  const lastDotIndex = fileName.lastIndexOf('.');
  const baseName = lastDotIndex !== -1 ? fileName.substring(0, lastDotIndex) : fileName;
  const extension = lastDotIndex !== -1 ? fileName.substring(lastDotIndex) : '';
  
  // Try adding (1), (2), etc. until we find a unique name
  let counter = 1;
  let newName = `${baseName} (${counter})${extension}`;
  
  while (existingNames.includes(newName.toLowerCase())) {
    counter++;
    newName = `${baseName} (${counter})${extension}`;
  }
  
  return newName;
};

export const Files: React.FC = () => {
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const { selectedCustomer, selectedJob } = useJob();
  const carbonClient = useCarbonClient();

  const [newFolderName, setNewFolderName] = useState('');
  const [renameFolderDialog, setRenameFolderDialog] = useState({ open: false, oldName: '', newName: '' });
  const [moveFileDialog, setMoveFileDialog] = useState({ open: false, fileName: '', targetFolder: '' });
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [folders, setFolders] = useState<string[]>([]);
  const [currentFolder, setCurrentFolder] = useState<string | null>(null);
  const [renameFileDialog, setRenameFileDialog] = useState<RenameFileDialog>({ 
    open: false, 
    oldName: '', 
    newName: '',
    folderPath: ''
  });
  const [errorNotification, setErrorNotification] = useState<string | null>(null);
  const [successNotification, setSuccessNotification] = useState<string | null>(null);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ field: 'name', order: 'asc' });
  const [createFolderDialog, setCreateFolderDialog] = useState({ open: false, folderName: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<FileInfo[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set());
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>(['Home']);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{open: boolean, itemToDelete: string | null}>({
    open: false,
    itemToDelete: null
  });

  useEffect(() => {
    fetchFiles();
  }, [selectedCustomer, selectedJob, carbonClient]);

  const fetchFiles = async () => {
    if (!selectedCustomer || !selectedJob || !carbonClient) {
      setFiles([]);
      setSelectedFiles(new Set());
      return;
    }

    setLoading(true);

    try {
      const sessionInfoString = localStorage.getItem('sessionInfo');
      if (!sessionInfoString) {
        throw new Error('No session info found');
      }
      const sessionInfo = JSON.parse(sessionInfoString);
      
      const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
      if (!customerInfo) {
        throw new Error('Selected customer not found in session info');
      }

      const storageKey = customerInfo.storageKey;

      const encodedJobName = encodeURIComponent(selectedJob.name);

      const response = await fetch(getApiEndpoint(`/listFiles?customer=${encodeURIComponent(selectedCustomer)}&job=${encodedJobName}&storageKey=${encodeURIComponent(storageKey)}`));
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to fetch files: ${errorData.error}. Details: ${errorData.details}`);
      }
      const fileList = await response.json();
      console.log('Received files:', fileList);
      
      const processedFileList = fileList.map((file: FileInfo) => ({
        ...file,
        lastModified: file.lastModified ? new Date(file.lastModified) : undefined
      }));
      
      setFiles(processedFileList);
      const folderSet = new Set<string>(
        fileList
          .filter((file: FileInfo) => file.isFolder)
          .map((file: FileInfo) => file.displayName)
          .filter((displayName: unknown): displayName is string => typeof displayName === 'string')
      );
      setFolders(Array.from(folderSet));
      setSelectedFiles(new Set());
    } catch (error: unknown) {
      console.error('Error fetching files:', error);
      if (error instanceof Error) {
        console.error('Error details:', error.message);
      }
      setFiles([]);
      setSelectedFiles(new Set());
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (fileName: string) => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfoString = localStorage.getItem('sessionInfo');
    if (!sessionInfoString) {
      console.error('No session info found');
      return;
    }
    const sessionInfo = JSON.parse(sessionInfoString);
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) {
      console.error('Selected customer not found in session info');
      return;
    }
    const storageKey = customerInfo.storageKey;

    try {
      const sanitizedFileName = sanitizeFileName(fileName);
      console.log('Downloading file:', {
        original: fileName,
        sanitized: sanitizedFileName
      });

      const downloadUrl = getApiEndpoint(`/downloadFile?customer=${encodeURIComponent(selectedCustomer)}&job=${encodeURIComponent(selectedJob.name)}&fileName=${encodeURIComponent(sanitizedFileName)}&storageKey=${encodeURIComponent(storageKey)}`);
      
      const response = await fetch(downloadUrl);

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // Use the original file name for the download
        const displayName = fileName.split('/').pop() || fileName;
        a.download = displayName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        const errorData = await response.json();
        console.error('Error downloading file:', errorData);
        setErrorNotification(`Failed to download file: ${errorData.details || errorData.error}`);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      if (error instanceof Error) {
        setErrorNotification(`Failed to download file: ${error.message}`);
      } else {
        setErrorNotification('An unknown error occurred while downloading the file');
      }
    }
  };

  const handleCreateFolder = async () => {
    if (!selectedCustomer || !selectedJob || !createFolderDialog.folderName) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      const response = await fetch(getApiEndpoint('/createFolder'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customer: selectedCustomer,
          job: selectedJob,
          folderName: createFolderDialog.folderName,
          storageKey: customerInfo.storageKey
        }),
      });

      if (response.ok) {
        console.log('Folder created successfully');
        setCreateFolderDialog({ open: false, folderName: '' });
        fetchFiles();
      } else {
        const errorData = await response.json();
        console.error('Error creating folder:', errorData.error);
      }
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  const handleRenameFolder = async () => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      const renameResponse = await fetch(getApiEndpoint('/renameFolder'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customer: selectedCustomer,
          job: selectedJob,
          oldFolderName: renameFolderDialog.oldName,
          newFolderName: renameFolderDialog.newName,
          storageKey: customerInfo.storageKey
        }),
      });

      if (renameResponse.ok) {
        console.log('Folder renamed successfully');
        setRenameFolderDialog({ open: false, oldName: '', newName: '' });
        fetchFiles();
      } else {
        console.error('Error renaming folder');
      }
    } catch (error) {
      console.error('Error renaming folder:', error);
    }
  };

  const handleDeleteFolder = async (folderName: string) => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      const response = await fetch(
        getApiEndpoint(`/deleteFolder?customer=${encodeURIComponent(selectedCustomer)}&job=${encodeURIComponent(selectedJob.name)}&folderName=${encodeURIComponent(folderName)}&storageKey=${encodeURIComponent(customerInfo.storageKey)}`), 
        {
          method: 'DELETE',
        }
      );

      if (response.ok) {
        console.log('Folder deleted successfully');
        fetchFiles();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete folder');
      }
    } catch (error) {
      console.error('Error deleting folder:', error);
      if (error instanceof Error) {
        setErrorNotification(error.message);
      } else {
        setErrorNotification('An unknown error occurred while deleting the folder');
      }
    }
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log("Upload triggered, files:", files);
    
    if (!files || !files.length) {
      console.log("No files selected");
      setErrorNotification("No files selected");
      return;
    }
    
    if (!selectedCustomer || !selectedJob) {
      console.log("Missing customer or job:", { selectedCustomer, selectedJob });
      setErrorNotification("Please select a customer and job first");
      return;
    }

    try {
      const sessionInfoString = localStorage.getItem('sessionInfo');
      if (!sessionInfoString) {
        console.error("No session info found in localStorage");
        setErrorNotification("Session information missing. Please log in again.");
        return;
      }
      
      const sessionInfo = JSON.parse(sessionInfoString);
      const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
      
      if (!customerInfo) {
        console.error("Selected customer not found in session:", selectedCustomer);
        setErrorNotification(`Customer information for ${selectedCustomer} not found`);
        return;
      }

      console.log("Preparing to upload files:", {
        count: files.length,
        customer: selectedCustomer,
        job: selectedJob.name,
        folder: currentFolder || 'root',
        storageKey: customerInfo.storageKey ? "Present" : "Missing"
      });

      let successCount = 0;
      let errorCount = 0;
      
      setLoading(true);
      
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        console.log(`Uploading file ${i+1}/${files.length}:`, file.name);
        
        const formData = new FormData();
        formData.append('file', file);
        formData.append('customer', selectedCustomer);
        formData.append('job', selectedJob?.name || '');
        formData.append('folderName', currentFolder || '');
        formData.append('storageKey', customerInfo.storageKey);

        const uploadUrl = getApiEndpoint('/uploadFile');
        console.log("Upload endpoint:", uploadUrl);

        try {
          const response = await fetch(uploadUrl, {
            method: 'POST',
            // Explicitly NOT setting Content-Type header to let the browser set it with the boundary parameter
            body: formData,
            credentials: 'include' // Include cookies if your API uses session-based auth
          });

          console.log(`Upload response for ${file.name}:`, {
            status: response.status,
            ok: response.ok,
            statusText: response.statusText
          });

          if (response.ok) {
            // Get the potentially modified filename from the response
            const data = await response.json();
            successCount++;
            console.log(`File uploaded successfully: ${data.fileName || file.name}`);
            setSuccessNotification(`Successfully uploaded: ${data.fileName || file.name}`);
          } else {
            errorCount++;
            try {
              const errorData = await response.json();
              console.error('Error uploading file (JSON):', errorData);
              setErrorNotification(`Error uploading ${file.name}: ${errorData.error || response.statusText}`);
            } catch {
              const errorText = await response.text();
              console.error('Error uploading file (Text):', errorText);
              setErrorNotification(`Error uploading ${file.name}: ${response.statusText}`);
            }
          }
        } catch (error) {
          errorCount++;
          console.error(`Network error uploading file ${file.name}:`, error);
          if (error instanceof Error) {
            setErrorNotification(`Network error for ${file.name}: ${error.message}`);
          } else {
            setErrorNotification(`Unknown error uploading ${file.name}`);
          }
        }
      }

      // Refresh the file list regardless of upload success/failure
      await fetchFiles();
      
      setLoading(false);
    } catch (error) {
      console.error('Error in upload process:', error);
      setErrorNotification(`Upload error: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setLoading(false);
    }
  };

  const handleMoveFile = async () => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      console.log('Moving file:', {
        fileName: moveFileDialog.fileName,
        targetFolder: moveFileDialog.targetFolder,
        customer: selectedCustomer,
        jobName: selectedJob.name
      });

      const response = await fetch(getApiEndpoint('/moveFile'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customer: selectedCustomer,
          job: selectedJob.name,
          sourceFileName: moveFileDialog.fileName,
          targetFolderName: moveFileDialog.targetFolder,
          storageKey: customerInfo.storageKey
        }),
      });

      if (response.ok) {
        console.log('File moved successfully');
        setMoveFileDialog({ open: false, fileName: '', targetFolder: '' });
        fetchFiles();
      } else {
        const errorData = await response.json();
        console.error('Error moving file:', errorData);
        throw new Error(errorData.error || 'Failed to move file');
      }
    } catch (error) {
      console.error('Error moving file:', error);
      if (error instanceof Error) {
        setErrorNotification(error.message);
      } else {
        setErrorNotification('An unknown error occurred while moving the file');
      }
    }
  };

  const handleContextMenu = (event: React.MouseEvent, item: string) => {
    event.preventDefault();
    setSelectedItem(item);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null,
    );
  };

  const handleContextMenuClose = () => {
    setContextMenu(null);
  };

  const handleDeleteFile = async (fileName: string) => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      const fullPath = fileName.startsWith('Docs/') ? fileName : `Docs/${fileName}`;
      console.log('Deleting file:', {
        customer: selectedCustomer,
        job: selectedJob.name,
        path: fullPath
      });

      const response = await fetch(getApiEndpoint('/deleteFile'), {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customer: selectedCustomer,
          job: selectedJob.name,
          fileName: fullPath,
          storageKey: customerInfo.storageKey
        }),
      });

      if (response.ok) {
        console.log('File deleted successfully');
        fetchFiles();
      } else {
        const errorData = await response.json();
        console.error('Error deleting file:', errorData);
        throw new Error(errorData.error || 'Failed to delete file');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      if (error instanceof Error) {
        setErrorNotification(error.message);
      } else {
        setErrorNotification('An unknown error occurred while deleting the file');
      }
    }
  };

  const handleDelete = async (item: string) => {
    setDeleteConfirmation({
      open: true,
      itemToDelete: item
    });
  };

  const confirmDelete = async () => {
    if (!deleteConfirmation.itemToDelete) return;
    
    try {
      const item = deleteConfirmation.itemToDelete;
      
      if (item.endsWith('/')) {
        // It's a folder
        await handleDeleteFolder(item.replace('Docs/', '').slice(0, -1));
      } else {
        // It's a file
        await handleDeleteFile(item.replace('Docs/', ''));
      }
      
      // Clear selected files after deletion
      setSelectedFiles(new Set());
      
      // Force refresh the file list to ensure UI updates correctly
      await fetchFiles();
    } catch (error) {
      if (error instanceof Error) {
        setErrorNotification(error.message);
      } else {
        setErrorNotification('An unknown error occurred');
      }
    } finally {
      setDeleteConfirmation({ open: false, itemToDelete: null });
    }
  };

  const sortFiles = (files: FileInfo[]): FileInfo[] => {
    const folders = files.filter(file => file.isFolder);
    const nonFolders = files.filter(file => !file.isFolder);

    const sortGroup = (group: FileInfo[]) => {
      return group.sort((a, b) => {
        if (sortConfig.field === 'name') {
          const comparison = a.displayName.localeCompare(b.displayName);
          return sortConfig.order === 'asc' ? comparison : -comparison;
        } else if (sortConfig.field === 'createdAt') {
          const dateA = a.lastModified instanceof Date ? a.lastModified.getTime() : 0;
          const dateB = b.lastModified instanceof Date ? b.lastModified.getTime() : 0;
          return sortConfig.order === 'asc' ? dateA - dateB : dateB - dateA;
        }
        return 0;
      });
    };

    const sortedFolders = sortGroup(folders);
    const sortedNonFolders = sortGroup(nonFolders);

    return [...sortedFolders, ...sortedNonFolders];
  };

  const handleSort = (field: SortField) => {
    setSortConfig(prevConfig => ({
      field,
      order: prevConfig.field === field && prevConfig.order === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
    if (term.trim() === '') {
      setSearchResults([]);
      return;
    }
    const results = files.filter(file => 
      file.displayName.toLowerCase().includes(term.toLowerCase())
    );
    setSearchResults(results);
  };

  const getCurrentFolderContents = () => {
    if (searchTerm.trim() !== '') {
      return sortFiles(searchResults);
    }
    const filteredFiles = files.filter((file: FileInfo) => 
      currentFolder === null 
        ? file.parentFolder === null 
        : file.parentFolder === currentFolder
    );
    return sortFiles(filteredFiles);
  };

  const handleFolderClick = (folderName: string) => {
    setCurrentFolder(folderName);
    setBreadcrumbs(prev => [...prev, folderName]);
  };

  const handleBackClick = () => {
    if (breadcrumbs.length > 1) {
      const newBreadcrumbs = breadcrumbs.slice(0, -1);
      setBreadcrumbs(newBreadcrumbs);
      setCurrentFolder(newBreadcrumbs[newBreadcrumbs.length - 1] === 'Home' ? null : newBreadcrumbs[newBreadcrumbs.length - 1]);
    }
  };

  const handleBreadcrumbClick = (index: number) => {
    const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
    setBreadcrumbs(newBreadcrumbs);
    setCurrentFolder(newBreadcrumbs[newBreadcrumbs.length - 1] === 'Home' ? null : newBreadcrumbs[newBreadcrumbs.length - 1]);
  };

  const handleItemClick = (event: React.MouseEvent, file: FileInfo) => {
    // Prevent folder opening when clicking on the ellipsis icon
    if ((event.target as HTMLElement).closest('.MuiIconButton-root')) {
      return;
    }
    if (file.isFolder) {
      if (searchTerm.trim() !== '') {
        // If we're in search mode, navigate to the folder and clear the search
        setCurrentFolder(file.parentFolder);
        setSearchTerm('');
        setSearchResults([]);
      } else {
        handleFolderClick(file.displayName);
      }
    }
  };

  const handleRenameFile = async () => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      // Use the folderPath to construct the correct paths
      const oldPath = renameFileDialog.oldName;
      const newPath = `${renameFileDialog.folderPath}/${renameFileDialog.newName}`;

      console.log('Renaming file:', { 
        oldPath, 
        newPath, 
        customerName: selectedCustomer, 
        jobName: selectedJob.name 
      });

      const response = await fetch(getApiEndpoint('/renameFile'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          customer: selectedCustomer,
          job: selectedJob.name,
          oldFileName: oldPath,
          newFileName: newPath,
          storageKey: customerInfo.storageKey
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('File renamed successfully:', data.message);
        setRenameFileDialog({ open: false, oldName: '', newName: '', folderPath: '' });
        fetchFiles();
      } else {
        const errorData = await response.json();
        console.error('Error renaming file. Response:', errorData);
        throw new Error(errorData.error || 'Failed to rename file');
      }
    } catch (error) {
      console.error('Error renaming file:', error);
      if (error instanceof Error) {
        setErrorNotification(error.message);
      } else {
        setErrorNotification('An unknown error occurred while renaming the file');
      }
    }
  };

  const handleOpenCreateFolderDialog = () => {
    setCreateFolderDialog({ open: true, folderName: '' });
  };

  const handleSelectFile = (fileName: string) => {
    setSelectedFiles(prev => {
      const newSet = new Set(prev);
      if (newSet.has(fileName)) {
        newSet.delete(fileName);
      } else {
        newSet.add(fileName);
      }
      return newSet;
    });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const visibleFileNames = getCurrentFolderContents().map(file => file.name);
      setSelectedFiles(new Set(visibleFileNames));
    } else {
      setSelectedFiles(new Set());
    }
  };

  const visibleFiles = getCurrentFolderContents();
  
  // Only consider it "all selected" if there are visible files and all of them are in the selectedFiles set
  const isAllSelected = visibleFiles.length > 0 && 
    selectedFiles.size === visibleFiles.length &&
    visibleFiles.every(file => selectedFiles.has(file.name));
  
  // It's indeterminate if some but not all files are selected
  const isIndeterminate = selectedFiles.size > 0 && 
    selectedFiles.size < visibleFiles.length && 
    visibleFiles.some(file => selectedFiles.has(file.name));

  // Helper to get first selected file
  const getFirstSelectedFile = (): string | undefined => {
    return Array.from(selectedFiles)[0];
  };

  const handleBulkDownload = async () => {
    if (!selectedCustomer || !selectedJob || selectedFiles.size === 0) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    // Download files sequentially using Array.from for iteration
    for (const item of Array.from(selectedFiles)) {
      try {
        const storageKey = customerInfo.storageKey;
        
        if (item.endsWith('/')) {
          // Handle folder download
          const sanitizedFolderPath = sanitizeFileName(item);
          const downloadUrl = getApiEndpoint(`/downloadFolder?customer=${encodeURIComponent(selectedCustomer)}&job=${encodeURIComponent(selectedJob.name)}&folderPath=${encodeURIComponent(sanitizedFolderPath)}&storageKey=${encodeURIComponent(storageKey)}`);
          
          console.log('Downloading folder:', {
            original: item,
            sanitized: sanitizedFolderPath,
            url: downloadUrl
          });

          const response = await fetch(downloadUrl);
          if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            const folderName = item.split('/').pop() || item;
            a.download = `${folderName}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          } else {
            const errorData = await response.json();
            console.error('Error downloading folder:', errorData);
            setErrorNotification(`Failed to download folder: ${errorData.details || errorData.error}`);
          }
        } else {
          // Handle file download
          const sanitizedFileName = sanitizeFileName(item);
          const downloadUrl = getApiEndpoint(`/downloadFile?customer=${encodeURIComponent(selectedCustomer)}&job=${encodeURIComponent(selectedJob.name)}&fileName=${encodeURIComponent(sanitizedFileName)}&storageKey=${encodeURIComponent(storageKey)}`);
          
          console.log('Downloading file:', {
            original: item,
            sanitized: sanitizedFileName,
            url: downloadUrl
          });

          const response = await fetch(downloadUrl);
          if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // Use the original file name for the download
            const displayName = item.split('/').pop() || item;
            a.download = displayName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          } else {
            const errorData = await response.json();
            console.error(`Error downloading file ${item}:`, errorData);
            setErrorNotification(`Failed to download ${item}: ${errorData.details || errorData.error}`);
          }
        }
      } catch (error) {
        console.error(`Error downloading ${item}:`, error);
        if (error instanceof Error) {
          setErrorNotification(`Failed to download ${item}: ${error.message}`);
        } else {
          setErrorNotification(`Failed to download ${item}`);
        }
      }
    }
  };

  const handleDownloadFolder = async (folderPath: string) => {
    if (!selectedCustomer || !selectedJob) return;

    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerInfo = sessionInfo.sessionCusts.find((cust: CustomerInfo) => cust.name === selectedCustomer);
    if (!customerInfo) return;

    try {
      const storageKey = customerInfo.storageKey;
      const sanitizedFolderPath = sanitizeFileName(folderPath);
      const downloadUrl = getApiEndpoint(`/downloadFolder?customer=${encodeURIComponent(selectedCustomer)}&job=${encodeURIComponent(selectedJob.name)}&folderPath=${encodeURIComponent(sanitizedFolderPath)}&storageKey=${encodeURIComponent(storageKey)}`);
      
      console.log('Downloading folder:', {
        original: folderPath,
        sanitized: sanitizedFolderPath,
        url: downloadUrl
      });

      const response = await fetch(downloadUrl);
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        const folderName = folderPath.split('/').pop() || folderPath;
        a.download = `${folderName}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        const errorData = await response.json();
        console.error('Error downloading folder:', errorData);
        setErrorNotification(`Failed to download folder: ${errorData.details || errorData.error}`);
      }
    } catch (error) {
      console.error('Error downloading folder:', error);
      if (error instanceof Error) {
        setErrorNotification(`Failed to download folder: ${error.message}`);
      } else {
        setErrorNotification('Failed to download folder');
      }
    }
  };

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          DocStore
        </Typography>
        {selectedCustomer && selectedJob && (
          <>
            <Toolbar sx={{ gap: 2 }}>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUpload />}
                disabled={!selectedCustomer || !selectedJob}
              >
                Upload
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={handleUpload}
                  onClick={(e) => {
                    // Reset the input to ensure onChange fires even if selecting the same file
                    (e.target as HTMLInputElement).value = '';
                  }}
                />
              </Button>
              <Button
                variant="contained"
                startIcon={<CreateNewFolder />}
                onClick={() => setCreateFolderDialog({ open: true, folderName: '' })}
              >
                New Folder
              </Button>
              <Button
                variant="contained"
                startIcon={<GetApp />}
                onClick={handleBulkDownload}
                disabled={selectedFiles.size === 0}
                sx={{ mr: 1 }}
              >
                Download {selectedFiles.size > 0 ? `(${selectedFiles.size})` : ''}
              </Button>
              <Button
                variant="contained"
                startIcon={<FileCopy />}
                onClick={() => {
                  const firstFile = getFirstSelectedFile();
                  if (firstFile) {
                    setMoveFileDialog({ open: true, fileName: firstFile, targetFolder: '' });
                  }
                }}
                disabled={selectedFiles.size !== 1}
                sx={{ mr: 1 }}
              >
                Move
              </Button>
              {/* Commented out due to issues with folder renaming
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={() => {
                  const firstFile = getFirstSelectedFile();
                  if (firstFile) {
                    const displayName = firstFile.split('/').pop() || '';
                    const folderPath = firstFile.substring(0, firstFile.lastIndexOf('/'));
                    setRenameFileDialog({
                      open: true,
                      oldName: firstFile,
                      newName: displayName,
                      folderPath
                    });
                    
                    // Clear selection after initiating rename
                    setSelectedFiles(new Set());
                  }
                }}
                disabled={selectedFiles.size !== 1}
                sx={{ mr: 1 }}
              >
                Rename
              </Button>
              */}
              <Button
                variant="contained"
                color="error"
                startIcon={<Delete />}
                onClick={() => {
                  const firstFile = getFirstSelectedFile();
                  if (firstFile) {
                    handleDelete(firstFile);
                  }
                }}
                disabled={selectedFiles.size !== 1}
              >
                Delete
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                size="small"
                placeholder="Search files..."
                value={searchTerm}
                onChange={(e) => {
                  const term = e.target.value;
                  setSearchTerm(term);
                  if (term.trim() === '') {
                    setSearchResults([]);
                  } else {
                    const results = files.filter(file => 
                      file.displayName.toLowerCase().includes(term.toLowerCase()) ||
                      file.name.toLowerCase().includes(term.toLowerCase())
                    );
                    setSearchResults(results);
                  }
                }}
                InputProps={{
                  startAdornment: <Search />
                }}
              />
            </Toolbar>

            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Box sx={{ padding: 2, paddingLeft: 0 }}>
                  <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                    {breadcrumbs.map((crumb, index) => (
                      <Typography
                        key={index}
                        color={index === breadcrumbs.length - 1 ? 'text.primary' : 'inherit'}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleBreadcrumbClick(index)}
                      >
                        {crumb}
                      </Typography>
                    ))}
                  </Breadcrumbs>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox" sx={{ width: '48px' }}>
                          <Checkbox
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            indeterminate={isIndeterminate}
                          />
                        </TableCell>
                        <TableCell sx={{ width: '40%' }}>
                          <Button
                            onClick={() => handleSort('name')}
                            startIcon={<Sort />}
                            color={sortConfig.field === 'name' ? 'secondary' : 'inherit'}
                          >
                            Name {getSortArrow('name', sortConfig)}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ width: '80px' }}>Actions</TableCell>
                        <TableCell sx={{ width: '30%' }}>
                          <Button
                            onClick={() => handleSort('createdAt')}
                            startIcon={<Sort />}
                            color={sortConfig.field === 'createdAt' ? 'secondary' : 'inherit'}
                          >
                            Modified {getSortArrow('createdAt', sortConfig)}
                          </Button>
                        </TableCell>
                        <TableCell sx={{ width: '100px' }}>Size</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getCurrentFolderContents().map((file: FileInfo) => (
                        <TableRow
                          key={file.name}
                          hover
                          onClick={(event) => handleItemClick(event, file)}
                          style={{ cursor: file.isFolder ? 'pointer' : 'default' }}
                        >
                          <TableCell padding="checkbox" sx={{ width: '48px' }}>
                            <Checkbox
                              className="MuiCheckbox-root"
                              checked={selectedFiles.has(file.name)}
                              onChange={(e) => handleSelectFile(file.name)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </TableCell>
                          <TableCell sx={{ width: '40%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {file.isFolder ? 
                                <FileIcon filename={file.displayName} isFolder={true} isOpen={false} size={24} /> : 
                                <Box sx={{ mr: 1, display: 'flex', alignItems: 'center', height: 24 }}>
                                  {getFileIcon(file.displayName)}
                                </Box>
                              }
                              {searchTerm.trim() !== '' ? file.name : file.displayName}
                            </Box>
                          </TableCell>
                          <TableCell sx={{ width: '80px' }}>
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleContextMenu(event, file.name);
                              }}
                            >
                              <MoreVert />
                            </IconButton>
                          </TableCell>
                          <TableCell sx={{ width: '30%' }}>{file.lastModified?.toLocaleString() ?? 'Unknown'}</TableCell>
                          <TableCell sx={{ width: '100px' }}>{file.isFolder ? '--' : formatFileSize(file.size)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}
        {!selectedCustomer || !selectedJob && (
          <Typography>Please select a customer and job to view files and folders.</Typography>
        )}
      </Paper>

      <Menu
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {!selectedItem?.endsWith('/') && (
          <MenuItem onClick={() => {
            handleContextMenuClose();
            handleDownload(selectedItem!.replace('Docs/', ''));
          }}>Download</MenuItem>
        )}
        <MenuItem onClick={() => {
          handleContextMenuClose();
          setMoveFileDialog({ open: true, fileName: selectedItem!, targetFolder: '' });
        }}>Move</MenuItem>
        {selectedItem?.endsWith('/') ? (
          <MenuItem onClick={() => {
            handleContextMenuClose();
            setRenameFolderDialog({ open: true, oldName: selectedItem!.replace('Docs/', '').slice(0, -1), newName: '' });
          }}>Rename</MenuItem>
        ) : (
          <MenuItem onClick={() => {
            handleContextMenuClose();
            const fileName = selectedItem!.split('/').pop() || '';
            const folderPath = selectedItem!.substring(0, selectedItem!.lastIndexOf('/'));
            setRenameFileDialog({ 
              open: true, 
              oldName: selectedItem!, 
              newName: fileName,
              folderPath: folderPath
            });
          }}>Rename</MenuItem>
        )}
        <MenuItem onClick={() => {
          handleContextMenuClose();
          handleDelete(selectedItem!);
        }}>Delete</MenuItem>
        {selectedItem?.endsWith('/') && (
          <MenuItem onClick={() => {
            handleDownloadFolder(selectedItem);
            handleContextMenuClose();
          }}>
            <ListItemIcon>
              <GetApp fontSize="small" />
            </ListItemIcon>
            Download as ZIP
          </MenuItem>
        )}
      </Menu>

      <Dialog open={renameFolderDialog.open} onClose={() => setRenameFolderDialog({ open: false, oldName: '', newName: '' })}>
        <DialogTitle>Rename Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Folder Name"
            fullWidth
            value={renameFolderDialog.newName}
            onChange={(e) => setRenameFolderDialog({ ...renameFolderDialog, newName: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameFolderDialog({ open: false, oldName: '', newName: '' })}>Cancel</Button>
          <Button onClick={handleRenameFolder}>Rename</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={moveFileDialog.open} onClose={() => setMoveFileDialog({ open: false, fileName: '', targetFolder: '' })}>
        <DialogTitle>Move File</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Moving: {moveFileDialog.fileName.replace('Docs/', '')}
          </Typography>
          <FormControl fullWidth margin="dense">
            <InputLabel id="target-folder-label">Target Folder</InputLabel>
            <Select
              labelId="target-folder-label"
              value={moveFileDialog.targetFolder}
              onChange={(e) => setMoveFileDialog({ ...moveFileDialog, targetFolder: e.target.value as string })}
              label="Target Folder"
            >
              <MenuItem value="">
                <em>Root</em>
              </MenuItem>
              {folders.map((folder) => (
                <MenuItem key={folder} value={folder}>{folder}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMoveFileDialog({ open: false, fileName: '', targetFolder: '' })}>Cancel</Button>
          <Button onClick={handleMoveFile}>Move</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={renameFileDialog.open} onClose={() => setRenameFileDialog({ open: false, oldName: '', newName: '', folderPath: '' })}>
        <DialogTitle>Rename File</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New File Name"
            fullWidth
            value={renameFileDialog.newName}
            onChange={(e) => setRenameFileDialog({ ...renameFileDialog, newName: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenameFileDialog({ open: false, oldName: '', newName: '', folderPath: '' })}>Cancel</Button>
          <Button onClick={handleRenameFile}>Rename</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={createFolderDialog.open} onClose={() => setCreateFolderDialog({ open: false, folderName: '' })}>
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={createFolderDialog.folderName}
            onChange={(e) => setCreateFolderDialog({ ...createFolderDialog, folderName: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => setCreateFolderDialog({ open: false, folderName: '' })}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleCreateFolder} disabled={!createFolderDialog.folderName}>Create</Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={errorNotification !== null} 
        autoHideDuration={6000} 
        onClose={() => setErrorNotification(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorNotification(null)} severity="error" sx={{ width: '100%' }}>
          {errorNotification}
        </Alert>
      </Snackbar>

      <Snackbar 
        open={successNotification !== null} 
        autoHideDuration={4000} 
        onClose={() => setSuccessNotification(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessNotification(null)} severity="success" sx={{ width: '100%' }}>
          {successNotification}
        </Alert>
      </Snackbar>

      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, itemToDelete: null })}
        aria-labelledby="delete-confirmation-dialog-title"
      >
        <DialogTitle id="delete-confirmation-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this {deleteConfirmation.itemToDelete?.endsWith('/') ? 'folder' : 'file'}?
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1 }}>
            {deleteConfirmation.itemToDelete?.replace('Docs/', '').endsWith('/') 
              ? deleteConfirmation.itemToDelete.replace('Docs/', '').slice(0, -1) 
              : deleteConfirmation.itemToDelete?.replace('Docs/', '')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation({ open: false, itemToDelete: null })} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 