import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Tooltip,
  CircularProgress,
  Toolbar,
  ButtonGroup,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Key as KeyIcon,
  Email as EmailIcon,
  Add as AddIcon,
  Search,
  List as ListIcon,
  Work as ProjectIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { licensingClient } from '../services/LicensingClient';
import { User, CustomerInfo, JobInfo } from '../types/types';

interface EditUserData {
  id?: string;
  name: string;
  email: string | null;
  roles: string[];
  password?: string;
  isInactive: boolean;
  sunset?: string | null;
}

interface Permission {
  id: string;
  name: string;
  selected: boolean;
}

const UserManagement: React.FC = () => {
  const { carbonClient } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [customers, setCustomers] = useState<Permission[]>([]);
  const [jobs, setJobs] = useState<Permission[]>([]);
  const [selectedUser, setSelectedUser] = useState<EditUserData>({
    name: '',
    email: null,
    roles: [],
    isInactive: false,
    sunset: null,
  });
  const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);
  const [allCustomers, setAllCustomers] = useState<{ id: string; name: string }[]>([]);
  const [allJobs, setAllJobs] = useState<{ id: string; name: string }[]>([]);

  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const users = await licensingClient.getUsers();
      setUsers(users);
      setError(null);
    } catch (err) {
      setError('Failed to fetch users');
      console.error('Error fetching users:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchCustomersAndJobs = useCallback(async () => {
    try {
      setLoading(true);
      if (!carbonClient) {
        throw new Error('Carbon client not available');
      }

      // Get customers from the session info
      const sessionInfo = carbonClient.getSessionInfo();
      if (!sessionInfo) {
        throw new Error('No session info available');
      }

      // Use the session customers directly
      const sessionCustomers = sessionInfo.sessionCusts;
      console.log('Session customers:', sessionCustomers);
      
      setAllCustomers(sessionCustomers.map(cust => ({
        id: cust.id,
        name: cust.name
      })));

      // Get all jobs from all customers
      const allJobsList = sessionCustomers.flatMap(cust => cust.sessionJobs.map(job => ({
        id: job.id,
        name: job.name
      })));
      console.log('All jobs:', allJobsList);
      
      setAllJobs(allJobsList);

    } catch (err) {
      setError('Failed to fetch customers and jobs');
      console.error('Error fetching customers and jobs:', err);
    } finally {
      setLoading(false);
    }
  }, [carbonClient]);

  const fetchUserPermissions = useCallback(async (userId: string) => {
    try {
      setLoading(true);
      console.log('Fetching permissions for user:', userId);
      const permissions = await licensingClient.getUserPermissions(userId);
      console.log('Received permissions:', permissions);
      console.log('All customers:', allCustomers);
      console.log('All jobs:', allJobs);
      
      // Initialize all customers with their selected state
      const updatedCustomers = allCustomers.map(customer => ({
        id: customer.id,
        name: customer.name,
        selected: permissions.customers.includes(customer.id)
      }));
      console.log('Updated customers:', updatedCustomers);
      setCustomers(updatedCustomers);
      
      // Initialize all jobs with their selected state
      const updatedJobs = allJobs.map(job => ({
        id: job.id,
        name: job.name,
        selected: permissions.jobs.includes(job.id)
      }));
      console.log('Updated jobs:', updatedJobs);
      setJobs(updatedJobs);
    } catch (err) {
      setError('Failed to fetch user permissions');
      console.error('Error fetching user permissions:', err);
    } finally {
      setLoading(false);
    }
  }, [allCustomers, allJobs]);

  useEffect(() => {
    fetchUsers();
    fetchCustomersAndJobs();
  }, [fetchUsers, fetchCustomersAndJobs]);

  const handleEditUser = (user: User) => {
    setSelectedUser({
      id: user.id,
      name: user.name,
      email: user.email,
      roles: user.roles,
      isInactive: user.isInactive,
      sunset: user.sunset,
    });
    setEditDialogOpen(true);
  };

  const handleNewUser = () => {
    setSelectedUser({
      name: '',
      email: null,
      roles: [],
      isInactive: false,
      sunset: null,
    });
    setEditDialogOpen(true);
  };

  const handleSaveUser = async () => {
    try {
      setLoading(true);
      if (selectedUser.id) {
        const { password, sunset, ...updateData } = selectedUser;
        
        // Send name and email in the update
        const updatedUser = await licensingClient.updateUser(selectedUser.id, {
          name: updateData.name,
          email: updateData.email || '',
          roles: updateData.roles
        });

        // Update the user in the list with the returned data
        setUsers(prevUsers => prevUsers.map(user => 
          user.id === selectedUser.id ? updatedUser : user
        ));
      } else {
        if (!selectedUser.password) {
          setError('Password is required for new users');
          return;
        }
        if (!selectedUser.email) {
          setError('Email is required for new users');
          return;
        }
        const newUser = await licensingClient.createUser({
          name: selectedUser.name,
          email: selectedUser.email,
          password: selectedUser.password,
          roles: selectedUser.roles
        });
        
        setUsers(prevUsers => [...prevUsers, newUser]);
      }
      setEditDialogOpen(false);
      setError(null);
    } catch (err) {
      setError('Failed to save user');
      console.error('Error saving user:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId: string) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;
    
    try {
      setLoading(true);
      await licensingClient.deleteUser(userId);
      await fetchUsers();
      setError(null);
    } catch (err) {
      setError('Failed to delete user');
      console.error('Error deleting user:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (!selectedUser.id || !selectedUser.password) {
      setError('Invalid operation');
      return;
    }

    try {
      setLoading(true);
      await licensingClient.updateUserPassword(selectedUser.id, selectedUser.password);
      setPasswordDialogOpen(false);
      setError(null);
    } catch (err) {
      setError('Failed to change password');
      console.error('Error changing password:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeEmail = async () => {
    if (!selectedUser.id || !selectedUser.email) {
      setError('Invalid operation');
      return;
    }

    try {
      setLoading(true);
      await licensingClient.updateUserEmail(selectedUser.id, selectedUser.email);
      await fetchUsers();
      setEmailDialogOpen(false);
      setError(null);
    } catch (err) {
      setError('Failed to change email');
      console.error('Error changing email:', err);
    } finally {
      setLoading(false);
    }
  };

  const getCurrentUsers = () => {
    if (searchTerm.trim() !== '') {
      return searchResults;
    }
    return users;
  };

  const handleListProjects = async () => {
    // TODO: Implement project listing functionality
    console.log('List Projects clicked');
  };

  const handleRefreshUsers = async () => {
    await fetchUsers();
  };

  const handleSavePermissions = async () => {
    try {
      setLoading(true);
      if (!selectedUser.id) return;

      // Get selected customer and job IDs
      const selectedCustomerIds = customers.filter(c => c.selected).map(c => c.id);
      const selectedJobIds = jobs.filter(j => j.selected).map(j => j.id);

      // Update permissions using the update endpoints
      await Promise.all([
        licensingClient.updateUserCustomers(selectedUser.id, selectedCustomerIds),
        licensingClient.updateUserJobs(selectedUser.id, selectedJobIds)
      ]);

      setPermissionsDialogOpen(false);
      setError(null);
    } catch (err) {
      setError('Failed to save permissions');
      console.error('Error saving permissions:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPermissionsDialog = async (user: User) => {
    console.log('Opening permissions dialog for user:', user);
    // Set the selected user first so the modal can open
    setSelectedUser({
      id: user.id,
      name: user.name,
      email: user.email,
      roles: user.roles,
      isInactive: user.isInactive,
      sunset: user.sunset,
    });
    
    // Open the dialog immediately
    setPermissionsDialogOpen(true);
    
    try {
      setLoading(true);
      setError(null);

      // First ensure we have all customers and jobs
      if (allCustomers.length === 0 || allJobs.length === 0) {
        console.log('Fetching customers and jobs...');
        await fetchCustomersAndJobs();
      }
      console.log('Available customers and jobs:', { 
        customers: allCustomers, 
        jobs: allJobs 
      });

      // Then fetch user permissions
      const permissions = await licensingClient.getUserPermissions(user.id);
      console.log('User permissions from API:', permissions);
      
      // Initialize all customers with their selected state
      const updatedCustomers = allCustomers.map(customer => {
        const isSelected = permissions.customers.includes(customer.id);
        console.log(`Customer ${customer.name} (${customer.id}): selected=${isSelected}`);
        return {
          id: customer.id,
          name: customer.name,
          selected: isSelected
        };
      });
      console.log('Final customer permissions:', updatedCustomers);
      setCustomers(updatedCustomers);
      
      // Initialize all jobs with their selected state
      const updatedJobs = allJobs.map(job => {
        const isSelected = permissions.jobs.includes(job.id);
        console.log(`Job ${job.name} (${job.id}): selected=${isSelected}`);
        return {
          id: job.id,
          name: job.name,
          selected: isSelected
        };
      });
      console.log('Final job permissions:', updatedJobs);
      setJobs(updatedJobs);
      
    } catch (err) {
      console.error('Error loading permissions:', err);
      setError('Failed to load permissions. The dialog will show all items unchecked.');
      
      // Set empty permissions but keep the dialog open
      setCustomers(allCustomers.map(customer => ({
        id: customer.id,
        name: customer.name,
        selected: false
      })));
      
      setJobs(allJobs.map(job => ({
        id: job.id,
        name: job.name,
        selected: false
      })));
    } finally {
      setLoading(false);
    }
  };

  const renderPermissionsDialog = () => (
    <Dialog 
      open={permissionsDialogOpen} 
      onClose={() => setPermissionsDialogOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Edit User and Customer/Job Access</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          User: {selectedUser.email || selectedUser.name}
        </Typography>
        
        <Box display="flex" gap={2}>
          {/* Customers List */}
          <Box flex={1}>
            <Typography variant="h6" gutterBottom>
              Customer Access
            </Typography>
            <List>
              {allCustomers.map((customer) => {
                const isSelected = customers.some(c => c.id === customer.id && c.selected);
                console.log(`Rendering customer ${customer.name}: selected=${isSelected}`);
                return (
                  <ListItem key={customer.id} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        console.log(`Toggling customer ${customer.name}`);
                        setCustomers(prev => {
                          const existing = prev.find(c => c.id === customer.id);
                          if (existing) {
                            return prev.map(c => 
                              c.id === customer.id ? { ...c, selected: !c.selected } : c
                            );
                          } else {
                            return [...prev, { id: customer.id, name: customer.name, selected: true }];
                          }
                        });
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={isSelected}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText 
                        primary={customer.name} 
                        secondary={customer.id}
                        primaryTypographyProps={{
                          style: { fontWeight: isSelected ? 'bold' : 'normal' }
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>

          {/* Jobs List */}
          <Box flex={1}>
            <Typography variant="h6" gutterBottom>
              Job Access
            </Typography>
            <List>
              {allJobs.map((job) => {
                const isSelected = jobs.some(j => j.id === job.id && j.selected);
                console.log(`Rendering job ${job.name}: selected=${isSelected}`);
                return (
                  <ListItem key={job.id} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        console.log(`Toggling job ${job.name}`);
                        setJobs(prev => {
                          const existing = prev.find(j => j.id === job.id);
                          if (existing) {
                            return prev.map(j => 
                              j.id === job.id ? { ...j, selected: !j.selected } : j
                            );
                          } else {
                            return [...prev, { id: job.id, name: job.name, selected: true }];
                          }
                        });
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={isSelected}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText 
                        primary={job.name} 
                        secondary={job.id}
                        primaryTypographyProps={{
                          style: { fontWeight: isSelected ? 'bold' : 'normal' }
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPermissionsDialogOpen(false)}>Cancel</Button>
        <Button onClick={handleSavePermissions} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
          <Typography variant="h4" component="h1">
            User Management
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <ButtonGroup variant="contained">
            <Button
              startIcon={<ListIcon />}
              onClick={handleRefreshUsers}
            >
              List Users
            </Button>
          </ButtonGroup>
          <Button
            component={Link}
            to="/licence-manager"
            variant="outlined"
          >
            Licence Manager
          </Button>
        </Box>

        <Toolbar sx={{ gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNewUser}
          >
            New User
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <TextField
            size="small"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => {
              const term = e.target.value;
              setSearchTerm(term);
              if (term.trim() === '') {
                setSearchResults([]);
              } else {
                const results = users.filter(user => 
                  user.name.toLowerCase().includes(term.toLowerCase()) ||
                  user.email?.toLowerCase().includes(term.toLowerCase()) ||
                  user.roles.some(role => role.toLowerCase().includes(term.toLowerCase()))
                );
                setSearchResults(results);
              }
            }}
            InputProps={{
              startAdornment: <Search />
            }}
          />
        </Toolbar>

        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id/Key</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>User email</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell width={140}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(searchTerm ? searchResults : users).map((user) => (
                <TableRow key={user.id}>
                  <TableCell
                    sx={{
                      fontFamily: 'monospace',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {user.id}
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.roles.map((role, index) => (
                      <Chip
                        key={index}
                        label={role}
                        size="small"
                        sx={{ mr: 0.5, mb: 0.5 }}
                      />
                    ))}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <Tooltip title="Edit User Properties">
                      <IconButton
                        size="small"
                        onClick={() => handleEditUser(user)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit User and Project Permissions">
                      <IconButton
                        size="small"
                        onClick={() => handleOpenPermissionsDialog(user)}
                      >
                        <ProjectIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete User">
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteUser(user.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Edit User Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>
          {selectedUser.id ? 'Edit User Properties' : 'New User'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={selectedUser.name}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={selectedUser.email || ''}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, email: e.target.value })
            }
          />
          {!selectedUser.id && (
            <TextField
              margin="dense"
              label="Password"
              type="password"
              fullWidth
              value={selectedUser.password || ''}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, password: e.target.value })
              }
            />
          )}
          <FormControl fullWidth margin="dense">
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              value={selectedUser.roles}
              onChange={(e) => {
                const value = e.target.value;
                setSelectedUser({
                  ...selectedUser,
                  roles: typeof value === 'string' ? value.split(',') : value
                });
              }}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {['Manager', 'Tables', 'Admin', 'TextAnalysis', 'Analyst', 'Silver', 'Realm'].map((role) => (
                <MenuItem key={role} value={role}>
                  <Checkbox checked={selectedUser.roles.indexOf(role) > -1} />
                  <ListItemText primary={role} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveUser} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {renderPermissionsDialog()}
    </Box>
  );
};

export default UserManagement; 