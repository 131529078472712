import axios from 'axios';
import { ResetPasswordRequest, GenericResponse, User } from '../types/types';

const LICENSING_URL = process.env.REACT_APP_BPR_LICENSING_URL || 'https://bayesprice.azurewebsites.net/licensingtest/';
const LICENSING_API_KEY = process.env.REACT_APP_LICENSING_API_KEY || '316227';

export class LicensingClient {
  private axiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: LICENSING_URL,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': LICENSING_API_KEY
      },
    });

    // Add response interceptor to log responses
    this.axiosInstance.interceptors.response.use(
      (response) => {
        console.log('Licensing response from:', response.config?.url || '');
        console.log('Response status:', response.status);
        console.log('Response data:', response.data);
        console.log('Request headers:', response.config?.headers);
        console.log('Request data:', response.config?.data);
        return response;
      },
      (error) => {
        console.error('Licensing request error:', error.config?.url || '');
        console.error('Error response:', error.response?.data);
        console.error('Request headers:', error.config?.headers);
        console.error('Request data:', error.config?.data);
        throw error;
      }
    );
  }

  async resetPassword(request: ResetPasswordRequest): Promise<GenericResponse> {
    try {
      console.log('Resetting password');
      const response = await this.axiosInstance.post<GenericResponse>('user/password/reset', request);
      console.log('Reset password response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error resetting password:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          throw new Error(`Failed to reset password: ${error.response.data.message || error.response.statusText}`);
        } else if (error.request) {
          console.error('No response received:', error.request);
          throw new Error('Failed to reset password: No response received from server');
        } else {
          console.error('Error setting up request:', error.message);
          throw new Error(`Failed to reset password: ${error.message}`);
        }
      }
      throw new Error(`Failed to reset password: ${(error as Error).message}`);
    }
  }

  // User Management Methods
  async getUsers(): Promise<User[]> {
    try {
      console.log('Making request to get users...');
      const response = await this.axiosInstance.post<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: User[];
      }>('user/list/picks', {
        realmIds: [] // Empty array to get all users
      });
      console.log('Response:', response);
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      // Get complete user data for each user
      const usersWithDetails = await Promise.all(
        (response.data.data || []).map(async user => {
          try {
            return await this.getUser(user.id);
          } catch (error) {
            console.error(`Error fetching details for user ${user.id}:`, error);
            return user;
          }
        })
      );
      
      return usersWithDetails;
    } catch (error: any) {
      console.error('Error getting users:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get users: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async createUser(userData: { name: string; email: string; password: string; roles: string[] }): Promise<User> {
    try {
      console.log('Creating user:', { ...userData, password: '***' });
      const response = await this.axiosInstance.post<{ user: User }>('user/create', userData);
      console.log('Create user response:', { ...response.data, user: { ...response.data.user, password: '***' } });
      return response.data.user;
    } catch (error: any) {
      console.error('Error creating user:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to create user: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async getUser(userId: string): Promise<User> {
    try {
      console.log('Getting user details:', userId);
      const response = await this.axiosInstance.post<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: User;
      }>('user/get', {
        id: userId
      });
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      return response.data.data;
    } catch (error: any) {
      console.error('Error getting user details:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get user details: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async updateUser(userId: string, userData: { name?: string; email?: string; comment?: string; roles?: string[] }): Promise<User> {
    try {
      console.log('Updating user:', userId, userData);
      
      if (userData.roles) {
        // If roles are being updated, use the /user endpoint
        const response = await this.axiosInstance.put<{
          message: string | null;
          detail: string | null;
          hasError: boolean;
          code: number | null;
          data: User;
        }>('user', {
          id: userId,
          name: userData.name || '',
          email: userData.email || '',
          comment: userData.comment || '',
          roles: userData.roles,
          isInactive: false // We don't modify this in this update
        });
        
        if (response.data.hasError) {
          throw new Error(response.data.message || 'Unknown error occurred');
        }
        
        return response.data.data;
      } else {
        // If only account details are being updated, use the /user/account endpoint
        const response = await this.axiosInstance.put<{
          message: string | null;
          detail: string | null;
          hasError: boolean;
          code: number | null;
          data: User;
        }>('user/account', {
          userId: userId,
          userName: userData.name,
          email: userData.email || '',
          comment: userData.comment || ''
        });
        
        if (response.data.hasError) {
          throw new Error(response.data.message || 'Unknown error occurred');
        }

        // Get the updated user data
        const updatedUser = await this.getUser(userId);
        return updatedUser;
      }
    } catch (error: any) {
      console.error('Error updating user:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to update user: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async deleteUser(userId: string): Promise<void> {
    try {
      console.log('Deleting user:', userId);
      await this.axiosInstance.delete(`user/${userId}`);
      console.log('User deleted successfully');
    } catch (error: any) {
      console.error('Error deleting user:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to delete user: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async updateUserPassword(userId: string, password: string): Promise<void> {
    try {
      console.log('Updating password for user:', userId);
      await this.axiosInstance.put(`user/${userId}/password`, { password });
      console.log('Password updated successfully');
    } catch (error: any) {
      console.error('Error updating password:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to update password: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async updateUserEmail(userId: string, email: string): Promise<void> {
    try {
      console.log('Updating email for user:', userId);
      await this.axiosInstance.put(`user/${userId}/email`, { email });
      console.log('Email updated successfully');
    } catch (error: any) {
      console.error('Error updating email:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to update email: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async getUserCustomers(userId: string): Promise<string[]> {
    try {
      console.log('Getting customer permissions for user:', userId);
      const response = await this.axiosInstance.post<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: string[];
      }>('user/customers/get', {
        id: userId
      });
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      return response.data.data;
    } catch (error: any) {
      console.error('Error getting user customer permissions:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get user customer permissions: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async getUserJobs(userId: string): Promise<string[]> {
    try {
      console.log('Getting job permissions for user:', userId);
      const response = await this.axiosInstance.post<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: string[];
      }>('user/jobs/get', {
        id: userId
      });
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      return response.data.data;
    } catch (error: any) {
      console.error('Error getting user job permissions:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get user job permissions: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async updateUserCustomers(userId: string, customers: string[]): Promise<void> {
    try {
      console.log('Updating customer permissions for user:', userId, customers);
      const response = await this.axiosInstance.put<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
      }>('user/customers', {
        id: userId,
        customers: customers
      });
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      console.log('Customer permissions updated successfully');
    } catch (error: any) {
      console.error('Error updating user customer permissions:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to update user customer permissions: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async updateUserJobs(userId: string, jobs: string[]): Promise<void> {
    try {
      console.log('Updating job permissions for user:', userId, jobs);
      const response = await this.axiosInstance.put<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
      }>('user/jobs', {
        id: userId,
        jobs: jobs
      });
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      console.log('Job permissions updated successfully');
    } catch (error: any) {
      console.error('Error updating user job permissions:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to update user job permissions: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  // User-Project Permission Methods
  async connectUserToCustomer(userId: string, customerIds: string[]): Promise<void> {
    try {
      console.log('Connecting user to customers:', { userId, customerIds });
      const response = await this.axiosInstance.post<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: any;
      }>('customer/connect/users', {
        parentId: userId,
        childIds: customerIds
      });
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      console.log('Successfully connected user to customers');
    } catch (error: any) {
      console.error('Error connecting user to customers:', error);
      if (axios.isAxiosError(error)) {
        throw new Error(`Failed to connect user to customers: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async connectUserToJobs(userId: string, jobIds: string[]): Promise<void> {
    try {
      console.log('Connecting user to jobs:', { userId, jobIds });
      const response = await this.axiosInstance.post<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: any;
      }>('job/connect/users', {
        parentId: userId,
        childIds: jobIds
      });
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      console.log('Successfully connected user to jobs');
    } catch (error: any) {
      console.error('Error connecting user to jobs:', error);
      if (axios.isAxiosError(error)) {
        throw new Error(`Failed to connect user to jobs: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  // Customer and Job Methods
  async getCustomers(): Promise<{ id: string; name: string }[]> {
    try {
      console.log('Getting customers...');
      const response = await this.axiosInstance.get<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: { id: string; name: string }[];
      }>('customer/list');
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      return response.data.data;
    } catch (error: any) {
      console.error('Error getting customers:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get customers: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async getJobs(): Promise<{ id: string; name: string }[]> {
    try {
      console.log('Getting jobs...');
      const response = await this.axiosInstance.get<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: { id: string; name: string }[];
      }>('job/list');
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      return response.data.data;
    } catch (error: any) {
      console.error('Error getting jobs:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get jobs: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  // Customer License Management
  async getCustomerLicenses(customerId: string): Promise<{
    rubyLicenses: number;
    platinumLicenses: number;
  }> {
    try {
      console.log('Getting licenses for customer:', customerId);
      const response = await this.axiosInstance.get<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: {
          rubyLicenses: number;
          platinumLicenses: number;
        };
      }>(`customer/${customerId}/licenses`);
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      return response.data.data;
    } catch (error: any) {
      console.error('Error getting customer licenses:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get customer licenses: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async updateCustomerLicenses(customerId: string, licenses: {
    rubyLicenses?: number;
    platinumLicenses?: number;
  }): Promise<void> {
    try {
      console.log('Updating licenses for customer:', customerId, licenses);
      const response = await this.axiosInstance.put<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
      }>(`customer/${customerId}/licenses`, licenses);
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
    } catch (error: any) {
      console.error('Error updating customer licenses:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to update customer licenses: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async getCustomerLicenseUsage(customerId: string): Promise<{
    rubyUsed: number;
    platinumUsed: number;
    totalRuby: number;
    totalPlatinum: number;
  }> {
    try {
      console.log('Getting license usage for customer:', customerId);
      const response = await this.axiosInstance.get<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: {
          rubyUsed: number;
          platinumUsed: number;
          totalRuby: number;
          totalPlatinum: number;
        };
      }>(`customer/${customerId}/licenses/usage`);
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      return response.data.data;
    } catch (error: any) {
      console.error('Error getting customer license usage:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get customer license usage: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  // Realm Management
  async getRealmUsers(realmId: string): Promise<User[]> {
    try {
      console.log('Getting users for realm:', realmId);
      const response = await this.axiosInstance.get<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: User[];
      }>(`realm/${realmId}/users`);
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      return response.data.data;
    } catch (error: any) {
      console.error('Error getting realm users:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get realm users: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async addUsersToRealm(realmId: string, userIds: string[]): Promise<void> {
    try {
      console.log('Adding users to realm:', realmId, userIds);
      const response = await this.axiosInstance.post<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
      }>(`realm/${realmId}/users`, { userIds });
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
    } catch (error: any) {
      console.error('Error adding users to realm:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to add users to realm: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  // User Permissions
  async getUserPermissions(userId: string): Promise<{
    customers: string[];
    jobs: string[];
    roles: string[];
  }> {
    try {
      console.log('Getting permissions for user:', userId);
      const response = await this.axiosInstance.get<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: {
          customers: string[];
          jobs: string[];
          roles: string[];
        };
      }>(`user/${userId}/permissions`);
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      return response.data.data;
    } catch (error: any) {
      console.error('Error getting user permissions:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get user permissions: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async createCustomer(customerData: {
    name: string;
    displayName: string | null;
    agencyId: string | null;
  }): Promise<void> {
    try {
      console.log('Creating/updating customer:', customerData);
      const response = await this.axiosInstance.put<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: any;
      }>('customer', {
        id: null, // For creation, id should be null
        name: customerData.name,
        displayName: customerData.displayName || '',
        agencyId: customerData.agencyId || '',
        info: '',
        logo: '',
        url: '',
        sequence: 0,
        storageKey: '',
        parentAgency: ''
      });
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
    } catch (error: any) {
      console.error('Error creating customer:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to create customer: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async updateCustomer(
    customerId: string,
    customerData: {
      name: string;
      displayName: string | null;
      agencyId: string | null;
    }
  ): Promise<void> {
    try {
      console.log('Updating customer:', customerId, customerData);
      const response = await this.axiosInstance.put<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: any;
      }>('customer', {
        id: customerId,
        name: customerData.name,
        displayName: customerData.displayName || '',
        agencyId: customerData.agencyId || '',
        info: '',
        logo: '',
        url: '',
        sequence: 0,
        storageKey: '',
        parentAgency: ''
      });
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
    } catch (error: any) {
      console.error('Error updating customer:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to update customer: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async getCustomerById(customerId: string): Promise<any> {
    try {
      console.log('Getting customer by ID:', customerId);
      const response = await this.axiosInstance.get<{
        message: string | null;
        detail: string | null;
        hasError: boolean;
        code: number | null;
        data: any;
      }>(`customer/read/${customerId}`);
      
      if (response.data.hasError) {
        throw new Error(response.data.message || 'Unknown error occurred');
      }
      
      return response.data.data;
    } catch (error: any) {
      console.error('Error getting customer by ID:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to get customer: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async deleteCustomer(customerId: string): Promise<void> {
    try {
      console.log('Deleting customer:', customerId);
      const response = await this.axiosInstance.delete(`customer/${customerId}`);
      if (response.status !== 200) {
        throw new Error('Failed to delete customer');
      }
    } catch (error: any) {
      console.error('Error deleting customer:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to delete customer: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async connectCustomerToJobs(customerId: string, jobIds: string[]): Promise<void> {
    try {
      console.log('Connecting customer to jobs:', { customerId, jobIds });
      const response = await this.axiosInstance.post('customer/connect/jobs', {
        parentId: customerId,
        childIds: jobIds
      });
      if (response.status !== 200) {
        throw new Error('Failed to connect customer to jobs');
      }
    } catch (error: any) {
      console.error('Error connecting customer to jobs:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to connect customer to jobs: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }

  async disconnectCustomerFromJob(customerId: string, jobId: string): Promise<void> {
    try {
      console.log('Disconnecting customer from job:', { customerId, jobId });
      const response = await this.axiosInstance.get(`customer/disconnect/${customerId}/job/${jobId}`);
      if (response.status !== 200) {
        throw new Error('Failed to disconnect customer from job');
      }
    } catch (error: any) {
      console.error('Error disconnecting customer from job:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response);
        throw new Error(`Failed to disconnect customer from job: ${error.response?.data?.message || error.message}`);
      }
      throw error;
    }
  }
}

export const licensingClient = new LicensingClient(); 