import { FC } from 'react';
import { Box } from '@mui/material';
import { FileIconProps } from '../types/types';

// Import only the icons we need
import folderIcon from '../assets/icons/default_folder.svg';
import folderOpenIcon from '../assets/icons/default_folder_opened.svg';
import pdfIcon from '../assets/icons/file_type_pdf2.svg';
import wordIcon from '../assets/icons/file_type_word2.svg';
import excelIcon from '../assets/icons/file_type_excel2.svg';
import powerPointIcon from '../assets/icons/file_type_powerpoint2.svg';
import imageIcon from '../assets/icons/file_type_image.svg';
import textIcon from '../assets/icons/file_type_text.svg';
import zipIcon from '../assets/icons/file_type_zip2.svg';
import defaultIcon from '../assets/icons/default_file.svg';

const getFileExtension = (filename: string): string => {
  return filename.toLowerCase().split('.').pop() || '';
};

export const FileIcon: FC<FileIconProps> = ({ filename, isFolder = false, isOpen = false, size = 24 }) => {
  const getIconPath = (): string => {
    if (isFolder) {
      return isOpen ? folderOpenIcon : folderIcon;
    }

    const extension = getFileExtension(filename);

    switch (extension) {
      // Documents
      case 'pdf':
        return pdfIcon;
      case 'doc':
      case 'docx':
        return wordIcon;
      
      // Spreadsheets
      case 'xls':
      case 'xlsx':
      case 'csv':
        return excelIcon;
      
      // Presentations
      case 'ppt':
      case 'pptx':
        return powerPointIcon;
      
      // Images
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
      case 'webp':
        return imageIcon;
      
      // Text
      case 'txt':
      case 'log':
      case 'md':
        return textIcon;
      
      // Archives
      case 'zip':
      case 'rar':
      case '7z':
      case 'tar':
      case 'gz':
        return zipIcon;
      
      default:
        return defaultIcon;
    }
  };

  return (
    <Box
      component="img"
      src={getIconPath()}
      alt={`${filename} icon`}
      sx={{
        width: size,
        height: size,
        objectFit: 'contain',
        marginRight: 1
      }}
    />
  );
}; 