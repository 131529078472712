import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, Snackbar, Alert } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';

export const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const { selectedCustomer, selectedJob } = useJob();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const parsedSessionInfo = {
    user,
    selectedCustomer,
    selectedJob
  };

  useEffect(() => {
    if (!parsedSessionInfo) {
      setErrorMessage('No session information available');
    }
  }, [parsedSessionInfo]);

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Session Information
            </Typography>
            {parsedSessionInfo ? (
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {JSON.stringify(parsedSessionInfo, null, 2)}
              </pre>
            ) : (
              <Typography>No session information available</Typography>
            )}
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Typography component="div">
              Navigate to:
              <ul>
                <li>Tables</li>
                <li>Files</li>
              </ul>
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar 
        open={errorMessage !== null} 
        autoHideDuration={6000} 
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setErrorMessage(null)} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
