import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Typography,
  IconButton,
  GlobalStyles,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { ArrowUpward, ArrowDownward, RemoveCircle, Add, List, Folder, ExpandMore, Edit, ExpandLess, Functions, Remove, VerticalAlignTop, VerticalAlignBottom, PlayArrow, SpaceBar, ContentCopy } from '@mui/icons-material';
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { TreeItem2Content, TreeItem2IconContainer, TreeItem2GroupTransition, TreeItem2Label, TreeItem2Root } from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';
import { useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { TreeItem2Props, RichTreeViewProSlotProps } from '@mui/x-tree-view-pro';
import { NodeEditModal, NodeEditOptions } from './NodeEditModal';
import { ExtendedGenNode } from '../types';
import { AddNodeModal } from './AddNodeModal';
import { NODE_TYPE_DEFINITIONS, VALID_PARENT_CHILDREN } from '../constants/node-types';
import { FilterCreationModal } from './FilterCreationModal';

const MAX_LEVEL_BY_TYPE: Record<string, number> = {
  'TabAxis': 0,
  'Codeframe': 2,
  'Code': 3,
  'Filter': 1,
  'Function': 2,
  'Spacer': 1,
  'Base': 2,
};

interface DragData {
  type: string;
  id: string;
  value1?: string;
  value2?: string;
  parentId?: string;
  sourceTree: 'variable' | 'node';
}

interface DropPosition {
  parentId: string | null;
  index: number;
}

type TreeViewMode = 'variable' | 'filter';

interface NodeTreeEditorProps {
  initialItems: ExtendedGenNode[];
  onItemsChange: (items: ExtendedGenNode[]) => void;
  onExternalDrop?: (data: DragData, position: DropPosition) => void;
  isLoading?: boolean;
  treeViewMode?: TreeViewMode;
  carbonClient?: any;
  variableTree: ExtendedGenNode[];
  onFetchCodes?: (nodeId: string) => Promise<string[]>;
}

interface VariableDropData {
  nodeId: string;
  treeType: 'variable';
  node: ExtendedGenNode; // Add the full node data to the drag payload
}

interface DragPayload {
  nodeId: string;
  treeType: 'variable' | 'node';
  type: string;
  id: string;
  value1: string | null;
  value2: string | null;
  parentId: string | null;
  meta: { key: string; value: string }[];
  isExpanded: boolean;
  isFolder: boolean;
  level: number;
  anyChildren: boolean;
  name: string | null;
  children?: ExtendedGenNode[];
}

const getNodeIcon = (node: ExtendedGenNode | undefined) => {
  if (!node) return null;

  // Special case for Filter type
  if (node.type === 'Filter') {
    return (
      <Box 
        component="img"
        src="/assets/icons/Filter.png"
        alt="Filter"
        sx={{ 
          width: 20,
          height: 20,
          mr: 1,
          objectFit: 'contain'
        }}
      />
    );
  }

  // For Codeframes, always use the base icon without PC
  if (node.type === 'Codeframe') {
    return (
      <Box 
        component="img"
        src="/assets/icons/NodeCodeframe.png"
        alt={node.type}
        sx={{ 
          width: 20,
          height: 20,
          mr: 1,
          objectFit: 'contain'
        }}
      />
    );
  }

  // For other nodes, check for PC metadata
  const hasPC = node.meta?.some(m => m.key === 'PC');
  const iconName = hasPC ? `Node${node.type}PC` : `Node${node.type}`;
  const iconPath = `/assets/icons/${iconName}.png`;

  return (
    <Box 
      component="img"
      src={iconPath}
      alt={node.type}
      sx={{ 
        width: 20,
        height: 20,
        mr: 1,
        objectFit: 'contain'
      }}
    />
  );
};

interface CustomTreeItemProps extends Omit<TreeItem2Props, 'onDrop'> {
  node?: ExtendedGenNode;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
  onMoveUp?: (id: string) => void;
  onMoveDown?: (id: string) => void;
  onMoveTop?: (id: string) => void;
  onMoveBottom?: (id: string) => void;
  onDuplicate?: (id: string) => void;
  onDrop?: (event: React.DragEvent<HTMLElement>, targetId: string | null, dropPosition?: 'before' | 'after' | 'inside') => void;
  onAddChild?: (id: string) => void;
  setAddModalParent?: (node: ExtendedGenNode | null) => void;
  setAddModalType?: (type: 'Function' | 'Spacer') => void;
  setIsAddModalOpen?: (open: boolean) => void;
  setSelectedFilterSource?: (node: ExtendedGenNode | null) => void;
  setSelectedFilterTarget?: (node: ExtendedGenNode | null) => void;
  setIsFilterModalOpen?: (open: boolean) => void;
  setTreeViewMode?: (mode: TreeViewMode) => void;
  parentIsFilter?: boolean;
}

const CustomTreeItem = React.forwardRef<HTMLLIElement, CustomTreeItemProps>(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>,
) {
  const { 
    id, 
    itemId, 
    label, 
    disabled, 
    children, 
    node, 
    onDelete, 
    onEdit, 
    onMoveUp, 
    onMoveDown, 
    onMoveTop, 
    onMoveBottom,
    onDuplicate,
    onAddChild, 
    setAddModalParent, 
    setAddModalType, 
    setIsAddModalOpen,
    setSelectedFilterSource,
    setSelectedFilterTarget,
    setIsFilterModalOpen,
    setTreeViewMode,
    parentIsFilter,
    ...other 
  } = props;
  const [localError, setLocalError] = useState<string | null>(null);

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
  } = useTreeItem2({ 
    id, 
    itemId, 
    children, 
    label, 
    disabled, 
    rootRef: ref 
  });

  if (node?.type === 'TabAxis') {
    return <li ref={ref} style={{ display: 'none' }} />;
  }

  const rootProps = getRootProps(other as object);
  const { draggable, onDragStart, onDragOver, onDragEnd, ...otherRootProps } = rootProps;

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onEdit && node) {
      onEdit(node.id);
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onDelete && node) {
      onDelete(node.id);
    }
  };

  const handleMoveUpClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onMoveUp && node) {
      onMoveUp(node.id);
    }
  };

  const handleMoveDownClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onMoveDown && node) {
      onMoveDown(node.id);
    }
  };

  const handleMoveTopClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onMoveTop && node) {
      onMoveTop(node.id);
    }
  };

  const handleMoveBottomClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onMoveBottom && node) {
      onMoveBottom(node.id);
    }
  };

  const handleDuplicate = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onDuplicate && node) {
      onDuplicate(node.id);
    }
  };

  const displayLabel = node ? 
    [node.value1, node.value2]
      .filter(Boolean)
      .join(' - ') || label
    : label;

  const handleFilterClick = (e: React.MouseEvent, node: ExtendedGenNode) => {
    e.stopPropagation();
    console.log('Add Function/Filter clicked for node:', node);
    if (node.type === 'Filter' && setSelectedFilterSource && setIsFilterModalOpen) {
      // For Filter nodes, extract the variable name and codes from value1
      const match = node.value1?.match(/^([^(]+)\(([^)]+)\)/);
      if (match) {
        const [_, varName, codes] = match;
        // Create a synthetic node with the extracted information
        const sourceNode: ExtendedGenNode = {
          id: generateNodeId(),
          type: 'Codeframe',
          value1: varName.trim(),
          value2: null,
          level: 0,
          parentId: null,
          isExpanded: false,
          isFolder: false,
          meta: [],
          anyChildren: true,
          name: varName.trim(),
          children: codes.split(/[/;]/).map(code => ({
            id: generateNodeId(),
            type: 'Code',
            value1: code.trim(),
            value2: null,
            level: 1,
            parentId: null,
            isExpanded: false,
            isFolder: false,
            meta: [],
            anyChildren: false,
            name: code.trim()
          }))
        };
        setSelectedFilterSource(sourceNode);
        setIsFilterModalOpen(true);
      } else {
        setLocalError('Invalid filter format. Expected format: variableName(codes)');
      }
    } else if (onAddChild && node && setAddModalParent && setAddModalType && setIsAddModalOpen) {
      setAddModalParent(node);
      setAddModalType('Function');
      setIsAddModalOpen(true);
    }
  };

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root 
        {...otherRootProps}
        sx={{
          position: 'relative',
          transition: 'all 0.2s ease',
          '& > .MuiTreeItem-content': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <TreeItem2Content 
          {...getContentProps()}
          sx={{
            display: 'flex !important',
            alignItems: 'center !important',
            p: '4px 0',
            pl: `${(node?.level || 0) * 24}px`,
            position: 'relative',
            '&:hover .action-buttons': {
              opacity: 1,
              visibility: 'visible',
            },
          }}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          {getNodeIcon(node)}
          <TreeItem2Label {...getLabelProps()}>{displayLabel}</TreeItem2Label>
          <Box 
            className="action-buttons"
            sx={{ 
              display: 'flex',
              position: 'absolute',
              right: 8,
              opacity: 0,
              visibility: 'hidden',
              transition: 'all 0.2s ease',
              bgcolor: 'background.paper',
              zIndex: 1
            }}
          >
            <Tooltip title="Move to Top" placement="top">
              <IconButton
                size="small"
                onClick={handleMoveTopClick}
                sx={{
                  padding: '4px',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                  mr: 0.5
                }}
              >
                <VerticalAlignTop sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Move Up" placement="top">
              <IconButton
                size="small"
                onClick={handleMoveUpClick}
                sx={{
                  padding: '4px',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                  mr: 0.5
                }}
              >
                <ArrowUpward sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Move Down" placement="top">
              <IconButton
                size="small"
                onClick={handleMoveDownClick}
                sx={{
                  padding: '4px',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                  mr: 0.5
                }}
              >
                <ArrowDownward sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Move to Bottom" placement="top">
              <IconButton
                size="small"
                onClick={handleMoveBottomClick}
                sx={{
                  padding: '4px',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                  mr: 0.5
                }}
              >
                <VerticalAlignBottom sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Duplicate" placement="top">
              <IconButton
                size="small"
                onClick={handleDuplicate}
                sx={{
                  padding: '4px',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                  mr: 0.5
                }}
              >
                <ContentCopy sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
            {(node?.type === 'Variable' || node?.type === 'Filter' || 
              node?.type === 'Codeframe' || node?.type === 'Function' || 
              node?.type === 'Spacer') && (
              <>
                <Tooltip title="Edit" placement="top">
                  <IconButton
                    size="small"
                    onClick={handleEdit}
                    sx={{
                      padding: '4px',
                      '&:hover': {
                        bgcolor: 'action.hover',
                      },
                      mr: 0.5
                    }}
                  >
                    <Edit sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
                
                {/* Only show Add Filter button if the node is a Variable/Codeframe at level 1 */}
                {!parentIsFilter &&
                  (node?.type === 'Codeframe' || node?.type === 'Variable') &&
                  node.level === 1 && (
                  <Tooltip title="Add Filter" placement="top">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (setSelectedFilterTarget && setSelectedFilterSource && setTreeViewMode && node) {
                          // Set this node as the target
                          setSelectedFilterTarget(node);
                          // Switch to filter mode
                          setTreeViewMode('filter');
                          // Clear any existing source
                          setSelectedFilterSource(null);
                          // Show explicit message that we're waiting for the source variable
                        }
                      }}
                      sx={{
                        padding: '4px',
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                        mr: 0.5
                      }}
                    >
                      <Box 
                        component="img"
                        src="/assets/icons/Filter.png"
                        alt="Add Filter"
                        sx={{ 
                          width: 16,
                          height: 16,
                          objectFit: 'contain'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            <Tooltip title="Remove" placement="top">
              <IconButton
                size="small"
                onClick={handleDelete}
                sx={{
                  padding: '4px',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <RemoveCircle sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </TreeItem2Content>
        {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
      </TreeItem2Root>
      {localError && (
        <Snackbar 
          open={!!localError} 
          autoHideDuration={6000} 
          onClose={() => setLocalError(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setLocalError(null)} 
            severity="error"
          >
            {localError}
          </Alert>
        </Snackbar>
      )}
    </TreeItem2Provider>
  );
});

const getAllNodeIds = (nodes: ExtendedGenNode[]): string[] => {
  let ids: string[] = [];
  nodes.forEach(node => {
    ids.push(node.id);
    if (node.children) {
      ids = [...ids, ...getAllNodeIds(node.children)];
    }
  });
  return ids;
};

// Add ID generation helper
const generateNodeId = (() => {
  let counter = Math.floor(Date.now() / 1000);
  return () => (counter++).toString();
})();

const NodeTreeEditor: React.FC<NodeTreeEditorProps> = ({ 
  initialItems, 
  onItemsChange,
  onExternalDrop,
  isLoading = false,
  treeViewMode: initialTreeViewMode = 'variable',
  carbonClient,
  variableTree,
  onFetchCodes
}): JSX.Element => {
  const [items, setItems] = useState<ExtendedGenNode[]>(initialItems);
  const [localTreeViewMode, setLocalTreeViewMode] = useState<TreeViewMode>(initialTreeViewMode);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [dragOverError, setDragOverError] = useState<string | null>(null);
  const [dragOverNodeId, setDragOverNodeId] = useState<string | null>(null);
  const [editingNode, setEditingNode] = useState<ExtendedGenNode | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [nodeToDelete, setNodeToDelete] = useState<string | null>(null);
  const [dropPosition, setDropPosition] = useState<'before' | 'after' | 'inside' | null>(null);
  const treeContainerRef = useRef<HTMLDivElement>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [availableCodes, setAvailableCodes] = useState<string[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [addModalParent, setAddModalParent] = useState<ExtendedGenNode | null>(null);
  const [addModalType, setAddModalType] = useState<'Function' | 'Spacer'>('Function');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedFilterSource, setSelectedFilterSource] = useState<ExtendedGenNode | null>(null);
  const [selectedFilterTarget, setSelectedFilterTarget] = useState<ExtendedGenNode | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // Debug log for initial items
  useEffect(() => {
    console.log('NodeTreeEditor received initialItems:', initialItems);
    if (initialItems?.length > 0) {
      console.log('First item:', initialItems[0]);
      
      // Check if we have a TabAxis as the first item
      const firstItem = initialItems[0];
      if (firstItem.type === 'TabAxis' && firstItem.children) {
        // Display the children of the TabAxis
        console.log('TabAxis found, displaying its children:', firstItem.children);
        setItems(firstItem.children);
      } else {
        // No TabAxis or no children, just display the items as is
        console.log('No TabAxis found or TabAxis has no children, displaying items as is');
        setItems(initialItems);
      }
    } else {
      // If there are no items, initialize with an empty array
      setItems([]);
    }
  }, [initialItems]);

  // Safe wrapper to notify parent of changes
  const notifyItemsChange = useCallback((items: ExtendedGenNode[]) => {
    console.log("Notifying parent of items change:", items);
    if (!onItemsChange) return;
    
    // Check if original items had a TabAxis
    const originalTabAxis = initialItems.find(item => item.type === 'TabAxis');
    
    if (originalTabAxis) {
      // Use the original TabAxis with updated children
      const wrappedItems = [{
        ...originalTabAxis,
        children: items,
        anyChildren: items.length > 0
      }];
      console.log('Returning items wrapped in original TabAxis:', wrappedItems);
      onItemsChange(wrappedItems);
    } else {
      // Create a new TabAxis wrapper
      const wrappedItems = [{
        id: `tabaxis-${generateNodeId()}`,
        type: 'TabAxis',
        name: 'TabAxis',
        value1: 'TabAxis',
        value2: null,
        meta: [],
        level: 0,
        children: items,
        isExpanded: true,
        isFolder: false,
        parentId: null,
        anyChildren: items.length > 0
      }];
      console.log('Returning items wrapped in new TabAxis:', wrappedItems);
      onItemsChange(wrappedItems);
    }
  }, [onItemsChange, initialItems]);

  // Other functions remain the same, but replace handleNodeChange with this simpler version
  const handleNodeChange = useCallback((nodeId: string, updates: Partial<ExtendedGenNode>) => {
    setItems(prevItems => {
      const updateNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] =>
        nodes.map(node => {
          if (node.id === nodeId) {
            return { ...node, ...updates };
          }
          if (node.children) {
            return { ...node, children: updateNode(node.children) };
          }
          return node;
        });

      const newItems = updateNode(prevItems);
      notifyItemsChange(newItems);
      return newItems;
    });
  }, [notifyItemsChange]);

  // Replace all calls to parent onItemsChange with notifyItemsChange
  // For example, in handleAddChild, confirmDelete, etc.

  // Fix validation circular dependency
  const validateNode = useCallback((node: ExtendedGenNode, parentNode?: ExtendedGenNode): boolean => {
    // Validate level constraints
    const maxLevel = MAX_LEVEL_BY_TYPE[node.type];
    if (maxLevel !== undefined && node.level > maxLevel) {
      setErrorMessage(`${node.type} cannot appear at level ${node.level} (max: ${maxLevel})`);
      return false;
    }

    // Validate parent-child relationships
    if (parentNode) {
      const validChildren = VALID_PARENT_CHILDREN[parentNode.type];
      if (validChildren && !validChildren.includes(node.type)) {
        setErrorMessage(`${node.type} cannot be a child of ${parentNode.type}`);
        return false;
      }
    }

    return true;
  }, []);

  const findParentNode = (nodes: ExtendedGenNode[], childId: string): ExtendedGenNode | undefined => {
    for (const node of nodes) {
      if (node.children?.some(child => child.id === childId)) {
        return node;
      }
      if (node.children) {
        const found = findParentNode(node.children, childId);
        if (found) return found;
      }
    }
    return undefined;
  };

  const moveItem = (
    nodes: ExtendedGenNode[], 
    sourceId: string | null, 
    targetId: string | null,
    newNode?: ExtendedGenNode
  ): ExtendedGenNode[] => {
    const result = [...nodes];
    
    // If we're adding a new node
    if (sourceId === null && newNode) {
      if (targetId === null) {
        // Add to root
        result.push(newNode);
      } else {
        // Add to specific parent
        const addToParent = (items: ExtendedGenNode[]): boolean => {
          for (const item of items) {
            if (item.id === targetId) {
              item.children = item.children || [];
              item.children.push(newNode);
              item.anyChildren = true;
              return true;
            }
            if (item.children && addToParent(item.children)) {
              return true;
            }
          }
          return false;
        };
        addToParent(result);
      }
      return result;
    }

    // If we're moving an existing node
    let nodeToMove: ExtendedGenNode | undefined;
    
    // Remove node from its current position
    const removeNode = (items: ExtendedGenNode[]): ExtendedGenNode[] => {
      return items.filter(item => {
        if (item.id === sourceId) {
          nodeToMove = { ...item };
          return false;
        }
        if (item.children) {
          item.children = removeNode(item.children);
          item.anyChildren = item.children.length > 0;
        }
        return true;
      });
    };

    const newItems = removeNode(result);
    
    if (!nodeToMove) return result;
    
    // Add node to new position
    if (targetId === null) {
      newItems.push(nodeToMove);
    } else {
      const addToParent = (items: ExtendedGenNode[]): boolean => {
        for (const item of items) {
          if (item.id === targetId) {
            item.children = item.children || [];
            item.children.push(nodeToMove!);
            item.anyChildren = true;
            return true;
          }
          if (item.children && addToParent(item.children)) {
            return true;
          }
        }
        return false;
      };
      addToParent(newItems);
    }
    
    return newItems;
  };

  const handleAddChild = useCallback((parentId: string) => {
    setItems(prevItems => {
      const addChildToNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] =>
        nodes.map(node => {
          if (node.id === parentId) {
            const validChildTypes = VALID_PARENT_CHILDREN[node.type] || [];
            const defaultChildType = validChildTypes[0] || 'Code';
            
            const newChild: ExtendedGenNode = {
              type: defaultChildType,
              id: generateNodeId(),
              parentId: node.id,
              isExpanded: false,
              isFolder: false,
              value1: null,
              value2: null,
              meta: [],
              level: node.level + 1,
              anyChildren: false,
              name: `New ${defaultChildType}`,
            };

            validateNode(newChild, node);
            
            return {
              ...node,
              children: node.children ? [...node.children, newChild] : [newChild],
              anyChildren: true,
            };
          }
          if (node.children) {
            return { ...node, children: addChildToNode(node.children) };
          }
          return node;
        });

      const newItems = addChildToNode(prevItems);
      notifyItemsChange(newItems);
      return newItems;
    });
  }, [notifyItemsChange, validateNode]);

  const handleDeleteNode = useCallback((nodeId: string) => {
    setNodeToDelete(nodeId);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDelete = useCallback(() => {
    if (!nodeToDelete) return;

    setItems(prevItems => {
      const deleteNode = (nodes: ExtendedGenNode[]): ExtendedGenNode[] =>
        nodes.filter(node => {
          if (node.id === nodeToDelete) {
            return false;
          }
          if (node.children) {
            node.children = deleteNode(node.children);
          }
          return true;
        });

      const newItems = deleteNode(prevItems);
      notifyItemsChange(newItems);
      return newItems;
    });

    setDeleteDialogOpen(false);
    setNodeToDelete(null);
  }, [nodeToDelete, notifyItemsChange]);

  const handleItemMove = useCallback((
    itemId: string,
    oldPosition: { parentId: string | null, index: number },
    newPosition: { parentId: string | null, index: number }
  ) => {
    setItems(prevItems => {
      // Deep clone the items to avoid mutation
      const clonedItems = JSON.parse(JSON.stringify(prevItems));
      
      // Helper function to update node levels
      const updateNodeLevels = (node: ExtendedGenNode, baseLevel: number): ExtendedGenNode => {
        const updatedNode = {
          ...node,
          level: baseLevel,
          parentId: baseLevel === 0 ? null : node.parentId
        };

        if (updatedNode.children) {
          updatedNode.children = updatedNode.children.map(child => 
            updateNodeLevels(child, baseLevel + 1)
          );
        }
        return updatedNode;
      };

      // Find and remove the item from its old position
      const removeItem = (nodes: ExtendedGenNode[], parentId: string | null): [ExtendedGenNode[], ExtendedGenNode | null] => {
        if (parentId === oldPosition.parentId) {
          const [removedItem] = nodes.splice(oldPosition.index, 1);
          return [nodes, removedItem];
        }
        
        for (let i = 0; i < nodes.length; i++) {
          const children = nodes[i].children || [];
          if (children.length > 0) {
            const [newChildren, removedItem] = removeItem(children, nodes[i].id);
            nodes[i].children = newChildren;
            if (removedItem) return [nodes, removedItem];
          }
        }
        return [nodes, null];
      };

      // Insert the item at its new position
      const insertItem = (nodes: ExtendedGenNode[], parentId: string | null, item: ExtendedGenNode): ExtendedGenNode[] => {
        if (parentId === newPosition.parentId) {
          // Update the level of the item and its children based on new position
          const updatedItem = updateNodeLevels(item, parentId === null ? 0 : 1);
          nodes.splice(newPosition.index, 0, updatedItem);
          return nodes;
        }
        
        return nodes.map(node => {
          const children = node.children || [];
          if (node.id === newPosition.parentId) {
            node.children = [...children];
            // Update level for insertion into children
            const updatedItem = updateNodeLevels(item, node.level + 1);
            node.children.splice(newPosition.index, 0, updatedItem);
          } else if (children.length > 0) {
            node.children = insertItem(children, node.id, item);
          }
          return node;
        });
      };

      const [itemsWithoutMoved, movedItem] = removeItem(clonedItems, null);
      if (movedItem) {
        const result = insertItem(itemsWithoutMoved, null, movedItem);
        
        // Final pass to ensure all root nodes are level 0
        const finalResult = result.map(node => updateNodeLevels(node, 0));
        notifyItemsChange(finalResult);
        return finalResult;
      }
      
      return clonedItems;
    });
  }, [notifyItemsChange]);

  const findItemById = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | undefined => {
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const found = findItemById(node.children, id);
        if (found) return found;
      }
    }
    return undefined;
  };

  const updateNode = (nodes: ExtendedGenNode[], nodeId: string, updater: (node: ExtendedGenNode) => ExtendedGenNode): ExtendedGenNode[] => {
    return nodes.map(node => {
      if (node.id === nodeId) {
        return updater(node);
      }
      if (node.children) {
        return { ...node, children: updateNode(node.children, nodeId, updater) };
      }
      return node;
    });
  };

  // Add function to get all available filters
  const getAllFilters = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
    let filters: ExtendedGenNode[] = [];
    nodes.forEach(node => {
      if (node.type === 'Filter') {
        filters.push(node);
      }
      if (node.children) {
        filters = [...filters, ...getAllFilters(node.children)];
      }
    });
    return filters;
  };

  // Modify handleEdit to pass available filters
  const handleEdit = async (nodeId: string) => {
    const node = findItemById(items, nodeId);
    console.log('handleEdit called:', { 
      nodeId, 
      node,
      nodeType: node?.type,
      parentId: node?.parentId,
      value1: node?.value1,
      meta: node?.meta,
      items
    });
    
    if (node) {
      // Get all available filters except the current node's parent filter
      const currentParentFilter = findParentNode(items, nodeId)?.type === 'Filter' 
        ? findParentNode(items, nodeId) 
        : null;
      const availableFilters = getAllFilters(items).filter(filter => 
        filter.id !== currentParentFilter?.id
      );

      // For Code nodes, get the current code from the node itself
      if (node.type === 'Code') {
        const currentCode = [node.value1, node.value2].filter(Boolean).join(' - ');
        setEditingNode({
          ...node,
          codes: currentCode ? [currentCode] : []
        });
      } 
      // For Codeframe nodes, get codes from all children
      else if (node.type === 'Codeframe') {
        const currentCodes = node.children?.map(child => 
          [child.value1, child.value2].filter(Boolean).join(' - ')
        ).filter(Boolean) || [];
        setEditingNode({
          ...node,
          codes: currentCodes
        });
      } else {
        setEditingNode(node);
      }

      // Fetch codes if it's a Codeframe or Code node
      if ((node.type === 'Codeframe' || node.type === 'Code') && carbonClient) {
        try {
          let targetName = '';

          // For Codeframe nodes, use the node's own value1 as the target name
          if (node.type === 'Codeframe') {
            targetName = node.value1 || node.name || '';
          }
          // For Code nodes, get the parent Codeframe's value1
          else if (node.type === 'Code' && node.parentId) {
            const parentNode = findItemById(items, node.parentId);
            if (parentNode) {
              targetName = parentNode.value1 || parentNode.name || '';
            }
          }
          
          console.log('Code fetch details:', { 
            nodeType: node.type, 
            targetName,
            currentCodes: editingNode?.codes,
            nodeValue: node.value1,
            nodeMeta: node.meta,
            hasCarbonClient: !!carbonClient,
            fullNode: node
          });
          
          if (targetName) {
            console.log('Fetching codes for:', targetName);
            const variables = await carbonClient.getVarNodes(targetName);
            console.log('Fetched variables:', variables);
            
            if (variables && variables.length > 0 && variables[0].children) {
              const codes = variables[0].children.map((child: ExtendedGenNode) => 
                [child.value1, child.value2].filter(Boolean).join(' - ')
              ).filter(Boolean);
              
              console.log('Extracted codes:', { 
                codes, 
                currentCodes: editingNode?.codes,
                nodeChildren: node.children
              });
              setAvailableCodes(codes);
            }
          } else {
            console.log('No target name found for fetching codes');
            setAvailableCodes([]);
          }
        } catch (error) {
          console.error('Error fetching codes:', error);
          setAvailableCodes([]);
        }
      } else {
        console.log('Skipping code fetch - conditions not met:', { 
          nodeType: node.type, 
          hasCarbonClient: !!carbonClient
        });
        setAvailableCodes([]);
      }
      setIsEditModalOpen(true);
    }
  };

  // Enhanced handleSaveEdit that combines functionality from both original functions
  const handleSaveEdit = (nodeId: string, options: NodeEditOptions) => {
    console.log('handleSaveEdit:', { 
      nodeId, 
      options,
      currentNode: findItemById(items, nodeId)
    });

    // Create a new state update that explicitly sets the item state and notifies parent
    setItems(prevItems => {
      // First make a deep copy to ensure we're not modifying the existing state
      const prevItemsCopy = JSON.parse(JSON.stringify(prevItems));
      let newItems = [...prevItemsCopy];
      
      // Find the node to confirm its type
      const node = findItemById(newItems, nodeId);
      if (!node) {
        console.error('Node not found:', nodeId);
        return prevItems;
      }
      const nodeType = node.type;
      
      // Handle node movement if requested
      if (options.moveToLevel === 1 || options.moveToFilter) {
        const moveNode = (nodes: ExtendedGenNode[]): [ExtendedGenNode[], ExtendedGenNode | null] => {
          let movedNode: ExtendedGenNode | null = null;
          const result = nodes.filter(node => {
            if (node.id === nodeId) {
              movedNode = { ...node };
              return false;
            }
            if (node.children) {
              const [newChildren, found] = moveNode(node.children);
              node.children = newChildren;
              node.anyChildren = newChildren.length > 0;
              if (found) movedNode = found;
            }
            return true;
          });
          return [result, movedNode];
        };

        const [remainingItems, nodeToMove] = moveNode(newItems);
        if (nodeToMove) {
          if (options.moveToLevel === 1) {
            // Move to level 1 (standalone)
            nodeToMove.level = 1;
            nodeToMove.parentId = null;
            remainingItems.push(nodeToMove);
            newItems = remainingItems;
          } else if (options.moveToFilter) {
            // Move to filter
            const addToFilter = (nodes: ExtendedGenNode[]): boolean => {
              for (let node of nodes) {
                if (node.id === options.moveToFilter) {
                  nodeToMove.level = node.level + 1;
                  nodeToMove.parentId = node.id;
                  node.children = node.children || [];
                  node.children.push(nodeToMove);
                  node.anyChildren = true;
                  return true;
                }
                if (node.children && addToFilter(node.children)) {
                  return true;
                }
              }
              return false;
            };
            addToFilter(remainingItems);
            newItems = remainingItems;
          }
        }
      }

      // Apply edits based on node type
      const updatedItems = updateNode(newItems, nodeId, (node: ExtendedGenNode) => {
        console.log('Updating node:', { 
          id: node.id, 
          type: node.type,
          before: node, 
          options 
        });
        
        if (node.type === 'Filter') {
          // For Filter nodes, update value1 which contains the variable and code being filtered on
          return {
            ...node,
            value1: options.value1 || '',
            name: `Filter by ${options.value1 || ''}`
          };
        } 
        else if (node.type === 'Codeframe') {
          // For Codeframe nodes, update children based on selected codes
          const selectedCodes = options.codes || [];
          
          // Create a map of existing children by value1 for reference
          const existingChildrenMap = (node.children || []).reduce((map, child) => {
            const childCode = [child.value1, child.value2].filter(Boolean).join(' - ');
            map[childCode] = child;
            return map;
          }, {} as Record<string, ExtendedGenNode>);
          
          // Create new children array with selected codes
          const newChildren = selectedCodes.map(code => {
            const [value1, value2] = code.split(' - ');
            const existingChild = existingChildrenMap[code];
            
            // If this code already exists as a child, preserve its properties
            if (existingChild) {
              return {
                ...existingChild,
                meta: [
                  ...(existingChild.meta || []).filter(m => m.key !== 'PC'),
                  ...(options.isPC ? [{ key: 'PC', value: '1' }] : [])
                ]
              };
            }
            
            // Otherwise create a new child node
            return {
              type: 'Code',
              id: `${node.id}_${value1}${value2 ? '_' + value2 : ''}`,
              name: code,
              value1: value1 || null,
              value2: value2 || null,
              isExpanded: false,
              isFolder: false,
              level: node.level + 1,
              parentId: node.id,
              meta: [
                ...(options.isPC ? [{ key: 'PC', value: '1' }] : [])
              ],
              anyChildren: false
            };
          });
          
          // Preserve existing meta values except PC which is handled by options
          const existingMeta = node.meta?.filter(m => m.key !== 'PC') || [];
          const baseMeta = existingMeta.find(m => m.key === 'Base');
          const newMeta = [
            ...existingMeta,
            // If there was no Base meta, add it with cwf value
            ...(baseMeta ? [] : [{ key: 'Base', value: 'cwf' }])
          ];
          
          return {
            ...node,
            isLocked: options.isLocked,
            value1: options.value1 || null,
            meta: newMeta,
            children: newChildren,
            anyChildren: newChildren.length > 0,
            codes: options.codes
          };
        }
        else if (node.type === 'Code') {
          // For Code nodes, update based on the first selected code (if any)
          const selectedCode = (options.codes || [])[0];
          
          if (selectedCode) {
            const [value1, value2] = selectedCode.split(' - ');
            return {
              ...node,
              isLocked: options.isLocked,
              value1: value1,
              value2: value2 || null,
              name: selectedCode,
              meta: [
                ...(node.meta || []).filter(m => m.key !== 'PC'),
                ...(options.isPC ? [{ key: 'PC', value: '1' }] : [])
              ],
              codes: options.codes
            };
          } else {
            // If no code selected, just update other properties
            return {
              ...node,
              isLocked: options.isLocked,
              meta: [
                ...(node.meta || []).filter(m => m.key !== 'PC'),
                ...(options.isPC ? [{ key: 'PC', value: '1' }] : [])
              ],
              codes: options.codes
            };
          }
        } 
        else {
          // For all other nodes
          return {
            ...node,
            isLocked: options.isLocked,
            value1: options.value1 || null,
            meta: [
              ...(node.meta || []).filter(m => m.key !== 'PC'),
              ...(options.isPC ? [{ key: 'PC', value: '1' }] : [])
            ],
            codes: options.codes
          };
        }
      });

      // Log the items before and after update
      console.log('Items before update:', newItems);
      console.log('Updated items:', updatedItems);
      
      // Ensure we notify parent of changes
      notifyItemsChange(updatedItems);
      
      return updatedItems;
    });
    
    // Close the modal
    setIsEditModalOpen(false);
    setEditingNode(null);
  };

  const editingNodeData = useMemo(() => {
    if (!editingNode) return null;
    const findNode = (nodes: ExtendedGenNode[]): ExtendedGenNode | null => {
      for (const node of nodes) {
        if (node.id === editingNode.id) return node;
        if (node.children) {
          const found = findNode(node.children);
          if (found) return found;
        }
      }
      return null;
    };
    return findNode(items);
  }, [editingNode, items]);

  // Helper function to safely check if a node has children
  const hasValidChildren = (node: ExtendedGenNode): boolean => {
    return Boolean(node.children && Array.isArray(node.children) && node.children.length > 0);
  };

  const handleMoveUp = useCallback((nodeId: string) => {
    setItems(prevItems => {
        console.log('Current items before move:', JSON.stringify(prevItems, null, 2));
        
        // Find the node and its parent
        const findNodeAndParent = (nodes: ExtendedGenNode[]): [ExtendedGenNode | null, ExtendedGenNode[], number] => {
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i].id === nodeId) {
                    console.log('Found node at index:', i, 'in array:', nodes);
                    return [nodes[i], nodes, i];
                }
                if (hasValidChildren(nodes[i])) {
                    const result = findNodeAndParent(nodes[i].children!);
                    if (result[0]) {
                        console.log('Found node in children of:', nodes[i].id);
                        return result;
                    }
                }
            }
            return [null, nodes, -1];
        };

        // Create a deep copy of the items
        const newItems = JSON.parse(JSON.stringify(prevItems)) as ExtendedGenNode[];
        console.log('Created deep copy of items');
        
        const result = findNodeAndParent(newItems);
        const [node, parent, index] = result;
        console.log('Search result:', { 
            nodeFound: node ? node.id : null, 
            parentLength: parent.length, 
            index 
        });

        if (node && index > 0) {
            console.log('Moving node up:', { 
                fromIndex: index, 
                toIndex: index - 1,
                node: node.id,
                parentLength: parent.length
            });
            
            // Create new array with swapped elements
            const temp = parent[index];
            parent[index] = parent[index - 1];
            parent[index - 1] = temp;
            
            console.log('Items after move:', JSON.stringify(newItems, null, 2));
            // Safe notification that works with empty arrays
            notifyItemsChange(newItems);
            return newItems;
        }

        console.log('Node not found or already at top');
        return prevItems;
    });
}, [notifyItemsChange]);

const handleMoveDown = useCallback((nodeId: string) => {
    setItems(prevItems => {
        console.log('Current items before move:', JSON.stringify(prevItems, null, 2));
        
        // Find the node and its parent
        const findNodeAndParent = (nodes: ExtendedGenNode[]): [ExtendedGenNode | null, ExtendedGenNode[], number] => {
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i].id === nodeId) {
                    console.log('Found node at index:', i, 'in array:', nodes);
                    return [nodes[i], nodes, i];
                }
                if (hasValidChildren(nodes[i])) {
                    const result = findNodeAndParent(nodes[i].children!);
                    if (result[0]) {
                        console.log('Found node in children of:', nodes[i].id);
                        return result;
                    }
                }
            }
            return [null, nodes, -1];
        };

        // Create a deep copy of the items
        const newItems = JSON.parse(JSON.stringify(prevItems)) as ExtendedGenNode[];
        console.log('Created deep copy of items');
        
        const result = findNodeAndParent(newItems);
        const [node, parent, index] = result;
        console.log('Search result:', { 
            nodeFound: node ? node.id : null, 
            parentLength: parent.length, 
            index 
        });

        if (node && index < parent.length - 1) {
            console.log('Moving node down:', { 
                fromIndex: index, 
                toIndex: index + 1,
                node: node.id,
                parentLength: parent.length
            });
            
            // Create new array with swapped elements
            const temp = parent[index];
            parent[index] = parent[index + 1];
            parent[index + 1] = temp;
            
            console.log('Items after move:', JSON.stringify(newItems, null, 2));
            // Safe notification that works with empty arrays
            notifyItemsChange(newItems);
            return newItems;
        }

        console.log('Node not found or already at bottom');
        return prevItems;
    });
}, [notifyItemsChange]);

  const handleMoveTop = useCallback((nodeId: string) => {
    setItems(prevItems => {
      console.log('Moving node to top:', nodeId);
      
      // Find the node and its parent
      const findNodeAndParent = (nodes: ExtendedGenNode[]): [ExtendedGenNode | null, ExtendedGenNode[], number] => {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].id === nodeId) {
            console.log('Found node at index:', i, 'in array:', nodes);
            return [nodes[i], nodes, i];
          }
          if (hasValidChildren(nodes[i])) {
            const result = findNodeAndParent(nodes[i].children!);
            if (result[0]) {
              console.log('Found node in children of:', nodes[i].id);
              return result;
            }
          }
        }
        return [null, nodes, -1];
      };

      // Create a deep copy of the items
      const newItems = JSON.parse(JSON.stringify(prevItems)) as ExtendedGenNode[];
      
      const [node, parent, index] = findNodeAndParent(newItems);
      
      if (node && index > 0) {
        // Remove node from current position
        parent.splice(index, 1);
        // Insert at beginning
        parent.unshift(node);
        
        console.log('Moved node to top');
        notifyItemsChange(newItems);
        return newItems;
      }

      console.log('Node not found or already at top');
      return prevItems;
    });
  }, [notifyItemsChange]);

  const handleMoveBottom = useCallback((nodeId: string) => {
    setItems(prevItems => {
      console.log('Moving node to bottom:', nodeId);
      
      // Find the node and its parent
      const findNodeAndParent = (nodes: ExtendedGenNode[]): [ExtendedGenNode | null, ExtendedGenNode[], number] => {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].id === nodeId) {
            console.log('Found node at index:', i, 'in array:', nodes);
            return [nodes[i], nodes, i];
          }
          if (hasValidChildren(nodes[i])) {
            const result = findNodeAndParent(nodes[i].children!);
            if (result[0]) {
              console.log('Found node in children of:', nodes[i].id);
              return result;
            }
          }
        }
        return [null, nodes, -1];
      };

      // Create a deep copy of the items
      const newItems = JSON.parse(JSON.stringify(prevItems)) as ExtendedGenNode[];
      
      const [node, parent, index] = findNodeAndParent(newItems);
      
      if (node && index < parent.length - 1) {
        // Remove node from current position
        parent.splice(index, 1);
        // Add to end
        parent.push(node);
        
        console.log('Moved node to bottom');
        notifyItemsChange(newItems);
        return newItems;
      }

      console.log('Node not found or already at bottom');
      return prevItems;
    });
  }, [notifyItemsChange]);

  // Define a helper function to get consistent node labels
  const getNodeLabel = (node: ExtendedGenNode): string => {
    if (!node) return '';
    return [node.value1, node.value2]
      .filter(Boolean)
      .join(' - ') || node.name || '';
  };

  const renderTreeItems = (nodes: ExtendedGenNode[]) => (
    nodes.map((node) => {
      const nodeId = String(node.id);
      // Find the parent node
      const parentNode = findParentNode(items, node.id);
      // Check if parent is a Filter node
      const parentIsFilter = parentNode?.type === 'Filter';
      
      // When rendering children, we need to pass down if the parent is a Filter
      const children = node.children ? renderTreeItems(node.children) : null;
      
      const handleAddChild = (id: string) => {
        console.log('handleAddChild called with id:', id);
        const targetNode = findItemById(items, id);
        console.log('Found target node:', targetNode);
        if (targetNode) {
          // Validate parent-child relationship
          const validChildren = VALID_PARENT_CHILDREN[targetNode.type];
          if (!validChildren) {
            console.error(`${targetNode.type} does not support child nodes`);
            return;
          }
          
          setAddModalParent(targetNode);
          setAddModalType('Function');
          setIsAddModalOpen(true);
        }
      };

      return (
        <CustomTreeItem
          key={nodeId}
          id={nodeId}
          itemId={nodeId}
          label={getNodeLabel(node)}
          node={node}
          onDelete={node.isLocked ? undefined : handleRemoveNode}
          onEdit={handleEdit}
          onMoveUp={node.isLocked ? undefined : handleMoveUp}
          onMoveDown={node.isLocked ? undefined : handleMoveDown} 
          onMoveTop={node.isLocked ? undefined : handleMoveTop}
          onMoveBottom={node.isLocked ? undefined : handleMoveBottom}
          onDuplicate={node.isLocked ? undefined : handleDuplicate}
          onDrop={handleDrop}
          onAddChild={node.isLocked ? undefined : handleAddChild}
          setAddModalParent={setAddModalParent}
          setAddModalType={setAddModalType}
          setIsAddModalOpen={setIsAddModalOpen}
          setSelectedFilterSource={setSelectedFilterSource}
          setSelectedFilterTarget={setSelectedFilterTarget}
          setIsFilterModalOpen={setIsFilterModalOpen}
          setTreeViewMode={setLocalTreeViewMode}
          parentIsFilter={parentIsFilter}
        >
          {children}
        </CustomTreeItem>
      );
    })
  );

  const handleRemoveNode = (nodeId: string) => {
    setItems(prev => {
      const removeNodeFromTree = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
        return nodes.filter(node => {
          if (node.id === nodeId) {
            return false;
          }
          if (node.children) {
            node.children = removeNodeFromTree(node.children);
          }
          return true;
        });
      };

      const newItems = removeNodeFromTree([...prev]);
      notifyItemsChange(newItems);
      return newItems;
    });
  };

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>, nodeId: string) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOverNodeId(nodeId);
  }, []);

  const handleDragLeave = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOverNodeId(null);
  }, []);

  const handleDragStart = (event: React.DragEvent<HTMLElement>, node: ExtendedGenNode) => {
    console.log('Drag started:', { node });
    const dragData: DragPayload = {
      nodeId: node.id,
      treeType: 'variable',
      type: node.type,
      id: node.id,
      value1: node.value1 || null,
      value2: node.value2 || null,
      parentId: node.parentId || null,
      meta: node.meta || [],
      isExpanded: node.isExpanded || false,
      isFolder: node.isFolder || false,
      level: node.level || 0,
      anyChildren: node.anyChildren || false,
      name: node.name || null,
      children: node.children
    };
    event.dataTransfer.setData('application/json', JSON.stringify(dragData));
  };

  const scrollToBottom = () => {
    if (treeContainerRef.current) {
      treeContainerRef.current.scrollTo({
        top: treeContainerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const handleDrop = async (event: React.DragEvent<HTMLElement>, targetId: string | null, dropPosition?: 'before' | 'after' | 'inside') => {
    event.preventDefault();
    event.stopPropagation();
    setDropPosition(null);
    const rawData = event.dataTransfer.getData('application/json');
    console.log("Received raw drag data:", rawData);
    
    if (!rawData) {
      console.log("No drag data received");
      setErrorMessage("No drag data received");
      return;
    }

    try {
      const dragData = JSON.parse(rawData) as DragPayload;
      console.log("Parsed drag data:", dragData);

      // If we're in filter mode
      if (localTreeViewMode === 'filter') {
        let nodeToUse = findItemById(variableTree, dragData.nodeId);
        
        // If the node is not a Variable or Codeframe, try to find its parent
        if (dragData.type !== 'Variable' && dragData.type !== 'Codeframe') {
          // Look for a parent that's a Variable or Codeframe
          const parentNode = findParentNode(variableTree, dragData.nodeId);
          
          if (parentNode && (parentNode.type === 'Variable' || parentNode.type === 'Codeframe')) {
            // Use the parent instead
            console.log('Using parent for code:', parentNode);
            nodeToUse = parentNode;
          } else {
            console.log('Only variables or codeframes can be used in filters');
            setErrorMessage('Only variables or codeframes can be used in filters');
            return;
          }
        }
        
        if (!nodeToUse) {
          setErrorMessage('Could not find variable data');
          return;
        }

        // First case: We have a target but no source yet (this is the source variable)
        if (selectedFilterTarget && !selectedFilterSource) {
          console.log('Setting source variable:', nodeToUse);
          setSelectedFilterSource(nodeToUse);
          setSuccessMessage(`Source variable selected: ${nodeToUse.value1 || nodeToUse.name}`);
          return;
        }
        
        // Second case: We have a source but no target yet (this is the target variable)
        if (selectedFilterSource && !selectedFilterTarget) {
          console.log('Setting target variable:', nodeToUse);
          setSelectedFilterTarget(nodeToUse);
          setSuccessMessage(`Target variable selected: ${nodeToUse.value1 || nodeToUse.name}`);
          return;
        }
        
        // Third case: We have neither (this is the first selection - source)
        if (!selectedFilterSource && !selectedFilterTarget) {
          console.log('Setting initial source variable:', nodeToUse);
          setSelectedFilterSource(nodeToUse);
          setSuccessMessage(`Source variable selected: ${nodeToUse.value1 || nodeToUse.name}. Now drag a variable to be filtered.`);
          return;
        }
        
        // Final case: We have both already (replace source with new drag)
        console.log('Replacing source variable:', nodeToUse);
        setSelectedFilterSource(nodeToUse);
        setSuccessMessage(`Updated source variable: ${nodeToUse.value1 || nodeToUse.name}`);
        return;
      }

      // Original variable mode logic
      let newNode: ExtendedGenNode | undefined;

      // Handle Variable or Codeframe type drops
      if (dragData.type === 'Variable' || dragData.type === 'Codeframe') {
        try {
          if (!carbonClient) {
            throw new Error('Carbon client not available');
          }

          console.log(`Fetching codeframe for: ${dragData.value1}`);
          const variables = await carbonClient.getVarNodes(dragData.value1 || '');
          console.log('Fetched variables:', variables);

          if (!variables || variables.length === 0 || !variables[0].children) {
            throw new Error(`No codeframe found for: ${dragData.value1}`);
          }

          const codeframe = variables[0];
          newNode = {
            type: 'Codeframe',
            id: generateNodeId(),
            parentId: null,
            isExpanded: true,
            isFolder: false,
            value1: codeframe.value1,
            value2: codeframe.value2,
            meta: [{ key: 'Base', value: 'cwf' }],
            level: 1,
            anyChildren: true,
            name: codeframe.name || codeframe.value1,
            children: codeframe.children.map((child: ExtendedGenNode) => ({
              ...child,
              id: generateNodeId(),
              parentId: codeframe.id,
              level: 2
            }))
          };
        } catch (error) {
          console.error('Error fetching codeframe:', error);
          setErrorMessage(`Error fetching codeframe: ${error instanceof Error ? error.message : String(error)}`);
          return;
        }
      }
      // Handle Code type drops
      else if (dragData.type === 'Code') {
        // Find the parent variable in the tree
        const findParentInTree = (nodes: ExtendedGenNode[]): ExtendedGenNode | null => {
          for (const node of nodes) {
            if (node.id === dragData.parentId) return node;
            if (node.children) {
              const found = findParentInTree(node.children);
              if (found) return found;
            }
          }
          return null;
        };

        const parentVar = findParentInTree(variableTree);
        if (!parentVar) {
          console.error('Parent variable not found for ID:', dragData.parentId);
          setErrorMessage('Could not find parent variable in tree');
          return;
        }

        // Create the codeframe structure with the parent variable
        const codeframeId = generateNodeId();
        newNode = {
          type: 'Codeframe',
          id: codeframeId,
          parentId: null,
          isExpanded: true,
          isFolder: false,
          value1: parentVar.value1,
          value2: parentVar.value2,
          meta: [{ key: 'Base', value: 'cwf' }],
          level: 1,
          anyChildren: true,
          name: parentVar.name || parentVar.value1 || '',
          children: [{
            type: 'Code',
            id: generateNodeId(),
            parentId: codeframeId,
            level: 2,
            anyChildren: false,
            isExpanded: dragData.isExpanded,
            isFolder: dragData.isFolder,
            value1: dragData.value1,
            value2: dragData.value2,
            meta: dragData.meta,
            name: dragData.name
          }]
        };
      }

      if (!newNode) {
        console.error('No node created');
        setErrorMessage('Failed to create node structure');
        return;
      }

      // Add the node to the tree
      setItems(prev => {
        let newItems: ExtendedGenNode[];
        if (targetId && dropPosition && dropPosition !== 'inside') {
          const targetIndex = prev.findIndex(item => item.id === targetId);
          const insertIndex = dropPosition === 'after' ? targetIndex + 1 : targetIndex;
          newItems = [
            ...prev.slice(0, insertIndex),
            newNode!,
            ...prev.slice(insertIndex)
          ];
        } else {
          newItems = [...prev, newNode!];
        }
        notifyItemsChange(newItems);
        scrollToBottom();
        return newItems;
      });
    } catch (error) {
      console.error("Error handling drop:", error);
      setErrorMessage(error instanceof Error ? error.message : "Error handling drop");
    }
  };

  const processNodes = (nodes: ExtendedGenNode[], parentNode: ExtendedGenNode): ExtendedGenNode[] => {
    return nodes.map((node: ExtendedGenNode) => ({
      ...node,
      type: node.type,
      id: node.id,
      parentId: parentNode.id,
      name: `${node.value1 || ''} ${node.value2 || ''}`.trim(),
      isExpanded: node.type === 'Variable',
      anyChildren: Boolean(node.anyChildren || (node.children && node.children.length > 0)),
      children: node.children ? processNodes(node.children, node) : undefined,
      isFolder: node.isFolder,
      value1: node.value1,
      value2: node.value2,
      meta: node.meta || [],
      level: node.level
    }));
  };

  const handleAddNode = (newNode: ExtendedGenNode) => {
    console.log('handleAddNode called with:', { newNode, addModalParent });
    
    // Validate parent-child relationship
    if (addModalParent) {
      const validChildren = VALID_PARENT_CHILDREN[addModalParent.type];
      if (!validChildren?.includes(newNode.type)) {
        setErrorMessage(`${newNode.type} cannot be a child of ${addModalParent.type}`);
        return;
      }

      // Check level constraints
      const maxLevel = MAX_LEVEL_BY_TYPE[newNode.type];
      const newLevel = addModalParent.level + 1;
      if (maxLevel !== undefined && newLevel > maxLevel) {
        setErrorMessage(`${newNode.type} cannot appear at level ${newLevel} (max: ${maxLevel})`);
        return;
      }
    }
    
    setItems(prev => {
      // Helper function to recursively update nodes
      const updateNodesRecursively = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
        return nodes.map(item => {
          if (item.id === addModalParent?.id) {
            console.log('Found parent node to add child to:', item);
            // Update the new node's level and parentId
            const childNode = {
              ...newNode,
              level: item.level + 1,
              parentId: item.id,
              id: generateNodeId(), // Ensure unique ID
              isExpanded: false,
              anyChildren: false
            };
            console.log('Created child node:', childNode);
            
            const updatedNode = {
              ...item,
              children: [...(item.children || []), childNode],
              anyChildren: true,
              isExpanded: true // Auto-expand parent when adding child
            };
            console.log('Updated parent node:', updatedNode);
            return updatedNode;
          }
          
          if (item.children) {
            return {
              ...item,
              children: updateNodesRecursively(item.children)
            };
          }
          return item;
        });
      };

      let newItems;
      if (addModalParent) {
        console.log('Adding as child to parent:', addModalParent);
        newItems = updateNodesRecursively(prev);
      } else {
        console.log('Adding to root level');
        newItems = [...prev, { 
          ...newNode, 
          level: 0, 
          parentId: null,
          id: generateNodeId(), // Ensure unique ID
          isExpanded: false,
          anyChildren: false
        }];
      }
      
      console.log('Final tree structure:', newItems);
      notifyItemsChange(newItems);
      return newItems;
    });
    
    // Reset the modal state
    setIsAddModalOpen(false);
    setAddModalParent(null);
    setAddModalType('Function'); // Reset to default type
  };

  const handleFilterCreate = useCallback((filterNode: ExtendedGenNode) => {
    console.log('Creating filter:', { 
      filterNode,
      source: selectedFilterSource,
      target: selectedFilterTarget,
      originalItems: items
    });

    // Make sure both source and target are available before proceeding
    if (!selectedFilterSource || !selectedFilterTarget) {
      console.error('Missing filter source or target');
      setErrorMessage('Cannot create filter: missing source or target variable');
      return;
    }

    setItems(prevItems => {
      // Get the target node ID to remove it from the tree
      const targetVariableId = selectedFilterTarget.id;
      
      // Find the target's index to preserve position
      const targetIndex = prevItems.findIndex(item => item.id === targetVariableId);
      
      // Ensure the filter node has a unique ID
      const newFilterNode = {
        ...filterNode,
        id: `filter-${Date.now()}`, // Ensure unique ID
        parentId: null // Set as root-level node
      };
      
      // Remove the target variable node from the tree
      const filteredItems = prevItems.filter(item => item.id !== targetVariableId);
      
      // Insert the new filter node at the target's original position
      let newItems;
      if (targetIndex >= 0) {
        // Insert at the original position
        newItems = [
          ...filteredItems.slice(0, targetIndex),
          newFilterNode,
          ...filteredItems.slice(targetIndex)
        ];
      } else {
        // Fallback: add to the end if target was not at root level
        newItems = [...filteredItems, newFilterNode];
      }
      
      notifyItemsChange(newItems);
      return newItems;
    });
    
    // Reset filter modal state
    setIsFilterModalOpen(false);
    setSelectedFilterSource(null);
    setSelectedFilterTarget(null);
    setLocalTreeViewMode('variable');
    
    // Show success message
    setSuccessMessage('Filter created successfully');
  }, [items, selectedFilterSource, selectedFilterTarget, setIsFilterModalOpen, setSelectedFilterSource, setSelectedFilterTarget, setLocalTreeViewMode, notifyItemsChange]);

  // Add this after the existing useEffect hooks
  useEffect(() => {
    if (localTreeViewMode === 'filter') {
      // Only reset if we don't have a pre-selected target
      if (!selectedFilterTarget) {
        setSelectedFilterSource(null);
        setSelectedFilterTarget(null);
      }
    } else {
      // Always reset when leaving filter mode
      setSelectedFilterSource(null);
      setSelectedFilterTarget(null);
      setIsFilterModalOpen(false);
    }
  }, [localTreeViewMode, selectedFilterTarget]);

  // Update the status bar text to show clear guidance based on current selection state
  const getFilterStatusText = () => {
    if (!selectedFilterSource && !selectedFilterTarget) {
      return 'Step 1: Drag a variable to filter by';
    } else if (selectedFilterSource && !selectedFilterTarget) {
      return 'Step 2: Drag a variable to be filtered';
    } else if (selectedFilterSource && selectedFilterTarget) {
      return 'Step 3: Click Configure Filter to set up filter conditions';
    } else {
      return 'Creating Filter...';
    }
  };

  // Update the drop zone text to be more explicit
  const getDropZoneText = () => {
    if (localTreeViewMode === 'filter') {
      if (selectedFilterSource && selectedFilterTarget) {
        return `Filter creation ready`;
      } else if (selectedFilterTarget) {
        return `Drop a variable to filter ${selectedFilterTarget.value1 || selectedFilterTarget.name} by`;
      } else if (selectedFilterSource) {
        return `Drop a variable to be filtered by ${selectedFilterSource.value1 || selectedFilterSource.name}`;
      } else {
        return 'Step 1: Drop a variable to filter by';
      }
    }
    return 'Drop variable here';
  };

  const handleDuplicate = useCallback((nodeId: string) => {
    setItems(prevItems => {
      const duplicateNode = (nodes: ExtendedGenNode[]): [ExtendedGenNode[], ExtendedGenNode | null] => {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].id === nodeId) {
            // Deep clone the node and its children
            const clonedNode = JSON.parse(JSON.stringify(nodes[i]));
            
            // Generate new IDs for the cloned node and all its children
            const generateNewIds = (node: ExtendedGenNode): ExtendedGenNode => {
              const newId = generateNodeId();
              const newNode = {
                ...node,
                id: newId,
                parentId: node.parentId
              };
              
              if (newNode.children) {
                newNode.children = newNode.children.map(child => generateNewIds({
                  ...child,
                  parentId: newId
                }));
              }
              
              return newNode;
            };
            
            const duplicatedNode = generateNewIds(clonedNode);
            
            // Insert the duplicated node after the original
            const newNodes = [...nodes];
            newNodes.splice(i + 1, 0, duplicatedNode);
            return [newNodes, duplicatedNode];
          }
          
          if (nodes[i].children?.length) {
            const [newChildren, duplicatedNode] = duplicateNode(nodes[i].children as ExtendedGenNode[]);
            if (duplicatedNode) {
              nodes[i] = { ...nodes[i], children: newChildren };
              return [nodes, duplicatedNode];
            }
          }
        }
        return [nodes, null];
      };

      const [newItems] = duplicateNode(prevItems);
      notifyItemsChange(newItems);
      return newItems;
    });
  }, [notifyItemsChange]);

  // Add effect to automatically open filter modal when both source and target are selected
  useEffect(() => {
    if (selectedFilterSource && selectedFilterTarget && localTreeViewMode === 'filter') {
      // Add a small delay to ensure any other modal operations have completed
      const timer = setTimeout(() => {
        setIsFilterModalOpen(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [selectedFilterSource, selectedFilterTarget, localTreeViewMode]);

  // Add a function to handle the first step completion
  const handleFirstStepComplete = () => {
    if (selectedFilterSource && !selectedFilterTarget) {
      // We've selected the source but not the target yet
      // Show a notification to indicate we're ready for step 2
      setSuccessMessage('Source variable selected. Now drag a variable to be filtered.');
      return;
    }
    
    if (!selectedFilterSource && selectedFilterTarget) {
      // We've selected the target but not the source yet
      // Show a notification to indicate we're ready for step 2
      setSuccessMessage('Target variable selected. Now drag a variable to filter by.');
      return;
    }
    
    // If both variables are selected, open the filter modal
    if (selectedFilterSource && selectedFilterTarget) {
      setTimeout(() => {
        setIsFilterModalOpen(true);
      }, 100);
    }
  };

  // Update the toolbar section to improve the filter creation flow
  return (
    <Box 
      sx={{ 
        height: '100%', 
        width: '100%', 
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/* Toolbar */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          p: 0.5,
          borderBottom: '1px solid',
          borderColor: 'divider',
          bgcolor: 'background.paper',
          minHeight: 40,
          gap: 1
        }}
      >
        {/* Add status bar for filter mode */}
        {localTreeViewMode === 'filter' && (
          <Box
            sx={{
              p: 1,
              borderBottom: '1px solid',
              borderColor: 'divider',
              bgcolor: 'background.paper',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 1
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              {getFilterStatusText()}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {selectedFilterSource && (
                <Typography variant="body2" sx={{ 
                  bgcolor: 'primary.light', 
                  color: 'primary.contrastText',
                  py: 0.5,
                  px: 1,
                  borderRadius: 1
                }}>
                  Filter by: {selectedFilterSource.value1 || selectedFilterSource.name}
                </Typography>
              )}
              {selectedFilterTarget && (
                <Typography variant="body2" sx={{ 
                  bgcolor: 'secondary.light', 
                  color: 'secondary.contrastText',
                  py: 0.5,
                  px: 1,
                  borderRadius: 1
                }}>
                  Variable: {selectedFilterTarget.value1 || selectedFilterTarget.name}
                </Typography>
              )}
              <Button 
                size="small"
                variant="outlined"
                onClick={() => {
                  setSelectedFilterSource(null);
                  setSelectedFilterTarget(null);
                  setLocalTreeViewMode('variable');
                }}
              >
                Cancel
              </Button>
              {(selectedFilterSource || selectedFilterTarget) && (
                <Button 
                  size="small"
                  variant="contained"
                  color={selectedFilterSource && selectedFilterTarget ? "primary" : "secondary"}
                  onClick={handleFirstStepComplete}
                >
                  {selectedFilterSource && selectedFilterTarget ? "Configure Filter" : "Continue"}
                </Button>
              )}
            </Box>
          </Box>
        )}
        {/* Commented out Add Filter button
        <Tooltip title="Add Filter">
          <span>
            <IconButton
              onClick={() => {
                setLocalTreeViewMode('filter');
                setAddModalParent(null);
                setSelectedFilterSource(null);
                setSelectedFilterTarget(null);
              }}
              size="small"
            >
              <Box 
                component="img"
                src="/assets/icons/Filter.png"
                alt="Add Filter"
                sx={{ 
                  width: 20,
                  height: 20,
                  objectFit: 'contain'
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
        */}
        {/* Commented out Add Function button
        <Tooltip title="Add Function">
          <span>
            <IconButton
              onClick={() => {
                setAddModalParent(null);
                setIsAddModalOpen(true);
                setAddModalType('Function');
              }}
              size="small"
            >
              <Functions fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        */}
        <Tooltip title="Add Spacer">
          <span>
            <IconButton
              onClick={() => {
                setAddModalParent(null);
                setIsAddModalOpen(true);
                setAddModalType('Spacer');
              }}
              size="small"
            >
              <SpaceBar fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      {/* Loading overlay */}
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      
      {/* Main tree area */}
      <Box 
        ref={treeContainerRef}
        sx={{ 
          flex: 1, 
          overflowY: 'auto', 
          minHeight: 0,
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: 'none',
            transition: 'all 0.2s',
            border: '2px dashed transparent',
            backgroundColor: 'transparent',
            zIndex: 1,
          },
          '&[data-can-drop="true"]::after': {
            borderColor: localTreeViewMode === 'filter' ? 
              (selectedFilterSource ? 'secondary.main' : 'primary.main') : 
              'primary.main',
            backgroundColor: localTreeViewMode === 'filter' ? 
              (selectedFilterSource ? 'rgba(156, 39, 176, 0.04)' : 'rgba(25, 118, 210, 0.04)') : 
              'rgba(25, 118, 210, 0.04)',
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.currentTarget.setAttribute('data-can-drop', 'true');
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.currentTarget.setAttribute('data-can-drop', 'false');
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.currentTarget.setAttribute('data-can-drop', 'false');
          handleDrop(e, null);
        }}
      >
        {localTreeViewMode === 'filter' && (
          <Box sx={{ 
            p: 2, 
            textAlign: 'center', 
            bgcolor: 'background.paper',
            border: '1px dashed',
            borderColor: selectedFilterSource ? 'secondary.main' : 'primary.main',
            borderRadius: 1,
            m: 2,
            pointerEvents: 'none'
          }}>
            <Typography variant="body1" color={selectedFilterSource ? 'secondary.main' : 'primary.main'}>
              {getDropZoneText()}
            </Typography>
          </Box>
        )}
        {items.length === 0 ? (
          <Box sx={{ p: 2, color: 'text.secondary' }}>
            <Typography variant="caption">No items to display</Typography>
            <Typography variant="caption" display="block">
              Initial items count: {initialItems.length}
            </Typography>
          </Box>
        ) : (
          <RichTreeViewPro
            items={items}
            defaultExpandedItems={getAllNodeIds(items)}
            getItemId={(item) => item.id}
            getItemLabel={(item: ExtendedGenNode): string => {
              if (!item) return '';
              if (item.type === 'Spacer') return 'Spacer';
              const label = [item.value1, item.value2]
                .filter(Boolean)
                .join(' - ');
              return label || '';
            }}
            onItemPositionChange={(params) => {
              handleItemMove(params.itemId, params.oldPosition, params.newPosition);
            }}
            experimentalFeatures={{
              indentationAtItemLevel: true,
              itemsReordering: true
            }}
            itemsReordering
            slots={{
              item: CustomTreeItem as React.ComponentType<TreeItem2Props>,
              expandIcon: ExpandMore,
              collapseIcon: ExpandLess
            }}
            slotProps={{
              item: (ownerState) => ({
                ...ownerState,
                node: findItemById(items, ownerState.itemId),
                onDelete: handleDeleteNode,
                onEdit: handleEdit,
                onMoveUp: handleMoveUp,
                onMoveDown: handleMoveDown,
                onMoveTop: handleMoveTop,
                onMoveBottom: handleMoveBottom,
                onDuplicate: handleDuplicate,
                setAddModalParent,
                setAddModalType,
                setIsAddModalOpen,
                setSelectedFilterSource,
                setSelectedFilterTarget,
                setIsFilterModalOpen,
                setTreeViewMode: setLocalTreeViewMode,
                onAddChild: (id: string) => {
                  console.log('onAddChild called with id:', id);
                  const targetNode = findItemById(items, id);
                  console.log('Found target node:', targetNode);
                  if (targetNode) {
                    setAddModalParent(targetNode);
                    setAddModalType('Function');
                    setIsAddModalOpen(true);
                  }
                },
                expandable: "true"
              })
            }}
          />
        )}
      </Box>

      {editingNode && (
        <NodeEditModal
          node={editingNode}
          open={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setEditingNode(null);
          }}
          onSave={handleSaveEdit}
          availableCodes={availableCodes}
          availableFilters={getAllFilters(items)}
        />
      )}

      {deleteDialogOpen && (
        <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
          <DialogTitle>Delete Node</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this node?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
            <Button onClick={confirmDelete} color="error">Delete</Button>
          </DialogActions>
        </Dialog>
      )}

      <GlobalStyles
        styles={{
          '.tree-item-content:hover .actions': {
            visibility: 'visible !important',
            opacity: '1 !important',
          },
        }}
      />

      {/* Error Snackbar */}
      <Snackbar 
        open={errorMessage !== null || dragOverError !== null} 
        autoHideDuration={6000} 
        onClose={() => {
          setErrorMessage(null);
          setDragOverError(null);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => {
            setErrorMessage(null);
            setDragOverError(null);
          }} 
          severity="error"
          sx={{ 
            width: '100%',
            '& .MuiAlert-message': {
              maxHeight: '200px',
              overflowY: 'auto'
            }
          }}
        >
          <Box component="pre" sx={{ m: 0, whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
            {errorMessage || dragOverError}
          </Box>
        </Alert>
      </Snackbar>

      {/* Success message */}
      <Snackbar 
        open={successMessage !== null} 
        autoHideDuration={4000} 
        onClose={() => setSuccessMessage(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSuccessMessage(null)} 
          severity="success"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Add Node Modal */}
      <AddNodeModal
        open={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setAddModalParent(null);
        }}
        onAdd={handleAddNode}
        parentNode={addModalParent}
        currentLevel={addModalParent?.level ? addModalParent.level + 1 : 0}
        type={addModalType}
      />

      <FilterCreationModal
        open={isFilterModalOpen}
        onClose={() => {
          setIsFilterModalOpen(false);
          // Don't reset source and target here, in case user wants to try again
        }}
        onAdd={handleFilterCreate}
        sourceVariable={selectedFilterSource}
        targetVariable={selectedFilterTarget}
        carbonClient={carbonClient}
      />
    </Box>
  );
};

export default NodeTreeEditor; 