import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { JobInfo } from '../types/types';

interface JobContextType {
  selectedCustomer: string | null;
  selectedJob: JobInfo | null;
  setSelectedCustomer: (customer: string | null) => void;
  setSelectedJob: (job: JobInfo | null) => void;
}

const JobContext = createContext<JobContextType | undefined>(undefined);

export const JobProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Initialize state from localStorage
  const [selectedCustomer, setSelectedCustomerState] = useState<string | null>(() => {
    const savedCustomer = localStorage.getItem('selectedCustomer');
    return savedCustomer ? JSON.parse(savedCustomer) : null;
  });

  const [selectedJob, setSelectedJobState] = useState<JobInfo | null>(() => {
    const savedJob = localStorage.getItem('selectedJob');
    return savedJob ? JSON.parse(savedJob) : null;
  });

  // Wrap setState functions to also update localStorage
  const setSelectedCustomer = useCallback((customer: string | null) => {
    setSelectedCustomerState(customer);
    if (customer) {
      localStorage.setItem('selectedCustomer', JSON.stringify(customer));
    } else {
      localStorage.removeItem('selectedCustomer');
    }
  }, []);

  const setSelectedJob = useCallback((job: JobInfo | null) => {
    setSelectedJobState(job);
    if (job) {
      localStorage.setItem('selectedJob', JSON.stringify(job));
    } else {
      localStorage.removeItem('selectedJob');
    }
  }, []);

  return (
    <JobContext.Provider value={{
      selectedCustomer,
      selectedJob,
      setSelectedCustomer,
      setSelectedJob
    }}>
      {children}
    </JobContext.Provider>
  );
};

export const useJob = () => {
  const context = useContext(JobContext);
  if (context === undefined) {
    throw new Error('useJob must be used within a JobProvider');
  }
  return context;
}; 