import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';
import {
  UpdateAccountRequest,
  ChangePasswordRequest,
  UpsertDashboardRequest,
  DashboardRequest,
  QuickUpdateRequest,
  RunSpecRequest,
  GenTabRequest,
  GenNode,
  CustomerInfo,
  JobInfo,
  XDisplayProperties,
  SessionStatus,
  ImportSettings,
  GenericResponse,
  TocNode,
  User,
  TableList
} from '../types/types';
import axios from 'axios';
import { licensingClient } from '../services/LicensingClient';

const reportFormatOptions = ['None', 'TSV', 'CSV', 'SSV', 'PlainText', 'XLSX', 'XML', 'HTML', 'OXT', 'OXTNums', 'Diamond', 'MultiCube', 'Pandas'];

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8090';

// Function to create API endpoint paths properly
const getApiEndpoint = (endpoint: string) => {
  // API_URL already includes /secret, just add /api prefix
  if (endpoint.startsWith('/')) {
    return `${API_URL}/api${endpoint}`;
  }
  return `${API_URL}/api/${endpoint}`;
};

type JobUrlCheckResult = {
  jobName: string;
  jobId: string;
  exists: boolean;
  hasData: boolean;
  recordCount: number;
  uniqueInterviewCount: number;
  variableCount: number;
  variableIds: string[];
};

export const Experiments: React.FC = () => {
  const { carbonClient, isClientReady, isAuthenticated, user } = useAuth();
  const { selectedCustomer, selectedJob } = useJob();
  const [selectedMethod, setSelectedMethod] = useState('');
  const [inputParams, setInputParams] = useState<Record<string, string>>({});
  const [response, setResponse] = useState<SessionStatus[] | GenericResponse | string | string[] | null>(null);
  const [customers, setCustomers] = useState<CustomerInfo[]>([]);
  const [jobs, setJobs] = useState<JobInfo[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [htmlReport, setHtmlReport] = useState<string | null>(null);
  const [exportFormat, setExportFormat] = useState<string>('HTML');
  const [tocNodes, setTocNodes] = useState<TocNode[]>([]);
  const [selectedReport, setSelectedReport] = useState<string>('');
  const [isUrlCheckModalOpen, setIsUrlCheckModalOpen] = useState(false);
  const [urlCheckResults, setUrlCheckResults] = useState<JobUrlCheckResult[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [userCustomers, setUserCustomers] = useState<string[]>([]);
  const [userJobs, setUserJobs] = useState<string[]>([]);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [originalPermissions, setOriginalPermissions] = useState<{ customers: string[]; jobs: string[] }>({ customers: [], jobs: [] });

  const exportFormats = ['HTML', 'TSV', 'CSV', 'SSV', 'XML', 'OXT', 'OXTNums', 'MultiCube', 'JSON'];

  const methods = [
    { name: 'getServiceInfo', params: [] },
    { name: 'listSessions', params: [] },
    { name: 'updateAccount', params: ['userId', 'userName', 'comment', 'email'] },
    { name: 'changePassword', params: ['userId', 'oldPassword', 'newPassword'] },
    { name: 'multiOxtCancel', params: ['id'] },
    { name: 'upsertDashboard', params: ['name', 'buffer', 'customerName', 'jobName', 'displayName', 'userName', 'isShared', 'comment'] },
    { name: 'deleteDashboard', params: ['customerName', 'jobName', 'dashboardName'] },
    { name: 'getDashboard', params: ['customerName', 'jobName', 'dashboardName'] },
    { name: 'multiOxtStart', params: ['reportId', 'oxtSpecs'] },
    { name: 'multiOxtQuery', params: ['id'] },
    { name: 'openCloudJob', params: ['customerName', 'jobName'] },
    { name: 'listDashboards', params: ['customerName', 'jobName'] },
    { name: 'authenticateName', params: ['username', 'password'] },
    { name: 'getProperties', params: ['customerName', 'jobName'] },
    { name: 'putProperties', params: ['properties'] },
    { name: 'unloadReport', params: [] },
    { name: 'deleteInUserToc', params: ['reportPathName'] },
    { name: 'saveReport', params: ['name', 'sub'] },
    { name: 'loadReport', params: ['fullName'] },
    { name: 'generateXlsx', params: [] },
    { name: 'quickUpdateReport', params: ['showFreq', 'showColPct', 'showRowPct', 'showSig', 'filter'] },
    { name: 'endSession', params: ['sessionId'] },
    { name: 'getEditSpec', params: [] },
    { name: 'getNewSpec', params: [] },
    { name: 'validateSpec', params: ['spec'] },
    { name: 'runSpecification', params: ['name', 'spec'] },
    { name: 'validateExp', params: ['funcManageExp'] },
    { name: 'functionAction', params: ['action', 'exp', 'newExp', 'label'] },
    { name: 'listAxisTreeChildren', params: ['exp'] },
    { name: 'nest', params: ['genaxnodes', 'variables'] },
    { name: 'varAsNodes', params: ['name'] },
    { name: 'generateReport', params: ['reportName', 'top', 'side', 'filter', 'weight'] },
    { name: 'GenTabAsHTML', params: ['top', 'side', 'flt', 'wgt'] },
    { name: 'AxisSyntaxToNodes', params: ['syntax'] },
    { name: 'AxisNodesToSyntax', params: ['nodes'] },
    { name: 'CurrentSyntax', params: [] },
    { name: 'ValidateSyntax', params: ['syntax'] },
    { name: 'importFullJob', params: ['source', 'surveyId'] },
    { name: 'importPartialJob', params: ['source', 'surveyId', 'link', 'overwrite'] },
    { name: 'getReportFormat', params: ['format'] },
    { name: 'checkJobUrls', params: [] },
    { name: 'manageUserPermissions', params: ['userId'] },
    { name: 'createTableList', params: ['name', 'tables'] },
    { name: 'getTableList', params: ['listId'] },
    { name: 'getUserTableLists', params: ['userId'] },
    { name: 'updateTableList', params: ['listId', 'name', 'tables'] },
    { name: 'deleteTableList', params: ['listId'] }
  ];

  useEffect(() => {
    const fetchCustomers = async () => {
      if (carbonClient && isClientReady) {
        try {
          const customerList = await carbonClient.getCustomers();
          setCustomers(customerList);
        } catch (error) {
          console.error('Error fetching customers:', error);
          setErrorMessage(`Error fetching customers: ${(error as Error).message}`);
        }
      }
    };

    fetchCustomers();
  }, [carbonClient, isClientReady]);

  useEffect(() => {
    const fetchJobs = async () => {
      if (carbonClient && isClientReady && inputParams.customerName) {
        try {
          const jobList = await carbonClient.getJobs(inputParams.customerName);
          setJobs(jobList);
        } catch (error) {
          console.error('Error fetching jobs:', error);
          setErrorMessage(`Error fetching jobs: ${(error as Error).message}`);
        }
      }
    };

    fetchJobs();
  }, [carbonClient, isClientReady, inputParams.customerName]);

  useEffect(() => {
    const openJob = async () => {
      if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
        try {
          await carbonClient.openCloudJob(selectedCustomer, selectedJob);
          console.log(`Job opened: ${selectedCustomer} - ${selectedJob}`);
        } catch (error) {
          console.error('Error opening job:', error);
          setErrorMessage(`Error opening job: ${(error as Error).message}`);
        }
      }
    };

    openJob();
  }, [carbonClient, isClientReady, selectedCustomer, selectedJob]);

  const fetchTocNodes = async () => {
    if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
      try {
        await carbonClient.openCloudJob(selectedCustomer, selectedJob);
        const nodes = await carbonClient.getTocNodes();
        setTocNodes(nodes);
      } catch (error) {
        console.error('Error fetching TOC nodes:', error);
        setErrorMessage(`Error fetching TOC nodes: ${(error as Error).message}`);
      }
    }
  };

  useEffect(() => {
    fetchTocNodes();
  }, [carbonClient, isClientReady, selectedCustomer, selectedJob]);

  const handleMethodChange = (event: SelectChangeEvent<string>) => {
    setSelectedMethod(event.target.value);
    setInputParams({});
    setResponse(null);
    setErrorMessage(null);
    setHtmlReport(null);
  };

  const handleInputChange = (param: string, value: string) => {
    setInputParams(prev => ({ ...prev, [param]: value }));
  };

  const handleExportFormatChange = (event: SelectChangeEvent<string>) => {
    setExportFormat(event.target.value);
  };

  const handleFullImport = async () => {
    if (!carbonClient || !isAuthenticated) {
      setErrorMessage('Not authenticated or Carbon client not available');
      return;
    }

    const importSettings: ImportSettings = {
      type: 'TSAPI',
      source: inputParams.source || 'https://rcsapps.azurewebsites.net/carbon-tsapi/',
      surveyId: inputParams.surveyId || '',
      link: inputParams.link || 'case',
      overwrite: inputParams.overwrite === 'true',
      authorization: `${process.env.REACT_APP_BEARER_TOKEN}`
    };

    try {
      const result = await carbonClient.importFullJob(importSettings);
      setResponse(result);
      console.log('Full import result:', result);
    } catch (error) {
      console.error('Error during full import:', error);
      setErrorMessage(`Error during full import: ${(error as Error).message}`);
    }
  };

  const handlePartialImport = async () => {
    if (!carbonClient || !isAuthenticated) {
      setErrorMessage('Not authenticated or Carbon client not available');
      return;
    }

    console.log('Authentication state:', isAuthenticated);
    console.log('Carbon client ready:', isClientReady);

    try {
      // First, open the job
      if (!selectedCustomer || !selectedJob) {
        throw new Error('Please select a customer and job before importing');
      }

      console.log(`Opening job: ${selectedCustomer} - ${selectedJob}`);
      await carbonClient.openCloudJob(selectedCustomer, selectedJob);
      console.log('Job opened successfully');

      // Now proceed with the partial import
      const importSettings: ImportSettings = {
        type: 'TSAPI',
        source: inputParams.source || 'https://bayesprice.helix.ml/secret/api/',
        surveyId: inputParams.surveyId || '27080725',
        link: inputParams.link || 'case',
        overwrite: inputParams.overwrite === 'true',
        authorization: `${process.env.REACT_APP_BEARER_TOKEN}`
      };

      console.log('Importing partial job with settings:', importSettings);
      const result = await carbonClient.importPartialJob(importSettings);
      setResponse(result);
      console.log('Partial import result:', result);
    } catch (error) {
      console.error('Error during job open or partial import:', error);
      setErrorMessage(`Error: ${(error as Error).message}`);
    }
  };

  const handleRunMethod = async () => {
    setErrorMessage(null);
    setResponse(null);
    setHtmlReport(null);

    if (!isAuthenticated) {
      setErrorMessage('Not authenticated. Please log in first.');
      return;
    }

    if (!isClientReady) {
      setErrorMessage('Carbon client is not ready. Please try again in a moment.');
      return;
    }

    if (!selectedCustomer || !selectedJob) {
      setErrorMessage('Please select a customer and job before running a method.');
      return;
    }

    if (!carbonClient) {
      setErrorMessage('Carbon client is not available. Please try again.');
      return;
    }

    try {
      let result;
      switch (selectedMethod) {
        case 'getServiceInfo':
          result = await carbonClient.getServiceInfo();
          break;
        case 'listSessions':
          const sessionList = await carbonClient.listSessions();
          result = sessionList.map(session => ({
            ...session,
            sessionIdDisplay: `Session ID: ${session.sessionId}`
          }));
          break;
        case 'updateAccount':
          const updateAccountRequest: UpdateAccountRequest = {
            userId: inputParams.userId || null,
            userName: inputParams.userName || null,
            comment: inputParams.comment || null,
            email: inputParams.email || null
          };
          result = await carbonClient.updateAccount(updateAccountRequest);
          break;
        case 'changePassword':
          const changePasswordRequest: ChangePasswordRequest = {
            userId: inputParams.userId || null,
            oldPassword: inputParams.oldPassword || null,
            newPassword: inputParams.newPassword || null
          };
          result = await carbonClient.changePassword(changePasswordRequest);
          break;
        case 'upsertDashboard':
          const upsertDashboardRequest: UpsertDashboardRequest = {
            name: inputParams.name || null,
            createdUtc: new Date().toISOString(), // You might want to adjust this
            modifiedUtc: new Date().toISOString(), // You might want to adjust this
            buffer: inputParams.buffer || null,
            customerName: inputParams.customerName || null,
            jobName: inputParams.jobName || null,
            displayName: inputParams.displayName || null,
            userName: inputParams.userName || null,
            isShared: inputParams.isShared === 'true',
            comment: inputParams.comment || null
          };
          result = await carbonClient.upsertDashboard(upsertDashboardRequest);
          break;
        case 'deleteDashboard':
          const deleteDashboardRequest: DashboardRequest = {
            customerName: inputParams.customerName || null,
            jobName: inputParams.jobName || null,
            dashboardName: inputParams.dashboardName || null
          };
          result = await carbonClient.deleteDashboard(deleteDashboardRequest);
          break;
        case 'getDashboard':
          const getDashboardRequest: DashboardRequest = {
            customerName: inputParams.customerName || null,
            jobName: inputParams.jobName || null,
            dashboardName: inputParams.dashboardName || null
          };
          result = await carbonClient.getDashboard(getDashboardRequest);
          break;
        case 'openCloudJob':
          const jobInfo: JobInfo = jobs.find(job => job.name === inputParams.jobName) || {
            id: '',
            name: inputParams.jobName,
            displayName: null,
            description: '',
            vartreeNames: [],
            realCloudVartreeNames: [],
            isAccessible: true,
            info: null,
            logo: null,
            url: null,
            sequence: null
          };
          result = await carbonClient.openCloudJob(inputParams.customerName, jobInfo);
          break;
        case 'listDashboards':
          result = await carbonClient.listDashboards(inputParams.customerName, inputParams.jobName);
          break;
        case 'authenticateName':
          result = await carbonClient.authenticate(inputParams.username, inputParams.password);
          break;
        case 'getProperties':
          if (!inputParams.customerName || !inputParams.jobName) {
            throw new Error('Customer name and job name are required for getProperties');
          }
          console.log('Calling getProperties with:', inputParams.customerName, inputParams.jobName);
          result = await carbonClient.getProperties(inputParams.customerName, inputParams.jobName);
          break;
        case 'saveReport':
          result = await carbonClient.saveReport(inputParams.name, inputParams.sub);
          break;
        case 'loadReport':
          if (!inputParams.fullName) {
            throw new Error('Full report name is required for loadReport');
          }
          console.log('Attempting to load report:', inputParams.fullName);
          const [value1, value2] = inputParams.fullName.split('/').reverse();
          result = await carbonClient.LoadReportAsync(value1, value2);
          if (result.code !== 0) {
            throw new Error(`Failed to load report: ${result.message}`);
          }
          console.log('Report loaded successfully');
          break;
        case 'generateXlsx':
          console.log('Attempting to generate XLSX report');
          result = await carbonClient.generateXlsx();
          if (!result.reportName) {
            setErrorMessage('Warning: XLSX generated, but no report name provided in the response');
          }
          break;
        case 'quickUpdateReport':
          const quickUpdateRequest: QuickUpdateRequest = {
            ShowFreq: inputParams.showFreq === 'true',
            ShowColPct: inputParams.showColPct === 'true',
            ShowRowPct: inputParams.showRowPct === 'true',
            ShowSig: inputParams.showSig === 'true',
            Filter: inputParams.filter || ''
          };
          result = await carbonClient.quickUpdate(quickUpdateRequest);
          break;
        case 'getEditSpec':
          result = await carbonClient.getSpecAggregate();
          break;
        case 'getNewSpec':
          result = await carbonClient.getNewSpec();
          break;
        case 'validateSpec':
          // This might need special handling for spec object
          result = await carbonClient.validateSpec(JSON.parse(inputParams.spec));
          break;
        case 'runSpecification':
          const runSpecRequest: RunSpecRequest = {
            name: inputParams.name,
            DProps: {}, // You might want to add logic to populate this
            spec: JSON.parse(inputParams.spec)
          };
          result = await carbonClient.runSpecification(runSpecRequest);
          break;
        case 'validateExp':
          result = await carbonClient.validateExpression({ expression: inputParams.funcManageExp });
          break;
        case 'generateReport':
          const genTabRequest: GenTabRequest = {
            name: inputParams.reportName || 'Test Report',
            top: inputParams.top || '',
            side: inputParams.side || '',
            filter: inputParams.filter || '',
            weight: inputParams.weight || '',
            sProps: {
              caseFilter: null,
              initAsMissing: false,
              excludeNE: false,
              padHierarchics: false,
              arithOverStats: false,
              topInsert: null,
              sideInsert: null,
              level: null,
              fullStats: false
            },
            dProps: {}
          };

          console.log('generateReport - Request Payload:', JSON.stringify(genTabRequest, null, 2));
          console.log('generateReport - Export Format:', exportFormat);

          result = await carbonClient.GenTab(genTabRequest, exportFormat);

          console.log('generateReport - Response:', result);

          if (exportFormat === 'JSON') {
            setResponse(JSON.stringify(result, null, 2));
          } else {
            setHtmlReport(result as string);
            setResponse(result as string);
          }
          break;
        case 'GenTabAsHTML':
          console.log('Calling GenTabAsHTML with params:', inputParams);
          result = await carbonClient.GenTabAsHTML(
            inputParams.top || '',
            inputParams.side || '',
            inputParams.flt || '',
            inputParams.wgt || '',
            inputParams.caseFilter || ''
          );
          console.log('GenTabAsHTML result:', result);
          if (Array.isArray(result)) {
            setHtmlReport(result.join('\n'));
          } else {
            setHtmlReport(result);
          }
          setResponse(null); // Clear the JSON response when showing HTML
          break;
        case 'AxisSyntaxToNodes':
          result = await carbonClient.AxisSyntaxToNodes(inputParams.syntax || '');
          break;
        case 'AxisNodesToSyntax':
          // Parse the input to create GenNode[]
          const nodes: GenNode[] = JSON.parse(inputParams.nodes || '[]');
          result = await carbonClient.AxisNodesToSyntax(nodes);
          break;
        case 'CurrentSyntax':
          result = await carbonClient.CurrentSyntax();
          break;
        case 'ValidateSyntax':
          result = await carbonClient.ValidateSyntax(inputParams.syntax || '');
          break;
        case 'endSession':
          if (!inputParams.sessionId) {
            throw new Error('Session ID is required to end a session');
          }
          await carbonClient.endSession(inputParams.sessionId);
          result = { message: `Session ${inputParams.sessionId} ended successfully` };
          break;
        case 'importFullJob':
          await handleFullImport();
          break;
        case 'importPartialJob':
          await handlePartialImport();
          break;
        case 'getReportFormat':
          result = await handleGetReportFormat();
          break;
        case 'checkJobUrls':
          await handleCheckJobUrls();
          break;
        case 'manageUserPermissions':
          await fetchUsers();
          setIsPermissionsModalOpen(true);
          break;
        case 'createTableList':
          try {
            if (!user) {
              throw new Error('User not authenticated');
            }

            const payload = {
              user_id: parseInt(user.userId, 10),
              list_name: inputParams.name,
              tables: inputParams.tables?.split(',').map(t => t.trim()) || []
            };
            
            console.log('Creating table list with URL:', getApiEndpoint('/table-lists'));
            console.log('Request payload:', payload);
            
            const response = await axios.post(getApiEndpoint('/table-lists'), payload, {
              headers: {
                'Content-Type': 'application/json'
              }
            });
            
            console.log('Response:', response.data);
            result = response.data;
          } catch (error) {
            console.error('Error creating table list:', error);
            if (axios.isAxiosError(error) && error.response) {
              console.error('Server response:', {
                status: error.response.status,
                statusText: error.response.statusText,
                data: error.response.data
              });
              throw new Error(error.response.data.detail || 'Failed to create table list');
            }
            throw new Error(`Failed to create table list: ${(error as Error).message}`);
          }
          break;
        case 'getTableList':
          try {
            const response = await axios.get(getApiEndpoint(`/table-lists/${inputParams.listId}`));
            result = response.data;
          } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
              throw new Error(error.response.data.detail || 'Failed to get table list');
            }
            throw new Error(`Failed to get table list: ${(error as Error).message}`);
          }
          break;
        case 'getUserTableLists':
          try {
            const response = await axios.get(getApiEndpoint(`/table-lists/user/${user?.userId}`));
            result = response.data;
          } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
              throw new Error(error.response.data.detail || 'Failed to get user table lists');
            }
            throw new Error(`Failed to get user table lists: ${(error as Error).message}`);
          }
          break;
        case 'updateTableList':
          try {
            if (!user) {
              throw new Error('User not authenticated');
            }
            
            const payload = {
              user_id: parseInt(user.userId, 10),
              list_name: inputParams.name,
              tables: inputParams.tables?.split(',').map(t => t.trim()) || []
            };
            
            const response = await axios.put(getApiEndpoint(`/table-lists/${inputParams.listId}`), payload);
            result = response.data;
          } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
              console.error('Server response:', {
                status: error.response.status,
                statusText: error.response.statusText,
                data: error.response.data
              });
              throw new Error(error.response.data.detail || 'Failed to update table list');
            }
            throw new Error(`Failed to update table list: ${(error as Error).message}`);
          }
          break;
        case 'deleteTableList':
          try {
            const response = await axios.delete(getApiEndpoint(`/table-lists/${inputParams.listId}`));
            result = response.data;
          } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
              throw new Error(error.response.data.detail || 'Failed to delete table list');
            }
            throw new Error(`Failed to delete table list: ${(error as Error).message}`);
          }
          break;
        default:
          throw new Error('Method not implemented');
      }
      if (selectedMethod !== 'GenTabAsHTML') {
        setResponse(result);
      }
    } catch (error) {
      console.error('Error running method:', error);
      if (error instanceof Error) {
        setErrorMessage(`Error: ${error.message}`);
      } else if (typeof error === 'object' && error !== null) {
        setErrorMessage(`Error: ${JSON.stringify(error)}`);
      } else {
        setErrorMessage(`An unknown error occurred`);
      }
    }
  };

  const handleGetReportFormat = async () => {
    if (!carbonClient || !isAuthenticated) {
      setErrorMessage('Not authenticated or Carbon client not available');
      return;
    }

    const format = inputParams.format || 'HTML';

    if (!selectedReport) {
      setErrorMessage('Please select a table first');
      return;
    }

    try {
      const result = await carbonClient.getReportFormat(format);
      setResponse(result);
      console.log('Report format result:', result);
    } catch (error) {
      console.error('Error getting report format:', error);
      setErrorMessage(`Error getting report format: ${(error as Error).message}`);
    }
  };

  const handleCheckJobUrls = async () => {
    console.log('Starting handleCheckJobUrls');
    if (!carbonClient || !isAuthenticated) {
      console.error('Carbon client not available or not authenticated');
      setErrorMessage('Not authenticated or Carbon client not available');
      return;
    }

    try {
      console.log('Fetching customers');
      const customers = await carbonClient.getCustomers();
      console.log(`Fetched ${customers.length} customers`);
      const results: JobUrlCheckResult[] = [];

      for (const customer of customers) {
        console.log(`Fetching jobs for customer: ${customer.name}`);
        const jobs = await carbonClient.getJobs(customer.name);
        console.log(`Fetched ${jobs.length} jobs for customer: ${customer.name}`);
        
        for (const job of jobs) {
          try {
            const response = await axios.get(`${API_URL}/api/check-interview-data`, {
              params: {
                job_id: job.id
              }
            });

            if (response.status === 200 && response.data.hasData) {
              results.push({
                jobName: job.name,
                jobId: job.id,
                exists: response.data.exists,
                hasData: response.data.hasData,
                recordCount: response.data.recordCount,
                uniqueInterviewCount: response.data.uniqueInterviewCount,
                variableCount: response.data.variableCount,
                variableIds: response.data.variableIds
              });
            }
          } catch (error) {
            // Don't add jobs that cause an error to the results
            console.error(`Error checking interview data for job ${job.name}:`, error);
          }
        }
      }

      console.log('Final results:', results);
      setUrlCheckResults(results);
      setIsUrlCheckModalOpen(true);
    } catch (error) {
      console.error('Error in handleCheckJobUrls:', error);
      setErrorMessage(`Error checking job URLs: ${(error as Error).message}`);
    }
  };

  const fetchUsers = async () => {
    try {
      const userList = await licensingClient.getUsers();
      setUsers(userList);
      console.log('Loaded users:', userList);
    } catch (error) {
      console.error('Error fetching users:', error);
      setErrorMessage(`Error fetching users: ${(error as Error).message}`);
    }
  };

  const fetchUserPermissions = async (userId: string) => {
    try {
      if (!carbonClient) {
        throw new Error('Carbon client is not available');
      }

      // Find user in the existing list
      const user = users.find(u => u.id === userId);
      if (!user) {
        throw new Error('User not found');
      }
      setSelectedUser(user);
      console.log('Selected user:', user);

      // Get all available customers and jobs first
      const availableCustomers = await carbonClient.getCustomers();
      console.log('All available customers:', availableCustomers);
      setCustomers(availableCustomers);

      // Get all jobs for these customers
      const jobPromises = availableCustomers.map(customer => 
        carbonClient.getJobs(customer.name)
      );
      const jobLists = await Promise.all(jobPromises);
      const allJobs = jobLists.flat();
      console.log('All available jobs:', allJobs);
      setJobs(allJobs);

      // Get user's current permissions using the correct method
      const permissions = await licensingClient.getUserPermissions(userId);
      console.log('Loaded user permissions:', permissions);
      
      // Set the original permissions
      setOriginalPermissions({
        customers: permissions.customers,
        jobs: permissions.jobs
      });

      // Set the current selections
      setUserCustomers(permissions.customers);
      setUserJobs(permissions.jobs);

    } catch (error) {
      console.error('Error fetching user permissions:', error);
      setErrorMessage(`Error fetching user permissions: ${(error as Error).message}`);
    }
  };

  const handleCustomerChange = async (event: SelectChangeEvent<string[]>) => {
    const selectedCustomerIds = typeof event.target.value === 'string' ? [event.target.value] : event.target.value;
    setUserCustomers(selectedCustomerIds);
    
    try {
      if (!carbonClient) {
        throw new Error('Carbon client is not available');
      }

      // Get jobs for selected customers
      if (selectedCustomerIds.length > 0) {
        const selectedCustomers = customers.filter(c => selectedCustomerIds.includes(c.id));
        const jobPromises = selectedCustomers.map(customer => 
          carbonClient.getJobs(customer.name)
        );
        const jobLists = await Promise.all(jobPromises);
        const allJobs = jobLists.flat();
        setJobs(allJobs);

        // Keep only the jobs that were previously assigned and are still available
        const availableJobIds = allJobs.map(job => job.id);
        setUserJobs(prev => prev.filter(jobId => availableJobIds.includes(jobId)));
      } else {
        setJobs([]);
        setUserJobs([]);
      }
    } catch (error) {
      console.error('Error fetching jobs for selected customers:', error);
      setErrorMessage(`Error fetching jobs: ${(error as Error).message}`);
    }
  };

  const saveUserPermissions = async () => {
    if (!selectedUser) return;
    try {
      await Promise.all([
        licensingClient.connectUserToCustomer(selectedUser.id, userCustomers),
        licensingClient.connectUserToJobs(selectedUser.id, userJobs)
      ]);
      
      // Refresh the user's permissions after saving
      await fetchUserPermissions(selectedUser.id);
      
      setErrorMessage('Permissions updated successfully');
    } catch (error) {
      console.error('Error saving user permissions:', error);
      setErrorMessage(`Error saving user permissions: ${(error as Error).message}`);
    }
  };

  const PermissionsManagementModal = () => (
    <Dialog open={isPermissionsModalOpen} onClose={() => setIsPermissionsModalOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>Manage User Permissions</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          {/* User Selection */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Select User</InputLabel>
            <Select
              value={selectedUser?.id || ''}
              onChange={(e) => {
                const userId = e.target.value;
                if (userId) {
                  // Clear existing permissions before loading new ones
                  setUserCustomers([]);
                  setUserJobs([]);
                  setJobs([]);
                  setOriginalPermissions({ customers: [], jobs: [] });
                  fetchUserPermissions(userId);
                }
              }}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name} ({user.email})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedUser && (
            <>
              {/* User Info */}
              <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h6" gutterBottom>User Information</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography>Name: {selectedUser.name}</Typography>
                  <Typography>Email: {selectedUser.email}</Typography>
                  <Typography>Roles: {selectedUser.roles?.join(', ') || 'None'}</Typography>
                </Box>
              </Paper>

              {/* Customer Access Section */}
              <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h6" gutterBottom>Customer Access</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                  Original access: {originalPermissions.customers.length > 0 ? originalPermissions.customers.join(', ') : 'No customers assigned'}
                </Typography>
                <FormControl fullWidth>
                  <InputLabel>Select Customers</InputLabel>
                  <Select
                    multiple
                    value={userCustomers}
                    onChange={handleCustomerChange}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Typography key={value} component="span" sx={{ 
                            bgcolor: originalPermissions.customers.includes(value) ? 'primary.light' : 'success.light',
                            px: 1, 
                            py: 0.5, 
                            borderRadius: 1,
                            mr: 0.5
                          }}>
                            {value}
                          </Typography>
                        ))}
                      </Box>
                    )}
                  >
                    {customers.map((customer) => (
                      <MenuItem key={customer.id} value={customer.name}>
                        <Checkbox checked={userCustomers.indexOf(customer.name) > -1} />
                        <ListItemText 
                          primary={customer.displayName || customer.name}
                          secondary={originalPermissions.customers.includes(customer.name) ? '(Currently assigned)' : ''}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Paper>

              {/* Jobs Access Section */}
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Job Access</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                  Original access: {originalPermissions.jobs.length > 0 ? originalPermissions.jobs.join(', ') : 'No jobs assigned'}
                </Typography>
                {userCustomers.length > 0 ? (
                  <FormControl fullWidth>
                    <InputLabel>Select Jobs</InputLabel>
                    <Select
                      multiple
                      value={userJobs}
                      onChange={(e) => setUserJobs(typeof e.target.value === 'string' ? [e.target.value] : e.target.value)}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Typography key={value} component="span" sx={{ 
                              bgcolor: originalPermissions.jobs.includes(value) ? 'secondary.light' : 'success.light',
                              px: 1, 
                              py: 0.5, 
                              borderRadius: 1,
                              mr: 0.5
                            }}>
                              {value}
                            </Typography>
                          ))}
                        </Box>
                      )}
                    >
                      {jobs.map((job) => (
                        <MenuItem key={job.id} value={job.name}>
                          <Checkbox checked={userJobs.indexOf(job.name) > -1} />
                          <ListItemText 
                            primary={job.displayName || job.name}
                            secondary={originalPermissions.jobs.includes(job.name) ? '(Currently assigned)' : ''}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography color="textSecondary">
                    Please select at least one customer to manage job access.
                  </Typography>
                )}
              </Paper>

              {/* Changes Summary */}
              <Paper sx={{ p: 2, mt: 3 }}>
                <Typography variant="h6" gutterBottom>Changes Summary</Typography>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" color="primary">Customer Access Changes</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography variant="body2">
                      Added: {userCustomers.filter(customer => !originalPermissions.customers.includes(customer)).join(', ') || 'None'}
                    </Typography>
                    <Typography variant="body2">
                      Removed: {originalPermissions.customers.filter(customer => !userCustomers.includes(customer)).join(', ') || 'None'}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="subtitle1" color="secondary">Job Access Changes</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography variant="body2">
                      Added: {userJobs.filter(job => !originalPermissions.jobs.includes(job)).join(', ') || 'None'}
                    </Typography>
                    <Typography variant="body2">
                      Removed: {originalPermissions.jobs.filter(job => !userJobs.includes(job)).join(', ') || 'None'}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsPermissionsModalOpen(false)}>Cancel</Button>
        <Button 
          onClick={saveUserPermissions} 
          variant="contained" 
          color="primary"
          disabled={!selectedUser}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderHtmlReport = () => {
    if (htmlReport) {
      return (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">HTML Report</Typography>
          <div dangerouslySetInnerHTML={{ __html: htmlReport }} />
        </Paper>
      );
    }
    return null;
  };

  const renderSessionsList = () => {
    if (Array.isArray(response) && response.length > 0 && typeof response[0] === 'object' && 'sessionId' in response[0]) {
      return (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">Active Sessions</Typography>
          {(response as SessionStatus[]).map((session) => (
            <Box key={session.sessionId} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography>{`${session.userName} (${session.sessionId})`}</Typography>
              <Button 
                variant="outlined" 
                size="small" 
                sx={{ ml: 2 }}
                onClick={() => handleEndSession(session.sessionId)}
              >
                End Session
              </Button>
            </Box>
          ))}
        </Paper>
      );
    }
    return null;
  };

  const handleEndSession = async (sessionId: string) => {
    try {
      await carbonClient?.endSession(sessionId);
      setResponse((prev) => {
        if (Array.isArray(prev) && prev.length > 0 && typeof prev[0] === 'object' && 'sessionId' in prev[0]) {
          return (prev as SessionStatus[]).filter(session => session.sessionId !== sessionId);
        }
        return prev;
      });
      setErrorMessage(`Session ${sessionId} ended successfully`);
    } catch (error) {
      setErrorMessage(`Failed to end session ${sessionId}: ${(error as Error).message}`);
    }
  };

  const getTableNodes = (nodes: TocNode[]): TocNode[] => {
    const tableNodes: TocNode[] = [];
    const traverse = (node: TocNode) => {
      if (node.type === 'Table') {
        tableNodes.push(node);
      }
      if (node.children) {
        node.children.forEach(traverse);
      }
    };
    nodes.forEach(traverse);
    return tableNodes;
  };

  const handleTableSelect = async (node: TocNode) => {
    if (!carbonClient || !isAuthenticated) {
      setErrorMessage('Not authenticated or Carbon client not available');
      return;
    }

    try {
      const value1 = node.value1 || node.name;
      const value2 = node.value2 || '';
      if (!value1) {
        throw new Error('Report name not found');
      }

      const loadResponse = await carbonClient.LoadReportAsync(value1, value2);
      console.log('Load report response:', loadResponse);

      if (loadResponse.code !== 0) {
        throw new Error(`Failed to load report: ${loadResponse.message}`);
      }

      const reportSyntax = await carbonClient.getReportSyntax();
      console.log('Report syntax:', reportSyntax);

      if (reportSyntax.length >= 5) {
        setInputParams({
          ...inputParams,
          top: reportSyntax[0] || '',
          side: reportSyntax[1] || '',
          filter: reportSyntax[2] || '',
          weight: reportSyntax[3] || '',
          caseFilter: reportSyntax[4] || '',
        });
      }

      setSelectedReport(value1);
    } catch (error) {
      console.error('Error selecting table:', error);
      setErrorMessage(`Error selecting table: ${(error as Error).message}`);
    }
  };

  const renderCsvResponse = (csvData: string[]) => {
    if (!csvData || csvData.length === 0) return null;

    const metadata = csvData.slice(0, 6).map(line => {
      const [key, value] = line.split(':');
      return { key, value: value.trim() };
    });

    const headers = csvData[6].split(',');
    const rows = csvData.slice(7).map(row => row.split(','));

    return (
      <>
        <Box sx={{ mb: 2 }}>
          {metadata.map(({ key, value }, index) => (
            <Typography key={index}><strong>{key}:</strong> {value}</Typography>
          ))}
        </Box>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const renderResponse = () => {
    if (!response) return null;

    if (Array.isArray(response) && response.every(item => typeof item === 'string')) {
      // This is our CSV-like data
      return (
        <>
          <Typography variant="h6">Report Format Result</Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table size="small">
              <TableBody>
                {response.map((row, index) => {
                  const [key, ...values] = row.split(':');
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                        {key}
                      </TableCell>
                      <TableCell>{values.join(':').trim()}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }

    // For other types of responses, keep the existing rendering logic
    return (
      <>
        <Typography variant="h6">Response</Typography>
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
          {Array.isArray(response)
            ? response.map(item => 
                typeof item === 'object' 
                  ? JSON.stringify(item, null, 2) 
                  : String(item)
              ).join('\n')
            : typeof response === 'object'
            ? JSON.stringify(response, null, 2)
            : String(response)}
        </pre>
      </>
    );
  };

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 84px)',
      mt: '84px',
      overflow: 'hidden',
      pl: 2,
      pr: 3,
      py: 2,
    }}>
      {/* Top section - Method selection and parameters */}
      <Box sx={{ display: 'flex', mb: 2 }}>
        {/* Left sidebar - Method selection */}
        <Paper sx={{ 
          width: 260, 
          p: 2, 
          mr: 2,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant="h6" gutterBottom>Methods</Typography>
          <Select
            value={selectedMethod}
            onChange={handleMethodChange}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>Select a method</MenuItem>
            {methods.map(method => (
              <MenuItem key={method.name} value={method.name}>{method.name}</MenuItem>
            ))}
          </Select>
        </Paper>

        {/* Right side - Method parameters */}
        <Paper sx={{ flex: 1, p: 2, overflowY: 'auto' }}>
          {selectedMethod && (
            <>
              <Typography variant="h6">{selectedMethod}</Typography>
              {methods.find(m => m.name === selectedMethod)?.params.map(param => (
                param === 'customerName' ? (
                  <FormControl fullWidth key={param} margin="normal">
                    <InputLabel>{param}</InputLabel>
                    <Select
                      value={inputParams[param] || ''}
                      onChange={(e) => handleInputChange(param, e.target.value)}
                      label={param}
                    >
                      {customers.map(customer => (
                        <MenuItem key={customer.id} value={customer.name}>{customer.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : param === 'jobName' ? (
                  <FormControl fullWidth key={param} margin="normal">
                    <InputLabel>{param}</InputLabel>
                    <Select
                      value={inputParams[param] || ''}
                      onChange={(e) => handleInputChange(param, e.target.value)}
                      label={param}
                      disabled={!inputParams.customerName}
                    >
                      {jobs.map(job => (
                        <MenuItem key={job.id} value={job.name}>{job.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    key={param}
                    label={param}
                    value={inputParams[param] || ''}
                    onChange={(e) => handleInputChange(param, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                )
              ))}
              {selectedMethod === 'generateReport' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Export Format</InputLabel>
                  <Select
                    value={exportFormat}
                    onChange={handleExportFormatChange}
                    label="Export Format"
                  >
                    {exportFormats.map(format => (
                      <MenuItem key={format} value={format}>{format}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {selectedMethod === 'getReportFormat' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Format</InputLabel>
                  <Select
                    value={inputParams.format || ''}
                    onChange={(e) => handleInputChange('format', e.target.value)}
                    label="Format"
                  >
                    {reportFormatOptions.map((format) => (
                      <MenuItem key={format} value={format}>{format}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {selectedMethod === 'getReportFormat' && (
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Typography variant="subtitle1">Select a table:</Typography>
                  <Select
                    fullWidth
                    value={selectedReport || ''}
                    onChange={(e) => {
                      const selectedNode = getTableNodes(tocNodes).find(node => (node.value1 || node.name) === e.target.value);
                      if (selectedNode) {
                        handleTableSelect(selectedNode);
                      }
                    }}
                  >
                    {getTableNodes(tocNodes).map((node) => (
                      <MenuItem key={node.id} value={node.value1 || node.name}>
                        {node.value1 || node.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
              <Button variant="contained" onClick={handleRunMethod} sx={{ mt: 2 }}>
                Run Method
              </Button>
            </>
          )}
        </Paper>
      </Box>

      {/* Bottom section - Results */}
      <Paper sx={{ 
        flex: 1, 
        p: 2, 
        overflowY: 'auto', 
        display: 'flex',
        flexDirection: 'column'
      }}>
        {!isAuthenticated && (
          <Typography color="error">Please log in to use this feature.</Typography>
        )}
        {!isClientReady && (
          <Typography color="warning">Carbon client is initializing. Please wait...</Typography>
        )}
        {errorMessage && (
          <Paper sx={{ p: 2, mb: 2, bgcolor: 'error.light' }}>
            <Typography color="error" variant="h6">Error</Typography>
            <Typography color="error">{errorMessage}</Typography>
          </Paper>
        )}
        {renderResponse()}
        {renderHtmlReport()}
        {renderSessionsList()}
      </Paper>
      <PermissionsManagementModal />
      <Dialog open={isUrlCheckModalOpen} onClose={() => setIsUrlCheckModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Jobs with Interview Data</DialogTitle>
        <DialogContent>
          <List>
            {urlCheckResults.map((result, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`${result.jobName} (ID: ${result.jobId})`}
                  secondary={
                    <>
                      <Typography component="span" variant="body2">
                        Total interviews: {result.recordCount}<br/>
                        Unique interviews: {result.uniqueInterviewCount}<br/>
                        Number of variables: {result.variableCount}<br/>
                        Variable IDs: {result.variableIds && result.variableIds.length > 0 ? result.variableIds.join(', ') : 'None'}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsUrlCheckModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};